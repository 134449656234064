import React, { useEffect, useState, memo } from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';

import { Task } from '../Task/Task';
import { AddTaskButton, ColumnContainer, ColumnTitle, Container, TaskList } from './Column.style';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import {
  GetProjectTasksByWorkflowStepDocument,
  GetProjectTasksByWorkflowStepQuery,
  ProjectTask,
} from '../../../generated/graphql';
import { InitialData } from '../Dashboard';
import { DashboardModal } from '../DashboardModal/DashboardModal';

interface ColumnProps {
  column?: any;
  tasks?: { [key: string]: ProjectTask[] }[];
  index: number;
  state?: InitialData;
  setState?(state: InitialData): void;
  projectId: string;
}

const setColumnName = (name: string) => {
  switch (name) {
    case 'new':
      return 'новые';
    case 'inProgress':
      return 'в работе';
    case 'done':
      return 'выполнены';
    default:
      return name;
  }
};

export const Column = memo(({ column, tasks, index, state, setState, projectId }: ColumnProps) => {
  const [t] = useTranslation('translation');

  const [editedTask, setEditedTask] = useState('');
  const [isCreating, setCreating] = useState(false);
  const [isModal, setIsModal] = useState(false);

  const { loading, error, data } = useQuery<GetProjectTasksByWorkflowStepQuery>(GetProjectTasksByWorkflowStepDocument, {
    variables: {
      projectId: projectId,
      workflowStepId: column.id,
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    const tasksData = data?.getProjectTasksByWorkflowStep?.tasks?.length;

    if (!tasksData) {
      setState({
        ...state,
        tasks: { ...state.tasks },
        columns: { ...state.columns, [column.id]: { ...column, tasks: [] } },
      });
    }

    if (tasksData) {
      const tasksFromBack = data.getProjectTasksByWorkflowStep?.tasks;
      const typeTask = tasksFromBack.reduce((acc: { [key: string]: any }, task) => {
        const key = task.id;
        acc[key] = { id: task.id, name: task.name, photo: null };
        return acc;
      }, {});

      const taskIds = tasksFromBack.map(t => t.id);

      setState({
        ...state,
        tasks: { ...state.tasks, ...typeTask },
        columns: { ...state.columns, [column.id]: { ...column, tasks: taskIds } },
      });
    }
  }, [data?.getProjectTasksByWorkflowStep?.tasks?.length]);

  const getCloseHandler = (): void => {
    if (isCreating) {
      return setCreating(false);
    }

    return setEditedTask('');
  };

  return (
    <ColumnContainer>
      <Draggable draggableId={column.id} index={index}>
        {provided => (
          <Container {...provided.draggableProps} ref={provided.innerRef}>
            <ColumnTitle {...provided.dragHandleProps}>{setColumnName(column.name)}</ColumnTitle>

            <Droppable direction="vertical" droppableId={column.id} type="task">
              {(provided, snapshot) => (
                <TaskList ref={provided.innerRef} {...provided.droppableProps} isDraggingOver={snapshot.isDraggingOver}>
                  {tasks?.map((task: any, index: number) => (
                    <Task
                      key={task.id}
                      task={task}
                      index={index}
                      edit={() => {
                        setEditedTask(task.id);
                        setIsModal(true);
                      }}
                    />
                  ))}
                  {provided.placeholder}
                </TaskList>
              )}
            </Droppable>

            <AddTaskButton onClick={() => setIsModal(true)}>{t('add task')} +</AddTaskButton>
          </Container>
        )}
      </Draggable>
      {isModal && (
        <DashboardModal
          projectId={projectId}
          closeModal={() => {
            setIsModal(false);
            getCloseHandler();
          }}
          taskId={isCreating ? undefined : editedTask}
          workflowStepId={column.id}
          workflowStepOrder={state.columns[column.id].tasks?.length || 0}
        />
      )}
    </ColumnContainer>
  );
});
