import React from 'react';
import { NavLink, useParams } from 'react-router-dom';
import classNames from 'classnames';
import scheduleIcon from '../../accets/icons/schedule-icon.svg';
import scheduleBtn from '../../accets/icons/project-schedule-btn.svg';
import projectInformationIcon from '../../accets/icons/project-information-icon.svg';
import projectInformationBtn from '../../accets/icons/project-info-btn.svg';
import editsIcon from '../../accets/icons/edits-icon.svg';
import editsBtn from '../../accets/icons/edits-btn.svg';
import financesIcon from '../../accets/icons/finances-icon.svg';
import financesBtn from '../../accets/icons/finance-btn.svg';
import bellIcon from '../../accets/icons/bell-icon.svg';
import bellBtn from '../../accets/icons/bell-btn.svg';
import './Footer.scss';

interface IFooterProps {
  className: string;
  activeBtn: string;
  projectId: string;
}

export const Footer = ({ className, activeBtn, projectId }: IFooterProps) => {
  console.log('activeBtn', activeBtn);
  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    if (!projectId) e.preventDefault();
  };

  return (
    <footer className={className}>
      <NavLink
        onClick={handleClick}
        className={classNames({
          'footer-btn': true,
          'active-footer-btn': activeBtn === 'project-schedule',
        })}
        to={`/project/${projectId}/project-schedule`}
      >
        <img
          className={classNames({
            'footer-btn-icon-active': activeBtn === 'project-schedule',
            'footer-btn-icon': activeBtn !== 'project-schedule',
          })}
          src={activeBtn === 'project-schedule' ? scheduleBtn : scheduleIcon}
          alt="icon"
        />
      </NavLink>
      <NavLink
        onClick={handleClick}
        className={classNames({
          'footer-btn': true,
          'active-footer-btn': activeBtn === 'project-information',
        })}
        to={`/project/${projectId}/project-information`}
      >
        <img
          className={classNames({
            'footer-btn-icon-active': activeBtn === 'project-information',
            'footer-btn-icon': activeBtn !== 'project-information',
          })}
          src={activeBtn === 'project-information' ? projectInformationBtn : projectInformationIcon}
          alt="icon"
        />
      </NavLink>
      <NavLink
        onClick={handleClick}
        className={classNames({
          'footer-btn': true,
          'active-footer-btn': activeBtn === 'corrections',
        })}
        to={`/project/${projectId}/corrections`}
      >
        <img
          className={classNames({
            'footer-btn-icon-active': activeBtn === 'corrections',
            'footer-btn-icon': activeBtn !== 'corrections',
          })}
          src={activeBtn === 'corrections' ? editsBtn : editsIcon}
          alt="icon"
        />
      </NavLink>
      <NavLink
        onClick={handleClick}
        className={classNames({
          'footer-btn': true,
          'active-footer-btn': activeBtn === 'notifications',
        })}
        to={`/project/${projectId}/notifications`}
      >
        <img
          className={classNames({
            'footer-btn-icon-active': activeBtn === 'notifications',
            'footer-btn-icon': activeBtn !== 'notifications',
          })}
          src={activeBtn === 'notifications' ? bellBtn : bellIcon}
          alt="icon"
        />
      </NavLink>
      <NavLink
        onClick={handleClick}
        className={classNames({
          'footer-btn': true,
          'active-footer-btn': activeBtn === 'finances',
        })}
        to={`/project/${projectId}/finances`}
      >
        <img
          className={classNames({
            'footer-btn-icon-active': activeBtn === 'finances',
            'footer-btn-icon': activeBtn !== 'finances',
          })}
          src={activeBtn === 'finances' ? financesBtn : financesIcon}
          alt="icon"
        />
      </NavLink>
    </footer>
  );
};
