import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};


export type ArBookUsersCountLight = {
  __typename?: 'ARBookUsersCountLight';
  role?: Maybe<UserRoleEnum>;
  count?: Maybe<Scalars['Int']>;
};

export type Ad = {
  __typename?: 'Ad';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  expireAt?: Maybe<Scalars['Date']>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export type AddedTasksByEpic = {
  __typename?: 'AddedTasksByEpic';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  assigneeName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  timeSpent?: Maybe<Scalars['Int']>;
  cost?: Maybe<Scalars['Int']>;
};

export type AddedTasksEpic = {
  __typename?: 'AddedTasksEpic';
  epicKey?: Maybe<Scalars['String']>;
  epicName?: Maybe<Scalars['String']>;
  totalTimeSpent?: Maybe<Scalars['Int']>;
  cost?: Maybe<Scalars['Int']>;
};

export type AddedTasksReport = {
  __typename?: 'AddedTasksReport';
  projectId?: Maybe<Scalars['String']>;
  projectName?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
  timeSpent?: Maybe<Scalars['Int']>;
  cost?: Maybe<Scalars['Int']>;
};

export type AdditionalData = {
  __typename?: 'AdditionalData';
  reports?: Maybe<Array<Maybe<Report>>>;
  reportsByProjects?: Maybe<Array<Maybe<ReportByProjects>>>;
  mainPart?: Maybe<Scalars['Int']>;
  bonusesValue?: Maybe<Scalars['Int']>;
  kpiValue?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  hoursFact?: Maybe<Scalars['Int']>;
};

export type AndroidBundle = {
  __typename?: 'AndroidBundle';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  fileLink?: Maybe<Scalars['String']>;
  animation?: Maybe<AnimationBundle>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export type AnimationBundle = {
  __typename?: 'AnimationBundle';
  id?: Maybe<Scalars['String']>;
  type?: Maybe<AnimationBundleType>;
  nameEN?: Maybe<Scalars['String']>;
  nameUKR?: Maybe<Scalars['String']>;
  descriptionEN?: Maybe<Scalars['String']>;
  descriptionUKR?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  iosBundle?: Maybe<IosBundle>;
  androidBundle?: Maybe<AndroidBundle>;
  participants?: Maybe<Array<Maybe<User>>>;
  availableForEveryone?: Maybe<Scalars['Boolean']>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export type AnimationBundleType = {
  __typename?: 'AnimationBundleType';
  id?: Maybe<Scalars['Int']>;
  type?: Maybe<AnimationBundleTypeEnum>;
  displayName?: Maybe<Scalars['String']>;
};

export enum AnimationBundleTypeEnum {
  Mural = 'MURAL',
  AntonovMuseum = 'ANTONOV_MUSEUM',
  Demo = 'DEMO',
  Portfolio = 'PORTFOLIO'
}

export type Answer = {
  __typename?: 'Answer';
  id?: Maybe<Scalars['String']>;
  isRight?: Maybe<Scalars['Boolean']>;
  questionId?: Maybe<Scalars['String']>;
  question?: Maybe<Question>;
  variantId?: Maybe<Scalars['String']>;
  variant?: Maybe<Variant>;
  testAnswerId?: Maybe<Scalars['String']>;
  testAnswer?: Maybe<TestAnswer>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export type AppMainThemeBackground = {
  __typename?: 'AppMainThemeBackground';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  manifestIOS?: Maybe<Scalars['String']>;
  manifestAndroid?: Maybe<Scalars['String']>;
  fileIOS?: Maybe<Scalars['String']>;
  fileAndroid?: Maybe<Scalars['String']>;
  themes?: Maybe<Array<Maybe<ApplicationMainTheme>>>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export enum AppMainThemeBackgroundFileTypeEnum {
  Manifest = 'MANIFEST',
  Bundle = 'BUNDLE'
}

export type Application = {
  __typename?: 'Application';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  privacyPolicy?: Maybe<Scalars['String']>;
  userAgreement?: Maybe<Scalars['String']>;
  appStoreLink?: Maybe<Scalars['String']>;
  playMarketLink?: Maybe<Scalars['String']>;
  mainPhoto?: Maybe<Scalars['String']>;
  modelPhoto?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  publishedAt?: Maybe<Scalars['Date']>;
  links?: Maybe<Array<Maybe<ApplicationLink>>>;
  mains?: Maybe<Array<Maybe<ApplicationMain>>>;
  notifications?: Maybe<Array<Maybe<MobileNotification>>>;
  versions?: Maybe<Array<Maybe<ApplicationVersion>>>;
  smartNotifications?: Maybe<Array<Maybe<SmartNotification>>>;
  users?: Maybe<Array<Maybe<User>>>;
  frontLink?: Maybe<Scalars['String']>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export enum ApplicationBundleTypeEnum {
  Bundle = 'BUNDLE',
  BundleLink = 'BUNDLE_LINK',
  Photo = 'PHOTO'
}

export enum ApplicationFileTypeEnum {
  Photo = 'PHOTO',
  PrivacyPolicy = 'PRIVACY_POLICY',
  UserAgreement = 'USER_AGREEMENT'
}

export type ApplicationLink = {
  __typename?: 'ApplicationLink';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  applicationId?: Maybe<Scalars['String']>;
  application?: Maybe<Application>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export type ApplicationMain = {
  __typename?: 'ApplicationMain';
  id?: Maybe<Scalars['String']>;
  nameEN?: Maybe<Scalars['String']>;
  nameUKR?: Maybe<Scalars['String']>;
  descriptionEN?: Maybe<Scalars['String']>;
  descriptionUKR?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  background1?: Maybe<Scalars['String']>;
  background2?: Maybe<Scalars['String']>;
  background3?: Maybe<Scalars['String']>;
  background4?: Maybe<Scalars['String']>;
  background5?: Maybe<Scalars['String']>;
  isMap?: Maybe<Scalars['Boolean']>;
  availableFor?: Maybe<ApplicationMainAvailableForEnum>;
  participants?: Maybe<Array<Maybe<User>>>;
  qr?: Maybe<Scalars['String']>;
  qrCustom?: Maybe<Scalars['String']>;
  applicationId?: Maybe<Scalars['String']>;
  application?: Maybe<Application>;
  models?: Maybe<Array<Maybe<ApplicationMainModel>>>;
  lessons?: Maybe<Array<Maybe<Lesson>>>;
  isPublished?: Maybe<Scalars['Boolean']>;
  publishedAt?: Maybe<Scalars['Date']>;
  city?: Maybe<ApplicationMainCityEnum>;
  priority?: Maybe<Scalars['Int']>;
  display?: Maybe<Scalars['Boolean']>;
  themes?: Maybe<Array<Maybe<ApplicationMainTheme>>>;
  tests?: Maybe<Array<Maybe<Test>>>;
  teachers?: Maybe<Array<Maybe<User>>>;
  showInSubjects?: Maybe<Scalars['Boolean']>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export enum ApplicationMainAvailableForEnum {
  All = 'ALL',
  Clients = 'CLIENTS',
  Qr = 'QR'
}

export enum ApplicationMainCityEnum {
  Kharkiv = 'Kharkiv',
  NotKharkiv = 'NotKharkiv'
}

export type ApplicationMainModel = {
  __typename?: 'ApplicationMainModel';
  id?: Maybe<Scalars['String']>;
  nameEN?: Maybe<Scalars['String']>;
  nameUKR?: Maybe<Scalars['String']>;
  descriptionEN?: Maybe<Scalars['String']>;
  descriptionUKR?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  isContour?: Maybe<Scalars['Boolean']>;
  mapMarkerPhoto?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  isBundle?: Maybe<Scalars['Boolean']>;
  bundleType?: Maybe<ApplicationBundleTypeEnum>;
  bundlePhoto?: Maybe<Scalars['String']>;
  bundleLink?: Maybe<Scalars['String']>;
  bundles?: Maybe<Array<Maybe<ApplicationMainModelFile>>>;
  answers?: Maybe<Array<Maybe<ApplicationMainModelFile>>>;
  tests?: Maybe<Array<Maybe<ApplicationMainModelFile>>>;
  variants?: Maybe<Array<Maybe<ApplicationMainModelFile>>>;
  iosBundle?: Maybe<Scalars['String']>;
  androidBundle?: Maybe<Scalars['String']>;
  isMarker?: Maybe<Scalars['Boolean']>;
  markerPhoto?: Maybe<Scalars['String']>;
  mainId?: Maybe<Scalars['String']>;
  main?: Maybe<ApplicationMain>;
  isMap?: Maybe<Scalars['Boolean']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  markerWidth?: Maybe<Scalars['Float']>;
  publishedAt?: Maybe<Scalars['Date']>;
  lessonId?: Maybe<Scalars['String']>;
  lesson?: Maybe<Lesson>;
  themeId?: Maybe<Scalars['String']>;
  theme?: Maybe<ApplicationMainTheme>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export type ApplicationMainModelFile = {
  __typename?: 'ApplicationMainModelFile';
  id?: Maybe<Scalars['String']>;
  type?: Maybe<ApplicationMainModelFileTypeEnum>;
  language?: Maybe<ApplicationMainModelFileLangEnum>;
  file?: Maybe<Scalars['String']>;
  manifest?: Maybe<Scalars['String']>;
  modelId?: Maybe<Scalars['String']>;
  model?: Maybe<ApplicationMainModel>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export enum ApplicationMainModelFileLangEnum {
  En = 'EN',
  Ukr = 'UKR'
}

export enum ApplicationMainModelFileTypeEnum {
  Ios = 'IOS',
  Android = 'ANDROID'
}

export type ApplicationMainTheme = {
  __typename?: 'ApplicationMainTheme';
  id?: Maybe<Scalars['String']>;
  nameEN?: Maybe<Scalars['String']>;
  nameUKR?: Maybe<Scalars['String']>;
  applicationMainId?: Maybe<Scalars['String']>;
  applicationMain?: Maybe<ApplicationMain>;
  applicationMainModels?: Maybe<Array<Maybe<ApplicationMainModel>>>;
  lessons?: Maybe<Array<Maybe<Lesson>>>;
  tests?: Maybe<Array<Maybe<ApplicationMainModelFile>>>;
  photo?: Maybe<Scalars['String']>;
  photoName?: Maybe<Scalars['String']>;
  background?: Maybe<AppMainThemeBackground>;
  backgroundId?: Maybe<Scalars['String']>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export type ApplicationVersion = {
  __typename?: 'ApplicationVersion';
  id?: Maybe<Scalars['String']>;
  os?: Maybe<Os>;
  version?: Maybe<Scalars['String']>;
  appId?: Maybe<Scalars['String']>;
  app?: Maybe<Application>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export type AssigneeProblems = {
  __typename?: 'AssigneeProblems';
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  assigneeJiraId?: Maybe<Scalars['String']>;
};

export type Auth = {
  __typename?: 'Auth';
  token?: Maybe<Scalars['String']>;
};

export enum AvailableForEnum {
  All = 'ALL',
  Clients = 'CLIENTS',
  Staff = 'STAFF',
  Teachers = 'TEACHERS'
}

export enum BaseTaskLabelsEnum {
  Bug = 'bug',
  AdditionalTask = 'additionalTask'
}

export enum BaseTaskStatusesEnum {
  New = 'new',
  InProgress = 'inProgress',
  Done = 'done'
}

export enum BaseWorkflowStepsEnum {
  New = 'new',
  InProgress = 'inProgress',
  Done = 'done'
}

export type BugsAdditionalTasksReport = {
  __typename?: 'BugsAdditionalTasksReport';
  name?: Maybe<Scalars['String']>;
  tasks?: Maybe<Scalars['Int']>;
  percents?: Maybe<Scalars['Int']>;
  cost?: Maybe<Scalars['Int']>;
};

export enum BundleFileTypeEnum {
  IosBundle = 'IOS_BUNDLE',
  AndroidBundle = 'ANDROID_BUNDLE'
}

export enum CacheControlScope {
  Public = 'PUBLIC',
  Private = 'PRIVATE'
}

export type ChartData = {
  __typename?: 'ChartData';
  minStart?: Maybe<Scalars['String']>;
  maxFinish?: Maybe<Scalars['String']>;
  data?: Maybe<Array<Maybe<ChartTaskData>>>;
};

export type ChartData2 = {
  __typename?: 'ChartData2';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
  progress?: Maybe<Scalars['Int']>;
  type?: Maybe<ChartData2Types>;
  project?: Maybe<Scalars['String']>;
  isClient?: Maybe<Scalars['Boolean']>;
  isAdditionalTask?: Maybe<Scalars['Boolean']>;
};

export enum ChartData2Types {
  Project = 'project',
  Task = 'task'
}

export type ChartTaskData = {
  __typename?: 'ChartTaskData';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  startDateEstimate?: Maybe<Scalars['String']>;
  startDateFact?: Maybe<Scalars['String']>;
  finishDateEstimate?: Maybe<Scalars['String']>;
  finishDateFact?: Maybe<Scalars['String']>;
};

export type City = {
  __typename?: 'City';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type Classroom = {
  __typename?: 'Classroom';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  teacherId?: Maybe<Scalars['String']>;
  teacher?: Maybe<User>;
  tests?: Maybe<Array<Maybe<Test>>>;
  pupils?: Maybe<Array<Maybe<User>>>;
  educationPlans?: Maybe<Array<Maybe<EducationPlan>>>;
  isArchived?: Maybe<Scalars['Boolean']>;
  archivedDate?: Maybe<Scalars['Date']>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export type ClientSite = {
  __typename?: 'ClientSite';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  iframe?: Maybe<Scalars['String']>;
  models?: Maybe<Array<Maybe<ClientSiteModel>>>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export type ClientSiteModel = {
  __typename?: 'ClientSiteModel';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  itemId?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<ClientSiteModelItem>>>;
  glbFile?: Maybe<Scalars['String']>;
  usdzFile?: Maybe<Scalars['String']>;
  gltfFile?: Maybe<Scalars['String']>;
  clientSiteId?: Maybe<Scalars['String']>;
  clientSite?: Maybe<ClientSite>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export enum ClientSiteModelFilesEnum {
  GlbFile = 'glbFile',
  UsdzFile = 'usdzFile',
  GltfFile = 'gltfFile'
}

export type ClientSiteModelItem = {
  __typename?: 'ClientSiteModelItem';
  id?: Maybe<Scalars['String']>;
  itemId?: Maybe<Scalars['String']>;
  clientSiteModelId?: Maybe<Scalars['String']>;
  clientSiteModel?: Maybe<ClientSiteModel>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export type CompletionPctReport = {
  __typename?: 'CompletionPctReport';
  projectId?: Maybe<Scalars['String']>;
  projectName?: Maybe<Scalars['String']>;
  prc?: Maybe<Array<Maybe<Prc>>>;
};

export type Contract = {
  __typename?: 'Contract';
  id?: Maybe<Scalars['String']>;
  trackerContractId?: Maybe<Scalars['Int']>;
  projectId?: Maybe<Scalars['String']>;
  project?: Maybe<Project>;
  userId?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
};

export type CorrectionComment = {
  __typename?: 'CorrectionComment';
  id?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  correction?: Maybe<ProjectCorrection>;
  files?: Maybe<Array<Maybe<UploadedFile>>>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export type CorrectionsLight = {
  __typename?: 'CorrectionsLight';
  status?: Maybe<ProjectCorrectionStatusEnum>;
  count?: Maybe<Scalars['Int']>;
};

export type CostReport = {
  __typename?: 'CostReport';
  projectId?: Maybe<Scalars['String']>;
  projectName?: Maybe<Scalars['String']>;
  totalEstimate?: Maybe<Scalars['Int']>;
  costEstimate?: Maybe<Scalars['Int']>;
  cost?: Maybe<Scalars['Int']>;
  difference?: Maybe<Scalars['Int']>;
};

export type CreateAnimationBundleInput = {
  typeId?: Maybe<Scalars['Int']>;
  nameEN?: Maybe<Scalars['String']>;
  nameUKR?: Maybe<Scalars['String']>;
  descriptionEN?: Maybe<Scalars['String']>;
  descriptionUKR?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['String']>;
};

export type CreateApplicationInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  appStoreLink?: Maybe<Scalars['String']>;
  playMarketLink?: Maybe<Scalars['String']>;
  mainPhoto?: Maybe<Scalars['String']>;
  modelPhoto?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  frontLink?: Maybe<Scalars['String']>;
};

export type CreateApplicationLinkInput = {
  name?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  applicationId?: Maybe<Scalars['String']>;
};

export type CreateApplicationMainInput = {
  nameEN?: Maybe<Scalars['String']>;
  nameUKR?: Maybe<Scalars['String']>;
  descriptionEN?: Maybe<Scalars['String']>;
  descriptionUKR?: Maybe<Scalars['String']>;
  background1?: Maybe<Scalars['String']>;
  background2?: Maybe<Scalars['String']>;
  background3?: Maybe<Scalars['String']>;
  background4?: Maybe<Scalars['String']>;
  background5?: Maybe<Scalars['String']>;
  isMap?: Maybe<Scalars['Boolean']>;
  applicationId: Scalars['String'];
  display?: Maybe<Scalars['Boolean']>;
  city?: Maybe<ApplicationMainCityEnum>;
  priority?: Maybe<Scalars['Int']>;
  showInSubjects?: Maybe<Scalars['Boolean']>;
};

export type CreateApplicationMainModelFileInput = {
  modelId: Scalars['String'];
  type: ApplicationMainModelFileTypeEnum;
  language: ApplicationMainModelFileLangEnum;
  file: Scalars['Upload'];
  manifest?: Maybe<Scalars['Upload']>;
};

export type CreateApplicationMainModelInput = {
  mainId: Scalars['String'];
  nameEN?: Maybe<Scalars['String']>;
  nameUKR?: Maybe<Scalars['String']>;
  descriptionEN?: Maybe<Scalars['String']>;
  descriptionUKR?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  isContour?: Maybe<Scalars['Boolean']>;
  themeId?: Maybe<Scalars['String']>;
};

export type CreateCorrectionCommentInput = {
  text?: Maybe<Scalars['String']>;
  correctionId?: Maybe<Scalars['String']>;
  files?: Maybe<Array<Maybe<Scalars['Upload']>>>;
};

export type CreateEmployeesDistribution = {
  name: Scalars['String'];
  projectId: Scalars['String'];
  employeesNumber?: Maybe<Scalars['Int']>;
};

export type CreateEventUserInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
  email: Scalars['String'];
  eventDateId: Scalars['String'];
  applicationId: Scalars['String'];
};

export type CreateExerciseInput = {
  content?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['Upload']>;
  pauseTime?: Maybe<Scalars['Int']>;
  type: ExerciseTypeEnum;
  traningId: Scalars['String'];
};

export type CreateMobileNotificationInput = {
  applicationId: Scalars['String'];
  nameEN?: Maybe<Scalars['String']>;
  nameUKR?: Maybe<Scalars['String']>;
  descriptionEN?: Maybe<Scalars['String']>;
  descriptionUKR?: Maybe<Scalars['String']>;
};

export type CreateNewsInput = {
  name: Scalars['String'];
  isFor: NewsIsForEnum;
  description?: Maybe<Scalars['String']>;
  youTubeLink?: Maybe<Scalars['String']>;
};

export type CreateNotificationInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type CreatePayrollKpiInput = {
  payrollId: Scalars['String'];
  name: Scalars['String'];
  unit?: Maybe<Scalars['String']>;
  weigh?: Maybe<Scalars['Float']>;
  plan?: Maybe<Scalars['Int']>;
  fact?: Maybe<Scalars['Int']>;
};

export type CreateProductInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  features?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId: Scalars['Int'];
  flex: Scalars['Int'];
  requiredKpi?: Maybe<Scalars['Int']>;
  requiredLevel?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
};

export type CreateProjectAdditionalContactInput = {
  name: Scalars['String'];
  phone: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  projectId: Scalars['String'];
};

export type CreateProjectCorrectionInput = {
  text: Scalars['String'];
  projectId: Scalars['String'];
  files?: Maybe<Array<Maybe<Scalars['Upload']>>>;
};

export type CreateProjectCustomFieldsMapInput = {
  projectId: Scalars['String'];
  startDateEstimate: Scalars['String'];
  startDateFact: Scalars['String'];
  finishDateEstimate: Scalars['String'];
  finishDateFact: Scalars['String'];
};

export type CreateProjectLinkInput = {
  title: Scalars['String'];
  link: Scalars['String'];
  projectId: Scalars['String'];
  login?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  isFor?: Maybe<IsForEnum>;
};

export type CreateProjectStepInput = {
  name: Scalars['String'];
  projectId: Scalars['String'];
  startDateEstimate?: Maybe<Scalars['String']>;
  startDateFact?: Maybe<Scalars['String']>;
  finishDateEstimate?: Maybe<Scalars['String']>;
  finishDateFact?: Maybe<Scalars['String']>;
  statusId?: Maybe<Scalars['String']>;
};

export type CreateProjectTaskInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  epicId: Scalars['String'];
  assigneeId?: Maybe<Scalars['String']>;
  responsibleId?: Maybe<Scalars['String']>;
  startDateEstimate?: Maybe<Scalars['String']>;
  startDateFact?: Maybe<Scalars['String']>;
  finishDateEstimate?: Maybe<Scalars['String']>;
  finishDateFact?: Maybe<Scalars['String']>;
  timeEstimate?: Maybe<Scalars['Int']>;
  timeSpent?: Maybe<Scalars['Int']>;
  taskStatusId?: Maybe<Scalars['String']>;
  workflowStepOrder?: Maybe<Scalars['Int']>;
  kanbanWorkflowStepId?: Maybe<Scalars['String']>;
};

export type CreateSchoolInput = {
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  cityId?: Maybe<Scalars['String']>;
  countryId?: Maybe<Scalars['String']>;
  regionId?: Maybe<Scalars['String']>;
  districtId?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type CreateSmartNotificationInput = {
  description: Scalars['String'];
  applicationId: Scalars['String'];
  type: SmartNotificationTypeEnum;
  link?: Maybe<Scalars['String']>;
};

export type CreateTestInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  modelId?: Maybe<Scalars['String']>;
  mainId?: Maybe<Scalars['String']>;
  themeId?: Maybe<Scalars['String']>;
  classroomId?: Maybe<Scalars['String']>;
  isOpenTest?: Maybe<Scalars['Boolean']>;
  attempts?: Maybe<Scalars['Int']>;
  time?: Maybe<Scalars['Int']>;
  class?: Maybe<TestClassEnum>;
  repeatAttemptTime?: Maybe<Scalars['Int']>;
};

export type CreateTraningInput = {
  name?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  statrTitle?: Maybe<Scalars['String']>;
  endTitle?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['Upload']>;
  startVideo?: Maybe<Scalars['Upload']>;
  endVideo?: Maybe<Scalars['Upload']>;
};

export type CreateUserGroupInput = {
  name?: Maybe<Scalars['String']>;
};

export type CreateUserInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  applicationId?: Maybe<Scalars['String']>;
  userRole?: Maybe<UserRoleEnum>;
  countryId?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['String']>;
  schoolId?: Maybe<Scalars['String']>;
  subjects?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CreateUserPaymentInput = {
  name: Scalars['String'];
  cost: Scalars['Int'];
  projectId: Scalars['String'];
  estimatedDate?: Maybe<Scalars['Date']>;
};

export type CreateUserPayrollInput = {
  userId: Scalars['String'];
  name: Scalars['Date'];
  hoursPlan: Scalars['Int'];
  firstPartSalary?: Maybe<Scalars['Int']>;
  secondPartSalary?: Maybe<Scalars['Int']>;
  salaryDescription?: Maybe<Scalars['String']>;
  kpiDescription?: Maybe<Scalars['String']>;
  bonusesDescription?: Maybe<Scalars['String']>;
  totalDescription?: Maybe<Scalars['String']>;
  bonusFund?: Maybe<Scalars['Int']>;
};

export type CreateVacanciesPlanInput = {
  userId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  jobId: Scalars['String'];
  positionId: Scalars['Int'];
  experience: Scalars['Float'];
  comment?: Maybe<Scalars['String']>;
};

export type CreatedTasksInTracker = {
  __typename?: 'CreatedTasksInTracker';
  count?: Maybe<Scalars['Int']>;
};

export type DashboardAddedTasksReport = {
  __typename?: 'DashboardAddedTasksReport';
  projectId?: Maybe<Scalars['String']>;
  projectName?: Maybe<Scalars['String']>;
  tasksCount?: Maybe<Scalars['Int']>;
};

export type DashboardCompletionPctReport = {
  __typename?: 'DashboardCompletionPctReport';
  projectId?: Maybe<Scalars['String']>;
  projectName?: Maybe<Scalars['String']>;
  pct?: Maybe<Scalars['Int']>;
};

export type DashboardInProgressTasksReport = {
  __typename?: 'DashboardInProgressTasksReport';
  projectId?: Maybe<Scalars['String']>;
  projectName?: Maybe<Scalars['String']>;
  tasksCount?: Maybe<Scalars['Int']>;
};

export type DashboardMasteringMoneyReport = {
  __typename?: 'DashboardMasteringMoneyReport';
  projectId?: Maybe<Scalars['String']>;
  projectName?: Maybe<Scalars['String']>;
  costFact?: Maybe<Scalars['Int']>;
  costEstimate?: Maybe<Scalars['Int']>;
  pct?: Maybe<Scalars['Int']>;
};

export type DashboardOverdueTasksReport = {
  __typename?: 'DashboardOverdueTasksReport';
  projectId?: Maybe<Scalars['String']>;
  projectName?: Maybe<Scalars['String']>;
  tasksCount?: Maybe<Scalars['Int']>;
};

export type DashboardUserWorkloadProjectsReport = {
  __typename?: 'DashboardUserWorkloadProjectsReport';
  projectName?: Maybe<Scalars['String']>;
  totalSeconds?: Maybe<Scalars['Int']>;
};

export type DashboardUserWorkloadReport = {
  __typename?: 'DashboardUserWorkloadReport';
  userId?: Maybe<Scalars['String']>;
  assigneeName?: Maybe<Scalars['String']>;
  pct?: Maybe<Scalars['Int']>;
};


export type DateEstimateProblems = {
  __typename?: 'DateEstimateProblems';
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  startDateEstimate?: Maybe<Scalars['String']>;
  finishDateEstimate?: Maybe<Scalars['String']>;
};

export type DateReport = {
  __typename?: 'DateReport';
  projectId?: Maybe<Scalars['String']>;
  projectName?: Maybe<Scalars['String']>;
  startDateEstimate?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
  startDifference?: Maybe<Scalars['Int']>;
  finishDateEstimate?: Maybe<Scalars['Date']>;
  finishDate?: Maybe<Scalars['Date']>;
  finishDifference?: Maybe<Scalars['Int']>;
};

export type DistributionAdditionalData = {
  __typename?: 'DistributionAdditionalData';
  employeesReport?: Maybe<Array<Maybe<EmployeesReport>>>;
  statusReport?: Maybe<Array<Maybe<StatusReport>>>;
};

export type DoneProblems = {
  __typename?: 'DoneProblems';
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  startDateEstimate?: Maybe<Scalars['String']>;
  finishDateEstimate?: Maybe<Scalars['String']>;
  startDateFact?: Maybe<Scalars['String']>;
  finishDateFact?: Maybe<Scalars['String']>;
  assigneeJiraId?: Maybe<Scalars['String']>;
};

export type EducationPlan = {
  __typename?: 'EducationPlan';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  multiLessons?: Maybe<Array<Maybe<MultiLesson>>>;
  classroomId?: Maybe<Scalars['String']>;
  classroom?: Maybe<Classroom>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export type EmployeeWorkload = {
  __typename?: 'EmployeeWorkload';
  date?: Maybe<Scalars['String']>;
  seconds?: Maybe<Scalars['Int']>;
};

export type EmployeeWorkloadDayReport = {
  __typename?: 'EmployeeWorkloadDayReport';
  projectName?: Maybe<Scalars['String']>;
  epicKey?: Maybe<Scalars['String']>;
  taskName?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  seconds?: Maybe<Scalars['Int']>;
};

export type EmployeeWorkloadReport = {
  __typename?: 'EmployeeWorkloadReport';
  projectName?: Maybe<Scalars['String']>;
  days?: Maybe<Scalars['Int']>;
  secondsPerDay?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['String']>;
};

export type EmployeeWorkloadReportByJobs = {
  __typename?: 'EmployeeWorkloadReportByJobs';
  jobName?: Maybe<Scalars['String']>;
  totalHours?: Maybe<Scalars['Int']>;
  totalDays?: Maybe<Scalars['Float']>;
  totalWeeks?: Maybe<Scalars['Float']>;
  totalMonth?: Maybe<Scalars['Float']>;
};

export type EmployeeWorkloadReportByProject = {
  __typename?: 'EmployeeWorkloadReportByProject';
  projectName?: Maybe<Scalars['String']>;
  report?: Maybe<Array<Maybe<EmployeeWorkload>>>;
};

export type EmployeeWorkloadReportByUser = {
  __typename?: 'EmployeeWorkloadReportByUser';
  userId?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  projectCount?: Maybe<Scalars['Int']>;
  totalHours?: Maybe<Scalars['Int']>;
  totalDays?: Maybe<Scalars['Float']>;
  totalWeeks?: Maybe<Scalars['Float']>;
  totalMonth?: Maybe<Scalars['Float']>;
};

export type EmployeesDistribution = {
  __typename?: 'EmployeesDistribution';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  projectName?: Maybe<Scalars['String']>;
  employeesNumber?: Maybe<Scalars['Int']>;
  projectType?: Maybe<ProjectType>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export type EmployeesDistributionLight = {
  __typename?: 'EmployeesDistributionLight';
  name?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
};

export type EmployeesReport = {
  __typename?: 'EmployeesReport';
  projectName?: Maybe<Scalars['String']>;
  employeesPercent?: Maybe<Scalars['Float']>;
};

export type Event = {
  __typename?: 'Event';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  dates?: Maybe<Array<Maybe<EventDate>>>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export type EventDate = {
  __typename?: 'EventDate';
  id?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  userLimit?: Maybe<Scalars['Int']>;
  eventId?: Maybe<Scalars['String']>;
  event?: Maybe<Event>;
  users?: Maybe<Array<Maybe<User>>>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export type Exercise = {
  __typename?: 'Exercise';
  id?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['String']>;
  pauseTime?: Maybe<Scalars['Int']>;
  type?: Maybe<ExerciseTypeEnum>;
  traningId?: Maybe<Scalars['String']>;
  traning?: Maybe<Traning>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export enum ExerciseTypeEnum {
  Exercise = 'EXERCISE',
  Pause = 'PAUSE'
}

export type Feedback = {
  __typename?: 'Feedback';
  id?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  status?: Maybe<FeedbackStatusEnum>;
  isFrom?: Maybe<FeedbackIsFromEnum>;
  comments?: Maybe<Array<Maybe<FeedbackComment>>>;
  files?: Maybe<Array<Maybe<UploadedFile>>>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export type FeedbackComment = {
  __typename?: 'FeedbackComment';
  id?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  feedback?: Maybe<Feedback>;
  files?: Maybe<Array<Maybe<UploadedFile>>>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export enum FeedbackIsFromEnum {
  LkStaff = 'LK_STAFF',
  LkTeacher = 'LK_TEACHER'
}

export enum FeedbackStatusEnum {
  New = 'NEW',
  Review = 'REVIEW',
  Discussion = 'DISCUSSION',
  Accepted = 'ACCEPTED',
  Done = 'DONE',
  Rejected = 'REJECTED'
}

export type FeedbacksLight = {
  __typename?: 'FeedbacksLight';
  status?: Maybe<FeedbackStatusEnum>;
  count?: Maybe<Scalars['Int']>;
};

export type GetAnimationBundles = {
  __typename?: 'GetAnimationBundles';
  animationBundles?: Maybe<Array<Maybe<AnimationBundle>>>;
  total?: Maybe<Scalars['Int']>;
};

export type GetAnimationBundlesInput = {
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  types?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type GetApplicationMainModels = {
  __typename?: 'GetApplicationMainModels';
  models?: Maybe<Array<Maybe<ApplicationMainModel>>>;
  total?: Maybe<Scalars['Int']>;
};

export type GetApplications = {
  __typename?: 'GetApplications';
  applications?: Maybe<Array<Maybe<Application>>>;
  total?: Maybe<Scalars['Int']>;
};

export type GetApplicationsMain = {
  __typename?: 'GetApplicationsMain';
  applicationsMain?: Maybe<Array<Maybe<ApplicationMain>>>;
  total?: Maybe<Scalars['Int']>;
};

export type GetClassrooms = {
  __typename?: 'GetClassrooms';
  classrooms?: Maybe<Array<Maybe<Classroom>>>;
  total?: Maybe<Scalars['Int']>;
};

export type GetClientSiteModels = {
  __typename?: 'GetClientSiteModels';
  models?: Maybe<Array<Maybe<ClientSiteModel>>>;
  total?: Maybe<Scalars['Int']>;
};

export type GetClientSites = {
  __typename?: 'GetClientSites';
  clientSites?: Maybe<Array<Maybe<ClientSite>>>;
  total?: Maybe<Scalars['Int']>;
};

export type GetCompletionPctReportByEpic = {
  __typename?: 'GetCompletionPctReportByEpic';
  tasks?: Maybe<Array<Maybe<TaskWithCompletionPct>>>;
  epicCompletionPct?: Maybe<Scalars['Float']>;
  projectCompletionPct?: Maybe<Scalars['Float']>;
};

export type GetCostReportByEpic = {
  __typename?: 'GetCostReportByEpic';
  tasks?: Maybe<Array<Maybe<TaskWithCost>>>;
  totalCostEstimate?: Maybe<Scalars['Int']>;
  totalCost?: Maybe<Scalars['Int']>;
  totalDifference?: Maybe<Scalars['Int']>;
};

export type GetDateReportByEpic = {
  __typename?: 'GetDateReportByEpic';
  tasks?: Maybe<Array<Maybe<TaskWithDate>>>;
  totalStartDifference?: Maybe<Scalars['Int']>;
  totalFinishDifference?: Maybe<Scalars['Int']>;
};

export type GetEducationPlans = {
  __typename?: 'GetEducationPlans';
  educationPlans?: Maybe<Array<Maybe<EducationPlan>>>;
  total?: Maybe<Scalars['Int']>;
};

export type GetEmployeesDistributionsByName = {
  __typename?: 'GetEmployeesDistributionsByName';
  distributions?: Maybe<Array<Maybe<EmployeesDistribution>>>;
  additionalData?: Maybe<DistributionAdditionalData>;
};

export type GetEpicsProgressReport = {
  __typename?: 'GetEpicsProgressReport';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  finishDate?: Maybe<Scalars['Date']>;
  lateStart?: Maybe<Scalars['Int']>;
  progress?: Maybe<Scalars['Int']>;
  lateEnd?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type GetEvents = {
  __typename?: 'GetEvents';
  events?: Maybe<Array<Maybe<Event>>>;
  total?: Maybe<Scalars['Int']>;
};

export type GetExercises = {
  __typename?: 'GetExercises';
  exercises?: Maybe<Array<Maybe<Exercise>>>;
  total?: Maybe<Scalars['Int']>;
};

export type GetKnowledgeBaseCategorys = {
  __typename?: 'GetKnowledgeBaseCategorys';
  categories?: Maybe<Array<Maybe<KnowledgeBaseCategory>>>;
  total?: Maybe<Scalars['Int']>;
};

export type GetKnowledgeBaseCategorysByParticipant = {
  __typename?: 'GetKnowledgeBaseCategorysByParticipant';
  categories?: Maybe<Array<Maybe<KnowledgeBaseCategoryByParticipant>>>;
  total?: Maybe<Scalars['Int']>;
};

export type GetKnowledgeBaseTestHistory = {
  __typename?: 'GetKnowledgeBaseTestHistory';
  user?: Maybe<User>;
  answers?: Maybe<Array<Maybe<KnowledgeBaseBlockTestAnswer>>>;
};

export type GetLessonPages = {
  __typename?: 'GetLessonPages';
  lessonPages?: Maybe<Array<Maybe<LessonPage>>>;
  total?: Maybe<Scalars['Int']>;
};

export type GetLessons = {
  __typename?: 'GetLessons';
  lessons?: Maybe<Array<Maybe<Lesson>>>;
  total?: Maybe<Scalars['Int']>;
};

export type GetLessonsParams = {
  mainId?: Maybe<Scalars['String']>;
  themeId?: Maybe<Scalars['String']>;
  modelId?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type GetMobileNotifications = {
  __typename?: 'GetMobileNotifications';
  notifications?: Maybe<Array<Maybe<MobileNotification>>>;
  total?: Maybe<Scalars['Int']>;
};

export type GetMobileNotificationsByParticipant = {
  __typename?: 'GetMobileNotificationsByParticipant';
  notifications?: Maybe<Array<Maybe<MobileNotificationByParticipant>>>;
  total?: Maybe<Scalars['Int']>;
};

export type GetMultiLessonBlocks = {
  __typename?: 'GetMultiLessonBlocks';
  multiLessonBlocks?: Maybe<Array<Maybe<MultiLessonBlock>>>;
  total?: Maybe<Scalars['Int']>;
};

export type GetMultiLessons = {
  __typename?: 'GetMultiLessons';
  multiLessons?: Maybe<Array<Maybe<MultiLesson>>>;
  total?: Maybe<Scalars['Int']>;
};

export type GetNews = {
  __typename?: 'GetNews';
  news?: Maybe<Array<Maybe<News>>>;
  total?: Maybe<Scalars['Int']>;
};

export type GetNewsByParticipant = {
  __typename?: 'GetNewsByParticipant';
  news?: Maybe<Array<Maybe<NewsByParticipant>>>;
  total?: Maybe<Scalars['Int']>;
};

export type GetNotifications = {
  __typename?: 'GetNotifications';
  notifications?: Maybe<Array<Maybe<Notification>>>;
  total?: Maybe<Scalars['Int']>;
};

export type GetNotificationsByParticipant = {
  __typename?: 'GetNotificationsByParticipant';
  notifications?: Maybe<Array<Maybe<NotificationByParticipant>>>;
  total?: Maybe<Scalars['Int']>;
};

export type GetProjectTasksByWorkflowStep = {
  __typename?: 'GetProjectTasksByWorkflowStep';
  tasks?: Maybe<Array<Maybe<ProjectTask>>>;
  total?: Maybe<Scalars['Int']>;
};

export type GetProjects = {
  __typename?: 'GetProjects';
  projects?: Maybe<Array<Maybe<Project>>>;
  total?: Maybe<Scalars['Int']>;
};

export type GetProjectsInput = {
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  statuses?: Maybe<Array<Maybe<Scalars['String']>>>;
  searchKey?: Maybe<Scalars['String']>;
};

export type GetPupilsAnswersByClassroom = {
  __typename?: 'GetPupilsAnswersByClassroom';
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  pupilId?: Maybe<Scalars['String']>;
  attempts?: Maybe<Scalars['Int']>;
  createdDate?: Maybe<Scalars['Date']>;
  rightQuestions?: Maybe<Scalars['Int']>;
  questions?: Maybe<Scalars['Int']>;
  testAnswerId?: Maybe<Scalars['String']>;
};

export type GetPurchases = {
  __typename?: 'GetPurchases';
  purchases?: Maybe<Array<Maybe<Purchase>>>;
  total?: Maybe<Scalars['Int']>;
};

export type GetPurchasesByUserInput = {
  userId?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  statuses?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type GetPurchasesInput = {
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  statuses?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type GetQuestions = {
  __typename?: 'GetQuestions';
  questions?: Maybe<Array<Maybe<Question>>>;
  total?: Maybe<Scalars['Int']>;
};

export type GetSchools = {
  __typename?: 'GetSchools';
  schools?: Maybe<Array<Maybe<School>>>;
  total?: Maybe<Scalars['Int']>;
};

export type GetSmartNotificationAnswers = {
  __typename?: 'GetSmartNotificationAnswers';
  total?: Maybe<Scalars['Int']>;
  answers?: Maybe<Array<Maybe<SmartNotificationAnswersWithPercents>>>;
};

export type GetSmartNotifications = {
  __typename?: 'GetSmartNotifications';
  notifications?: Maybe<Array<Maybe<SmartNotification>>>;
  total?: Maybe<Scalars['Int']>;
};

export type GetTests = {
  __typename?: 'GetTests';
  tests?: Maybe<Array<Maybe<Test>>>;
  total?: Maybe<Scalars['Int']>;
};

export type GetTestsAnswersByPupil = {
  __typename?: 'GetTestsAnswersByPupil';
  createdDate?: Maybe<Scalars['Date']>;
  answers?: Maybe<Array<Maybe<Answer>>>;
};

export type GetTimeReportByEpic = {
  __typename?: 'GetTimeReportByEpic';
  tasks?: Maybe<Array<Maybe<TaskWithTime>>>;
  totalTimeEstimate?: Maybe<Scalars['Int']>;
  totalTimeSpent?: Maybe<Scalars['Int']>;
};

export type GetTranings = {
  __typename?: 'GetTranings';
  tranings?: Maybe<Array<Maybe<Traning>>>;
  total?: Maybe<Scalars['Int']>;
};

export type GetUserExpHistoryByUser = {
  __typename?: 'GetUserExpHistoryByUser';
  userExpHistory?: Maybe<Array<Maybe<UserExpHistory>>>;
  total?: Maybe<Scalars['Int']>;
};

export type GetUserPayments = {
  __typename?: 'GetUserPayments';
  payments?: Maybe<Array<Maybe<UserPayment>>>;
  total?: Maybe<Scalars['Int']>;
};

export type GetUsers = {
  __typename?: 'GetUsers';
  users?: Maybe<Array<Maybe<User>>>;
  total?: Maybe<Scalars['Int']>;
};

export type GetUsersInput = {
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  roles?: Maybe<Array<Maybe<UserRoleEnum>>>;
  statuses?: Maybe<Array<Maybe<Scalars['String']>>>;
  searchKey?: Maybe<Scalars['String']>;
  applicationId?: Maybe<Scalars['String']>;
};

export type HandleAccessInput = {
  applicationMainId: Scalars['String'];
  availableFor: ApplicationMainAvailableForEnum;
  participantsIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  qrOptions?: Maybe<QrOptions>;
};

export type ImportTasksFromGSheetResult = {
  __typename?: 'ImportTasksFromGSheetResult';
  epicsCount?: Maybe<Scalars['Int']>;
  tasksCount?: Maybe<Scalars['Int']>;
};

export type Invitation = {
  __typename?: 'Invitation';
  id?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
};

export type IosBundle = {
  __typename?: 'IosBundle';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  fileLink?: Maybe<Scalars['String']>;
  animation?: Maybe<AnimationBundle>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export enum IsForEnum {
  Client = 'CLIENT',
  Staff = 'STAFF',
  ClientAndStaff = 'CLIENT_AND_STAFF'
}

export type Issue = {
  __typename?: 'Issue';
  expand?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  self?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  fields?: Maybe<IssueFields>;
};

export type IssueFields = {
  __typename?: 'IssueFields';
  summary?: Maybe<Scalars['String']>;
  duedate?: Maybe<Scalars['String']>;
  customfield_10015?: Maybe<Scalars['String']>;
};

export type JiraProject = {
  __typename?: 'JiraProject';
  id?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
};

export type KanbanWorkflowStep = {
  __typename?: 'KanbanWorkflowStep';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  taskStatusId?: Maybe<Scalars['String']>;
  taskStatus?: Maybe<ProjectTaskStatus>;
  isBase?: Maybe<Scalars['Boolean']>;
  isMutable?: Maybe<Scalars['Boolean']>;
  projectId?: Maybe<Scalars['String']>;
  project?: Maybe<Project>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
  deletedDate?: Maybe<Scalars['Date']>;
};

export type KnowledgeBaseBlock = {
  __typename?: 'KnowledgeBaseBlock';
  id?: Maybe<Scalars['String']>;
  type?: Maybe<KnowledgeBaseBlockTypeEnum>;
  theme?: Maybe<KnowledgeBaseTheme>;
  themeId?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['String']>;
  lists?: Maybe<Array<Maybe<KnowledgeBaseBlockList>>>;
  testQuestion?: Maybe<Scalars['String']>;
  testAnswers?: Maybe<Array<Maybe<KnowledgeBaseBlockTestAnswer>>>;
  testsHistory?: Maybe<Array<Maybe<KnowledgeBaseBlockTestHistory>>>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export type KnowledgeBaseBlockList = {
  __typename?: 'KnowledgeBaseBlockList';
  id?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  isNumbered?: Maybe<Scalars['Boolean']>;
  blockId?: Maybe<Scalars['String']>;
  block?: Maybe<KnowledgeBaseBlock>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export type KnowledgeBaseBlockTestAnswer = {
  __typename?: 'KnowledgeBaseBlockTestAnswer';
  id?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  isAnswerTrue?: Maybe<Scalars['Boolean']>;
  blockId?: Maybe<Scalars['String']>;
  block?: Maybe<KnowledgeBaseBlock>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export type KnowledgeBaseBlockTestHistory = {
  __typename?: 'KnowledgeBaseBlockTestHistory';
  id?: Maybe<Scalars['String']>;
  answer?: Maybe<KnowledgeBaseBlockTestAnswer>;
  answerId?: Maybe<Scalars['String']>;
  block?: Maybe<KnowledgeBaseBlock>;
  blockId?: Maybe<Scalars['String']>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
};

export enum KnowledgeBaseBlockTypeEnum {
  Subtitle = 'SUBTITLE',
  Header = 'HEADER',
  List = 'LIST',
  Text = 'TEXT',
  Photo = 'PHOTO',
  Video = 'VIDEO',
  Test = 'TEST',
  Question = 'QUESTION'
}

export type KnowledgeBaseCategory = {
  __typename?: 'KnowledgeBaseCategory';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  isFor?: Maybe<KnowledgeBaseCategoryIsForEnum>;
  themes?: Maybe<Array<Maybe<KnowledgeBaseTheme>>>;
  availableFor?: Maybe<AvailableForEnum>;
  participants?: Maybe<Array<Maybe<KnowledgeBaseCategoryParticipant>>>;
  publishedAt?: Maybe<Scalars['Date']>;
  photo?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
  deletedDate?: Maybe<Scalars['Date']>;
};

export type KnowledgeBaseCategoryByParticipant = {
  __typename?: 'KnowledgeBaseCategoryByParticipant';
  category?: Maybe<KnowledgeBaseCategory>;
  isViewed?: Maybe<Scalars['Boolean']>;
};

export enum KnowledgeBaseCategoryIsForEnum {
  AdminPanel = 'ADMIN_PANEL',
  LkTeacherGuide = 'LK_TEACHER_GUIDE'
}

export type KnowledgeBaseCategoryParticipant = {
  __typename?: 'KnowledgeBaseCategoryParticipant';
  id?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  knowledgeBaseCategory?: Maybe<KnowledgeBaseCategory>;
  isWatched?: Maybe<Scalars['Boolean']>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export type KnowledgeBaseTheme = {
  __typename?: 'KnowledgeBaseTheme';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  blocks?: Maybe<Array<Maybe<KnowledgeBaseBlock>>>;
  isPublished?: Maybe<Scalars['Boolean']>;
  isTestPublished?: Maybe<Scalars['Boolean']>;
  publishedAt?: Maybe<Scalars['Date']>;
  testPublishedAt?: Maybe<Scalars['Date']>;
  categoryId?: Maybe<Scalars['String']>;
  category?: Maybe<KnowledgeBaseCategory>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
  deletedDate?: Maybe<Scalars['Date']>;
};

export type LateTasks = {
  __typename?: 'LateTasks';
  id?: Maybe<Scalars['String']>;
  epicName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  assigneeName?: Maybe<Scalars['String']>;
  startDateEstimate?: Maybe<Scalars['String']>;
  startDateFact?: Maybe<Scalars['String']>;
  finishDateEstimate?: Maybe<Scalars['String']>;
  finishDateFact?: Maybe<Scalars['String']>;
  lateStart?: Maybe<Scalars['Int']>;
  lateFinish?: Maybe<Scalars['Int']>;
  costEstimate?: Maybe<Scalars['Int']>;
  cost?: Maybe<Scalars['Int']>;
  lateReason?: Maybe<Scalars['String']>;
};

export type Lesson = {
  __typename?: 'Lesson';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  pages?: Maybe<Array<Maybe<LessonPage>>>;
  main?: Maybe<ApplicationMain>;
  mainId?: Maybe<Scalars['String']>;
  theme?: Maybe<ApplicationMainTheme>;
  themeId?: Maybe<Scalars['String']>;
  model?: Maybe<ApplicationMainModel>;
  modelId?: Maybe<Scalars['String']>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export type LessonBlock = {
  __typename?: 'LessonBlock';
  id?: Maybe<Scalars['String']>;
  type?: Maybe<LessonBlockTypeEnum>;
  page?: Maybe<LessonPage>;
  pageId?: Maybe<Scalars['String']>;
  contentUKR?: Maybe<Scalars['String']>;
  contentEN?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['String']>;
  videoPreview?: Maybe<Scalars['String']>;
  lists?: Maybe<Array<Maybe<LessonListItem>>>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export type LessonBlockListInput = {
  contentUKR?: Maybe<Scalars['String']>;
  contentEN?: Maybe<Scalars['String']>;
  isNumbered?: Maybe<Scalars['Boolean']>;
};

export enum LessonBlockTypeEnum {
  Subtitle = 'SUBTITLE',
  Header = 'HEADER',
  List = 'LIST',
  Text = 'TEXT',
  Photo = 'PHOTO',
  Video = 'VIDEO'
}

export type LessonListItem = {
  __typename?: 'LessonListItem';
  id?: Maybe<Scalars['String']>;
  contentUKR?: Maybe<Scalars['String']>;
  contentEN?: Maybe<Scalars['String']>;
  isNumbered?: Maybe<Scalars['Boolean']>;
  blockId?: Maybe<Scalars['String']>;
  block?: Maybe<LessonBlock>;
  questionId?: Maybe<Scalars['String']>;
  question?: Maybe<LessonQuestion>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export type LessonPage = {
  __typename?: 'LessonPage';
  id?: Maybe<Scalars['String']>;
  type?: Maybe<LessonPageTypeEnum>;
  lesson?: Maybe<Lesson>;
  lessonId?: Maybe<Scalars['String']>;
  blocks?: Maybe<Array<Maybe<LessonBlock>>>;
  question?: Maybe<LessonQuestion>;
  questionId?: Maybe<Scalars['String']>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export enum LessonPageTypeEnum {
  Information = 'INFORMATION',
  Question = 'QUESTION'
}

export type LessonQuestion = {
  __typename?: 'LessonQuestion';
  id?: Maybe<Scalars['String']>;
  type?: Maybe<LessonQuestionTypeEnum>;
  page?: Maybe<LessonPage>;
  pageId?: Maybe<Scalars['String']>;
  questionUKR?: Maybe<Scalars['String']>;
  questionEN?: Maybe<Scalars['String']>;
  enterWord?: Maybe<Scalars['String']>;
  booleanVariant?: Maybe<Scalars['Boolean']>;
  rightVariant?: Maybe<LessonListItem>;
  rightVariantId?: Maybe<Scalars['String']>;
  variants?: Maybe<Array<Maybe<LessonListItem>>>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export enum LessonQuestionTypeEnum {
  EnterWord = 'ENTER_WORD',
  Test = 'TEST',
  Boolean = 'BOOLEAN'
}

export type LessonVideoBlockInput = {
  video?: Maybe<Scalars['String']>;
  videoPreview?: Maybe<Scalars['Upload']>;
};

export type MasteringMoneyReport = {
  __typename?: 'MasteringMoneyReport';
  projectId?: Maybe<Scalars['String']>;
  projectName?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Int']>;
  costEstimate?: Maybe<Scalars['Int']>;
  costFact?: Maybe<Scalars['Int']>;
};

export type MobileNotification = {
  __typename?: 'MobileNotification';
  id?: Maybe<Scalars['String']>;
  nameEN?: Maybe<Scalars['String']>;
  nameUKR?: Maybe<Scalars['String']>;
  descriptionEN?: Maybe<Scalars['String']>;
  descriptionUKR?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  availableForEveryone?: Maybe<Scalars['Boolean']>;
  participants?: Maybe<Array<Maybe<MobileNotificationParticipant>>>;
  isPublished?: Maybe<Scalars['Boolean']>;
  publishedAt?: Maybe<Scalars['Date']>;
  application?: Maybe<Application>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export type MobileNotificationByParticipant = {
  __typename?: 'MobileNotificationByParticipant';
  notification?: Maybe<MobileNotification>;
  isViewed?: Maybe<Scalars['Boolean']>;
};

export type MobileNotificationParticipant = {
  __typename?: 'MobileNotificationParticipant';
  id?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  notification?: Maybe<MobileNotification>;
  isWatched?: Maybe<Scalars['Boolean']>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export enum ModelFileTypeEnum {
  Photo = 'PHOTO',
  MapMarkerPhoto = 'MAP_MARKER_PHOTO',
  MarkerPhoto = 'MARKER_PHOTO',
  IosBundle = 'IOS_BUNDLE',
  AndroidBundle = 'ANDROID_BUNDLE',
  BundlePhoto = 'BUNDLE_PHOTO'
}

export type MonthHourPlan = {
  __typename?: 'MonthHourPlan';
  id?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
  hoursPlan?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export type MultiLesson = {
  __typename?: 'MultiLesson';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  educationPlanId?: Maybe<Scalars['String']>;
  educationPlan?: Maybe<EducationPlan>;
  blocks?: Maybe<Array<Maybe<MultiLessonBlock>>>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export type MultiLessonBlock = {
  __typename?: 'MultiLessonBlock';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<MultiLessonBlockTypeEnum>;
  multiLessonId?: Maybe<Scalars['String']>;
  multiLesson?: Maybe<MultiLesson>;
  lessonId?: Maybe<Scalars['String']>;
  lesson?: Maybe<Lesson>;
  modelId?: Maybe<Scalars['String']>;
  model?: Maybe<ApplicationMainModel>;
  testId?: Maybe<Scalars['String']>;
  test?: Maybe<Test>;
  traningId?: Maybe<Scalars['String']>;
  traning?: Maybe<Traning>;
  link?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['String']>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export enum MultiLessonBlockTypeEnum {
  Lesson = 'LESSON',
  Model = 'MODEL',
  Test = 'TEST',
  Traning = 'TRANING',
  Video = 'VIDEO',
  Link = 'LINK',
  File = 'FILE'
}

export type Mutation = {
  __typename?: 'Mutation';
  createAd?: Maybe<Ad>;
  updateAd?: Maybe<Ad>;
  deleteAd?: Maybe<Scalars['Boolean']>;
  createAnimationBundle?: Maybe<AnimationBundle>;
  updateAnimationBundle?: Maybe<AnimationBundle>;
  deleteAnimationBundle?: Maybe<Scalars['Boolean']>;
  setIosBundle?: Maybe<Scalars['Boolean']>;
  setAndroidBundle?: Maybe<Scalars['Boolean']>;
  createAnswer?: Maybe<Answer>;
  deleteAnswer?: Maybe<Scalars['Boolean']>;
  createAppMainThemeBackground?: Maybe<AppMainThemeBackground>;
  updateAppMainThemeBackground?: Maybe<AppMainThemeBackground>;
  deleteAppMainThemeBackground?: Maybe<Scalars['Boolean']>;
  createApplication?: Maybe<Application>;
  updateApplication?: Maybe<Application>;
  deleteApplication?: Maybe<Scalars['Boolean']>;
  updateApplicationFiles?: Maybe<Scalars['Boolean']>;
  deleteApplicationFile?: Maybe<Scalars['Boolean']>;
  publishApplication?: Maybe<Scalars['Boolean']>;
  createApplicationLink?: Maybe<ApplicationLink>;
  updateApplicationLink?: Maybe<ApplicationLink>;
  deleteApplicationLink?: Maybe<Scalars['Boolean']>;
  createApplicationMain?: Maybe<ApplicationMain>;
  updateApplicationMain?: Maybe<ApplicationMain>;
  deleteApplicationMain?: Maybe<Scalars['Boolean']>;
  updateApplicationMainPhoto?: Maybe<Scalars['Boolean']>;
  deleteApplicationMainPhoto?: Maybe<Scalars['Boolean']>;
  handleAccess?: Maybe<Scalars['Boolean']>;
  setQrCustom?: Maybe<Scalars['Boolean']>;
  deleteQrCustom?: Maybe<Scalars['Boolean']>;
  publishApplicationMain?: Maybe<Scalars['Boolean']>;
  assignSubjectsToTeacher?: Maybe<Scalars['Boolean']>;
  createApplicationModel?: Maybe<ApplicationMainModel>;
  updateApplicationModel?: Maybe<ApplicationMainModel>;
  deleteApplicationModel?: Maybe<Scalars['Boolean']>;
  updateApplicationModelPhoto?: Maybe<Scalars['Boolean']>;
  updateApplicationModelMarkerPhoto?: Maybe<Scalars['Boolean']>;
  updateApplicationModelMapMarkerPhoto?: Maybe<Scalars['Boolean']>;
  updateApplicationModelBundle?: Maybe<Scalars['Boolean']>;
  deleteApplicationModelFiles?: Maybe<Scalars['Boolean']>;
  publishApplicationMainModel?: Maybe<Scalars['Boolean']>;
  createApplicationMainModelFile?: Maybe<ApplicationMainModelFile>;
  updateApplicationMainModelFile?: Maybe<ApplicationMainModelFile>;
  deleteApplicationMainModelFile?: Maybe<Scalars['Boolean']>;
  createApplicationMainTheme?: Maybe<ApplicationMainTheme>;
  updateApplicationMainTheme?: Maybe<ApplicationMainTheme>;
  addApplicationMainThemePhoto?: Maybe<Scalars['Boolean']>;
  deleteApplicationMainThemePhoto?: Maybe<Scalars['Boolean']>;
  deleteApplicationMainTheme?: Maybe<Scalars['Boolean']>;
  createApplicationVersion?: Maybe<ApplicationVersion>;
  updateApplicationVersion?: Maybe<ApplicationVersion>;
  deleteApplicationVersion?: Maybe<Scalars['Boolean']>;
  createClassroom?: Maybe<Classroom>;
  updateClassroom?: Maybe<Classroom>;
  deleteClassroom?: Maybe<Scalars['Boolean']>;
  archiveClassroom?: Maybe<Scalars['Boolean']>;
  assignPuiplToClassroom?: Maybe<Scalars['Boolean']>;
  removePuiplFromClassroom?: Maybe<Scalars['Boolean']>;
  createClientSite?: Maybe<ClientSite>;
  updateClientSite?: Maybe<ClientSite>;
  deleteClientSite?: Maybe<Scalars['Boolean']>;
  createClientSiteModel?: Maybe<ClientSiteModel>;
  updateClientSiteModel?: Maybe<ClientSiteModel>;
  deleteClientSiteModel?: Maybe<Scalars['Boolean']>;
  uploadClientSiteModelFile?: Maybe<Scalars['Boolean']>;
  deleteClientSiteModelFile?: Maybe<Scalars['Boolean']>;
  createClientSiteModelItem?: Maybe<ClientSiteModelItem>;
  updateClientSiteModelItem?: Maybe<ClientSiteModelItem>;
  deleteClientSiteModelItem?: Maybe<Scalars['Boolean']>;
  createCorrectionComment?: Maybe<CorrectionComment>;
  updateCorrectionComment?: Maybe<CorrectionComment>;
  deleteCorrectionComment?: Maybe<Scalars['Boolean']>;
  createEducationPlan?: Maybe<EducationPlan>;
  updateEducationPlan?: Maybe<EducationPlan>;
  deleteEducationPlan?: Maybe<Scalars['Boolean']>;
  publishEducationPlan?: Maybe<Scalars['Boolean']>;
  unpublishEducationPlan?: Maybe<Scalars['Boolean']>;
  createEmployeesDistribution?: Maybe<EmployeesDistribution>;
  createEmployeesDistributions?: Maybe<Array<Maybe<EmployeesDistribution>>>;
  updateEmployeesDistribution?: Maybe<EmployeesDistribution>;
  deleteEmployeesDistribution?: Maybe<Scalars['Boolean']>;
  createEvent?: Maybe<Event>;
  updateEvent?: Maybe<Event>;
  deleteEvent?: Maybe<Scalars['Boolean']>;
  createEventDate?: Maybe<EventDate>;
  updateEventDate?: Maybe<EventDate>;
  deleteEventDate?: Maybe<Scalars['Boolean']>;
  addUserToEventDate?: Maybe<Scalars['Boolean']>;
  createExercise?: Maybe<Exercise>;
  updateExercise?: Maybe<Exercise>;
  deleteExercise?: Maybe<Scalars['Boolean']>;
  createFeedback?: Maybe<Feedback>;
  updateFeedback?: Maybe<Feedback>;
  addFeedbackFiles?: Maybe<Scalars['Boolean']>;
  deleteFeedback?: Maybe<Scalars['Boolean']>;
  createFeedbackComment?: Maybe<FeedbackComment>;
  updateFeedbackComment?: Maybe<FeedbackComment>;
  addFeedbackCommentFiles?: Maybe<Scalars['Boolean']>;
  deleteFeedbackComment?: Maybe<Scalars['Boolean']>;
  sendInvitationEmail?: Maybe<Scalars['Boolean']>;
  createKnowledgeBaseBlock?: Maybe<KnowledgeBaseBlock>;
  createKnowledgeBaseBlockTestAnswer?: Maybe<KnowledgeBaseBlockTestAnswer>;
  createKnowledgeBaseBlockListItem?: Maybe<KnowledgeBaseBlockList>;
  uploudKnowledgeBaseBlockPhoto?: Maybe<KnowledgeBaseBlock>;
  updateKnowledgeBaseBlock?: Maybe<KnowledgeBaseBlock>;
  updateKnowledgeBaseBlockTestAnswer?: Maybe<KnowledgeBaseBlockTestAnswer>;
  updateKnowledgeBaseBlockListItem?: Maybe<KnowledgeBaseBlockList>;
  deleteKnowledgeBaseBlockListItem?: Maybe<Scalars['Boolean']>;
  deleteKnowledgeBaseBlockTestAnswer?: Maybe<Scalars['Boolean']>;
  deleteKnowledgeBaseBlock?: Maybe<Scalars['Boolean']>;
  answerKnowledgeBaseBlockTest?: Maybe<KnowledgeBaseBlockTestHistory>;
  publishKnowledgeBaseTestBlocksByThemeId?: Maybe<Scalars['Boolean']>;
  createKnowledgeBaseCategory?: Maybe<KnowledgeBaseCategory>;
  updateKnowledgeBaseCategory?: Maybe<KnowledgeBaseCategory>;
  deleteKnowledgeBaseCategory?: Maybe<Scalars['Boolean']>;
  setKnowledgeBaseCategoryParticipants?: Maybe<Scalars['Boolean']>;
  knowledgeBaseCategoryParticipantStatusChange?: Maybe<Scalars['Boolean']>;
  createKnowledgeBaseTheme?: Maybe<KnowledgeBaseTheme>;
  updateKnowledgeBaseTheme?: Maybe<KnowledgeBaseTheme>;
  deleteKnowledgeBaseTheme?: Maybe<Scalars['Boolean']>;
  publishKnowledgeBaseTheme?: Maybe<Scalars['Boolean']>;
  createLesson?: Maybe<Lesson>;
  updateLesson?: Maybe<Lesson>;
  deleteLesson?: Maybe<Scalars['Boolean']>;
  publishLesson?: Maybe<Scalars['Boolean']>;
  unpublishLesson?: Maybe<Scalars['Boolean']>;
  createLessonBlock?: Maybe<LessonBlock>;
  updateLessonBlock?: Maybe<LessonBlock>;
  deleteLessonBlock?: Maybe<Scalars['Boolean']>;
  createLessonListItem?: Maybe<LessonListItem>;
  updateLessonListItem?: Maybe<LessonListItem>;
  deleteLessonListItem?: Maybe<Scalars['Boolean']>;
  createLessonPage?: Maybe<LessonPage>;
  deleteLessonPage?: Maybe<Scalars['Boolean']>;
  createLessonQuestion?: Maybe<LessonQuestion>;
  updateLessonQuestion?: Maybe<LessonQuestion>;
  deleteLessonQuestion?: Maybe<Scalars['Boolean']>;
  createQuestionVariant?: Maybe<LessonListItem>;
  updateQuestionVariant?: Maybe<LessonListItem>;
  deleteQuestionVariant?: Maybe<Scalars['Boolean']>;
  createMobileNotification?: Maybe<MobileNotification>;
  updateMobileNotification?: Maybe<MobileNotification>;
  setMobileNotificationParticipants?: Maybe<Scalars['Boolean']>;
  publishMobileNotification?: Maybe<Scalars['Boolean']>;
  mobileNotificationParticipantStatusChange?: Maybe<Scalars['Boolean']>;
  deleteMobileNotification?: Maybe<Scalars['Boolean']>;
  createMonthHourPlan?: Maybe<MonthHourPlan>;
  updateMonthHourPlan?: Maybe<MonthHourPlan>;
  deleteMonthHourPlan?: Maybe<Scalars['Boolean']>;
  createMultiLesson?: Maybe<MultiLesson>;
  updateMultiLesson?: Maybe<MultiLesson>;
  deleteMultiLesson?: Maybe<Scalars['Boolean']>;
  createMultiLessonBlock?: Maybe<MultiLessonBlock>;
  updateMultiLessonBlock?: Maybe<MultiLessonBlock>;
  deleteMultiLessonBlock?: Maybe<Scalars['Boolean']>;
  createNews?: Maybe<News>;
  updateNews?: Maybe<News>;
  setNewsParticipants?: Maybe<Scalars['Boolean']>;
  deleteNews?: Maybe<Scalars['Boolean']>;
  publishNews?: Maybe<Scalars['Boolean']>;
  newsParticipantStatusChange?: Maybe<Scalars['Boolean']>;
  createNotification?: Maybe<Notification>;
  updateNotification?: Maybe<Notification>;
  setNotificationParticipants?: Maybe<Scalars['Boolean']>;
  deleteNotification?: Maybe<Scalars['Boolean']>;
  publishNotification?: Maybe<Scalars['Boolean']>;
  viewNotification?: Maybe<Scalars['Boolean']>;
  notificationParticipantStatusChange?: Maybe<Scalars['Boolean']>;
  createPayrollBonus?: Maybe<PayrollBonus>;
  updatePayrollBonus?: Maybe<PayrollBonus>;
  deletePayrollBonus?: Maybe<Scalars['Boolean']>;
  createPayrollKpi?: Maybe<PayrollKpi>;
  updatePayrollKpi?: Maybe<PayrollKpi>;
  deletePayrollKpi?: Maybe<Scalars['Boolean']>;
  createPayrollSalaryPart?: Maybe<PayrollSalaryPart>;
  updatePayrollSalaryPart?: Maybe<PayrollSalaryPart>;
  deletePayrollSalaryPart?: Maybe<Scalars['Boolean']>;
  createProduct?: Maybe<Product>;
  updateProduct?: Maybe<Product>;
  deleteProduct?: Maybe<Scalars['Boolean']>;
  createProject?: Maybe<Project>;
  createProjectByJiraData?: Maybe<Project>;
  updateProject?: Maybe<Project>;
  deleteProject?: Maybe<Scalars['Boolean']>;
  syncProject?: Maybe<SyncProject>;
  assignProjectTrackerId?: Maybe<Scalars['Boolean']>;
  updateProjectFiles?: Maybe<Array<Maybe<ProjectFile>>>;
  createProjectAdditionalContact?: Maybe<ProjectAdditionalContact>;
  updateProjectAdditionalContact?: Maybe<ProjectAdditionalContact>;
  deleteProjectAdditionalContact?: Maybe<Scalars['Boolean']>;
  createProjectCorrection?: Maybe<ProjectCorrection>;
  updateProjectCorrection?: Maybe<ProjectCorrection>;
  deleteProjectCorrection?: Maybe<Scalars['Boolean']>;
  addCorrectionsToProject?: Maybe<Scalars['Boolean']>;
  removeCorrectionsFromProject?: Maybe<Scalars['Boolean']>;
  createProjectCustomFieldsMap?: Maybe<ProjectCustomFieldsMap>;
  updateProjectCustomFieldsMap?: Maybe<ProjectCustomFieldsMap>;
  updateProjectCustomFieldsMapByProject?: Maybe<ProjectCustomFieldsMap>;
  deleteProjectCustomFieldsMap?: Maybe<Scalars['Boolean']>;
  createProjectKanban?: Maybe<ProjectKanban>;
  restartSprint?: Maybe<ProjectKanban>;
  deleteProjectKanban?: Maybe<Scalars['Boolean']>;
  clearProjectKanban?: Maybe<Scalars['Boolean']>;
  activateProjectKanban?: Maybe<Scalars['Boolean']>;
  createBaseKanbanWorkflowStep?: Maybe<KanbanWorkflowStep>;
  createKanbanWorkflowStepForProject?: Maybe<KanbanWorkflowStep>;
  updateKanbanWorkflowStep?: Maybe<KanbanWorkflowStep>;
  deleteKanbanWorkflowStep?: Maybe<Scalars['Boolean']>;
  createProjectLink?: Maybe<ProjectLink>;
  updateProjectLink?: Maybe<ProjectLink>;
  deleteProjectLink?: Maybe<Scalars['Boolean']>;
  addLinksToProject?: Maybe<Scalars['Boolean']>;
  removeLinksFromProject?: Maybe<Scalars['Boolean']>;
  createProjectPresentation?: Maybe<ProjectPresentation>;
  updateProjectPresentation?: Maybe<ProjectPresentation>;
  deleteProjectPresentation?: Maybe<Scalars['Boolean']>;
  createProjectStep?: Maybe<ProjectStep>;
  updateProjectStep?: Maybe<ProjectStep>;
  deleteProjectStep?: Maybe<Scalars['Boolean']>;
  createProjectTask?: Maybe<ProjectTask>;
  createProjectTaskInKanban?: Maybe<ProjectTask>;
  updateProjectTask?: Maybe<ProjectTask>;
  deleteProjectTask?: Maybe<Scalars['Boolean']>;
  createProjectTasksInTracker?: Maybe<CreatedTasksInTracker>;
  syncProjectTaskWithTracker?: Maybe<Scalars['Boolean']>;
  syncProjectTasksWithTracker?: Maybe<Scalars['Boolean']>;
  importProjectTasksFromGSheets?: Maybe<ImportTasksFromGSheetResult>;
  addProjectTasksToKanban?: Maybe<Scalars['Boolean']>;
  changeProjectTaskWorkflowStep?: Maybe<Scalars['Boolean']>;
  removeProjectTaskFromKanban?: Maybe<Scalars['Boolean']>;
  setProjectTaskLateReason?: Maybe<Scalars['Boolean']>;
  setTaskBoundaries?: Maybe<Scalars['Boolean']>;
  createPTCheckListItem?: Maybe<ProjectTaskCheckListItem>;
  updatePTCheckListItem?: Maybe<ProjectTaskCheckListItem>;
  deletePTCheckListItem?: Maybe<Scalars['Boolean']>;
  changePTCheckListItemStatus?: Maybe<ProjectTaskCheckListItem>;
  createProjectTaskComment?: Maybe<ProjectTaskComment>;
  updateProjectTaskComment?: Maybe<ProjectTaskComment>;
  deleteProjectTaskComment?: Maybe<Scalars['Boolean']>;
  deleteProjectTaskCommentFile?: Maybe<Scalars['Boolean']>;
  createBaseProjectTaskLabel?: Maybe<ProjectTaskLabel>;
  createProjectTaskLabelForProject?: Maybe<ProjectTaskLabel>;
  updateProjectTaskLabel?: Maybe<ProjectTaskLabel>;
  deleteProjectTaskLabel?: Maybe<Scalars['Boolean']>;
  assignProjectTaskLabel?: Maybe<Scalars['Boolean']>;
  removeProjectTaskLabel?: Maybe<Scalars['Boolean']>;
  createBaseProjectTaskStatus?: Maybe<ProjectTaskStatus>;
  createProjectTaskStatusForProject?: Maybe<ProjectTaskStatus>;
  updateProjectTaskStatus?: Maybe<ProjectTaskStatus>;
  deleteProjectTaskStatus?: Maybe<Scalars['Boolean']>;
  createProjectType?: Maybe<ProjectType>;
  updateProjectType?: Maybe<ProjectType>;
  deleteProjectType?: Maybe<Scalars['Boolean']>;
  createPurchase?: Maybe<Purchase>;
  updatePurchase?: Maybe<Purchase>;
  deletePurchase?: Maybe<Scalars['Boolean']>;
  confirmPurchase?: Maybe<Scalars['Boolean']>;
  createQuestion?: Maybe<Question>;
  updateQuestion?: Maybe<Question>;
  setQuestionRightVariant?: Maybe<Question>;
  deleteQuestion?: Maybe<Scalars['Boolean']>;
  createSchool?: Maybe<School>;
  updateSchool?: Maybe<School>;
  addTeacherToSchool?: Maybe<Scalars['Boolean']>;
  deleteSchool?: Maybe<Scalars['Boolean']>;
  deleteSchoolPhoto?: Maybe<Scalars['Boolean']>;
  createSmartNotification?: Maybe<SmartNotification>;
  updateSmartNotification?: Maybe<SmartNotification>;
  deleteSmartNotification?: Maybe<Scalars['Boolean']>;
  publishSmartNotification?: Maybe<Scalars['Boolean']>;
  unpublishSmartNotification?: Maybe<Scalars['Boolean']>;
  deleteSmartNotificationPhoto?: Maybe<Scalars['Boolean']>;
  createSmartNotificationPollList?: Maybe<SmartNotificationPollList>;
  updateSmartNotificationPollList?: Maybe<SmartNotificationPollList>;
  deleteSmartNotificationPollList?: Maybe<Scalars['Boolean']>;
  createTeacherSchoolReport?: Maybe<Scalars['Boolean']>;
  createTest?: Maybe<Test>;
  updateTest?: Maybe<Test>;
  publishTest?: Maybe<Scalars['Boolean']>;
  deleteTest?: Maybe<Scalars['Boolean']>;
  createTraning?: Maybe<Traning>;
  updateTraning?: Maybe<Traning>;
  deleteTraning?: Maybe<Scalars['Boolean']>;
  publishTraning?: Maybe<Scalars['Boolean']>;
  unpublishTraning?: Maybe<Scalars['Boolean']>;
  sendTrainingPush?: Maybe<Scalars['Boolean']>;
  deleteUploadedFile?: Maybe<Scalars['Boolean']>;
  createUser?: Maybe<User>;
  confirmUser?: Maybe<Scalars['Boolean']>;
  login?: Maybe<Auth>;
  loginMobile?: Maybe<Auth>;
  logout: Scalars['Boolean'];
  forgotPassword?: Maybe<Scalars['Boolean']>;
  deleteOldTokens?: Maybe<Scalars['Boolean']>;
  sendConfirmEmail?: Maybe<Scalars['Boolean']>;
  resetPassword?: Maybe<Auth>;
  updateUser?: Maybe<User>;
  deleteUser?: Maybe<Scalars['Boolean']>;
  assignUsersToGroup?: Maybe<Scalars['Boolean']>;
  removeUsersFromGroup?: Maybe<Scalars['Boolean']>;
  assignUsersToProject?: Maybe<Scalars['Boolean']>;
  removeUsersFromProject?: Maybe<Scalars['Boolean']>;
  assignProjectsToUser?: Maybe<Scalars['Boolean']>;
  removeProjectsFromUser?: Maybe<Scalars['Boolean']>;
  assignAnimationBundleParticipants?: Maybe<Scalars['Boolean']>;
  assignUserTrackerId?: Maybe<Scalars['Boolean']>;
  inviteUserToTracker?: Maybe<Scalars['Boolean']>;
  createTrackerContract?: Maybe<Contract>;
  deleteTrackerContract?: Maybe<Scalars['Boolean']>;
  cleanUserDataForClientRole?: Maybe<Scalars['Boolean']>;
  createUserPassword?: Maybe<Scalars['Boolean']>;
  createEventUser?: Maybe<Scalars['Boolean']>;
  registerEventUser?: Maybe<User>;
  createUserAgreement?: Maybe<UserAgreement>;
  updateUserAgreement?: Maybe<UserAgreement>;
  deleteUserAgreement?: Maybe<Scalars['Boolean']>;
  createUserDepartment?: Maybe<UserDepartment>;
  updateUserDepartment?: Maybe<UserDepartment>;
  deleteUserDepartment?: Maybe<Scalars['Boolean']>;
  createUserExpHistory?: Maybe<UserExpHistory>;
  createUserGroup?: Maybe<UserGroup>;
  updateUserGroup?: Maybe<UserGroup>;
  deleteUserGroup?: Maybe<Scalars['Boolean']>;
  createUserJob?: Maybe<UserJob>;
  updateUserJob?: Maybe<UserJob>;
  deleteUserJob?: Maybe<Scalars['Boolean']>;
  createUserPayment?: Maybe<UserPayment>;
  updateUserPayment?: Maybe<UserPayment>;
  setUserPaymentDocuments?: Maybe<Scalars['Boolean']>;
  deleteUserPayment?: Maybe<Scalars['Boolean']>;
  payUserPayment?: Maybe<Scalars['Boolean']>;
  publishUserPayment?: Maybe<Scalars['Boolean']>;
  createUserPayroll?: Maybe<UserPayroll>;
  updateUserPayroll?: Maybe<UserPayroll>;
  deleteUserPayroll?: Maybe<Scalars['Boolean']>;
  createMonthUserPayrolls?: Maybe<Scalars['Boolean']>;
  createRateHistory?: Maybe<UserRateHistory>;
  updateRateHistory?: Maybe<UserRateHistory>;
  deleteSRateHistory?: Maybe<Scalars['Boolean']>;
  createMonthlyStaffRate?: Maybe<Scalars['Boolean']>;
  createSalaryHistory?: Maybe<UserSalaryHistory>;
  updateSalaryHistory?: Maybe<UserSalaryHistory>;
  deleteSalaryHistory?: Maybe<Scalars['Boolean']>;
  updateUserStats?: Maybe<UserStats>;
  createVacanciesPlan?: Maybe<VacanciesPlan>;
  updateVacanciesPlan?: Maybe<VacanciesPlan>;
  deleteVacanciesPlan?: Maybe<Scalars['Boolean']>;
  createVariant?: Maybe<Variant>;
  updateVariant?: Maybe<Variant>;
  deleteVariant?: Maybe<Scalars['Boolean']>;
};


export type MutationCreateAdArgs = {
  name: Scalars['String'];
  description: Scalars['String'];
};


export type MutationUpdateAdArgs = {
  updateAdData: UpdateAdInput;
};


export type MutationDeleteAdArgs = {
  adId: Scalars['String'];
};


export type MutationCreateAnimationBundleArgs = {
  animationData: CreateAnimationBundleInput;
  photo?: Maybe<Scalars['Upload']>;
};


export type MutationUpdateAnimationBundleArgs = {
  animationData: UpdateAnimationBundleInput;
  photo?: Maybe<Scalars['Upload']>;
};


export type MutationDeleteAnimationBundleArgs = {
  animationId: Scalars['String'];
};


export type MutationSetIosBundleArgs = {
  animationId: Scalars['String'];
  deletedIosBundleId?: Maybe<Scalars['String']>;
  newIosBundle?: Maybe<Scalars['Upload']>;
};


export type MutationSetAndroidBundleArgs = {
  animationId: Scalars['String'];
  deletedAndroidBundleId?: Maybe<Scalars['String']>;
  newAndroidBundle?: Maybe<Scalars['Upload']>;
};


export type MutationCreateAnswerArgs = {
  questionId: Scalars['String'];
  variantId: Scalars['String'];
};


export type MutationDeleteAnswerArgs = {
  id: Scalars['String'];
};


export type MutationCreateAppMainThemeBackgroundArgs = {
  name: Scalars['String'];
  fileIOS: Scalars['Upload'];
  fileAndroid: Scalars['Upload'];
  manifestIOS?: Maybe<Scalars['Upload']>;
  manifestAndroid?: Maybe<Scalars['Upload']>;
};


export type MutationUpdateAppMainThemeBackgroundArgs = {
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  fileIOS?: Maybe<Scalars['Upload']>;
  fileAndroid?: Maybe<Scalars['Upload']>;
  manifestIOS?: Maybe<Scalars['Upload']>;
  manifestAndroid?: Maybe<Scalars['Upload']>;
};


export type MutationDeleteAppMainThemeBackgroundArgs = {
  id: Scalars['String'];
};


export type MutationCreateApplicationArgs = {
  applicationData: CreateApplicationInput;
};


export type MutationUpdateApplicationArgs = {
  applicationData: UpdateApplicationInput;
};


export type MutationDeleteApplicationArgs = {
  id: Scalars['String'];
};


export type MutationUpdateApplicationFilesArgs = {
  applicationId: Scalars['String'];
  file: Scalars['Upload'];
  ApplicationFileType: ApplicationFileTypeEnum;
};


export type MutationDeleteApplicationFileArgs = {
  applicationId: Scalars['String'];
  ApplicationFileType: ApplicationFileTypeEnum;
};


export type MutationPublishApplicationArgs = {
  id: Scalars['String'];
};


export type MutationCreateApplicationLinkArgs = {
  name: Scalars['String'];
  link: Scalars['String'];
  applicationId: Scalars['String'];
};


export type MutationUpdateApplicationLinkArgs = {
  applicationLinkId: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
};


export type MutationDeleteApplicationLinkArgs = {
  id: Scalars['String'];
};


export type MutationCreateApplicationMainArgs = {
  applicationMainData: CreateApplicationMainInput;
};


export type MutationUpdateApplicationMainArgs = {
  applicationMainData: UpdateApplicationMainInput;
};


export type MutationDeleteApplicationMainArgs = {
  id: Scalars['String'];
};


export type MutationUpdateApplicationMainPhotoArgs = {
  applicationMainId: Scalars['String'];
  photo: Scalars['Upload'];
};


export type MutationDeleteApplicationMainPhotoArgs = {
  applicationMainId: Scalars['String'];
};


export type MutationHandleAccessArgs = {
  applicationMainData: HandleAccessInput;
};


export type MutationSetQrCustomArgs = {
  appMainId: Scalars['String'];
  qrData: SetQrCustomInput;
};


export type MutationDeleteQrCustomArgs = {
  appMainId: Scalars['String'];
};


export type MutationPublishApplicationMainArgs = {
  id: Scalars['String'];
};


export type MutationAssignSubjectsToTeacherArgs = {
  mainPageIds: Array<Maybe<Scalars['String']>>;
  teacherId?: Maybe<Scalars['String']>;
};


export type MutationCreateApplicationModelArgs = {
  modelData: CreateApplicationMainModelInput;
};


export type MutationUpdateApplicationModelArgs = {
  modelData: UpdateApplicationMainModelInput;
};


export type MutationDeleteApplicationModelArgs = {
  id: Scalars['String'];
};


export type MutationUpdateApplicationModelPhotoArgs = {
  modelId: Scalars['String'];
  file: Scalars['Upload'];
};


export type MutationUpdateApplicationModelMarkerPhotoArgs = {
  modelId: Scalars['String'];
  isMarker: Scalars['Boolean'];
  file?: Maybe<Scalars['Upload']>;
  markerWidth?: Maybe<Scalars['Float']>;
};


export type MutationUpdateApplicationModelMapMarkerPhotoArgs = {
  modelId: Scalars['String'];
  file: Scalars['Upload'];
};


export type MutationUpdateApplicationModelBundleArgs = {
  modelId: Scalars['String'];
  bundleType: ApplicationBundleTypeEnum;
  bundleFileType?: Maybe<BundleFileTypeEnum>;
  file?: Maybe<Scalars['Upload']>;
  bundleLink?: Maybe<Scalars['String']>;
};


export type MutationDeleteApplicationModelFilesArgs = {
  modelId: Scalars['String'];
  modelFileType: ModelFileTypeEnum;
};


export type MutationPublishApplicationMainModelArgs = {
  id: Scalars['String'];
};


export type MutationCreateApplicationMainModelFileArgs = {
  fileData: CreateApplicationMainModelFileInput;
};


export type MutationUpdateApplicationMainModelFileArgs = {
  fileData: UpdateApplicationMainModelFileInput;
};


export type MutationDeleteApplicationMainModelFileArgs = {
  fileId: Scalars['String'];
};


export type MutationCreateApplicationMainThemeArgs = {
  applicationMainId: Scalars['String'];
  nameEN?: Maybe<Scalars['String']>;
  nameUKR?: Maybe<Scalars['String']>;
  backgroundId?: Maybe<Scalars['String']>;
};


export type MutationUpdateApplicationMainThemeArgs = {
  id: Scalars['String'];
  nameEN?: Maybe<Scalars['String']>;
  nameUKR?: Maybe<Scalars['String']>;
  backgroundId?: Maybe<Scalars['String']>;
};


export type MutationAddApplicationMainThemePhotoArgs = {
  id: Scalars['String'];
  photo: Scalars['Upload'];
};


export type MutationDeleteApplicationMainThemePhotoArgs = {
  id: Scalars['String'];
};


export type MutationDeleteApplicationMainThemeArgs = {
  id: Scalars['String'];
};


export type MutationCreateApplicationVersionArgs = {
  appId: Scalars['String'];
  os: Os;
  version: Scalars['String'];
};


export type MutationUpdateApplicationVersionArgs = {
  id: Scalars['String'];
  os: Os;
  version: Scalars['String'];
};


export type MutationDeleteApplicationVersionArgs = {
  id: Scalars['String'];
};


export type MutationCreateClassroomArgs = {
  name: Scalars['String'];
  password: Scalars['String'];
  teacherId: Scalars['String'];
};


export type MutationUpdateClassroomArgs = {
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  teacherId?: Maybe<Scalars['String']>;
};


export type MutationDeleteClassroomArgs = {
  id: Scalars['String'];
};


export type MutationArchiveClassroomArgs = {
  id: Scalars['String'];
};


export type MutationAssignPuiplToClassroomArgs = {
  classroomId: Scalars['String'];
  name: Scalars['String'];
  password: Scalars['String'];
  pupilId: Scalars['String'];
};


export type MutationRemovePuiplFromClassroomArgs = {
  classroomId: Scalars['String'];
  pupilId: Scalars['String'];
};


export type MutationCreateClientSiteArgs = {
  name: Scalars['String'];
  link?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  iframe: Scalars['String'];
};


export type MutationUpdateClientSiteArgs = {
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  iframe: Scalars['String'];
};


export type MutationDeleteClientSiteArgs = {
  id: Scalars['String'];
};


export type MutationCreateClientSiteModelArgs = {
  name: Scalars['String'];
  clientSiteId: Scalars['String'];
  itemIds: Array<Maybe<Scalars['String']>>;
};


export type MutationUpdateClientSiteModelArgs = {
  id: Scalars['String'];
  name: Scalars['String'];
};


export type MutationDeleteClientSiteModelArgs = {
  id: Scalars['String'];
};


export type MutationUploadClientSiteModelFileArgs = {
  clientSiteModelId: Scalars['String'];
  file: Scalars['Upload'];
  fileType: ClientSiteModelFilesEnum;
};


export type MutationDeleteClientSiteModelFileArgs = {
  clientSiteModelId: Scalars['String'];
  fileType: ClientSiteModelFilesEnum;
};


export type MutationCreateClientSiteModelItemArgs = {
  clientSiteModelId: Scalars['String'];
  itemId: Scalars['String'];
};


export type MutationUpdateClientSiteModelItemArgs = {
  id: Scalars['String'];
  itemId: Scalars['String'];
};


export type MutationDeleteClientSiteModelItemArgs = {
  id: Scalars['String'];
};


export type MutationCreateCorrectionCommentArgs = {
  correctionCommentData: CreateCorrectionCommentInput;
};


export type MutationUpdateCorrectionCommentArgs = {
  correctionCommentData: UpdateCorrectionCommentInput;
};


export type MutationDeleteCorrectionCommentArgs = {
  correctionCommentId: Scalars['String'];
};


export type MutationCreateEducationPlanArgs = {
  classroomId: Scalars['String'];
  name: Scalars['String'];
};


export type MutationUpdateEducationPlanArgs = {
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};


export type MutationDeleteEducationPlanArgs = {
  id: Scalars['String'];
};


export type MutationPublishEducationPlanArgs = {
  id: Scalars['String'];
};


export type MutationUnpublishEducationPlanArgs = {
  id: Scalars['String'];
};


export type MutationCreateEmployeesDistributionArgs = {
  distributionData: CreateEmployeesDistribution;
};


export type MutationCreateEmployeesDistributionsArgs = {
  name: Scalars['String'];
};


export type MutationUpdateEmployeesDistributionArgs = {
  distributionData: UpdateEmployeesDistribution;
};


export type MutationDeleteEmployeesDistributionArgs = {
  id: Scalars['String'];
};


export type MutationCreateEventArgs = {
  name: Scalars['String'];
  photo?: Maybe<Scalars['Upload']>;
};


export type MutationUpdateEventArgs = {
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['Upload']>;
};


export type MutationDeleteEventArgs = {
  id: Scalars['String'];
};


export type MutationCreateEventDateArgs = {
  date: Scalars['String'];
  link?: Maybe<Scalars['String']>;
  userLimit?: Maybe<Scalars['Int']>;
  eventId: Scalars['String'];
};


export type MutationUpdateEventDateArgs = {
  id: Scalars['String'];
  date?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  userLimit?: Maybe<Scalars['Int']>;
  eventId?: Maybe<Scalars['String']>;
};


export type MutationDeleteEventDateArgs = {
  id: Scalars['String'];
};


export type MutationAddUserToEventDateArgs = {
  eventDateId: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationCreateExerciseArgs = {
  exerciseData: CreateExerciseInput;
};


export type MutationUpdateExerciseArgs = {
  id: Scalars['String'];
  exerciseData: UpdateExerciseInput;
};


export type MutationDeleteExerciseArgs = {
  id: Scalars['String'];
};


export type MutationCreateFeedbackArgs = {
  text: Scalars['String'];
  files?: Maybe<Array<Maybe<Scalars['Upload']>>>;
  isFrom?: Maybe<FeedbackIsFromEnum>;
};


export type MutationUpdateFeedbackArgs = {
  feedbackData: UpdateFeedbackInput;
};


export type MutationAddFeedbackFilesArgs = {
  feedbackId: Scalars['String'];
  files: Array<Maybe<Scalars['Upload']>>;
};


export type MutationDeleteFeedbackArgs = {
  feedbackId: Scalars['String'];
};


export type MutationCreateFeedbackCommentArgs = {
  text: Scalars['String'];
  feedbackId: Scalars['String'];
  files?: Maybe<Array<Maybe<Scalars['Upload']>>>;
};


export type MutationUpdateFeedbackCommentArgs = {
  id: Scalars['String'];
  text?: Maybe<Scalars['String']>;
};


export type MutationAddFeedbackCommentFilesArgs = {
  feedbackCommentId: Scalars['String'];
  files: Array<Maybe<Scalars['Upload']>>;
};


export type MutationDeleteFeedbackCommentArgs = {
  feedbackCommentId: Scalars['String'];
};


export type MutationSendInvitationEmailArgs = {
  email: Scalars['String'];
};


export type MutationCreateKnowledgeBaseBlockArgs = {
  type: KnowledgeBaseBlockTypeEnum;
  themeId: Scalars['String'];
  blockTypeData?: Maybe<BlockTypeInput>;
};


export type MutationCreateKnowledgeBaseBlockTestAnswerArgs = {
  blockId: Scalars['String'];
  content: Scalars['String'];
  isAnswerTrue: Scalars['Boolean'];
};


export type MutationCreateKnowledgeBaseBlockListItemArgs = {
  blockId: Scalars['String'];
  content: Scalars['String'];
  isNumbered?: Maybe<Scalars['Boolean']>;
};


export type MutationUploudKnowledgeBaseBlockPhotoArgs = {
  blockId: Scalars['String'];
  photo: Scalars['Upload'];
};


export type MutationUpdateKnowledgeBaseBlockArgs = {
  id: Scalars['String'];
  blockTypeData?: Maybe<BlockTypeInput>;
};


export type MutationUpdateKnowledgeBaseBlockTestAnswerArgs = {
  answerId: Scalars['String'];
  content: Scalars['String'];
  isAnswerTrue: Scalars['Boolean'];
};


export type MutationUpdateKnowledgeBaseBlockListItemArgs = {
  listItemId: Scalars['String'];
  content: Scalars['String'];
  isNumbered?: Maybe<Scalars['Boolean']>;
};


export type MutationDeleteKnowledgeBaseBlockListItemArgs = {
  id: Scalars['String'];
};


export type MutationDeleteKnowledgeBaseBlockTestAnswerArgs = {
  id: Scalars['String'];
};


export type MutationDeleteKnowledgeBaseBlockArgs = {
  id: Scalars['String'];
};


export type MutationAnswerKnowledgeBaseBlockTestArgs = {
  blockId: Scalars['String'];
  answerId: Scalars['String'];
};


export type MutationPublishKnowledgeBaseTestBlocksByThemeIdArgs = {
  themeId: Scalars['String'];
};


export type MutationCreateKnowledgeBaseCategoryArgs = {
  name: Scalars['String'];
  isFor: KnowledgeBaseCategoryIsForEnum;
  photo?: Maybe<Scalars['Upload']>;
  backgroundColor?: Maybe<Scalars['String']>;
};


export type MutationUpdateKnowledgeBaseCategoryArgs = {
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  isFor?: Maybe<KnowledgeBaseCategoryIsForEnum>;
  photo?: Maybe<Scalars['Upload']>;
  backgroundColor?: Maybe<Scalars['String']>;
};


export type MutationDeleteKnowledgeBaseCategoryArgs = {
  id: Scalars['String'];
};


export type MutationSetKnowledgeBaseCategoryParticipantsArgs = {
  knowledgeBaseCategoryData: SetKnowledgeBaseCategoryParticipantsInput;
};


export type MutationKnowledgeBaseCategoryParticipantStatusChangeArgs = {
  knowledgeBaseCategoryIds: Array<Maybe<Scalars['String']>>;
  userId: Scalars['String'];
};


export type MutationCreateKnowledgeBaseThemeArgs = {
  name: Scalars['String'];
  categoryId: Scalars['String'];
};


export type MutationUpdateKnowledgeBaseThemeArgs = {
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
};


export type MutationDeleteKnowledgeBaseThemeArgs = {
  id: Scalars['String'];
};


export type MutationPublishKnowledgeBaseThemeArgs = {
  id: Scalars['String'];
};


export type MutationCreateLessonArgs = {
  mainId: Scalars['String'];
  themeId?: Maybe<Scalars['String']>;
  modelId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};


export type MutationUpdateLessonArgs = {
  lessonId: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};


export type MutationDeleteLessonArgs = {
  lessonId: Scalars['String'];
};


export type MutationPublishLessonArgs = {
  lessonId: Scalars['String'];
};


export type MutationUnpublishLessonArgs = {
  lessonId: Scalars['String'];
};


export type MutationCreateLessonBlockArgs = {
  type: LessonBlockTypeEnum;
  pageId: Scalars['String'];
};


export type MutationUpdateLessonBlockArgs = {
  lessonBlockData: UpdateLessonBlockInput;
};


export type MutationDeleteLessonBlockArgs = {
  lessonBlockId: Scalars['String'];
};


export type MutationCreateLessonListItemArgs = {
  lessonBlockId: Scalars['String'];
  lessonListItemData: LessonBlockListInput;
};


export type MutationUpdateLessonListItemArgs = {
  listItemId: Scalars['String'];
  lessonListItemData: LessonBlockListInput;
};


export type MutationDeleteLessonListItemArgs = {
  listItemId: Scalars['String'];
};


export type MutationCreateLessonPageArgs = {
  type: LessonPageTypeEnum;
  lessonId: Scalars['String'];
};


export type MutationDeleteLessonPageArgs = {
  id: Scalars['String'];
};


export type MutationCreateLessonQuestionArgs = {
  type: LessonQuestionTypeEnum;
  pageId: Scalars['String'];
};


export type MutationUpdateLessonQuestionArgs = {
  data: UpdateLessonQuestionInput;
};


export type MutationDeleteLessonQuestionArgs = {
  id: Scalars['String'];
};


export type MutationCreateQuestionVariantArgs = {
  questionId: Scalars['String'];
  questionVariantData: QuestionVariantInput;
};


export type MutationUpdateQuestionVariantArgs = {
  variantId: Scalars['String'];
  questionVariantData: QuestionVariantInput;
};


export type MutationDeleteQuestionVariantArgs = {
  variantId: Scalars['String'];
};


export type MutationCreateMobileNotificationArgs = {
  notificationData: CreateMobileNotificationInput;
  photo?: Maybe<Scalars['Upload']>;
};


export type MutationUpdateMobileNotificationArgs = {
  notificationData: UpdateMobileNotificationInput;
  photo?: Maybe<Scalars['Upload']>;
};


export type MutationSetMobileNotificationParticipantsArgs = {
  notificationData: SetMobileNotificationParticipantsInput;
};


export type MutationPublishMobileNotificationArgs = {
  id: Scalars['String'];
};


export type MutationMobileNotificationParticipantStatusChangeArgs = {
  notificationIds: Array<Maybe<Scalars['String']>>;
  userId: Scalars['String'];
};


export type MutationDeleteMobileNotificationArgs = {
  id: Scalars['String'];
};


export type MutationCreateMonthHourPlanArgs = {
  date: Scalars['Date'];
  hoursPlan: Scalars['Int'];
};


export type MutationUpdateMonthHourPlanArgs = {
  id: Scalars['String'];
  hoursPlan: Scalars['Int'];
};


export type MutationDeleteMonthHourPlanArgs = {
  id: Scalars['String'];
};


export type MutationCreateMultiLessonArgs = {
  educationPlanId: Scalars['String'];
  name: Scalars['String'];
};


export type MutationUpdateMultiLessonArgs = {
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};


export type MutationDeleteMultiLessonArgs = {
  id: Scalars['String'];
};


export type MutationCreateMultiLessonBlockArgs = {
  name: Scalars['String'];
  type: MultiLessonBlockTypeEnum;
  multiLessonId: Scalars['String'];
};


export type MutationUpdateMultiLessonBlockArgs = {
  id: Scalars['String'];
  data: UpdateMultiLessonBlockInput;
};


export type MutationDeleteMultiLessonBlockArgs = {
  id: Scalars['String'];
};


export type MutationCreateNewsArgs = {
  newsData: CreateNewsInput;
  photo?: Maybe<Scalars['Upload']>;
};


export type MutationUpdateNewsArgs = {
  newsData: UpdateNewsInput;
  photo?: Maybe<Scalars['Upload']>;
};


export type MutationSetNewsParticipantsArgs = {
  newsData: SetNewsParticipantsInput;
};


export type MutationDeleteNewsArgs = {
  id: Scalars['String'];
};


export type MutationPublishNewsArgs = {
  id: Scalars['String'];
};


export type MutationNewsParticipantStatusChangeArgs = {
  newsId: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationCreateNotificationArgs = {
  notificationData: CreateNotificationInput;
};


export type MutationUpdateNotificationArgs = {
  notificationData: UpdateNotificationInput;
};


export type MutationSetNotificationParticipantsArgs = {
  notificationData: SetNotificationParticipantsInput;
};


export type MutationDeleteNotificationArgs = {
  id: Scalars['String'];
};


export type MutationPublishNotificationArgs = {
  id: Scalars['String'];
};


export type MutationViewNotificationArgs = {
  notificationId: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationNotificationParticipantStatusChangeArgs = {
  notificationIds: Array<Maybe<Scalars['String']>>;
  userId: Scalars['String'];
};


export type MutationCreatePayrollBonusArgs = {
  payrollId: Scalars['String'];
  name: Scalars['String'];
  value: Scalars['Int'];
};


export type MutationUpdatePayrollBonusArgs = {
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Int']>;
};


export type MutationDeletePayrollBonusArgs = {
  bonusId: Scalars['String'];
};


export type MutationCreatePayrollKpiArgs = {
  kpiData: CreatePayrollKpiInput;
};


export type MutationUpdatePayrollKpiArgs = {
  kpiData: UpdatePayrollKpiInput;
};


export type MutationDeletePayrollKpiArgs = {
  kpiId: Scalars['String'];
};


export type MutationCreatePayrollSalaryPartArgs = {
  payrollId: Scalars['String'];
  name: Scalars['String'];
  date: Scalars['Date'];
  value: Scalars['Int'];
};


export type MutationUpdatePayrollSalaryPartArgs = {
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
  value?: Maybe<Scalars['Int']>;
};


export type MutationDeletePayrollSalaryPartArgs = {
  partId: Scalars['String'];
};


export type MutationCreateProductArgs = {
  productData: CreateProductInput;
};


export type MutationUpdateProductArgs = {
  productData: UpdateProductInput;
};


export type MutationDeleteProductArgs = {
  productId: Scalars['String'];
};


export type MutationCreateProjectArgs = {
  jiraKey: Scalars['String'];
  name: Scalars['String'];
};


export type MutationCreateProjectByJiraDataArgs = {
  jira: Scalars['String'];
  jiraKey: Scalars['String'];
};


export type MutationUpdateProjectArgs = {
  projectData: UpdateProjectInput;
  photo?: Maybe<Scalars['Upload']>;
};


export type MutationDeleteProjectArgs = {
  projectId: Scalars['String'];
};


export type MutationSyncProjectArgs = {
  projectId: Scalars['String'];
};


export type MutationAssignProjectTrackerIdArgs = {
  projectId: Scalars['String'];
};


export type MutationUpdateProjectFilesArgs = {
  projectId: Scalars['String'];
  deleteFileId?: Maybe<Scalars['String']>;
  uploadFile?: Maybe<UploadFileInput>;
};


export type MutationCreateProjectAdditionalContactArgs = {
  projectAdditionalContactData: CreateProjectAdditionalContactInput;
};


export type MutationUpdateProjectAdditionalContactArgs = {
  projectAdditionalContactData: UpdateProjectAdditionalContactInput;
};


export type MutationDeleteProjectAdditionalContactArgs = {
  projectAdditionalContactId: Scalars['String'];
};


export type MutationCreateProjectCorrectionArgs = {
  projectCorrectionData: CreateProjectCorrectionInput;
};


export type MutationUpdateProjectCorrectionArgs = {
  projectCorrectionData: UpdateProjectCorrectionInput;
};


export type MutationDeleteProjectCorrectionArgs = {
  projectCorrectionId: Scalars['String'];
};


export type MutationAddCorrectionsToProjectArgs = {
  projectCorrectionIds: Array<Maybe<Scalars['String']>>;
  projectId: Scalars['String'];
};


export type MutationRemoveCorrectionsFromProjectArgs = {
  projectCorrectionIds: Array<Maybe<Scalars['String']>>;
  projectId: Scalars['String'];
};


export type MutationCreateProjectCustomFieldsMapArgs = {
  customFieldsData: CreateProjectCustomFieldsMapInput;
};


export type MutationUpdateProjectCustomFieldsMapArgs = {
  customFieldsData: UpdateProjectCustomFieldsMapInput;
};


export type MutationUpdateProjectCustomFieldsMapByProjectArgs = {
  customFieldsData: UpdateProjectCustomFieldsMapByProjectInput;
};


export type MutationDeleteProjectCustomFieldsMapArgs = {
  id: Scalars['String'];
};


export type MutationCreateProjectKanbanArgs = {
  projectId: Scalars['String'];
  name: Scalars['String'];
};


export type MutationRestartSprintArgs = {
  id: Scalars['String'];
  name: Scalars['String'];
};


export type MutationDeleteProjectKanbanArgs = {
  id: Scalars['String'];
};


export type MutationClearProjectKanbanArgs = {
  id: Scalars['String'];
};


export type MutationActivateProjectKanbanArgs = {
  id: Scalars['String'];
};


export type MutationCreateBaseKanbanWorkflowStepArgs = {
  name: Scalars['String'];
  taskStatusId: Scalars['String'];
  order: Scalars['Int'];
};


export type MutationCreateKanbanWorkflowStepForProjectArgs = {
  name: Scalars['String'];
  taskStatusId: Scalars['String'];
  projectId: Scalars['String'];
  order: Scalars['Int'];
};


export type MutationUpdateKanbanWorkflowStepArgs = {
  id: Scalars['String'];
  name: Scalars['String'];
  order: Scalars['Int'];
};


export type MutationDeleteKanbanWorkflowStepArgs = {
  id: Scalars['String'];
};


export type MutationCreateProjectLinkArgs = {
  projectLinkData: CreateProjectLinkInput;
};


export type MutationUpdateProjectLinkArgs = {
  projectLinkData: UpdateProjectLinkInput;
};


export type MutationDeleteProjectLinkArgs = {
  projectLinkId: Scalars['String'];
};


export type MutationAddLinksToProjectArgs = {
  projectLinkIds: Array<Maybe<Scalars['String']>>;
  projectId: Scalars['String'];
};


export type MutationRemoveLinksFromProjectArgs = {
  projectLinkIds: Array<Maybe<Scalars['String']>>;
  projectId: Scalars['String'];
};


export type MutationCreateProjectPresentationArgs = {
  projectId: Scalars['String'];
  link: Scalars['String'];
  title?: Maybe<Scalars['String']>;
};


export type MutationUpdateProjectPresentationArgs = {
  id: Scalars['String'];
  link?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};


export type MutationDeleteProjectPresentationArgs = {
  projectPresentationId: Scalars['String'];
};


export type MutationCreateProjectStepArgs = {
  projectStepData: CreateProjectStepInput;
};


export type MutationUpdateProjectStepArgs = {
  projectStepData: UpdateProjectStepInput;
};


export type MutationDeleteProjectStepArgs = {
  projectStepId: Scalars['String'];
};


export type MutationCreateProjectTaskArgs = {
  taskData: CreateProjectTaskInput;
};


export type MutationCreateProjectTaskInKanbanArgs = {
  taskData: CreateProjectTaskInput;
  workflowStepId: Scalars['String'];
  workflowStepOrder: Scalars['Int'];
};


export type MutationUpdateProjectTaskArgs = {
  taskData: UpdateProjectTaskInput;
};


export type MutationDeleteProjectTaskArgs = {
  id: Scalars['String'];
};


export type MutationCreateProjectTasksInTrackerArgs = {
  projectId: Scalars['String'];
};


export type MutationSyncProjectTaskWithTrackerArgs = {
  id: Scalars['String'];
};


export type MutationSyncProjectTasksWithTrackerArgs = {
  projectId: Scalars['String'];
};


export type MutationImportProjectTasksFromGSheetsArgs = {
  projectId: Scalars['String'];
  gSheetId: Scalars['String'];
  gSheetTitle: Scalars['String'];
};


export type MutationAddProjectTasksToKanbanArgs = {
  tasksIds: Array<Maybe<Scalars['String']>>;
  workflowStepId: Scalars['String'];
  workflowStepStartOrder: Scalars['Int'];
};


export type MutationChangeProjectTaskWorkflowStepArgs = {
  tasksId: Scalars['String'];
  workflowStepId: Scalars['String'];
  workflowStepOrder: Scalars['Int'];
};


export type MutationRemoveProjectTaskFromKanbanArgs = {
  tasksId: Scalars['String'];
};


export type MutationSetProjectTaskLateReasonArgs = {
  tasksId: Scalars['String'];
  lateReason: Scalars['String'];
};


export type MutationSetTaskBoundariesArgs = {
  id: Scalars['String'];
  startDateEstimate: Scalars['String'];
  finishDateEstimate: Scalars['String'];
  isEpic?: Maybe<Scalars['Boolean']>;
};


export type MutationCreatePtCheckListItemArgs = {
  projectTaskId: Scalars['String'];
  name: Scalars['String'];
};


export type MutationUpdatePtCheckListItemArgs = {
  id: Scalars['String'];
  name: Scalars['String'];
};


export type MutationDeletePtCheckListItemArgs = {
  id: Scalars['String'];
};


export type MutationChangePtCheckListItemStatusArgs = {
  id: Scalars['String'];
};


export type MutationCreateProjectTaskCommentArgs = {
  projectTaskId: Scalars['String'];
  text: Scalars['String'];
  file?: Maybe<Scalars['Upload']>;
};


export type MutationUpdateProjectTaskCommentArgs = {
  id: Scalars['String'];
  text: Scalars['String'];
  file?: Maybe<Scalars['Upload']>;
};


export type MutationDeleteProjectTaskCommentArgs = {
  id: Scalars['String'];
};


export type MutationDeleteProjectTaskCommentFileArgs = {
  id: Scalars['String'];
};


export type MutationCreateBaseProjectTaskLabelArgs = {
  name: Scalars['String'];
  color: Scalars['String'];
};


export type MutationCreateProjectTaskLabelForProjectArgs = {
  name: Scalars['String'];
  color: Scalars['String'];
  projectId: Scalars['String'];
};


export type MutationUpdateProjectTaskLabelArgs = {
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
};


export type MutationDeleteProjectTaskLabelArgs = {
  id: Scalars['String'];
};


export type MutationAssignProjectTaskLabelArgs = {
  projectTaskId: Scalars['String'];
  labelId: Scalars['String'];
};


export type MutationRemoveProjectTaskLabelArgs = {
  projectTaskId: Scalars['String'];
  labelId: Scalars['String'];
};


export type MutationCreateBaseProjectTaskStatusArgs = {
  name: Scalars['String'];
};


export type MutationCreateProjectTaskStatusForProjectArgs = {
  name: Scalars['String'];
  projectId: Scalars['String'];
};


export type MutationUpdateProjectTaskStatusArgs = {
  id: Scalars['String'];
  name: Scalars['String'];
};


export type MutationDeleteProjectTaskStatusArgs = {
  id: Scalars['String'];
};


export type MutationCreateProjectTypeArgs = {
  name: Scalars['String'];
};


export type MutationUpdateProjectTypeArgs = {
  typeId: Scalars['String'];
  name: Scalars['String'];
};


export type MutationDeleteProjectTypeArgs = {
  typeId: Scalars['String'];
};


export type MutationCreatePurchaseArgs = {
  productId: Scalars['String'];
};


export type MutationUpdatePurchaseArgs = {
  purchaseId: Scalars['String'];
  status: PurchaseStatusEnum;
};


export type MutationDeletePurchaseArgs = {
  purchaseId: Scalars['String'];
};


export type MutationCreateQuestionArgs = {
  testId: Scalars['String'];
  question: Scalars['String'];
  photo?: Maybe<Scalars['Upload']>;
};


export type MutationUpdateQuestionArgs = {
  id: Scalars['String'];
  question: Scalars['String'];
  photo?: Maybe<Scalars['Upload']>;
};


export type MutationSetQuestionRightVariantArgs = {
  questionId: Scalars['String'];
  rightVariantId: Scalars['String'];
};


export type MutationDeleteQuestionArgs = {
  id: Scalars['String'];
};


export type MutationCreateSchoolArgs = {
  schoolData: CreateSchoolInput;
  photo?: Maybe<Scalars['Upload']>;
};


export type MutationUpdateSchoolArgs = {
  schoolData: CreateSchoolInput;
  photo?: Maybe<Scalars['Upload']>;
  id: Scalars['String'];
};


export type MutationAddTeacherToSchoolArgs = {
  schoolId: Scalars['String'];
  teacherId: Scalars['String'];
};


export type MutationDeleteSchoolArgs = {
  id: Scalars['String'];
};


export type MutationDeleteSchoolPhotoArgs = {
  id: Scalars['String'];
};


export type MutationCreateSmartNotificationArgs = {
  smartNotificationData: CreateSmartNotificationInput;
  photo?: Maybe<Scalars['Upload']>;
  video?: Maybe<Scalars['Upload']>;
};


export type MutationUpdateSmartNotificationArgs = {
  smartNotificationData: UpdateSmartNotificationInput;
  photo?: Maybe<Scalars['Upload']>;
  video?: Maybe<Scalars['Upload']>;
};


export type MutationDeleteSmartNotificationArgs = {
  id: Scalars['String'];
};


export type MutationPublishSmartNotificationArgs = {
  id: Scalars['String'];
};


export type MutationUnpublishSmartNotificationArgs = {
  id: Scalars['String'];
};


export type MutationDeleteSmartNotificationPhotoArgs = {
  id: Scalars['String'];
};


export type MutationCreateSmartNotificationPollListArgs = {
  name: Scalars['String'];
  notificationId: Scalars['String'];
};


export type MutationUpdateSmartNotificationPollListArgs = {
  id: Scalars['String'];
  name: Scalars['String'];
};


export type MutationDeleteSmartNotificationPollListArgs = {
  id: Scalars['String'];
};


export type MutationCreateTeacherSchoolReportArgs = {
  reportToId: Scalars['String'];
};


export type MutationCreateTestArgs = {
  testData: CreateTestInput;
};


export type MutationUpdateTestArgs = {
  testData: UpdateTestInput;
};


export type MutationPublishTestArgs = {
  id: Scalars['String'];
};


export type MutationDeleteTestArgs = {
  id: Scalars['String'];
};


export type MutationCreateTraningArgs = {
  traningData: CreateTraningInput;
};


export type MutationUpdateTraningArgs = {
  id: Scalars['String'];
  traningData: CreateTraningInput;
};


export type MutationDeleteTraningArgs = {
  id: Scalars['String'];
};


export type MutationPublishTraningArgs = {
  id: Scalars['String'];
};


export type MutationUnpublishTraningArgs = {
  id: Scalars['String'];
};


export type MutationDeleteUploadedFileArgs = {
  uploadedFileId: Scalars['String'];
};


export type MutationCreateUserArgs = {
  userData: CreateUserInput;
  isFrom: IsFromEnum;
};


export type MutationConfirmUserArgs = {
  token: Scalars['String'];
};


export type MutationLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  isFrom: IsFromEnum;
};


export type MutationLoginMobileArgs = {
  phone: Scalars['String'];
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String'];
  isFrom: IsFromEnum;
};


export type MutationSendConfirmEmailArgs = {
  userId: Scalars['String'];
  isFrom: IsFromEnum;
};


export type MutationResetPasswordArgs = {
  password: Scalars['String'];
  token: Scalars['String'];
  isFrom: IsFromEnum;
};


export type MutationUpdateUserArgs = {
  userData: UpdateUserInput;
  photo?: Maybe<Scalars['Upload']>;
};


export type MutationDeleteUserArgs = {
  userId: Scalars['String'];
};


export type MutationAssignUsersToGroupArgs = {
  userIds: Array<Maybe<Scalars['String']>>;
  userGroupId: Scalars['String'];
};


export type MutationRemoveUsersFromGroupArgs = {
  userIds: Array<Maybe<Scalars['String']>>;
  userGroupId: Scalars['String'];
};


export type MutationAssignUsersToProjectArgs = {
  userIds: Array<Maybe<Scalars['String']>>;
  projectId: Scalars['String'];
};


export type MutationRemoveUsersFromProjectArgs = {
  userIds: Array<Maybe<Scalars['String']>>;
  projectId: Scalars['String'];
};


export type MutationAssignProjectsToUserArgs = {
  userId: Scalars['String'];
  projectIds: Array<Maybe<Scalars['String']>>;
};


export type MutationRemoveProjectsFromUserArgs = {
  userId: Scalars['String'];
  projectIds: Array<Maybe<Scalars['String']>>;
};


export type MutationAssignAnimationBundleParticipantsArgs = {
  animationId: Scalars['String'];
  userIds: Array<Maybe<Scalars['String']>>;
  availableForEveryone: Scalars['Boolean'];
};


export type MutationAssignUserTrackerIdArgs = {
  userId?: Maybe<Scalars['String']>;
};


export type MutationInviteUserToTrackerArgs = {
  userId?: Maybe<Scalars['String']>;
};


export type MutationCreateTrackerContractArgs = {
  userId?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['String']>;
};


export type MutationDeleteTrackerContractArgs = {
  contractId?: Maybe<Scalars['String']>;
};


export type MutationCleanUserDataForClientRoleArgs = {
  userId?: Maybe<Scalars['String']>;
};


export type MutationCreateUserPasswordArgs = {
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};


export type MutationCreateEventUserArgs = {
  userData: CreateEventUserInput;
};


export type MutationRegisterEventUserArgs = {
  userData: RegisterEventUserInput;
};


export type MutationCreateUserAgreementArgs = {
  name: Scalars['String'];
};


export type MutationUpdateUserAgreementArgs = {
  userAgreementId: Scalars['String'];
  name: Scalars['String'];
};


export type MutationDeleteUserAgreementArgs = {
  userAgreementId: Scalars['String'];
};


export type MutationCreateUserDepartmentArgs = {
  name: Scalars['String'];
};


export type MutationUpdateUserDepartmentArgs = {
  departmentId: Scalars['String'];
  name: Scalars['String'];
};


export type MutationDeleteUserDepartmentArgs = {
  departmentId: Scalars['String'];
};


export type MutationCreateUserExpHistoryArgs = {
  difference: Scalars['Int'];
  userId?: Maybe<Scalars['String']>;
};


export type MutationCreateUserGroupArgs = {
  userGroupData: CreateUserGroupInput;
};


export type MutationUpdateUserGroupArgs = {
  userGroupData: UpdateUserGroupInput;
};


export type MutationDeleteUserGroupArgs = {
  userGroupId: Scalars['String'];
};


export type MutationCreateUserJobArgs = {
  name: Scalars['String'];
};


export type MutationUpdateUserJobArgs = {
  userJobId: Scalars['String'];
  name: Scalars['String'];
};


export type MutationDeleteUserJobArgs = {
  userJobId: Scalars['String'];
};


export type MutationCreateUserPaymentArgs = {
  paymentData: CreateUserPaymentInput;
};


export type MutationUpdateUserPaymentArgs = {
  paymentData: UpdateUserPaymentInput;
};


export type MutationSetUserPaymentDocumentsArgs = {
  paymentId: Scalars['String'];
  deletedDocuments?: Maybe<Array<Maybe<Scalars['String']>>>;
  newDocuments?: Maybe<Array<Maybe<Scalars['Upload']>>>;
};


export type MutationDeleteUserPaymentArgs = {
  paymentId: Scalars['String'];
};


export type MutationPayUserPaymentArgs = {
  paymentId: Scalars['String'];
};


export type MutationPublishUserPaymentArgs = {
  paymentId: Scalars['String'];
};


export type MutationCreateUserPayrollArgs = {
  payrollData: CreateUserPayrollInput;
};


export type MutationUpdateUserPayrollArgs = {
  payrollData: UpdateUserPayrollInput;
};


export type MutationDeleteUserPayrollArgs = {
  payrollId: Scalars['String'];
};


export type MutationCreateRateHistoryArgs = {
  userId: Scalars['String'];
  rate: Scalars['Int'];
  changeDate: Scalars['Date'];
};


export type MutationUpdateRateHistoryArgs = {
  id: Scalars['String'];
  rate?: Maybe<Scalars['Int']>;
  changeDate?: Maybe<Scalars['Date']>;
};


export type MutationDeleteSRateHistoryArgs = {
  id: Scalars['String'];
};


export type MutationCreateSalaryHistoryArgs = {
  userId: Scalars['String'];
  newSalary: Scalars['Int'];
  changeDate: Scalars['Date'];
  description?: Maybe<Scalars['String']>;
};


export type MutationUpdateSalaryHistoryArgs = {
  salaryHistoryId: Scalars['String'];
  newSalary?: Maybe<Scalars['Int']>;
  changeDate?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
};


export type MutationDeleteSalaryHistoryArgs = {
  salaryHistoryId: Scalars['String'];
};


export type MutationUpdateUserStatsArgs = {
  userStatsData: UpdateUserStatsInput;
};


export type MutationCreateVacanciesPlanArgs = {
  planData: CreateVacanciesPlanInput;
};


export type MutationUpdateVacanciesPlanArgs = {
  planData: UpdateVacanciesPlanInput;
};


export type MutationDeleteVacanciesPlanArgs = {
  planId: Scalars['String'];
};


export type MutationCreateVariantArgs = {
  questionId: Scalars['String'];
  content: Scalars['String'];
};


export type MutationUpdateVariantArgs = {
  variantId: Scalars['String'];
  content: Scalars['String'];
};


export type MutationDeleteVariantArgs = {
  id: Scalars['String'];
};

export type News = {
  __typename?: 'News';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  isFor?: Maybe<NewsIsForEnum>;
  description?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  youTubeLink?: Maybe<Scalars['String']>;
  availableFor?: Maybe<AvailableForEnum>;
  participants?: Maybe<Array<Maybe<NewsParticipant>>>;
  isPublished?: Maybe<Scalars['Boolean']>;
  publishedAt?: Maybe<Scalars['Date']>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export type NewsByParticipant = {
  __typename?: 'NewsByParticipant';
  news?: Maybe<News>;
  isViewed?: Maybe<Scalars['Boolean']>;
};

export enum NewsIsForEnum {
  AdminPanel = 'ADMIN_PANEL',
  LkTeacher = 'LK_TEACHER',
  AdminPanelAndLkTeacher = 'ADMIN_PANEL_AND_LK_TEACHER'
}

export type NewsParticipant = {
  __typename?: 'NewsParticipant';
  id?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  news?: Maybe<News>;
  isWatched?: Maybe<Scalars['Boolean']>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export type NoEpicProblems = {
  __typename?: 'NoEpicProblems';
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  epicKey?: Maybe<Scalars['String']>;
};

export type Notification = {
  __typename?: 'Notification';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  availableFor?: Maybe<AvailableForEnum>;
  participants?: Maybe<Array<Maybe<NotificationParticipant>>>;
  isPublished?: Maybe<Scalars['Boolean']>;
  publishedAt?: Maybe<Scalars['Date']>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export type NotificationByParticipant = {
  __typename?: 'NotificationByParticipant';
  notification?: Maybe<Notification>;
  isViewed?: Maybe<Scalars['Boolean']>;
};

export type NotificationParticipant = {
  __typename?: 'NotificationParticipant';
  id?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  notification?: Maybe<Notification>;
  isWatched?: Maybe<Scalars['Boolean']>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export enum Os {
  IOs = 'iOS',
  Android = 'android'
}

export type PayrollBonus = {
  __typename?: 'PayrollBonus';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Int']>;
  payroll?: Maybe<UserPayroll>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export type PayrollKpi = {
  __typename?: 'PayrollKpi';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  weigh?: Maybe<Scalars['Float']>;
  plan?: Maybe<Scalars['Int']>;
  fact?: Maybe<Scalars['Int']>;
  payroll?: Maybe<UserPayroll>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export type PayrollSalaryPart = {
  __typename?: 'PayrollSalaryPart';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
  value?: Maybe<Scalars['Int']>;
  payroll?: Maybe<UserPayroll>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export type Prc = {
  __typename?: 'Prc';
  plan?: Maybe<Scalars['Float']>;
  fact?: Maybe<Scalars['Float']>;
};

export type Product = {
  __typename?: 'Product';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  features?: Maybe<Array<Maybe<Scalars['String']>>>;
  type?: Maybe<ProductType>;
  flex?: Maybe<Scalars['Int']>;
  requiredKpi?: Maybe<Scalars['Int']>;
  requiredLevel?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export type ProductType = {
  __typename?: 'ProductType';
  id?: Maybe<Scalars['Int']>;
  type?: Maybe<ProductTypeEnum>;
  displayName?: Maybe<Scalars['String']>;
};

export enum ProductTypeEnum {
  Impression = 'IMPRESSION',
  Merch = 'MERCH',
  Dance = 'DANCE',
  Item = 'ITEM',
  Clothes = 'CLOTHES',
  Other = 'OTHER'
}

export type Project = {
  __typename?: 'Project';
  id?: Maybe<Scalars['String']>;
  trackerId?: Maybe<Scalars['Int']>;
  isLocal?: Maybe<Scalars['Boolean']>;
  jiraId?: Maybe<Scalars['String']>;
  jira?: Maybe<Scalars['String']>;
  jiraKey?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<ProjectStatus>;
  projectType?: Maybe<ProjectType>;
  stages?: Maybe<Array<Maybe<Scalars['String']>>>;
  startDateEstimate?: Maybe<Scalars['Date']>;
  finishDateEstimate?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
  finishDate?: Maybe<Scalars['Date']>;
  budget?: Maybe<Scalars['Int']>;
  cost?: Maybe<Scalars['Int']>;
  client?: Maybe<User>;
  projectManager?: Maybe<User>;
  additionalContacts?: Maybe<Array<Maybe<ProjectAdditionalContact>>>;
  employees?: Maybe<Array<Maybe<User>>>;
  contactPersons?: Maybe<Array<Maybe<User>>>;
  links?: Maybe<Array<Maybe<ProjectLink>>>;
  corrections?: Maybe<Array<Maybe<ProjectCorrection>>>;
  diagram?: Maybe<Array<Maybe<ProjectStep>>>;
  tasks?: Maybe<Array<Maybe<ProjectTask>>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  stack?: Maybe<Array<Maybe<Scalars['String']>>>;
  kanbanId?: Maybe<Scalars['String']>;
  kanban?: Maybe<ProjectKanban>;
  blockDiagrams?: Maybe<Scalars['String']>;
  contractTemplates?: Maybe<Scalars['String']>;
  finalAgreement?: Maybe<Scalars['String']>;
  brief?: Maybe<Scalars['String']>;
  testTaskFile?: Maybe<Scalars['String']>;
  renderingFile?: Maybe<Scalars['String']>;
  presentations?: Maybe<Array<Maybe<ProjectPresentation>>>;
  designFigma?: Maybe<Scalars['String']>;
  risks?: Maybe<Scalars['String']>;
  riskScore?: Maybe<Scalars['Int']>;
  trackerContracts?: Maybe<Array<Maybe<Contract>>>;
  files?: Maybe<Array<Maybe<ProjectFile>>>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
  deletedDate?: Maybe<Scalars['Date']>;
};

export type ProjectAdditionalContact = {
  __typename?: 'ProjectAdditionalContact';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  project?: Maybe<Project>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export type ProjectCorrection = {
  __typename?: 'ProjectCorrection';
  id?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  status?: Maybe<ProjectCorrectionStatus>;
  files?: Maybe<Array<Maybe<UploadedFile>>>;
  project?: Maybe<Project>;
  comments?: Maybe<Array<Maybe<CorrectionComment>>>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export type ProjectCorrectionStatus = {
  __typename?: 'ProjectCorrectionStatus';
  id?: Maybe<Scalars['Int']>;
  status?: Maybe<ProjectCorrectionStatusEnum>;
  displayName?: Maybe<Scalars['String']>;
};

export enum ProjectCorrectionStatusEnum {
  New = 'NEW',
  Review = 'REVIEW',
  Discussion = 'DISCUSSION',
  Accepted = 'ACCEPTED',
  Done = 'DONE',
  Rejected = 'REJECTED'
}

export type ProjectCustomFieldsMap = {
  __typename?: 'ProjectCustomFieldsMap';
  id?: Maybe<Scalars['String']>;
  startDateEstimate?: Maybe<Scalars['String']>;
  startDateFact?: Maybe<Scalars['String']>;
  finishDateEstimate?: Maybe<Scalars['String']>;
  finishDateFact?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['String']>;
  project?: Maybe<Project>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export type ProjectEpic = {
  __typename?: 'ProjectEpic';
  epicKey?: Maybe<Scalars['String']>;
  epicName?: Maybe<Scalars['String']>;
};

export type ProjectFile = {
  __typename?: 'ProjectFile';
  id?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  project?: Maybe<Project>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  isFor?: Maybe<IsForEnum>;
};

export type ProjectJiraTask = {
  __typename?: 'ProjectJiraTask';
  status?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  assignee?: Maybe<Scalars['String']>;
  costPerHour?: Maybe<Scalars['Int']>;
  costEstimate?: Maybe<Scalars['Int']>;
  costActual?: Maybe<Scalars['Int']>;
  timeEstimate?: Maybe<Scalars['Int']>;
  timeSpent?: Maybe<Scalars['Int']>;
};

export type ProjectKanban = {
  __typename?: 'ProjectKanban';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['String']>;
  project?: Maybe<Project>;
  isCompleted?: Maybe<Scalars['Boolean']>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
  deletedDate?: Maybe<Scalars['Date']>;
};

export type ProjectLight = {
  __typename?: 'ProjectLight';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ProjectLink = {
  __typename?: 'ProjectLink';
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  login?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  isFor?: Maybe<IsForEnum>;
  project?: Maybe<Project>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export type ProjectPresentation = {
  __typename?: 'ProjectPresentation';
  id?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  project?: Maybe<Project>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export type ProjectStatus = {
  __typename?: 'ProjectStatus';
  id?: Maybe<Scalars['Int']>;
  status?: Maybe<ProjectStatusEnum>;
  displayName?: Maybe<Scalars['String']>;
};

export enum ProjectStatusEnum {
  New = 'NEW',
  InProgress = 'IN_PROGRESS',
  Done = 'DONE'
}

export type ProjectStep = {
  __typename?: 'ProjectStep';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  startDateEstimate?: Maybe<Scalars['String']>;
  startDateFact?: Maybe<Scalars['String']>;
  finishDateEstimate?: Maybe<Scalars['String']>;
  finishDateFact?: Maybe<Scalars['String']>;
  statusId?: Maybe<Scalars['String']>;
  status?: Maybe<ProjectTaskStatus>;
  project?: Maybe<Project>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
  deletedDate?: Maybe<Scalars['Date']>;
};

export type ProjectTask = {
  __typename?: 'ProjectTask';
  id?: Maybe<Scalars['String']>;
  trackerId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  comments?: Maybe<Array<Maybe<ProjectTaskComment>>>;
  items?: Maybe<Array<Maybe<ProjectTaskCheckListItem>>>;
  key?: Maybe<Scalars['String']>;
  epicKey?: Maybe<Scalars['String']>;
  epicName?: Maybe<Scalars['String']>;
  assignee?: Maybe<User>;
  assigneeId?: Maybe<Scalars['String']>;
  assigneeJiraId?: Maybe<Scalars['String']>;
  assigneeName?: Maybe<Scalars['String']>;
  responsibleId?: Maybe<Scalars['String']>;
  responsible?: Maybe<User>;
  projectKey?: Maybe<Scalars['String']>;
  startDateEstimate?: Maybe<Scalars['String']>;
  startDateFact?: Maybe<Scalars['String']>;
  finishDateEstimate?: Maybe<Scalars['String']>;
  finishDateFact?: Maybe<Scalars['String']>;
  timeEstimate?: Maybe<Scalars['Int']>;
  timeSpent?: Maybe<Scalars['Int']>;
  taskStatusId?: Maybe<Scalars['String']>;
  taskStatus?: Maybe<ProjectTaskStatus>;
  workflowStepOrder?: Maybe<Scalars['Int']>;
  kanbanWorkflowStepId?: Maybe<Scalars['String']>;
  kanbanWorkflowStep?: Maybe<KanbanWorkflowStep>;
  labels?: Maybe<Array<Maybe<ProjectTaskLabel>>>;
  projectId?: Maybe<Scalars['String']>;
  project?: Maybe<Project>;
  history?: Maybe<Array<Maybe<ProjectTaskHistory>>>;
  lateReason?: Maybe<Scalars['String']>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
  deletedDate?: Maybe<Scalars['Date']>;
};

export type ProjectTaskCheckListItem = {
  __typename?: 'ProjectTaskCheckListItem';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  isCompleted?: Maybe<Scalars['Boolean']>;
  projectTaskId?: Maybe<Scalars['String']>;
  projectTask?: Maybe<ProjectTask>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export type ProjectTaskComment = {
  __typename?: 'ProjectTaskComment';
  id?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['String']>;
  projectTaskId?: Maybe<Scalars['String']>;
  projectTask?: Maybe<ProjectTask>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export type ProjectTaskHistory = {
  __typename?: 'ProjectTaskHistory';
  id?: Maybe<Scalars['String']>;
  previousStatusId?: Maybe<Scalars['String']>;
  previousStatus?: Maybe<ProjectTaskStatus>;
  currentStatusId?: Maybe<Scalars['String']>;
  currentStatus?: Maybe<ProjectTaskStatus>;
  projectTaskId?: Maybe<Scalars['String']>;
  projectTask?: Maybe<ProjectTask>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
};

export type ProjectTaskLabel = {
  __typename?: 'ProjectTaskLabel';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  isBase?: Maybe<Scalars['Boolean']>;
  isMutable?: Maybe<Scalars['Boolean']>;
  projectId?: Maybe<Scalars['String']>;
  project?: Maybe<Project>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
  deletedDate?: Maybe<Scalars['Date']>;
};

export type ProjectTaskStatus = {
  __typename?: 'ProjectTaskStatus';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  isBase?: Maybe<Scalars['Boolean']>;
  isMutable?: Maybe<Scalars['Boolean']>;
  projectId?: Maybe<Scalars['String']>;
  project?: Maybe<Project>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
  deletedDate?: Maybe<Scalars['Date']>;
};

export type ProjectType = {
  __typename?: 'ProjectType';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export type ProjectsCostReport = {
  __typename?: 'ProjectsCostReport';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Int']>;
};

export type Purchase = {
  __typename?: 'Purchase';
  id?: Maybe<Scalars['String']>;
  product?: Maybe<Product>;
  status?: Maybe<PurchaseStatus>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export type PurchaseStatus = {
  __typename?: 'PurchaseStatus';
  id?: Maybe<Scalars['Int']>;
  status?: Maybe<PurchaseStatusEnum>;
  displayName?: Maybe<Scalars['String']>;
};

export enum PurchaseStatusEnum {
  Pending = 'PENDING',
  Confirmed = 'CONFIRMED',
  Sent = 'SENT',
  Completed = 'COMPLETED'
}

export type QrOptions = {
  dotColor?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
};

export type Query = {
  __typename?: 'Query';
  getAds?: Maybe<Array<Maybe<Ad>>>;
  getAd?: Maybe<Ad>;
  getAdsByUser?: Maybe<Array<Maybe<Ad>>>;
  getAnimationBundles?: Maybe<GetAnimationBundles>;
  getAnimationBundle?: Maybe<AnimationBundle>;
  getAnimationBundleTypes?: Maybe<Array<Maybe<AnimationBundleType>>>;
  getTestsAnswersByPupil?: Maybe<Array<Maybe<Answer>>>;
  getTestsAnswer?: Maybe<Answer>;
  getPupilsAnswersByClassroom?: Maybe<Array<Maybe<GetPupilsAnswersByClassroom>>>;
  getPupilsAnswersByTestId?: Maybe<Array<Maybe<GetPupilsAnswersByClassroom>>>;
  getAppMainThemeBackground?: Maybe<AppMainThemeBackground>;
  getAppMainThemeBackgrounds?: Maybe<Array<Maybe<AppMainThemeBackground>>>;
  getApplication?: Maybe<Application>;
  getApplications?: Maybe<GetApplications>;
  getApplicationMain?: Maybe<ApplicationMain>;
  getApplicationsMain?: Maybe<GetApplicationsMain>;
  getApplicationMainModel?: Maybe<ApplicationMainModel>;
  getApplicationMainModels?: Maybe<GetApplicationMainModels>;
  getApplicationMainModelFiles?: Maybe<Array<Maybe<ApplicationMainModelFile>>>;
  getApplicationMainModelFilesByApplicationId?: Maybe<Array<Maybe<ApplicationMainModelFile>>>;
  getApplicationMainTheme?: Maybe<ApplicationMainTheme>;
  getApplicationMainThemes?: Maybe<Array<Maybe<ApplicationMainTheme>>>;
  getApplicationMainThemesByMainId?: Maybe<Array<Maybe<ApplicationMainTheme>>>;
  getApplicationVersion?: Maybe<ApplicationVersion>;
  getApplicationVersions?: Maybe<Array<Maybe<ApplicationVersion>>>;
  getCurrentApplicationVersion?: Maybe<Scalars['String']>;
  getCities?: Maybe<Array<Maybe<City>>>;
  getClassroom?: Maybe<Classroom>;
  getClassrooms?: Maybe<GetClassrooms>;
  getClassroomsByTeacher?: Maybe<GetClassrooms>;
  getClassroomsByPupil?: Maybe<GetClassrooms>;
  getClientSites?: Maybe<GetClientSites>;
  getClientSiteModel?: Maybe<ClientSiteModel>;
  getModelsByClientSiteId?: Maybe<GetClientSiteModels>;
  getClientSiteModelByItemId?: Maybe<ClientSiteModel>;
  getClientSiteModelItem?: Maybe<ClientSiteModelItem>;
  getClientSiteModelItemsByModelId?: Maybe<Array<Maybe<ClientSiteModelItem>>>;
  getCorrectionComments?: Maybe<Array<Maybe<CorrectionComment>>>;
  getCorrectionComment?: Maybe<CorrectionComment>;
  getCommentsByCorrectionId?: Maybe<Array<Maybe<CorrectionComment>>>;
  getEducationPlan?: Maybe<EducationPlan>;
  getEducationPlansByClassroomId?: Maybe<GetEducationPlans>;
  getEmployeesDistributionsByName?: Maybe<GetEmployeesDistributionsByName>;
  getEmployeesDistribution?: Maybe<EmployeesDistribution>;
  getEmployeesDistributionLight?: Maybe<Array<Maybe<EmployeesDistributionLight>>>;
  getEvent?: Maybe<Event>;
  getEvents?: Maybe<GetEvents>;
  getEventDate?: Maybe<EventDate>;
  getEventDates?: Maybe<Array<Maybe<EventDate>>>;
  getExercise?: Maybe<Exercise>;
  getExercises?: Maybe<GetExercises>;
  getFeedbacks?: Maybe<Array<Maybe<Feedback>>>;
  getFeedbacksLight?: Maybe<Array<Maybe<FeedbacksLight>>>;
  getFeedback?: Maybe<Feedback>;
  getFeedbacksForCurrentUser?: Maybe<Array<Maybe<Feedback>>>;
  getFeedbackComments?: Maybe<Array<Maybe<FeedbackComment>>>;
  getFeedbackComment?: Maybe<FeedbackComment>;
  getCommentsByFeedbackId?: Maybe<Array<Maybe<FeedbackComment>>>;
  getKnowledgeBaseBlocksByThemeId?: Maybe<Array<Maybe<KnowledgeBaseBlock>>>;
  getKnowledgeBaseBlocksTestByThemeId?: Maybe<Array<Maybe<KnowledgeBaseBlock>>>;
  getKnowledgeBaseBlocksTestHistory?: Maybe<Array<Maybe<GetKnowledgeBaseTestHistory>>>;
  getKnowledgeBaseBlocksTestByManager?: Maybe<Array<Maybe<KnowledgeBaseBlock>>>;
  getKnowledgeBaseBlocksTestAnswersByTestId?: Maybe<Array<Maybe<KnowledgeBaseBlockTestAnswer>>>;
  getBlockTestHistoryByBlockId?: Maybe<Array<Maybe<KnowledgeBaseBlockTestHistory>>>;
  getBaseKnowledgeBaseCategories?: Maybe<GetKnowledgeBaseCategorys>;
  getKnowledgeBaseCategoriesByManager?: Maybe<GetKnowledgeBaseCategorys>;
  getKnowledgeBaseCategoriesByParticipant?: Maybe<GetKnowledgeBaseCategorysByParticipant>;
  getKnowledgeBaseTheme?: Maybe<KnowledgeBaseTheme>;
  getKnowledgeBaseThemes?: Maybe<Array<Maybe<KnowledgeBaseTheme>>>;
  getKnowledgeBaseThemesByCategoryId?: Maybe<Array<Maybe<KnowledgeBaseTheme>>>;
  getKnowledgeBaseThemesByManager?: Maybe<Array<Maybe<KnowledgeBaseTheme>>>;
  getPercentageCompleteThemeTests?: Maybe<Scalars['Int']>;
  getPercentageCompleteCategory?: Maybe<Scalars['Int']>;
  getLesson?: Maybe<Lesson>;
  getLessonsByMasterId?: Maybe<GetLessons>;
  getLessonBlock?: Maybe<LessonBlock>;
  getLessonBlocksByPageId?: Maybe<Array<Maybe<LessonBlock>>>;
  getLessonPage?: Maybe<LessonPage>;
  getLessonPages?: Maybe<GetLessonPages>;
  getLessonQuestion?: Maybe<LessonQuestion>;
  getLessonQuestionsByPageId?: Maybe<Array<Maybe<LessonQuestion>>>;
  getMobileNotifications?: Maybe<GetMobileNotifications>;
  getMobileNotificationsByApp?: Maybe<GetMobileNotifications>;
  getMobileNotificationsByManager?: Maybe<GetMobileNotifications>;
  getMobileNotificationsByParticipant?: Maybe<GetMobileNotificationsByParticipant>;
  getIsUnreadNotifications?: Maybe<Scalars['Boolean']>;
  getMonthHoursPlan?: Maybe<Array<Maybe<MonthHourPlan>>>;
  getMonthHourPlan?: Maybe<MonthHourPlan>;
  getMultiLesson?: Maybe<MultiLesson>;
  getMultiLessonsByEducationPlanId?: Maybe<GetMultiLessons>;
  getMultiLessonBlock?: Maybe<MultiLessonBlock>;
  getBlocksByMultiLessonnId?: Maybe<GetMultiLessonBlocks>;
  getNew?: Maybe<News>;
  getNews?: Maybe<GetNews>;
  getNewsByManager?: Maybe<GetNews>;
  getNewsByParticipant?: Maybe<GetNewsByParticipant>;
  getNotifications?: Maybe<GetNotifications>;
  getNotificationsByManager?: Maybe<GetNotifications>;
  getNotificationsByParticipant?: Maybe<GetNotificationsByParticipant>;
  getIsNewNotification?: Maybe<Scalars['Boolean']>;
  getPayrollBonuses?: Maybe<Array<Maybe<PayrollBonus>>>;
  getPayrollBonus?: Maybe<PayrollBonus>;
  getPayrollKpis?: Maybe<Array<Maybe<PayrollKpi>>>;
  getPayrollSalaryPart?: Maybe<Array<Maybe<PayrollSalaryPart>>>;
  getProducts?: Maybe<Array<Maybe<Product>>>;
  getProduct?: Maybe<Product>;
  getProductsByType?: Maybe<Array<Maybe<Product>>>;
  getProductTypes?: Maybe<Array<Maybe<ProductType>>>;
  getProjects?: Maybe<GetProjects>;
  getProjectsLight?: Maybe<Array<Maybe<ProjectLight>>>;
  getPMProjectsLight?: Maybe<Array<Maybe<ProjectLight>>>;
  getProject?: Maybe<Project>;
  getProjectsByUser?: Maybe<Array<Maybe<Project>>>;
  getProjectsByClient?: Maybe<Array<Maybe<Project>>>;
  getEarliestProjectDate?: Maybe<Scalars['Date']>;
  getProjectFilesByProject?: Maybe<Array<Maybe<ProjectLink>>>;
  getProjectAdditionalContacts?: Maybe<Array<Maybe<ProjectAdditionalContact>>>;
  getProjectAdditionalContact?: Maybe<ProjectAdditionalContact>;
  getProjectAdditionalContactsByProject?: Maybe<Array<Maybe<ProjectAdditionalContact>>>;
  getProjectCorrections?: Maybe<Array<Maybe<ProjectCorrection>>>;
  getProjectCorrectionsLight?: Maybe<Array<Maybe<CorrectionsLight>>>;
  getProjectCorrection?: Maybe<ProjectCorrection>;
  getProjectCorrectionStatuses?: Maybe<Array<Maybe<ProjectCorrectionStatus>>>;
  getProjectCustomFieldsMap?: Maybe<ProjectCustomFieldsMap>;
  getProjectCustomFieldsMapByProject?: Maybe<ProjectCustomFieldsMap>;
  getProjectKanban?: Maybe<ProjectKanban>;
  getKanbanWorkflowStep?: Maybe<KanbanWorkflowStep>;
  getBaseKanbanWorkflowSteps?: Maybe<Array<Maybe<KanbanWorkflowStep>>>;
  getKanbanWorkflowStepsByProject?: Maybe<Array<Maybe<KanbanWorkflowStep>>>;
  getProjectLinks?: Maybe<Array<Maybe<ProjectLink>>>;
  getProjectLink?: Maybe<ProjectLink>;
  getProjectLinksByProject?: Maybe<Array<Maybe<ProjectLink>>>;
  getProjectStatuses?: Maybe<Array<Maybe<ProjectStatus>>>;
  getProjectStep?: Maybe<ProjectStep>;
  getProjectStepsByProject?: Maybe<Array<Maybe<ProjectStep>>>;
  getProjectEpics?: Maybe<Array<Maybe<ProjectEpic>>>;
  getProjectStepsFromJira?: Maybe<Array<Maybe<Issue>>>;
  getEpicsProgressReport?: Maybe<Array<Maybe<GetEpicsProgressReport>>>;
  getChartData?: Maybe<ChartData>;
  getChartData2?: Maybe<Array<Maybe<ChartData2>>>;
  getProjectStepsInProgress?: Maybe<Array<Maybe<ProjectStep>>>;
  getProjectTask?: Maybe<ProjectTask>;
  getProjectTasks?: Maybe<Array<Maybe<ProjectTask>>>;
  getProjectTasksByProject?: Maybe<Array<Maybe<ProjectTask>>>;
  getProjectTasksByEpic?: Maybe<Array<Maybe<ProjectTask>>>;
  getProjectTasksByWorkflowStep?: Maybe<GetProjectTasksByWorkflowStep>;
  getTasksWithProblems?: Maybe<TasksWithProblems>;
  getOldestTaskDate?: Maybe<Scalars['String']>;
  getLateTasks?: Maybe<Array<Maybe<LateTasks>>>;
  getBugsAdditionalTasksReport?: Maybe<Array<Maybe<BugsAdditionalTasksReport>>>;
  getProjectTaskHistory?: Maybe<Array<Maybe<ProjectTaskHistory>>>;
  getProjectTaskLabel?: Maybe<ProjectTaskLabel>;
  getBaseProjectTaskLabels?: Maybe<Array<Maybe<ProjectTaskLabel>>>;
  getProjectTaskLabelsByProject?: Maybe<Array<Maybe<ProjectTaskLabel>>>;
  getAvailableProjectTaskLabels?: Maybe<Array<Maybe<ProjectTaskLabel>>>;
  getProjectTaskStatus?: Maybe<ProjectTaskStatus>;
  getBaseProjectTaskStatuses?: Maybe<Array<Maybe<ProjectTaskStatus>>>;
  getProjectTaskStatusesByProject?: Maybe<Array<Maybe<ProjectTaskStatus>>>;
  getProjectTypes?: Maybe<Array<Maybe<ProjectType>>>;
  getProjectType?: Maybe<ProjectType>;
  getPurchase?: Maybe<Purchase>;
  getPurchases?: Maybe<GetPurchases>;
  getPurchasesByUser?: Maybe<GetPurchases>;
  getPurchasesInCart?: Maybe<GetPurchases>;
  getPurchaseHistory?: Maybe<GetPurchases>;
  getPurchaseStatuses?: Maybe<Array<Maybe<PurchaseStatus>>>;
  getQuestionsByTestId?: Maybe<GetQuestions>;
  getTimeReport?: Maybe<Array<Maybe<TimeReport>>>;
  getTimeReportByEpic?: Maybe<GetTimeReportByEpic>;
  getCostReport?: Maybe<Array<Maybe<CostReport>>>;
  getCostReportByEpic?: Maybe<GetCostReportByEpic>;
  getDateReport?: Maybe<Array<Maybe<DateReport>>>;
  getDateReportByEpic?: Maybe<GetDateReportByEpic>;
  getCompletionPctReport?: Maybe<Array<Maybe<CompletionPctReport>>>;
  getCompletionPctReportByEpic?: Maybe<GetCompletionPctReportByEpic>;
  getAddedTasksReport?: Maybe<Array<Maybe<AddedTasksReport>>>;
  getAddedTasksEpics?: Maybe<Array<Maybe<AddedTasksEpic>>>;
  getAddedTasksReportByEpic?: Maybe<Array<Maybe<AddedTasksByEpic>>>;
  getMasteringMoneyReport?: Maybe<Array<Maybe<MasteringMoneyReport>>>;
  getEmployeeWorkloadReportByJobs?: Maybe<Array<Maybe<EmployeeWorkloadReportByJobs>>>;
  getEmployeeWorkloadReportByUser?: Maybe<Array<Maybe<EmployeeWorkloadReportByUser>>>;
  getEmployeeWorkloadReport?: Maybe<Array<Maybe<EmployeeWorkloadReportByProject>>>;
  getEmployeeWorkloadDayReport?: Maybe<Array<Maybe<EmployeeWorkloadDayReport>>>;
  getDashboardMasteringMoneyReport?: Maybe<Array<Maybe<DashboardMasteringMoneyReport>>>;
  getDashboardInProgressTasksReport?: Maybe<Array<Maybe<DashboardInProgressTasksReport>>>;
  getDashboardCompletionPctReport?: Maybe<Array<Maybe<DashboardCompletionPctReport>>>;
  getDashboardAddedTasksReport?: Maybe<Array<Maybe<DashboardAddedTasksReport>>>;
  getDashboardAddedTasks?: Maybe<Array<Maybe<TaskName>>>;
  getDashboardUserWorkloadReport?: Maybe<Array<Maybe<DashboardUserWorkloadReport>>>;
  getDashboardUserWorkloadProjects?: Maybe<Array<Maybe<DashboardUserWorkloadProjectsReport>>>;
  getDashboardOverdueTasksReport?: Maybe<Array<Maybe<DashboardOverdueTasksReport>>>;
  getDashboardOverdueTasks?: Maybe<Array<Maybe<TaskName>>>;
  getProjectsCostReport?: Maybe<Array<Maybe<ProjectsCostReport>>>;
  getSchools?: Maybe<GetSchools>;
  getSchool?: Maybe<School>;
  getPupilsBySchool?: Maybe<GetUsers>;
  getTeachersBySchool?: Maybe<GetUsers>;
  getGiveawaySchools?: Maybe<GetSchools>;
  getSchoolCities?: Maybe<Array<Maybe<SchoolCity>>>;
  getSchoolCountries?: Maybe<Array<Maybe<SchoolCountry>>>;
  getSchoolDistrictsByRegion?: Maybe<Array<Maybe<SchoolDistrict>>>;
  getSchoolRegions?: Maybe<Array<Maybe<SchoolRegion>>>;
  getSmartNotification?: Maybe<SmartNotification>;
  getSmartNotifications?: Maybe<GetSmartNotifications>;
  getSmartNotificationAnswer?: Maybe<SmartNotificationAnswer>;
  getAnswersBySmartNotificationId?: Maybe<Array<Maybe<SmartNotificationAnswer>>>;
  getAnswersBySmartNotificationPollListId?: Maybe<Array<Maybe<SmartNotificationAnswer>>>;
  getAnswersWithPercentsBySmartNotificationId?: Maybe<GetSmartNotificationAnswers>;
  getRatingAnswersCountBySmartNotificationId?: Maybe<Scalars['Float']>;
  getSmartNotificationPollList?: Maybe<SmartNotificationPollList>;
  getPollListsBySmartNotificationId?: Maybe<Array<Maybe<SmartNotificationPollList>>>;
  getTest?: Maybe<Test>;
  getTestsByModelId?: Maybe<GetTests>;
  getTestsByClassroomId?: Maybe<GetTests>;
  getTestsByThemeId?: Maybe<GetTests>;
  getTestsByTeacherId?: Maybe<GetTests>;
  getTestsByMainlId?: Maybe<GetTests>;
  getTestAnswersByPupilAndTest?: Maybe<Array<Maybe<TestAnswer>>>;
  getTestAnswer?: Maybe<TestAnswer>;
  getTraning?: Maybe<Traning>;
  getTranings?: Maybe<GetTranings>;
  getUser?: Maybe<User>;
  getUserInfo?: Maybe<User>;
  getUserOptionFields?: Maybe<UserOptionFields>;
  getUsers?: Maybe<GetUsers>;
  getUsersByGroup?: Maybe<Array<Maybe<User>>>;
  getUsersCountLight?: Maybe<Array<Maybe<UsersCountLight>>>;
  getUsersWithoutAnimationBundle?: Maybe<GetUsers>;
  getUsersWithoutGroups?: Maybe<Array<Maybe<User>>>;
  getUsersWithoutProject?: Maybe<GetUsers>;
  getUsersWithoutApplicationMain?: Maybe<GetUsers>;
  getTrackerUsers?: Maybe<Array<Maybe<TrackerUser>>>;
  getProjectAssignees?: Maybe<GetUsers>;
  getUsersWithProjectContract?: Maybe<GetUsers>;
  getUsersByApplicationId?: Maybe<GetUsers>;
  getGiveawayTeachers?: Maybe<GetUsers>;
  getARBookUsersCountLight?: Maybe<Array<Maybe<ArBookUsersCountLight>>>;
  getUserAgreements?: Maybe<Array<Maybe<UserAgreement>>>;
  getUserAgreement?: Maybe<UserAgreement>;
  getUserAgreementTypes?: Maybe<Array<Maybe<UserAgreementType>>>;
  getUserDepartments?: Maybe<Array<Maybe<UserDepartment>>>;
  getUserDepartment?: Maybe<UserDepartment>;
  getUserExpHistoryByUser?: Maybe<GetUserExpHistoryByUser>;
  getUserExpByDate?: Maybe<Scalars['Int']>;
  getUserGroups?: Maybe<Array<Maybe<UserGroup>>>;
  getUserGroup?: Maybe<UserGroup>;
  getGroupsByUserId?: Maybe<Array<Maybe<UserGroup>>>;
  getUserJobs?: Maybe<Array<Maybe<UserJob>>>;
  getUserJob?: Maybe<UserJob>;
  getUserPayments?: Maybe<GetUserPayments>;
  getUserPaymentsByProject?: Maybe<GetUserPayments>;
  getUserPayment?: Maybe<UserPayment>;
  getUserPaymentStatuses?: Maybe<Array<Maybe<UserPaymentStatus>>>;
  getUserPayrolls?: Maybe<Array<Maybe<UserPayroll>>>;
  getUserPayroll?: Maybe<UserPayroll>;
  getDayReport?: Maybe<Array<Maybe<ReportByTasks>>>;
  getUserPositions?: Maybe<Array<Maybe<UserPosition>>>;
  getRateHistory?: Maybe<Array<Maybe<UserRateHistory>>>;
  getOneRateHistory?: Maybe<UserRateHistory>;
  getUserRoles?: Maybe<Array<Maybe<UserRole>>>;
  getSalaryHistory?: Maybe<Array<Maybe<UserSalaryHistory>>>;
  getOneSalaryHistory?: Maybe<UserSalaryHistory>;
  getUserStatsByUser?: Maybe<UserStats>;
  getUserStatuses?: Maybe<Array<Maybe<UserStatus>>>;
  getVacanciesPlans?: Maybe<Array<Maybe<VacanciesPlan>>>;
  getVacanciesPlansByName?: Maybe<VacanciesPlansByName>;
  getVacanciesPlan?: Maybe<VacanciesPlan>;
  getVacanciesPlanOptionsFields?: Maybe<VacanciesPlanOptionFields>;
  getVacanciesPlanLight?: Maybe<Array<Maybe<VacanciesPlanLight>>>;
  getVacanciesPlanStatuses?: Maybe<Array<Maybe<VacanciesPlanStatus>>>;
  getVariantByQuestionId?: Maybe<Array<Maybe<Variant>>>;
};


export type QueryGetAdArgs = {
  adId: Scalars['String'];
};


export type QueryGetAdsByUserArgs = {
  userId: Scalars['String'];
};


export type QueryGetAnimationBundlesArgs = {
  animationData: GetAnimationBundlesInput;
};


export type QueryGetAnimationBundleArgs = {
  animationId: Scalars['String'];
};


export type QueryGetTestsAnswersByPupilArgs = {
  testId: Scalars['String'];
  pupilId: Scalars['String'];
};


export type QueryGetTestsAnswerArgs = {
  id: Scalars['String'];
};


export type QueryGetPupilsAnswersByClassroomArgs = {
  classroomId: Scalars['String'];
};


export type QueryGetPupilsAnswersByTestIdArgs = {
  testId: Scalars['String'];
  searchKey?: Maybe<Scalars['String']>;
};


export type QueryGetAppMainThemeBackgroundArgs = {
  id: Scalars['String'];
};


export type QueryGetApplicationArgs = {
  id: Scalars['String'];
};


export type QueryGetApplicationsArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  isPublished?: Maybe<Scalars['Boolean']>;
};


export type QueryGetApplicationMainArgs = {
  id: Scalars['String'];
};


export type QueryGetApplicationsMainArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  applicationId: Scalars['String'];
  isPublished?: Maybe<Scalars['Boolean']>;
};


export type QueryGetApplicationMainModelArgs = {
  id: Scalars['String'];
};


export type QueryGetApplicationMainModelsArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  applicationMainId: Scalars['String'];
  isPublished?: Maybe<Scalars['Boolean']>;
};


export type QueryGetApplicationMainModelFilesByApplicationIdArgs = {
  modelId: Scalars['String'];
};


export type QueryGetApplicationMainThemeArgs = {
  id: Scalars['String'];
};


export type QueryGetApplicationMainThemesByMainIdArgs = {
  applicationMainId: Scalars['String'];
};


export type QueryGetApplicationVersionArgs = {
  id: Scalars['String'];
};


export type QueryGetApplicationVersionsArgs = {
  appId: Scalars['String'];
};


export type QueryGetCurrentApplicationVersionArgs = {
  appId: Scalars['String'];
  os: Os;
};


export type QueryGetClassroomArgs = {
  id: Scalars['String'];
};


export type QueryGetClassroomsArgs = {
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  isArchived?: Maybe<Scalars['Boolean']>;
};


export type QueryGetClassroomsByTeacherArgs = {
  teacherId: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  isArchived?: Maybe<Scalars['Boolean']>;
};


export type QueryGetClassroomsByPupilArgs = {
  pupilId: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryGetClientSitesArgs = {
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryGetClientSiteModelArgs = {
  id: Scalars['String'];
};


export type QueryGetModelsByClientSiteIdArgs = {
  clientSiteId: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryGetClientSiteModelByItemIdArgs = {
  itemId: Scalars['String'];
  siteKey: Scalars['String'];
};


export type QueryGetClientSiteModelItemArgs = {
  id: Scalars['String'];
};


export type QueryGetClientSiteModelItemsByModelIdArgs = {
  clientSiteModelId: Scalars['String'];
};


export type QueryGetCorrectionCommentArgs = {
  correctionCommentId: Scalars['String'];
};


export type QueryGetCommentsByCorrectionIdArgs = {
  correctionId: Scalars['String'];
};


export type QueryGetEducationPlanArgs = {
  id: Scalars['String'];
};


export type QueryGetEducationPlansByClassroomIdArgs = {
  classroomId: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryGetEmployeesDistributionsByNameArgs = {
  name: Scalars['String'];
};


export type QueryGetEmployeesDistributionArgs = {
  id: Scalars['String'];
};


export type QueryGetEventArgs = {
  id: Scalars['String'];
};


export type QueryGetEventsArgs = {
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryGetEventDateArgs = {
  id: Scalars['String'];
};


export type QueryGetEventDatesArgs = {
  eventId: Scalars['String'];
};


export type QueryGetExerciseArgs = {
  id: Scalars['String'];
};


export type QueryGetExercisesArgs = {
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryGetFeedbacksArgs = {
  statuses?: Maybe<Array<Maybe<FeedbackStatusEnum>>>;
  isFrom?: Maybe<FeedbackIsFromEnum>;
};


export type QueryGetFeedbacksLightArgs = {
  isFrom?: Maybe<FeedbackIsFromEnum>;
};


export type QueryGetFeedbackArgs = {
  feedbackId: Scalars['String'];
};


export type QueryGetFeedbacksForCurrentUserArgs = {
  statuses?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryGetFeedbackCommentArgs = {
  feedbackCommentId: Scalars['String'];
};


export type QueryGetCommentsByFeedbackIdArgs = {
  feedbackId: Scalars['String'];
};


export type QueryGetKnowledgeBaseBlocksByThemeIdArgs = {
  themeId: Scalars['String'];
};


export type QueryGetKnowledgeBaseBlocksTestByThemeIdArgs = {
  themeId: Scalars['String'];
  isTestPublished?: Maybe<Scalars['Boolean']>;
};


export type QueryGetKnowledgeBaseBlocksTestHistoryArgs = {
  themeId: Scalars['String'];
};


export type QueryGetKnowledgeBaseBlocksTestByManagerArgs = {
  userId?: Maybe<Scalars['String']>;
  isTestPublished?: Maybe<Scalars['Boolean']>;
};


export type QueryGetKnowledgeBaseBlocksTestAnswersByTestIdArgs = {
  testBlockId: Scalars['String'];
};


export type QueryGetBlockTestHistoryByBlockIdArgs = {
  blockId: Scalars['String'];
};


export type QueryGetBaseKnowledgeBaseCategoriesArgs = {
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  isFor?: Maybe<KnowledgeBaseCategoryIsForEnum>;
};


export type QueryGetKnowledgeBaseCategoriesByManagerArgs = {
  userId?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryGetKnowledgeBaseCategoriesByParticipantArgs = {
  userId: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryGetKnowledgeBaseThemeArgs = {
  id: Scalars['String'];
};


export type QueryGetKnowledgeBaseThemesArgs = {
  isPublished?: Maybe<Scalars['Boolean']>;
};


export type QueryGetKnowledgeBaseThemesByCategoryIdArgs = {
  categoryId: Scalars['String'];
  isPublished?: Maybe<Scalars['Boolean']>;
};


export type QueryGetKnowledgeBaseThemesByManagerArgs = {
  userId?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
};


export type QueryGetPercentageCompleteThemeTestsArgs = {
  userId: Scalars['String'];
  themeId: Scalars['String'];
};


export type QueryGetPercentageCompleteCategoryArgs = {
  userId: Scalars['String'];
  categoryId: Scalars['String'];
};


export type QueryGetLessonArgs = {
  lessonId: Scalars['String'];
};


export type QueryGetLessonsByMasterIdArgs = {
  getLessonsParams: GetLessonsParams;
};


export type QueryGetLessonBlockArgs = {
  blockId: Scalars['String'];
};


export type QueryGetLessonBlocksByPageIdArgs = {
  pageId: Scalars['String'];
};


export type QueryGetLessonPageArgs = {
  id: Scalars['String'];
};


export type QueryGetLessonPagesArgs = {
  lessonId: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryGetLessonQuestionArgs = {
  id: Scalars['String'];
};


export type QueryGetLessonQuestionsByPageIdArgs = {
  pageId: Scalars['String'];
};


export type QueryGetMobileNotificationsArgs = {
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  isPublished?: Maybe<Scalars['Boolean']>;
};


export type QueryGetMobileNotificationsByAppArgs = {
  appId: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  isPublished?: Maybe<Scalars['Boolean']>;
};


export type QueryGetMobileNotificationsByManagerArgs = {
  userId?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  isPublished?: Maybe<Scalars['Boolean']>;
};


export type QueryGetMobileNotificationsByParticipantArgs = {
  userId: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  isPublished?: Maybe<Scalars['Boolean']>;
};


export type QueryGetIsUnreadNotificationsArgs = {
  userId?: Maybe<Scalars['String']>;
};


export type QueryGetMonthHourPlanArgs = {
  id: Scalars['String'];
};


export type QueryGetMultiLessonArgs = {
  id: Scalars['String'];
};


export type QueryGetMultiLessonsByEducationPlanIdArgs = {
  educationPlanId: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryGetMultiLessonBlockArgs = {
  id: Scalars['String'];
};


export type QueryGetBlocksByMultiLessonnIdArgs = {
  multiLessonId: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryGetNewArgs = {
  id: Scalars['String'];
};


export type QueryGetNewsArgs = {
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  isFor?: Maybe<NewsIsForEnum>;
};


export type QueryGetNewsByManagerArgs = {
  userId?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  isPublished?: Maybe<Scalars['Boolean']>;
};


export type QueryGetNewsByParticipantArgs = {
  userId: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  isPublished?: Maybe<Scalars['Boolean']>;
};


export type QueryGetNotificationsArgs = {
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  isPublished?: Maybe<Scalars['Boolean']>;
};


export type QueryGetNotificationsByManagerArgs = {
  userId?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  isPublished?: Maybe<Scalars['Boolean']>;
};


export type QueryGetNotificationsByParticipantArgs = {
  userId: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  isPublished?: Maybe<Scalars['Boolean']>;
};


export type QueryGetIsNewNotificationArgs = {
  userId?: Maybe<Scalars['String']>;
};


export type QueryGetPayrollBonusesArgs = {
  payrollId: Scalars['String'];
};


export type QueryGetPayrollBonusArgs = {
  bonusId: Scalars['String'];
};


export type QueryGetPayrollKpisArgs = {
  payrollId: Scalars['String'];
};


export type QueryGetPayrollSalaryPartArgs = {
  payrollId: Scalars['String'];
};


export type QueryGetProductArgs = {
  productId: Scalars['String'];
};


export type QueryGetProductsByTypeArgs = {
  productTypeId: Scalars['Int'];
};


export type QueryGetProjectsArgs = {
  getProjectsInput: GetProjectsInput;
};


export type QueryGetProjectArgs = {
  projectId: Scalars['String'];
};


export type QueryGetProjectsByUserArgs = {
  userId: Scalars['String'];
};


export type QueryGetProjectsByClientArgs = {
  userId: Scalars['String'];
};


export type QueryGetProjectFilesByProjectArgs = {
  projectId: Scalars['String'];
  isFor?: Maybe<IsForEnum>;
};


export type QueryGetProjectAdditionalContactArgs = {
  projectAdditionalContactId: Scalars['String'];
};


export type QueryGetProjectAdditionalContactsByProjectArgs = {
  projectId: Scalars['String'];
};


export type QueryGetProjectCorrectionsArgs = {
  projectId: Scalars['String'];
  statuses?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryGetProjectCorrectionsLightArgs = {
  projectId: Scalars['String'];
};


export type QueryGetProjectCorrectionArgs = {
  projectCorrectionId: Scalars['String'];
};


export type QueryGetProjectCustomFieldsMapArgs = {
  id: Scalars['String'];
};


export type QueryGetProjectCustomFieldsMapByProjectArgs = {
  projectId: Scalars['String'];
};


export type QueryGetProjectKanbanArgs = {
  projectId: Scalars['String'];
};


export type QueryGetKanbanWorkflowStepArgs = {
  id: Scalars['String'];
};


export type QueryGetKanbanWorkflowStepsByProjectArgs = {
  projectId: Scalars['String'];
};


export type QueryGetProjectLinksArgs = {
  isFor?: Maybe<IsForEnum>;
};


export type QueryGetProjectLinkArgs = {
  projectLinkId: Scalars['String'];
};


export type QueryGetProjectLinksByProjectArgs = {
  projectId: Scalars['String'];
  isFor?: Maybe<IsForEnum>;
};


export type QueryGetProjectStepArgs = {
  id: Scalars['String'];
};


export type QueryGetProjectStepsByProjectArgs = {
  projectId: Scalars['String'];
};


export type QueryGetProjectEpicsArgs = {
  projectId: Scalars['String'];
};


export type QueryGetProjectStepsFromJiraArgs = {
  projectId: Scalars['String'];
};


export type QueryGetEpicsProgressReportArgs = {
  projectId: Scalars['String'];
};


export type QueryGetChartDataArgs = {
  projectId: Scalars['String'];
  isEpic?: Maybe<Scalars['Boolean']>;
};


export type QueryGetChartData2Args = {
  projectId: Scalars['String'];
  isEpic?: Maybe<Scalars['Boolean']>;
  epicId?: Maybe<Scalars['String']>;
};


export type QueryGetProjectStepsInProgressArgs = {
  projectId: Scalars['String'];
};


export type QueryGetProjectTaskArgs = {
  id: Scalars['String'];
};


export type QueryGetProjectTasksArgs = {
  projectId?: Maybe<Scalars['String']>;
  taskStatusId?: Maybe<Scalars['String']>;
};


export type QueryGetProjectTasksByProjectArgs = {
  projectId: Scalars['String'];
};


export type QueryGetProjectTasksByEpicArgs = {
  epicKey: Scalars['String'];
};


export type QueryGetProjectTasksByWorkflowStepArgs = {
  projectId: Scalars['String'];
  workflowStepId: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryGetTasksWithProblemsArgs = {
  projectId: Scalars['String'];
};


export type QueryGetLateTasksArgs = {
  projectId: Scalars['String'];
  taskStatusId?: Maybe<Scalars['String']>;
  isClient?: Maybe<Scalars['Boolean']>;
};


export type QueryGetBugsAdditionalTasksReportArgs = {
  projectId: Scalars['String'];
};


export type QueryGetProjectTaskHistoryArgs = {
  projectTaskId: Scalars['String'];
};


export type QueryGetProjectTaskLabelArgs = {
  id: Scalars['String'];
};


export type QueryGetProjectTaskLabelsByProjectArgs = {
  projectId: Scalars['String'];
};


export type QueryGetAvailableProjectTaskLabelsArgs = {
  projectId: Scalars['String'];
  projectTaskId: Scalars['String'];
};


export type QueryGetProjectTaskStatusArgs = {
  id: Scalars['String'];
};


export type QueryGetProjectTaskStatusesByProjectArgs = {
  projectId: Scalars['String'];
};


export type QueryGetProjectTypeArgs = {
  typeId: Scalars['String'];
};


export type QueryGetPurchaseArgs = {
  purchaseId: Scalars['String'];
};


export type QueryGetPurchasesArgs = {
  getPurchasesData: GetPurchasesInput;
};


export type QueryGetPurchasesByUserArgs = {
  getPurchasesData: GetPurchasesByUserInput;
};


export type QueryGetPurchasesInCartArgs = {
  getPurchasesData: GetPurchasesByUserInput;
};


export type QueryGetPurchaseHistoryArgs = {
  getPurchasesData: GetPurchasesByUserInput;
};


export type QueryGetQuestionsByTestIdArgs = {
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  testId: Scalars['String'];
};


export type QueryGetTimeReportArgs = {
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
};


export type QueryGetTimeReportByEpicArgs = {
  epicKey: Scalars['String'];
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
};


export type QueryGetCostReportArgs = {
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
};


export type QueryGetCostReportByEpicArgs = {
  epicKey: Scalars['String'];
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
};


export type QueryGetDateReportByEpicArgs = {
  epicKey: Scalars['String'];
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
};


export type QueryGetCompletionPctReportArgs = {
  dates?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryGetCompletionPctReportByEpicArgs = {
  epicKey: Scalars['String'];
};


export type QueryGetAddedTasksReportArgs = {
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
};


export type QueryGetAddedTasksEpicsArgs = {
  projectId: Scalars['String'];
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
};


export type QueryGetAddedTasksReportByEpicArgs = {
  epicKey: Scalars['String'];
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
};


export type QueryGetMasteringMoneyReportArgs = {
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
};


export type QueryGetEmployeeWorkloadReportByUserArgs = {
  jobName: Scalars['String'];
};


export type QueryGetEmployeeWorkloadReportArgs = {
  userId: Scalars['String'];
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
};


export type QueryGetEmployeeWorkloadDayReportArgs = {
  userId: Scalars['String'];
  date: Scalars['Date'];
};


export type QueryGetDashboardMasteringMoneyReportArgs = {
  projectId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
};


export type QueryGetDashboardInProgressTasksReportArgs = {
  projectId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
};


export type QueryGetDashboardCompletionPctReportArgs = {
  projectId?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
};


export type QueryGetDashboardAddedTasksReportArgs = {
  projectId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
};


export type QueryGetDashboardAddedTasksArgs = {
  projectId: Scalars['String'];
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
};


export type QueryGetDashboardUserWorkloadReportArgs = {
  projectId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
};


export type QueryGetDashboardUserWorkloadProjectsArgs = {
  assigneeId: Scalars['String'];
  projectId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
};


export type QueryGetDashboardOverdueTasksReportArgs = {
  projectId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
};


export type QueryGetDashboardOverdueTasksArgs = {
  projectId: Scalars['String'];
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
};


export type QueryGetSchoolsArgs = {
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  searchKey?: Maybe<Scalars['String']>;
  cityId?: Maybe<Scalars['String']>;
  countryId?: Maybe<Scalars['String']>;
};


export type QueryGetSchoolArgs = {
  id: Scalars['String'];
};


export type QueryGetPupilsBySchoolArgs = {
  schoolId: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryGetTeachersBySchoolArgs = {
  schoolId: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryGetGiveawaySchoolsArgs = {
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryGetSchoolCitiesArgs = {
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  searchKey?: Maybe<Scalars['String']>;
  regionId?: Maybe<Scalars['String']>;
  districtId?: Maybe<Scalars['String']>;
};


export type QueryGetSchoolCountriesArgs = {
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  searchKey?: Maybe<Scalars['String']>;
};


export type QueryGetSchoolDistrictsByRegionArgs = {
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  searchKey?: Maybe<Scalars['String']>;
  regionId?: Maybe<Scalars['String']>;
};


export type QueryGetSchoolRegionsArgs = {
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  searchKey?: Maybe<Scalars['String']>;
  countryId?: Maybe<Scalars['String']>;
};


export type QueryGetSmartNotificationArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryGetSmartNotificationsArgs = {
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  type?: Maybe<SmartNotificationTypeEnum>;
};


export type QueryGetSmartNotificationAnswerArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryGetAnswersBySmartNotificationIdArgs = {
  smartNotificationId: Scalars['String'];
};


export type QueryGetAnswersBySmartNotificationPollListIdArgs = {
  pollListId: Scalars['String'];
};


export type QueryGetAnswersWithPercentsBySmartNotificationIdArgs = {
  smartNotificationId: Scalars['String'];
};


export type QueryGetRatingAnswersCountBySmartNotificationIdArgs = {
  smartNotificationId: Scalars['String'];
};


export type QueryGetSmartNotificationPollListArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryGetPollListsBySmartNotificationIdArgs = {
  smartNotificationId: Scalars['String'];
};


export type QueryGetTestArgs = {
  id: Scalars['String'];
};


export type QueryGetTestsByModelIdArgs = {
  modelId: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryGetTestsByClassroomIdArgs = {
  classroomId: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryGetTestsByThemeIdArgs = {
  themeId: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryGetTestsByTeacherIdArgs = {
  teacherId: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryGetTestsByMainlIdArgs = {
  mainId: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryGetTestAnswersByPupilAndTestArgs = {
  testId: Scalars['String'];
  pupilId: Scalars['String'];
};


export type QueryGetTestAnswerArgs = {
  id: Scalars['String'];
};


export type QueryGetTraningArgs = {
  id: Scalars['String'];
};


export type QueryGetTraningsArgs = {
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  isPublished?: Maybe<Scalars['Boolean']>;
};


export type QueryGetUserArgs = {
  userId: Scalars['String'];
};


export type QueryGetUsersArgs = {
  getUsersData: GetUsersInput;
};


export type QueryGetUsersByGroupArgs = {
  userGroupId: Scalars['String'];
};


export type QueryGetUsersWithoutAnimationBundleArgs = {
  animationId: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryGetUsersWithoutProjectArgs = {
  projectId: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryGetUsersWithoutApplicationMainArgs = {
  applicationMainId: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryGetProjectAssigneesArgs = {
  projectId: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryGetUsersWithProjectContractArgs = {
  projectId: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  withOutContract?: Maybe<Scalars['Boolean']>;
};


export type QueryGetUsersByApplicationIdArgs = {
  applicationId: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryGetGiveawayTeachersArgs = {
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryGetUserAgreementArgs = {
  userAgreementId: Scalars['String'];
};


export type QueryGetUserDepartmentArgs = {
  departmentId: Scalars['String'];
};


export type QueryGetUserExpHistoryByUserArgs = {
  userId?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryGetUserExpByDateArgs = {
  userId?: Maybe<Scalars['String']>;
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
};


export type QueryGetUserGroupArgs = {
  userGroupId: Scalars['String'];
};


export type QueryGetGroupsByUserIdArgs = {
  userId: Scalars['String'];
};


export type QueryGetUserJobArgs = {
  userJobId: Scalars['String'];
};


export type QueryGetUserPaymentsArgs = {
  userId: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryGetUserPaymentsByProjectArgs = {
  projectId: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  isPublished?: Maybe<Scalars['Boolean']>;
};


export type QueryGetUserPaymentArgs = {
  paymentId: Scalars['String'];
};


export type QueryGetUserPayrollsArgs = {
  userId: Scalars['String'];
};


export type QueryGetUserPayrollArgs = {
  payrollId: Scalars['String'];
};


export type QueryGetDayReportArgs = {
  payrollId: Scalars['String'];
  date: Scalars['String'];
};


export type QueryGetRateHistoryArgs = {
  userId: Scalars['String'];
};


export type QueryGetOneRateHistoryArgs = {
  id: Scalars['String'];
};


export type QueryGetSalaryHistoryArgs = {
  userId: Scalars['String'];
};


export type QueryGetOneSalaryHistoryArgs = {
  salaryHistoryId: Scalars['String'];
};


export type QueryGetUserStatsByUserArgs = {
  userId?: Maybe<Scalars['String']>;
};


export type QueryGetVacanciesPlansArgs = {
  userId?: Maybe<Scalars['String']>;
};


export type QueryGetVacanciesPlansByNameArgs = {
  userId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};


export type QueryGetVacanciesPlanArgs = {
  planId: Scalars['String'];
};


export type QueryGetVariantByQuestionIdArgs = {
  questionId: Scalars['String'];
};

export type Question = {
  __typename?: 'Question';
  id?: Maybe<Scalars['String']>;
  question?: Maybe<Scalars['String']>;
  testId?: Maybe<Scalars['String']>;
  test?: Maybe<Test>;
  variants?: Maybe<Array<Maybe<Variant>>>;
  answers?: Maybe<Array<Maybe<Answer>>>;
  rightVariantId?: Maybe<Scalars['String']>;
  rightVariant?: Maybe<Variant>;
  photo?: Maybe<Scalars['String']>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export type QuestionVariantInput = {
  contentUKR?: Maybe<Scalars['String']>;
  contentEN?: Maybe<Scalars['String']>;
};

export type RegisterEventUserInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  countryId?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['String']>;
  schoolId?: Maybe<Scalars['String']>;
  subjects?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Report = {
  __typename?: 'Report';
  salary?: Maybe<Scalars['Int']>;
  mainPart?: Maybe<Scalars['Int']>;
  totalTime?: Maybe<Scalars['Int']>;
  times?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type ReportByProjects = {
  __typename?: 'ReportByProjects';
  projectName?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['Int']>;
};

export type ReportByTasks = {
  __typename?: 'ReportByTasks';
  taskName?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['Int']>;
};

export type School = {
  __typename?: 'School';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  country?: Maybe<SchoolCountry>;
  countryId?: Maybe<Scalars['String']>;
  region?: Maybe<SchoolRegion>;
  regionId?: Maybe<Scalars['String']>;
  district?: Maybe<SchoolDistrict>;
  districtId?: Maybe<Scalars['String']>;
  city?: Maybe<SchoolCity>;
  cityId?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  teachers?: Maybe<Array<Maybe<User>>>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export type SchoolCity = {
  __typename?: 'SchoolCity';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  schools?: Maybe<Array<Maybe<School>>>;
  district?: Maybe<SchoolDistrict>;
  districtId?: Maybe<Scalars['String']>;
  region?: Maybe<SchoolRegion>;
  regionId?: Maybe<Scalars['String']>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export type SchoolCountry = {
  __typename?: 'SchoolCountry';
  id?: Maybe<Scalars['String']>;
  nameEN?: Maybe<Scalars['String']>;
  nameUKR?: Maybe<Scalars['String']>;
  alpha2?: Maybe<Scalars['String']>;
  alpha3?: Maybe<Scalars['String']>;
  dialCode?: Maybe<Scalars['String']>;
  regions?: Maybe<Array<Maybe<SchoolRegion>>>;
  users?: Maybe<Array<Maybe<User>>>;
  schools?: Maybe<Array<Maybe<School>>>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export type SchoolDistrict = {
  __typename?: 'SchoolDistrict';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  cities?: Maybe<Array<Maybe<SchoolCity>>>;
  region?: Maybe<SchoolRegion>;
  regionId?: Maybe<Scalars['String']>;
  schools?: Maybe<Array<Maybe<School>>>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export type SchoolRegion = {
  __typename?: 'SchoolRegion';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  cities?: Maybe<Array<Maybe<SchoolCity>>>;
  districts?: Maybe<Array<Maybe<SchoolDistrict>>>;
  countryId?: Maybe<Scalars['String']>;
  country?: Maybe<SchoolCountry>;
  schools?: Maybe<Array<Maybe<School>>>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export type SetKnowledgeBaseCategoryParticipantsInput = {
  id: Scalars['String'];
  availableFor?: Maybe<AvailableForEnum>;
  participants?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SetMobileNotificationParticipantsInput = {
  notificationId: Scalars['String'];
  availableForEveryone?: Maybe<Scalars['Boolean']>;
  participants?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SetNewsParticipantsInput = {
  id: Scalars['String'];
  availableFor?: Maybe<AvailableForEnum>;
  participants?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SetNotificationParticipantsInput = {
  id: Scalars['String'];
  availableFor?: Maybe<AvailableForEnum>;
  participants?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SetQrCustomInput = {
  string?: Maybe<Scalars['String']>;
  dotColor?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
};

export type SmartNotification = {
  __typename?: 'SmartNotification';
  id?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<SmartNotificationTypeEnum>;
  isPublished?: Maybe<Scalars['Boolean']>;
  publishedAt?: Maybe<Scalars['Date']>;
  unpublishedAt?: Maybe<Scalars['Date']>;
  photo?: Maybe<Scalars['String']>;
  photoName?: Maybe<Scalars['String']>;
  pollLists?: Maybe<Array<Maybe<SmartNotificationPollList>>>;
  answers?: Maybe<Array<Maybe<SmartNotificationAnswer>>>;
  application?: Maybe<Application>;
  applicationId?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['String']>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export type SmartNotificationAnswer = {
  __typename?: 'SmartNotificationAnswer';
  id?: Maybe<Scalars['String']>;
  ratting?: Maybe<Scalars['Int']>;
  notificationId?: Maybe<Scalars['String']>;
  notification?: Maybe<SmartNotification>;
  pollListId?: Maybe<Scalars['String']>;
  pollList?: Maybe<SmartNotificationPollList>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
};

export type SmartNotificationAnswersWithPercents = {
  __typename?: 'SmartNotificationAnswersWithPercents';
  ratting?: Maybe<Scalars['Int']>;
  pollListId?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  percents?: Maybe<Scalars['Int']>;
};

export type SmartNotificationPollList = {
  __typename?: 'SmartNotificationPollList';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notificationId?: Maybe<Scalars['String']>;
  notification?: Maybe<SmartNotification>;
  answers?: Maybe<Array<Maybe<SmartNotificationAnswer>>>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export enum SmartNotificationTypeEnum {
  Rating = 'RATING',
  Poll = 'POLL',
  Link = 'LINK'
}

export type StatusReport = {
  __typename?: 'StatusReport';
  status?: Maybe<Scalars['String']>;
  projectsTypesPercent?: Maybe<Scalars['Float']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  workflowStepChanged?: Maybe<Scalars['String']>;
};


export type SubscriptionWorkflowStepChangedArgs = {
  projectId: Scalars['String'];
};

export type SyncProject = {
  __typename?: 'SyncProject';
  tasksToAdd?: Maybe<Scalars['Int']>;
  tasksToDelete?: Maybe<Scalars['Int']>;
  tasksToUpdate?: Maybe<Scalars['Int']>;
};

export type TaskName = {
  __typename?: 'TaskName';
  name?: Maybe<Scalars['String']>;
};

export type TaskWithCompletionPct = {
  __typename?: 'TaskWithCompletionPct';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  assigneeName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  epicPrc?: Maybe<Scalars['Float']>;
  projectPrc?: Maybe<Scalars['Float']>;
};

export type TaskWithCost = {
  __typename?: 'TaskWithCost';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  assigneeName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  costEstimate?: Maybe<Scalars['Int']>;
  cost?: Maybe<Scalars['Int']>;
  difference?: Maybe<Scalars['Int']>;
};

export type TaskWithDate = {
  __typename?: 'TaskWithDate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  assigneeName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  startDateEstimate?: Maybe<Scalars['String']>;
  startDateFact?: Maybe<Scalars['String']>;
  startDifference?: Maybe<Scalars['Int']>;
  finishDateEstimate?: Maybe<Scalars['String']>;
  finishDateFact?: Maybe<Scalars['String']>;
  finishDifference?: Maybe<Scalars['Int']>;
};

export type TaskWithTime = {
  __typename?: 'TaskWithTime';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  assigneeName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  timeEstimate?: Maybe<Scalars['Int']>;
  timeSpent?: Maybe<Scalars['Int']>;
};

export type TasksWithProblems = {
  __typename?: 'TasksWithProblems';
  done?: Maybe<Array<Maybe<DoneProblems>>>;
  dateEstimate?: Maybe<Array<Maybe<DateEstimateProblems>>>;
  assignee?: Maybe<Array<Maybe<AssigneeProblems>>>;
  noEpic?: Maybe<Array<Maybe<NoEpicProblems>>>;
};

export type TeacherSchoolReport = {
  __typename?: 'TeacherSchoolReport';
  id?: Maybe<Scalars['String']>;
  reportToId?: Maybe<Scalars['String']>;
  reportTo?: Maybe<User>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
};

export type Test = {
  __typename?: 'Test';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  questions?: Maybe<Array<Maybe<Question>>>;
  modelId?: Maybe<Scalars['String']>;
  model?: Maybe<ApplicationMainModel>;
  mainId?: Maybe<Scalars['String']>;
  main?: Maybe<ApplicationMain>;
  themeId?: Maybe<Scalars['String']>;
  theme?: Maybe<ApplicationMainTheme>;
  classroomId?: Maybe<Scalars['String']>;
  classroom?: Maybe<Classroom>;
  isOpenTest?: Maybe<Scalars['Boolean']>;
  attempts?: Maybe<Scalars['Int']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  publishedAt?: Maybe<Scalars['Date']>;
  time?: Maybe<Scalars['Int']>;
  class?: Maybe<TestClassEnum>;
  testAnswers?: Maybe<Array<Maybe<TestAnswer>>>;
  repeatAttemptTime?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export type TestAnswer = {
  __typename?: 'TestAnswer';
  id?: Maybe<Scalars['String']>;
  testId?: Maybe<Scalars['String']>;
  test?: Maybe<Test>;
  answers?: Maybe<Array<Maybe<Answer>>>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
};

export type TestAnswersForApp = {
  __typename?: 'TestAnswersForApp';
  questions?: Maybe<Scalars['Int']>;
  answers?: Maybe<Scalars['Int']>;
  attempt?: Maybe<Scalars['Int']>;
  rightAnswers?: Maybe<Scalars['Int']>;
};

export enum TestClassEnum {
  Class_7 = 'CLASS_7',
  Class_8 = 'CLASS_8',
  Class_9 = 'CLASS_9',
  Class_10 = 'CLASS_10',
  Class_11 = 'CLASS_11'
}

export type TestForApp = {
  __typename?: 'TestForApp';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  questions?: Maybe<Array<Maybe<Question>>>;
  modelId?: Maybe<Scalars['String']>;
  mainId?: Maybe<Scalars['String']>;
  themeId?: Maybe<Scalars['String']>;
  classroomId?: Maybe<Scalars['String']>;
  isOpenTest?: Maybe<Scalars['Boolean']>;
  attempts?: Maybe<Scalars['Int']>;
  time?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<User>;
  rightQuestions?: Maybe<Scalars['Int']>;
  questionsLength?: Maybe<Scalars['Int']>;
  class?: Maybe<Scalars['Int']>;
  lastAttemptDate?: Maybe<Scalars['String']>;
  author?: Maybe<Scalars['String']>;
};

export type TimeReport = {
  __typename?: 'TimeReport';
  projectId?: Maybe<Scalars['String']>;
  projectName?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  totalTimeEstimate?: Maybe<Scalars['Int']>;
  totalTimeSpent?: Maybe<Scalars['Int']>;
  difference?: Maybe<Scalars['Int']>;
};

export type TrackerUser = {
  __typename?: 'TrackerUser';
  id?: Maybe<Scalars['String']>;
  fullname?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type Traning = {
  __typename?: 'Traning';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  statrTitle?: Maybe<Scalars['String']>;
  endTitle?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  startVideo?: Maybe<Scalars['String']>;
  endVideo?: Maybe<Scalars['String']>;
  exercises?: Maybe<Array<Maybe<Exercise>>>;
  isPublished?: Maybe<Scalars['Boolean']>;
  publishedAt?: Maybe<Scalars['Date']>;
  unpublishedAt?: Maybe<Scalars['Date']>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export type UpdateAdInput = {
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type UpdateAnimationBundleInput = {
  id: Scalars['String'];
  typeId?: Maybe<Scalars['Int']>;
  nameEN?: Maybe<Scalars['String']>;
  nameUKR?: Maybe<Scalars['String']>;
  descriptionEN?: Maybe<Scalars['String']>;
  descriptionUKR?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['String']>;
};

export type UpdateApplicationInput = {
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  appStoreLink?: Maybe<Scalars['String']>;
  playMarketLink?: Maybe<Scalars['String']>;
  mainPhoto?: Maybe<Scalars['String']>;
  modelPhoto?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  frontLink?: Maybe<Scalars['String']>;
};

export type UpdateApplicationLinkInput = {
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
};

export type UpdateApplicationMainInput = {
  id: Scalars['String'];
  nameEN?: Maybe<Scalars['String']>;
  nameUKR?: Maybe<Scalars['String']>;
  descriptionEN?: Maybe<Scalars['String']>;
  descriptionUKR?: Maybe<Scalars['String']>;
  background1?: Maybe<Scalars['String']>;
  background2?: Maybe<Scalars['String']>;
  background3?: Maybe<Scalars['String']>;
  background4?: Maybe<Scalars['String']>;
  background5?: Maybe<Scalars['String']>;
  isMap?: Maybe<Scalars['Boolean']>;
  display?: Maybe<Scalars['Boolean']>;
  city?: Maybe<ApplicationMainCityEnum>;
  priority?: Maybe<Scalars['Int']>;
  showInSubjects?: Maybe<Scalars['Boolean']>;
};

export type UpdateApplicationMainModelFileInput = {
  id: Scalars['String'];
  file?: Maybe<Scalars['Upload']>;
  manifest?: Maybe<Scalars['Upload']>;
};

export type UpdateApplicationMainModelInput = {
  id: Scalars['String'];
  nameEN?: Maybe<Scalars['String']>;
  nameUKR?: Maybe<Scalars['String']>;
  descriptionEN?: Maybe<Scalars['String']>;
  descriptionUKR?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  isContour?: Maybe<Scalars['Boolean']>;
  themeId?: Maybe<Scalars['String']>;
};

export type UpdateCorrectionCommentInput = {
  id: Scalars['String'];
  text?: Maybe<Scalars['String']>;
  files?: Maybe<Array<Maybe<Scalars['Upload']>>>;
};

export type UpdateEmployeesDistribution = {
  id: Scalars['String'];
  projectName?: Maybe<Scalars['String']>;
  employeesNumber?: Maybe<Scalars['Int']>;
  projectTypeId?: Maybe<Scalars['String']>;
};

export type UpdateExerciseInput = {
  content?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['Upload']>;
  pauseTime?: Maybe<Scalars['Int']>;
};

export type UpdateFeedbackInput = {
  id: Scalars['String'];
  text?: Maybe<Scalars['String']>;
  status?: Maybe<FeedbackStatusEnum>;
};

export type UpdateLessonBlockInput = {
  lessonBlockId: Scalars['String'];
  contentEN?: Maybe<Scalars['String']>;
  contentUKR?: Maybe<Scalars['String']>;
  videoData?: Maybe<LessonVideoBlockInput>;
  photo?: Maybe<Scalars['Upload']>;
};

export type UpdateLessonQuestionInput = {
  id: Scalars['String'];
  questionUKR?: Maybe<Scalars['String']>;
  questionEN?: Maybe<Scalars['String']>;
  enterWord?: Maybe<Scalars['String']>;
  booleanVariant?: Maybe<Scalars['Boolean']>;
  rightVariantId?: Maybe<Scalars['String']>;
};

export type UpdateMobileNotificationInput = {
  id: Scalars['String'];
  nameEN?: Maybe<Scalars['String']>;
  nameUKR?: Maybe<Scalars['String']>;
  descriptionEN?: Maybe<Scalars['String']>;
  descriptionUKR?: Maybe<Scalars['String']>;
};

export type UpdateMultiLessonBlockInput = {
  lessonId?: Maybe<Scalars['String']>;
  modelId?: Maybe<Scalars['String']>;
  testId?: Maybe<Scalars['String']>;
  traningId?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['Upload']>;
};

export type UpdateNewsInput = {
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  isFor?: Maybe<NewsIsForEnum>;
  description?: Maybe<Scalars['String']>;
  youTubeLink?: Maybe<Scalars['String']>;
};

export type UpdateNotificationInput = {
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type UpdatePayrollKpiInput = {
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  weigh?: Maybe<Scalars['Float']>;
  plan?: Maybe<Scalars['Int']>;
  fact?: Maybe<Scalars['Int']>;
};

export type UpdateProductInput = {
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  features?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Scalars['Int']>;
  flex?: Maybe<Scalars['Int']>;
  requiredKpi?: Maybe<Scalars['Int']>;
  requiredLevel?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
};

export type UpdateProjectAdditionalContactInput = {
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type UpdateProjectCorrectionInput = {
  id: Scalars['String'];
  text?: Maybe<Scalars['String']>;
  statusId?: Maybe<Scalars['Int']>;
  files?: Maybe<Array<Maybe<Scalars['Upload']>>>;
};

export type UpdateProjectCustomFieldsMapByProjectInput = {
  projectId: Scalars['String'];
  startDateEstimate?: Maybe<Scalars['String']>;
  startDateFact?: Maybe<Scalars['String']>;
  finishDateEstimate?: Maybe<Scalars['String']>;
  finishDateFact?: Maybe<Scalars['String']>;
};

export type UpdateProjectCustomFieldsMapInput = {
  id: Scalars['String'];
  startDateEstimate?: Maybe<Scalars['String']>;
  startDateFact?: Maybe<Scalars['String']>;
  finishDateEstimate?: Maybe<Scalars['String']>;
  finishDateFact?: Maybe<Scalars['String']>;
};

export type UpdateProjectInput = {
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  statusId?: Maybe<Scalars['Int']>;
  projectTypeId?: Maybe<Scalars['String']>;
  startDateEstimate?: Maybe<Scalars['Date']>;
  finishDateEstimate?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
  finishDate?: Maybe<Scalars['Date']>;
  budget?: Maybe<Scalars['Int']>;
  cost?: Maybe<Scalars['Int']>;
  clientId?: Maybe<Scalars['String']>;
  projectManagerId?: Maybe<Scalars['String']>;
  stack?: Maybe<Array<Maybe<Scalars['String']>>>;
  blockDiagrams?: Maybe<Scalars['String']>;
  contractTemplates?: Maybe<Scalars['String']>;
  finalAgreement?: Maybe<Scalars['String']>;
  brief?: Maybe<Scalars['String']>;
  testTaskFile?: Maybe<Scalars['String']>;
  renderingFile?: Maybe<Scalars['String']>;
  designFigma?: Maybe<Scalars['String']>;
  risks?: Maybe<Scalars['String']>;
  riskScore?: Maybe<Scalars['Int']>;
};

export type UpdateProjectLinkInput = {
  id: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  login?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  isFor?: Maybe<IsForEnum>;
};

export type UpdateProjectStepInput = {
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  startDateEstimate?: Maybe<Scalars['String']>;
  startDateFact?: Maybe<Scalars['String']>;
  finishDateEstimate?: Maybe<Scalars['String']>;
  finishDateFact?: Maybe<Scalars['String']>;
  statusId?: Maybe<Scalars['String']>;
};

export type UpdateProjectTaskInput = {
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  assigneeId?: Maybe<Scalars['String']>;
  responsibleId?: Maybe<Scalars['String']>;
  startDateEstimate?: Maybe<Scalars['String']>;
  startDateFact?: Maybe<Scalars['String']>;
  finishDateEstimate?: Maybe<Scalars['String']>;
  finishDateFact?: Maybe<Scalars['String']>;
  timeEstimate?: Maybe<Scalars['Int']>;
  timeSpent?: Maybe<Scalars['Int']>;
  taskStatusId?: Maybe<Scalars['String']>;
};

export type UpdateSmartNotificationInput = {
  id: Scalars['String'];
  applicationId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
};

export type UpdateTestInput = {
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  modelId?: Maybe<Scalars['String']>;
  mainId?: Maybe<Scalars['String']>;
  themeId?: Maybe<Scalars['String']>;
  classroomId?: Maybe<Scalars['String']>;
  isOpenTest?: Maybe<Scalars['Boolean']>;
  attempts?: Maybe<Scalars['Int']>;
  time?: Maybe<Scalars['Int']>;
  class?: Maybe<TestClassEnum>;
  repeatAttemptTime?: Maybe<Scalars['Int']>;
};

export type UpdateUserGroupInput = {
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

export type UpdateUserInput = {
  id: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  jiraId?: Maybe<Scalars['String']>;
  oneCId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  cityId?: Maybe<Scalars['String']>;
  addressReal?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  instagram?: Maybe<Scalars['String']>;
  telegram?: Maybe<Scalars['String']>;
  contract?: Maybe<Scalars['Boolean']>;
  isRemote?: Maybe<Scalars['Boolean']>;
  drfo?: Maybe<Scalars['Boolean']>;
  nda?: Maybe<Scalars['Boolean']>;
  inn?: Maybe<Scalars['Boolean']>;
  creditCard?: Maybe<Scalars['String']>;
  roleId?: Maybe<Scalars['Int']>;
  statusId?: Maybe<Scalars['Int']>;
  jobId?: Maybe<Scalars['String']>;
  positionId?: Maybe<Scalars['Int']>;
  hireDate?: Maybe<Scalars['Date']>;
  agreementTypeId?: Maybe<Scalars['Int']>;
  agreementId?: Maybe<Scalars['String']>;
  departmentId?: Maybe<Scalars['String']>;
  loadPercent?: Maybe<Scalars['Int']>;
  promotionDate?: Maybe<Scalars['Date']>;
  promotionReason?: Maybe<Scalars['String']>;
  bonuses?: Maybe<Array<Maybe<Scalars['String']>>>;
  diploma?: Maybe<Scalars['Boolean']>;
  passport?: Maybe<Scalars['Boolean']>;
  contractType?: Maybe<Scalars['String']>;
  excerpt?: Maybe<Scalars['Boolean']>;
  idCard?: Maybe<Scalars['Boolean']>;
  applicationId?: Maybe<Scalars['String']>;
  countryId?: Maybe<Scalars['String']>;
  schoolId?: Maybe<Scalars['String']>;
  sendTrainingPush?: Maybe<Scalars['Boolean']>;
  teacherCertificate?: Maybe<Scalars['Boolean']>;
};

export type UpdateUserPaymentInput = {
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Int']>;
  estimatedDate?: Maybe<Scalars['Date']>;
};

export type UpdateUserPayrollInput = {
  id: Scalars['String'];
  hoursPlan?: Maybe<Scalars['Int']>;
  firstPartSalary?: Maybe<Scalars['Int']>;
  secondPartSalary?: Maybe<Scalars['Int']>;
  salaryDescription?: Maybe<Scalars['String']>;
  kpiDescription?: Maybe<Scalars['String']>;
  bonusesDescription?: Maybe<Scalars['String']>;
  totalDescription?: Maybe<Scalars['String']>;
  bonusFund?: Maybe<Scalars['Int']>;
};

export type UpdateUserStatsInput = {
  levelValue?: Maybe<Scalars['Int']>;
  exp?: Maybe<Scalars['Int']>;
  nextLevelExp?: Maybe<Scalars['Int']>;
  cof?: Maybe<Scalars['Float']>;
  baseExp?: Maybe<Scalars['Int']>;
  flex?: Maybe<Scalars['Int']>;
  kpi?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['String']>;
};

export type UpdateVacanciesPlanInput = {
  id: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
  jobId?: Maybe<Scalars['String']>;
  positionId?: Maybe<Scalars['Int']>;
  experience?: Maybe<Scalars['Float']>;
  statusId?: Maybe<Scalars['Int']>;
  comment?: Maybe<Scalars['String']>;
};


export type UploadFileInput = {
  file: Scalars['Upload'];
  name: Scalars['String'];
  isFor?: Maybe<IsForEnum>;
};

export type UploadedFile = {
  __typename?: 'UploadedFile';
  id?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  feedbackId?: Maybe<Scalars['String']>;
  feedbackCommentId?: Maybe<Scalars['String']>;
  correctionCommentId?: Maybe<Scalars['String']>;
  projectCorrectionId?: Maybe<Scalars['String']>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export type User = {
  __typename?: 'User';
  id?: Maybe<Scalars['String']>;
  jiraId?: Maybe<Scalars['String']>;
  trackerId?: Maybe<Scalars['Int']>;
  oneCId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  city?: Maybe<City>;
  addressReal?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  instagram?: Maybe<Scalars['String']>;
  telegram?: Maybe<Scalars['String']>;
  contract?: Maybe<Scalars['Boolean']>;
  isRemote?: Maybe<Scalars['Boolean']>;
  drfo?: Maybe<Scalars['Boolean']>;
  nda?: Maybe<Scalars['Boolean']>;
  inn?: Maybe<Scalars['Boolean']>;
  salary?: Maybe<Scalars['Int']>;
  userSalaryHistory?: Maybe<Array<Maybe<UserSalaryHistory>>>;
  userRateHistory?: Maybe<Array<Maybe<UserRateHistory>>>;
  creditCard?: Maybe<Scalars['String']>;
  role?: Maybe<UserRole>;
  status?: Maybe<UserStatus>;
  job?: Maybe<UserJob>;
  position?: Maybe<UserPosition>;
  stats?: Maybe<UserStats>;
  userExpHistory?: Maybe<Array<Maybe<UserExpHistory>>>;
  purchases?: Maybe<Array<Maybe<Purchase>>>;
  cart?: Maybe<Array<Maybe<Purchase>>>;
  purchaseHistory?: Maybe<Array<Maybe<Purchase>>>;
  projects?: Maybe<Array<Maybe<Project>>>;
  groups?: Maybe<Array<Maybe<UserGroup>>>;
  friends?: Maybe<Array<Maybe<User>>>;
  rate?: Maybe<Scalars['Int']>;
  hireDate?: Maybe<Scalars['Date']>;
  agreementType?: Maybe<UserAgreementType>;
  agreement?: Maybe<UserAgreement>;
  department?: Maybe<UserDepartment>;
  loadPercent?: Maybe<Scalars['Int']>;
  promotionDate?: Maybe<Scalars['Date']>;
  promotionReason?: Maybe<Scalars['String']>;
  previousSalary?: Maybe<Scalars['Int']>;
  payrolls?: Maybe<Array<Maybe<UserPayroll>>>;
  payments?: Maybe<Array<Maybe<UserPayment>>>;
  bonuses?: Maybe<Array<Maybe<Scalars['String']>>>;
  diploma?: Maybe<Scalars['Boolean']>;
  passport?: Maybe<Scalars['Boolean']>;
  contractType?: Maybe<Scalars['String']>;
  excerpt?: Maybe<Scalars['Boolean']>;
  cvs?: Maybe<Array<Maybe<UserCv>>>;
  idCard?: Maybe<Scalars['Boolean']>;
  trackerContracts?: Maybe<Array<Maybe<Contract>>>;
  vacanciesPlans?: Maybe<Array<Maybe<VacanciesPlan>>>;
  applicationId?: Maybe<Scalars['String']>;
  application?: Maybe<Application>;
  classrooms?: Maybe<Array<Maybe<Classroom>>>;
  countryId?: Maybe<Scalars['String']>;
  country?: Maybe<SchoolCountry>;
  testAnswers?: Maybe<Array<Maybe<TestAnswer>>>;
  schoolId?: Maybe<Scalars['String']>;
  school?: Maybe<School>;
  subjects?: Maybe<Array<Maybe<ApplicationMain>>>;
  eventDates?: Maybe<Array<Maybe<EventDate>>>;
  loggedFromARBook?: Maybe<Scalars['Boolean']>;
  loggedFromLKT?: Maybe<Scalars['Boolean']>;
  sendTrainingPush?: Maybe<Scalars['Boolean']>;
  teacherCertificate?: Maybe<Scalars['Boolean']>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
  deletedDate?: Maybe<Scalars['Date']>;
};

export type UserAgreement = {
  __typename?: 'UserAgreement';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export type UserAgreementType = {
  __typename?: 'UserAgreementType';
  id?: Maybe<Scalars['Int']>;
  type?: Maybe<UserAgreementTypesEnum>;
  displayName?: Maybe<Scalars['String']>;
};

export enum UserAgreementTypesEnum {
  Outsourcing = 'OUTSOURCING',
  Staff = 'STAFF'
}

export type UserCv = {
  __typename?: 'UserCV';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export type UserDepartment = {
  __typename?: 'UserDepartment';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export type UserExpHistory = {
  __typename?: 'UserExpHistory';
  id?: Maybe<Scalars['String']>;
  difference?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
};

export type UserGroup = {
  __typename?: 'UserGroup';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  members?: Maybe<Array<Maybe<User>>>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export type UserJob = {
  __typename?: 'UserJob';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export type UserOptionFields = {
  __typename?: 'UserOptionFields';
  cities?: Maybe<Array<Maybe<City>>>;
  agreements?: Maybe<Array<Maybe<UserAgreement>>>;
  departments?: Maybe<Array<Maybe<UserDepartment>>>;
  roles?: Maybe<Array<Maybe<UserRole>>>;
  statuses?: Maybe<Array<Maybe<UserStatus>>>;
  jobs?: Maybe<Array<Maybe<UserJob>>>;
  positions?: Maybe<Array<Maybe<UserPosition>>>;
  agreementType?: Maybe<Array<Maybe<UserAgreementType>>>;
};

export type UserPayment = {
  __typename?: 'UserPayment';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Int']>;
  user?: Maybe<User>;
  project?: Maybe<Project>;
  isPaid?: Maybe<Scalars['Boolean']>;
  paidDate?: Maybe<Scalars['Date']>;
  status?: Maybe<UserPaymentStatus>;
  estimatedDate?: Maybe<Scalars['Date']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  documents?: Maybe<Array<Maybe<UserPaymentDocument>>>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export type UserPaymentDocument = {
  __typename?: 'UserPaymentDocument';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  fileLink?: Maybe<Scalars['String']>;
  userPayment?: Maybe<UserPayment>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
};

export type UserPaymentStatus = {
  __typename?: 'UserPaymentStatus';
  id?: Maybe<Scalars['Int']>;
  status?: Maybe<UserPaymentStatusEnum>;
  displayName?: Maybe<Scalars['String']>;
};

export enum UserPaymentStatusEnum {
  Pending = 'PENDING',
  Paid = 'PAID',
  Late = 'LATE'
}

export type UserPayroll = {
  __typename?: 'UserPayroll';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['Date']>;
  user?: Maybe<User>;
  bonuses?: Maybe<Array<Maybe<PayrollBonus>>>;
  kpi?: Maybe<Array<Maybe<PayrollKpi>>>;
  parts?: Maybe<Array<Maybe<PayrollSalaryPart>>>;
  salaryDescription?: Maybe<Scalars['String']>;
  kpiDescription?: Maybe<Scalars['String']>;
  bonusesDescription?: Maybe<Scalars['String']>;
  totalDescription?: Maybe<Scalars['String']>;
  hoursPlan?: Maybe<Scalars['Int']>;
  bonusFund?: Maybe<Scalars['Int']>;
  additionalData?: Maybe<AdditionalData>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export type UserPosition = {
  __typename?: 'UserPosition';
  id?: Maybe<Scalars['Int']>;
  position?: Maybe<UserPositionEnum>;
  displayName?: Maybe<Scalars['String']>;
};

export enum UserPositionEnum {
  Candidate = 'CANDIDATE',
  Trainee = 'TRAINEE',
  Junior = 'JUNIOR',
  Middle = 'MIDDLE',
  Senior = 'SENIOR'
}

export type UserRateHistory = {
  __typename?: 'UserRateHistory';
  id?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['Int']>;
  user?: Maybe<User>;
  changeDate?: Maybe<Scalars['Date']>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export type UserRole = {
  __typename?: 'UserRole';
  id?: Maybe<Scalars['Int']>;
  role?: Maybe<UserRoleEnum>;
  displayName?: Maybe<Scalars['String']>;
};

export enum UserRoleEnum {
  Super = 'SUPER',
  Manager = 'MANAGER',
  Client = 'CLIENT',
  Staff = 'STAFF',
  Pupil = 'PUPIL',
  Subclient = 'SUBCLIENT',
  Teacher = 'TEACHER',
  Parent = 'PARENT'
}

export type UserSalaryHistory = {
  __typename?: 'UserSalaryHistory';
  id?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  difference?: Maybe<Scalars['Int']>;
  previous?: Maybe<Scalars['Int']>;
  current?: Maybe<Scalars['Int']>;
  user?: Maybe<User>;
  changeDate?: Maybe<Scalars['Date']>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export type UserStats = {
  __typename?: 'UserStats';
  id?: Maybe<Scalars['String']>;
  levelValue?: Maybe<Scalars['Int']>;
  exp?: Maybe<Scalars['Int']>;
  nextLevelExp?: Maybe<Scalars['Int']>;
  cof?: Maybe<Scalars['Float']>;
  baseExp?: Maybe<Scalars['Int']>;
  flex?: Maybe<Scalars['Int']>;
  kpi?: Maybe<Scalars['Int']>;
  user?: Maybe<User>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export type UserStatus = {
  __typename?: 'UserStatus';
  id?: Maybe<Scalars['Int']>;
  status?: Maybe<UserStatusEnum>;
  displayName?: Maybe<Scalars['String']>;
};

export enum UserStatusEnum {
  Pending = 'PENDING',
  Verified = 'VERIFIED',
  NotVerified = 'NOT_VERIFIED',
  Fired = 'FIRED',
  Unregistered = 'UNREGISTERED'
}

export type UsersCountLight = {
  __typename?: 'UsersCountLight';
  status?: Maybe<UsersCountStatusesEnum>;
  count?: Maybe<Scalars['Int']>;
};

export enum UsersCountStatusesEnum {
  Client = 'CLIENT',
  Staff = 'STAFF',
  New = 'NEW'
}

export type VacanciesPlan = {
  __typename?: 'VacanciesPlan';
  id?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  name?: Maybe<Scalars['String']>;
  job?: Maybe<UserJob>;
  position?: Maybe<UserPosition>;
  experience?: Maybe<Scalars['Float']>;
  status?: Maybe<VacanciesPlanStatus>;
  comment?: Maybe<Scalars['String']>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export type VacanciesPlanLight = {
  __typename?: 'VacanciesPlanLight';
  name?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
};

export type VacanciesPlanOptionFields = {
  __typename?: 'VacanciesPlanOptionFields';
  statuses?: Maybe<Array<Maybe<VacanciesPlanStatus>>>;
  jobs?: Maybe<Array<Maybe<UserJob>>>;
  positions?: Maybe<Array<Maybe<UserPosition>>>;
};

export type VacanciesPlanStatus = {
  __typename?: 'VacanciesPlanStatus';
  id?: Maybe<Scalars['Int']>;
  status?: Maybe<VacanciesPlanStatusEnum>;
  displayName?: Maybe<Scalars['String']>;
};

export enum VacanciesPlanStatusEnum {
  New = 'NEW',
  Pending = 'PENDING',
  Success = 'SUCCESS',
  Failed = 'FAILED'
}

export type VacanciesPlansByName = {
  __typename?: 'VacanciesPlansByName';
  plans?: Maybe<Array<Maybe<VacanciesPlan>>>;
  progress?: Maybe<Scalars['Float']>;
};

export type Variant = {
  __typename?: 'Variant';
  id?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  questionId?: Maybe<Scalars['String']>;
  question?: Maybe<Question>;
  answers?: Maybe<Array<Maybe<Answer>>>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export type BlockTypeInput = {
  content?: Maybe<Scalars['String']>;
  testQuestion?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['String']>;
};

export enum IsFromEnum {
  AdminPanel = 'ADMIN_PANEL',
  LkStaff = 'LK_STAFF',
  LkTeacher = 'LK_TEACHER',
  Arbook = 'ARBOOK'
}

export type CreateProjectTaskCommentMutationVariables = Exact<{
  projectTaskId: Scalars['String'];
  text: Scalars['String'];
  file?: Maybe<Scalars['Upload']>;
}>;


export type CreateProjectTaskCommentMutation = (
  { __typename?: 'Mutation' }
  & { createProjectTaskComment?: Maybe<(
    { __typename?: 'ProjectTaskComment' }
    & Pick<ProjectTaskComment, 'id'>
  )> }
);

export type UpdateProjectTaskCommentMutationVariables = Exact<{
  id: Scalars['String'];
  text: Scalars['String'];
  file?: Maybe<Scalars['Upload']>;
}>;


export type UpdateProjectTaskCommentMutation = (
  { __typename?: 'Mutation' }
  & { updateProjectTaskComment?: Maybe<(
    { __typename?: 'ProjectTaskComment' }
    & Pick<ProjectTaskComment, 'id'>
  )> }
);

export type DeleteProjectTaskCommentMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteProjectTaskCommentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteProjectTaskComment'>
);

export type DeleteProjectTaskCommentFileMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteProjectTaskCommentFileMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteProjectTaskCommentFile'>
);

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
  isFrom: IsFromEnum;
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login?: Maybe<(
    { __typename?: 'Auth' }
    & Pick<Auth, 'token'>
  )> }
);

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'logout'>
);

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['String'];
  isFrom: IsFromEnum;
}>;


export type ForgotPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'forgotPassword'>
);

export type ResetPasswordMutationVariables = Exact<{
  password: Scalars['String'];
  token: Scalars['String'];
  isFrom: IsFromEnum;
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & { resetPassword?: Maybe<(
    { __typename?: 'Auth' }
    & Pick<Auth, 'token'>
  )> }
);

export type AnswerKnowledgeBaseBlockTestMutationVariables = Exact<{
  blockId: Scalars['String'];
  answerId: Scalars['String'];
}>;


export type AnswerKnowledgeBaseBlockTestMutation = (
  { __typename?: 'Mutation' }
  & { answerKnowledgeBaseBlockTest?: Maybe<(
    { __typename?: 'KnowledgeBaseBlockTestHistory' }
    & Pick<KnowledgeBaseBlockTestHistory, 'id'>
  )> }
);

export type CreatePtCheckListItemMutationVariables = Exact<{
  projectTaskId: Scalars['String'];
  name: Scalars['String'];
}>;


export type CreatePtCheckListItemMutation = (
  { __typename?: 'Mutation' }
  & { createPTCheckListItem?: Maybe<(
    { __typename?: 'ProjectTaskCheckListItem' }
    & Pick<ProjectTaskCheckListItem, 'id'>
  )> }
);

export type UpdatePtCheckListItemMutationVariables = Exact<{
  id: Scalars['String'];
  name: Scalars['String'];
}>;


export type UpdatePtCheckListItemMutation = (
  { __typename?: 'Mutation' }
  & { updatePTCheckListItem?: Maybe<(
    { __typename?: 'ProjectTaskCheckListItem' }
    & Pick<ProjectTaskCheckListItem, 'id'>
  )> }
);

export type DeletePtCheckListItemMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeletePtCheckListItemMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletePTCheckListItem'>
);

export type ChangePtCheckListItemStatusMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type ChangePtCheckListItemStatusMutation = (
  { __typename?: 'Mutation' }
  & { changePTCheckListItemStatus?: Maybe<(
    { __typename?: 'ProjectTaskCheckListItem' }
    & Pick<ProjectTaskCheckListItem, 'id' | 'isCompleted'>
  )> }
);

export type CreateProjectCorrectionMutationVariables = Exact<{
  projectCorrectionData: CreateProjectCorrectionInput;
}>;


export type CreateProjectCorrectionMutation = (
  { __typename?: 'Mutation' }
  & { createProjectCorrection?: Maybe<(
    { __typename?: 'ProjectCorrection' }
    & Pick<ProjectCorrection, 'id' | 'text'>
    & { status?: Maybe<(
      { __typename?: 'ProjectCorrectionStatus' }
      & Pick<ProjectCorrectionStatus, 'id' | 'displayName'>
    )> }
  )> }
);

export type DeleteProjectCorrectionMutationVariables = Exact<{
  projectCorrectionId: Scalars['String'];
}>;


export type DeleteProjectCorrectionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteProjectCorrection'>
);

export type UpdateProjectCorrectionMutationVariables = Exact<{
  projectCorrectionData: UpdateProjectCorrectionInput;
}>;


export type UpdateProjectCorrectionMutation = (
  { __typename?: 'Mutation' }
  & { updateProjectCorrection?: Maybe<(
    { __typename?: 'ProjectCorrection' }
    & Pick<ProjectCorrection, 'text' | 'updatedDate'>
  )> }
);

export type CreateCorrectionCommentMutationVariables = Exact<{
  correctionCommentData: CreateCorrectionCommentInput;
}>;


export type CreateCorrectionCommentMutation = (
  { __typename?: 'Mutation' }
  & { createCorrectionComment?: Maybe<(
    { __typename?: 'CorrectionComment' }
    & { correction?: Maybe<(
      { __typename?: 'ProjectCorrection' }
      & Pick<ProjectCorrection, 'id' | 'text' | 'createdDate'>
    )>, createdBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'firstName'>
    )> }
  )> }
);

export type CreateProjectTaskInKanbanMutationVariables = Exact<{
  taskData: CreateProjectTaskInput;
  workflowStepId: Scalars['String'];
  workflowStepOrder: Scalars['Int'];
}>;


export type CreateProjectTaskInKanbanMutation = (
  { __typename?: 'Mutation' }
  & { createProjectTaskInKanban?: Maybe<(
    { __typename?: 'ProjectTask' }
    & Pick<ProjectTask, 'id'>
  )> }
);

export type UpdateProjectTaskMutationVariables = Exact<{
  taskData: UpdateProjectTaskInput;
}>;


export type UpdateProjectTaskMutation = (
  { __typename?: 'Mutation' }
  & { updateProjectTask?: Maybe<(
    { __typename?: 'ProjectTask' }
    & Pick<ProjectTask, 'id'>
  )> }
);

export type ChangeProjectTaskWorkflowStepMutationVariables = Exact<{
  tasksId: Scalars['String'];
  workflowStepId: Scalars['String'];
  workflowStepOrder: Scalars['Int'];
}>;


export type ChangeProjectTaskWorkflowStepMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'changeProjectTaskWorkflowStep'>
);

export type AssignProjectTaskLabelMutationVariables = Exact<{
  projectTaskId: Scalars['String'];
  labelId: Scalars['String'];
}>;


export type AssignProjectTaskLabelMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'assignProjectTaskLabel'>
);

export type RemoveProjectTaskLabelMutationVariables = Exact<{
  projectTaskId: Scalars['String'];
  labelId: Scalars['String'];
}>;


export type RemoveProjectTaskLabelMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeProjectTaskLabel'>
);

export type CreatePurchaseMutationVariables = Exact<{
  productId: Scalars['String'];
}>;


export type CreatePurchaseMutation = (
  { __typename?: 'Mutation' }
  & { createPurchase?: Maybe<(
    { __typename?: 'Purchase' }
    & Pick<Purchase, 'id'>
    & { status?: Maybe<(
      { __typename?: 'PurchaseStatus' }
      & Pick<PurchaseStatus, 'status'>
    )> }
  )> }
);

export type DeletePurchaseMutationVariables = Exact<{
  purchaseId: Scalars['String'];
}>;


export type DeletePurchaseMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletePurchase'>
);

export type ConfirmPurchaseMutationVariables = Exact<{ [key: string]: never; }>;


export type ConfirmPurchaseMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'confirmPurchase'>
);

export type CreateUserMutationVariables = Exact<{
  userData: CreateUserInput;
  isFrom: IsFromEnum;
}>;


export type CreateUserMutation = (
  { __typename?: 'Mutation' }
  & { createUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'phone' | 'email'>
  )> }
);

export type UpdateUserMutationVariables = Exact<{
  userData: UpdateUserInput;
  photo?: Maybe<Scalars['Upload']>;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'email' | 'phone' | 'photo'>
  )> }
);

export type GetChartData2QueryVariables = Exact<{
  projectId: Scalars['String'];
  isEpic?: Maybe<Scalars['Boolean']>;
  epicId?: Maybe<Scalars['String']>;
}>;


export type GetChartData2Query = (
  { __typename?: 'Query' }
  & { getChartData2?: Maybe<Array<Maybe<(
    { __typename?: 'ChartData2' }
    & Pick<ChartData2, 'id' | 'name' | 'start' | 'end' | 'progress' | 'type' | 'project' | 'isClient' | 'isAdditionalTask'>
  )>>> }
);

export type GetBaseKanbanWorkflowStepsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBaseKanbanWorkflowStepsQuery = (
  { __typename?: 'Query' }
  & { getBaseKanbanWorkflowSteps?: Maybe<Array<Maybe<(
    { __typename?: 'KanbanWorkflowStep' }
    & Pick<KanbanWorkflowStep, 'id' | 'name'>
  )>>> }
);

export type GetKanbanWorkflowStepsByProjectQueryVariables = Exact<{
  projectId: Scalars['String'];
}>;


export type GetKanbanWorkflowStepsByProjectQuery = (
  { __typename?: 'Query' }
  & { getKanbanWorkflowStepsByProject?: Maybe<Array<Maybe<(
    { __typename?: 'KanbanWorkflowStep' }
    & Pick<KanbanWorkflowStep, 'id' | 'name' | 'projectId'>
  )>>> }
);

export type GetProjectTasksByWorkflowStepQueryVariables = Exact<{
  projectId: Scalars['String'];
  workflowStepId: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
}>;


export type GetProjectTasksByWorkflowStepQuery = (
  { __typename?: 'Query' }
  & { getProjectTasksByWorkflowStep?: Maybe<(
    { __typename?: 'GetProjectTasksByWorkflowStep' }
    & Pick<GetProjectTasksByWorkflowStep, 'total'>
    & { tasks?: Maybe<Array<Maybe<(
      { __typename?: 'ProjectTask' }
      & Pick<ProjectTask, 'id' | 'name' | 'workflowStepOrder'>
    )>>> }
  )> }
);

export type GetProjectTasksByProjectQueryVariables = Exact<{
  projectId: Scalars['String'];
}>;


export type GetProjectTasksByProjectQuery = (
  { __typename?: 'Query' }
  & { getProjectTasksByProject?: Maybe<Array<Maybe<(
    { __typename?: 'ProjectTask' }
    & Pick<ProjectTask, 'id' | 'name' | 'kanbanWorkflowStepId'>
  )>>> }
);

export type GetProjectKanbanQueryVariables = Exact<{
  projectId: Scalars['String'];
}>;


export type GetProjectKanbanQuery = (
  { __typename?: 'Query' }
  & { getProjectKanban?: Maybe<(
    { __typename?: 'ProjectKanban' }
    & Pick<ProjectKanban, 'id' | 'name'>
  )> }
);

export type GetProjectStepsByProjectQueryVariables = Exact<{
  projectId: Scalars['String'];
}>;


export type GetProjectStepsByProjectQuery = (
  { __typename?: 'Query' }
  & { getProjectStepsByProject?: Maybe<Array<Maybe<(
    { __typename?: 'ProjectStep' }
    & Pick<ProjectStep, 'id' | 'name'>
  )>>> }
);

export type GetProjectStepsByProjectLightQueryVariables = Exact<{
  projectId: Scalars['String'];
}>;


export type GetProjectStepsByProjectLightQuery = (
  { __typename?: 'Query' }
  & { getProjectStepsByProject?: Maybe<Array<Maybe<(
    { __typename?: 'ProjectStep' }
    & Pick<ProjectStep, 'id' | 'name' | 'key'>
  )>>> }
);

export type GetKnowledgeBaseBlocksByThemeIdQueryVariables = Exact<{
  themeId: Scalars['String'];
}>;


export type GetKnowledgeBaseBlocksByThemeIdQuery = (
  { __typename?: 'Query' }
  & { getKnowledgeBaseBlocksByThemeId?: Maybe<Array<Maybe<(
    { __typename?: 'KnowledgeBaseBlock' }
    & Pick<KnowledgeBaseBlock, 'id' | 'type' | 'content' | 'photo' | 'video' | 'testQuestion'>
    & { lists?: Maybe<Array<Maybe<(
      { __typename?: 'KnowledgeBaseBlockList' }
      & Pick<KnowledgeBaseBlockList, 'id' | 'content' | 'blockId' | 'isNumbered'>
    )>>>, testAnswers?: Maybe<Array<Maybe<(
      { __typename?: 'KnowledgeBaseBlockTestAnswer' }
      & Pick<KnowledgeBaseBlockTestAnswer, 'id' | 'content' | 'blockId' | 'isAnswerTrue'>
    )>>> }
  )>>> }
);

export type GetKnowledgeBaseBlocksTestByThemeIdQueryVariables = Exact<{
  themeId: Scalars['String'];
  isTestPublished?: Maybe<Scalars['Boolean']>;
}>;


export type GetKnowledgeBaseBlocksTestByThemeIdQuery = (
  { __typename?: 'Query' }
  & { getKnowledgeBaseBlocksTestByThemeId?: Maybe<Array<Maybe<(
    { __typename?: 'KnowledgeBaseBlock' }
    & Pick<KnowledgeBaseBlock, 'id' | 'type' | 'testQuestion'>
    & { testAnswers?: Maybe<Array<Maybe<(
      { __typename?: 'KnowledgeBaseBlockTestAnswer' }
      & Pick<KnowledgeBaseBlockTestAnswer, 'id' | 'content' | 'blockId' | 'isAnswerTrue'>
    )>>>, testsHistory?: Maybe<Array<Maybe<(
      { __typename?: 'KnowledgeBaseBlockTestHistory' }
      & Pick<KnowledgeBaseBlockTestHistory, 'id' | 'answerId' | 'blockId'>
      & { createdBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id'>
      )> }
    )>>> }
  )>>> }
);

export type GetKnowledgeBaseCategoriesByParticipantQueryVariables = Exact<{
  userId: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
}>;


export type GetKnowledgeBaseCategoriesByParticipantQuery = (
  { __typename?: 'Query' }
  & { getKnowledgeBaseCategoriesByParticipant?: Maybe<(
    { __typename?: 'GetKnowledgeBaseCategorysByParticipant' }
    & Pick<GetKnowledgeBaseCategorysByParticipant, 'total'>
    & { categories?: Maybe<Array<Maybe<(
      { __typename?: 'KnowledgeBaseCategoryByParticipant' }
      & { category?: Maybe<(
        { __typename?: 'KnowledgeBaseCategory' }
        & Pick<KnowledgeBaseCategory, 'id' | 'name'>
      )> }
    )>>> }
  )> }
);

export type GetKnowledgeBaseThemesByCategoryIdQueryVariables = Exact<{
  categoryId: Scalars['String'];
  isPublished?: Maybe<Scalars['Boolean']>;
}>;


export type GetKnowledgeBaseThemesByCategoryIdQuery = (
  { __typename?: 'Query' }
  & { getKnowledgeBaseThemesByCategoryId?: Maybe<Array<Maybe<(
    { __typename?: 'KnowledgeBaseTheme' }
    & Pick<KnowledgeBaseTheme, 'id' | 'name'>
  )>>> }
);

export type GetNewsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  isPublished?: Maybe<Scalars['Boolean']>;
}>;


export type GetNewsQuery = (
  { __typename?: 'Query' }
  & { getNews?: Maybe<(
    { __typename?: 'GetNews' }
    & Pick<GetNews, 'total'>
    & { news?: Maybe<Array<Maybe<(
      { __typename?: 'News' }
      & Pick<News, 'id' | 'name' | 'description' | 'photo' | 'youTubeLink' | 'publishedAt'>
    )>>> }
  )> }
);

export type GetNotificationsByParticipantQueryVariables = Exact<{
  userId: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  isPublished?: Maybe<Scalars['Boolean']>;
}>;


export type GetNotificationsByParticipantQuery = (
  { __typename?: 'Query' }
  & { getNotificationsByParticipant?: Maybe<(
    { __typename?: 'GetNotificationsByParticipant' }
    & Pick<GetNotificationsByParticipant, 'total'>
    & { notifications?: Maybe<Array<Maybe<(
      { __typename?: 'NotificationByParticipant' }
      & Pick<NotificationByParticipant, 'isViewed'>
      & { notification?: Maybe<(
        { __typename?: 'Notification' }
        & Pick<Notification, 'id' | 'name' | 'description' | 'publishedAt'>
      )> }
    )>>> }
  )> }
);

export type NotificationParticipantStatusChangeMutationVariables = Exact<{
  notificationIds: Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>;
  userId: Scalars['String'];
}>;


export type NotificationParticipantStatusChangeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'notificationParticipantStatusChange'>
);

export type GetUserPaymentsByProjectQueryVariables = Exact<{
  projectId: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  isPublished?: Maybe<Scalars['Boolean']>;
}>;


export type GetUserPaymentsByProjectQuery = (
  { __typename?: 'Query' }
  & { getUserPaymentsByProject?: Maybe<(
    { __typename?: 'GetUserPayments' }
    & Pick<GetUserPayments, 'total'>
    & { payments?: Maybe<Array<Maybe<(
      { __typename?: 'UserPayment' }
      & Pick<UserPayment, 'id' | 'name' | 'cost' | 'estimatedDate' | 'createdDate' | 'isPaid'>
      & { status?: Maybe<(
        { __typename?: 'UserPaymentStatus' }
        & Pick<UserPaymentStatus, 'displayName'>
      )> }
    )>>> }
  )> }
);

export type GetUserPayrollsQueryVariables = Exact<{
  userId: Scalars['String'];
}>;


export type GetUserPayrollsQuery = (
  { __typename?: 'Query' }
  & { getUserPayrolls?: Maybe<Array<Maybe<(
    { __typename?: 'UserPayroll' }
    & Pick<UserPayroll, 'id' | 'name' | 'hoursPlan'>
    & { bonuses?: Maybe<Array<Maybe<(
      { __typename?: 'PayrollBonus' }
      & Pick<PayrollBonus, 'id' | 'name' | 'value'>
    )>>>, additionalData?: Maybe<(
      { __typename?: 'AdditionalData' }
      & Pick<AdditionalData, 'total' | 'mainPart'>
      & { reports?: Maybe<Array<Maybe<(
        { __typename?: 'Report' }
        & Pick<Report, 'salary' | 'mainPart' | 'times' | 'totalTime'>
      )>>> }
    )> }
  )>>> }
);

export type GetUserPayrollQueryVariables = Exact<{
  payrollId: Scalars['String'];
}>;


export type GetUserPayrollQuery = (
  { __typename?: 'Query' }
  & { getUserPayroll?: Maybe<(
    { __typename?: 'UserPayroll' }
    & Pick<UserPayroll, 'id' | 'name' | 'bonusFund' | 'salaryDescription' | 'bonusesDescription' | 'totalDescription' | 'kpiDescription' | 'hoursPlan'>
    & { bonuses?: Maybe<Array<Maybe<(
      { __typename?: 'PayrollBonus' }
      & Pick<PayrollBonus, 'id' | 'name' | 'value'>
    )>>>, kpi?: Maybe<Array<Maybe<(
      { __typename?: 'PayrollKpi' }
      & Pick<PayrollKpi, 'id' | 'name' | 'unit' | 'weigh' | 'plan' | 'fact'>
    )>>>, parts?: Maybe<Array<Maybe<(
      { __typename?: 'PayrollSalaryPart' }
      & Pick<PayrollSalaryPart, 'id' | 'name' | 'value' | 'date'>
    )>>>, additionalData?: Maybe<(
      { __typename?: 'AdditionalData' }
      & Pick<AdditionalData, 'bonusesValue' | 'kpiValue' | 'total' | 'mainPart'>
      & { reports?: Maybe<Array<Maybe<(
        { __typename?: 'Report' }
        & Pick<Report, 'totalTime' | 'times' | 'salary'>
      )>>> }
    )> }
  )> }
);

export type GetUserInfoPayrollQueryVariables = Exact<{
  userId: Scalars['String'];
}>;


export type GetUserInfoPayrollQuery = (
  { __typename?: 'Query' }
  & { getUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'salary' | 'firstName' | 'lastName'>
    & { job?: Maybe<(
      { __typename?: 'UserJob' }
      & Pick<UserJob, 'id' | 'name'>
    )> }
  )> }
);

export type GetProductsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProductsQuery = (
  { __typename?: 'Query' }
  & { getProducts?: Maybe<Array<Maybe<(
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'name' | 'description' | 'features' | 'flex' | 'requiredKpi' | 'requiredLevel' | 'image' | 'url' | 'location'>
    & { type?: Maybe<(
      { __typename?: 'ProductType' }
      & Pick<ProductType, 'displayName'>
    )> }
  )>>> }
);

export type GetProjectQueryVariables = Exact<{
  projectId: Scalars['String'];
}>;


export type GetProjectQuery = (
  { __typename?: 'Query' }
  & { getProject?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'name' | 'designFigma' | 'brief' | 'blockDiagrams' | 'contractTemplates'>
    & { projectManager?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'phone' | 'photo'>
      & { job?: Maybe<(
        { __typename?: 'UserJob' }
        & Pick<UserJob, 'name'>
      )> }
    )>, contactPersons?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName' | 'phone' | 'photo'>
      & { job?: Maybe<(
        { __typename?: 'UserJob' }
        & Pick<UserJob, 'name'>
      )> }
    )>>>, employees?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'photo'>
      & { job?: Maybe<(
        { __typename?: 'UserJob' }
        & Pick<UserJob, 'name'>
      )> }
    )>>>, links?: Maybe<Array<Maybe<(
      { __typename?: 'ProjectLink' }
      & Pick<ProjectLink, 'title' | 'link' | 'login' | 'password'>
    )>>>, presentations?: Maybe<Array<Maybe<(
      { __typename?: 'ProjectPresentation' }
      & Pick<ProjectPresentation, 'id' | 'title' | 'link'>
    )>>> }
  )> }
);

export type GetProjectsByUserQueryVariables = Exact<{
  userId: Scalars['String'];
}>;


export type GetProjectsByUserQuery = (
  { __typename?: 'Query' }
  & { getProjectsByUser?: Maybe<Array<Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'name' | 'id'>
  )>>> }
);

export type GetProjectsByClientQueryVariables = Exact<{
  userId: Scalars['String'];
}>;


export type GetProjectsByClientQuery = (
  { __typename?: 'Query' }
  & { getProjectsByClient?: Maybe<Array<Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'name'>
  )>>> }
);

export type GetProjectCorrectionsQueryVariables = Exact<{
  projectId: Scalars['String'];
  statuses?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
}>;


export type GetProjectCorrectionsQuery = (
  { __typename?: 'Query' }
  & { getProjectCorrections?: Maybe<Array<Maybe<(
    { __typename?: 'ProjectCorrection' }
    & Pick<ProjectCorrection, 'id' | 'text' | 'createdDate' | 'updatedDate'>
    & { status?: Maybe<(
      { __typename?: 'ProjectCorrectionStatus' }
      & Pick<ProjectCorrectionStatus, 'displayName'>
    )>, comments?: Maybe<Array<Maybe<(
      { __typename?: 'CorrectionComment' }
      & Pick<CorrectionComment, 'id' | 'text'>
      & { correction?: Maybe<(
        { __typename?: 'ProjectCorrection' }
        & Pick<ProjectCorrection, 'id' | 'text' | 'createdDate'>
      )> }
    )>>>, createdBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'photo'>
      & { job?: Maybe<(
        { __typename?: 'UserJob' }
        & Pick<UserJob, 'name'>
      )> }
    )> }
  )>>> }
);

export type GetProjectCorrectionsLightQueryVariables = Exact<{
  projectId: Scalars['String'];
}>;


export type GetProjectCorrectionsLightQuery = (
  { __typename?: 'Query' }
  & { getProjectCorrectionsLight?: Maybe<Array<Maybe<(
    { __typename?: 'CorrectionsLight' }
    & Pick<CorrectionsLight, 'status' | 'count'>
  )>>> }
);

export type GetProjectStepsFromJiraQueryVariables = Exact<{
  projectId: Scalars['String'];
}>;


export type GetProjectStepsFromJiraQuery = (
  { __typename?: 'Query' }
  & { getProjectStepsFromJira?: Maybe<Array<Maybe<(
    { __typename?: 'Issue' }
    & { fields?: Maybe<(
      { __typename?: 'IssueFields' }
      & Pick<IssueFields, 'summary' | 'duedate' | 'customfield_10015'>
    )> }
  )>>> }
);

export type GetProjectTaskQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetProjectTaskQuery = (
  { __typename?: 'Query' }
  & { getProjectTask?: Maybe<(
    { __typename?: 'ProjectTask' }
    & Pick<ProjectTask, 'id' | 'name' | 'description' | 'key' | 'epicKey' | 'assigneeId' | 'responsibleId' | 'startDateEstimate' | 'startDateFact' | 'finishDateEstimate' | 'finishDateFact' | 'timeEstimate' | 'timeSpent'>
    & { comments?: Maybe<Array<Maybe<(
      { __typename?: 'ProjectTaskComment' }
      & Pick<ProjectTaskComment, 'id' | 'text' | 'file'>
      & { createdBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName' | 'photo'>
      )> }
    )>>>, items?: Maybe<Array<Maybe<(
      { __typename?: 'ProjectTaskCheckListItem' }
      & Pick<ProjectTaskCheckListItem, 'id' | 'name' | 'isCompleted'>
    )>>>, labels?: Maybe<Array<Maybe<(
      { __typename?: 'ProjectTaskLabel' }
      & Pick<ProjectTaskLabel, 'id' | 'name' | 'color'>
    )>>>, createdBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName' | 'photo'>
    )> }
  )> }
);

export type GetLateTasksQueryVariables = Exact<{
  projectId: Scalars['String'];
  taskStatusId?: Maybe<Scalars['String']>;
  isClient?: Maybe<Scalars['Boolean']>;
}>;


export type GetLateTasksQuery = (
  { __typename?: 'Query' }
  & { getLateTasks?: Maybe<Array<Maybe<(
    { __typename?: 'LateTasks' }
    & Pick<LateTasks, 'id' | 'name' | 'lateReason' | 'assigneeName' | 'startDateFact' | 'startDateEstimate' | 'finishDateFact' | 'finishDateEstimate' | 'cost' | 'costEstimate' | 'lateStart' | 'lateFinish'>
  )>>> }
);

export type GetAvailableProjectTaskLabelsQueryVariables = Exact<{
  projectId: Scalars['String'];
  projectTaskId: Scalars['String'];
}>;


export type GetAvailableProjectTaskLabelsQuery = (
  { __typename?: 'Query' }
  & { getAvailableProjectTaskLabels?: Maybe<Array<Maybe<(
    { __typename?: 'ProjectTaskLabel' }
    & Pick<ProjectTaskLabel, 'id' | 'name' | 'color'>
  )>>> }
);

export type GetPurchasesInCartQueryVariables = Exact<{
  getPurchasesData: GetPurchasesByUserInput;
}>;


export type GetPurchasesInCartQuery = (
  { __typename?: 'Query' }
  & { getPurchasesInCart?: Maybe<(
    { __typename?: 'GetPurchases' }
    & Pick<GetPurchases, 'total'>
    & { purchases?: Maybe<Array<Maybe<(
      { __typename?: 'Purchase' }
      & Pick<Purchase, 'id'>
      & { product?: Maybe<(
        { __typename?: 'Product' }
        & Pick<Product, 'id' | 'name' | 'flex' | 'requiredKpi' | 'requiredLevel' | 'image' | 'description' | 'features' | 'url' | 'location'>
        & { type?: Maybe<(
          { __typename?: 'ProductType' }
          & Pick<ProductType, 'displayName'>
        )> }
      )>, status?: Maybe<(
        { __typename?: 'PurchaseStatus' }
        & Pick<PurchaseStatus, 'status'>
      )> }
    )>>> }
  )> }
);

export type GetPurchaseHistoryQueryVariables = Exact<{
  getPurchasesData: GetPurchasesByUserInput;
}>;


export type GetPurchaseHistoryQuery = (
  { __typename?: 'Query' }
  & { getPurchaseHistory?: Maybe<(
    { __typename?: 'GetPurchases' }
    & Pick<GetPurchases, 'total'>
    & { purchases?: Maybe<Array<Maybe<(
      { __typename?: 'Purchase' }
      & Pick<Purchase, 'id'>
      & { product?: Maybe<(
        { __typename?: 'Product' }
        & Pick<Product, 'id' | 'name' | 'image' | 'description' | 'features' | 'url' | 'location'>
        & { type?: Maybe<(
          { __typename?: 'ProductType' }
          & Pick<ProductType, 'displayName'>
        )> }
      )>, status?: Maybe<(
        { __typename?: 'PurchaseStatus' }
        & Pick<PurchaseStatus, 'status'>
      )> }
    )>>> }
  )> }
);

export type GetBaseProjectTaskStatusesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBaseProjectTaskStatusesQuery = (
  { __typename?: 'Query' }
  & { getBaseProjectTaskStatuses?: Maybe<Array<Maybe<(
    { __typename?: 'ProjectTaskStatus' }
    & Pick<ProjectTaskStatus, 'id' | 'name' | 'isBase'>
  )>>> }
);

export type GetUserInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserInfoQuery = (
  { __typename?: 'Query' }
  & { getUserInfo?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'photo' | 'phone' | 'email'>
    & { stats?: Maybe<(
      { __typename?: 'UserStats' }
      & Pick<UserStats, 'flex' | 'kpi' | 'levelValue'>
    )>, projects?: Maybe<Array<Maybe<(
      { __typename?: 'Project' }
      & Pick<Project, 'id' | 'name'>
    )>>>, job?: Maybe<(
      { __typename?: 'UserJob' }
      & Pick<UserJob, 'name'>
    )>, role?: Maybe<(
      { __typename?: 'UserRole' }
      & Pick<UserRole, 'id' | 'displayName'>
    )> }
  )> }
);

export type GetUsersNameQueryVariables = Exact<{
  getUsersData: GetUsersInput;
}>;


export type GetUsersNameQuery = (
  { __typename?: 'Query' }
  & { getUsers?: Maybe<(
    { __typename?: 'GetUsers' }
    & { users?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'lastName' | 'firstName' | 'photo'>
    )>>> }
  )> }
);

export type GetUserInfoLightQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserInfoLightQuery = (
  { __typename?: 'Query' }
  & { getUserInfo?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'photo'>
  )> }
);


export const CreateProjectTaskCommentDocument = gql`
    mutation createProjectTaskComment($projectTaskId: String!, $text: String!, $file: Upload) {
  createProjectTaskComment(
    projectTaskId: $projectTaskId
    text: $text
    file: $file
  ) {
    id
  }
}
    `;
export type CreateProjectTaskCommentMutationFn = Apollo.MutationFunction<CreateProjectTaskCommentMutation, CreateProjectTaskCommentMutationVariables>;

/**
 * __useCreateProjectTaskCommentMutation__
 *
 * To run a mutation, you first call `useCreateProjectTaskCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectTaskCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectTaskCommentMutation, { data, loading, error }] = useCreateProjectTaskCommentMutation({
 *   variables: {
 *      projectTaskId: // value for 'projectTaskId'
 *      text: // value for 'text'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useCreateProjectTaskCommentMutation(baseOptions?: Apollo.MutationHookOptions<CreateProjectTaskCommentMutation, CreateProjectTaskCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProjectTaskCommentMutation, CreateProjectTaskCommentMutationVariables>(CreateProjectTaskCommentDocument, options);
      }
export type CreateProjectTaskCommentMutationHookResult = ReturnType<typeof useCreateProjectTaskCommentMutation>;
export type CreateProjectTaskCommentMutationResult = Apollo.MutationResult<CreateProjectTaskCommentMutation>;
export type CreateProjectTaskCommentMutationOptions = Apollo.BaseMutationOptions<CreateProjectTaskCommentMutation, CreateProjectTaskCommentMutationVariables>;
export const UpdateProjectTaskCommentDocument = gql`
    mutation updateProjectTaskComment($id: String!, $text: String!, $file: Upload) {
  updateProjectTaskComment(id: $id, text: $text, file: $file) {
    id
  }
}
    `;
export type UpdateProjectTaskCommentMutationFn = Apollo.MutationFunction<UpdateProjectTaskCommentMutation, UpdateProjectTaskCommentMutationVariables>;

/**
 * __useUpdateProjectTaskCommentMutation__
 *
 * To run a mutation, you first call `useUpdateProjectTaskCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectTaskCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectTaskCommentMutation, { data, loading, error }] = useUpdateProjectTaskCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      text: // value for 'text'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUpdateProjectTaskCommentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProjectTaskCommentMutation, UpdateProjectTaskCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProjectTaskCommentMutation, UpdateProjectTaskCommentMutationVariables>(UpdateProjectTaskCommentDocument, options);
      }
export type UpdateProjectTaskCommentMutationHookResult = ReturnType<typeof useUpdateProjectTaskCommentMutation>;
export type UpdateProjectTaskCommentMutationResult = Apollo.MutationResult<UpdateProjectTaskCommentMutation>;
export type UpdateProjectTaskCommentMutationOptions = Apollo.BaseMutationOptions<UpdateProjectTaskCommentMutation, UpdateProjectTaskCommentMutationVariables>;
export const DeleteProjectTaskCommentDocument = gql`
    mutation deleteProjectTaskComment($id: String!) {
  deleteProjectTaskComment(id: $id)
}
    `;
export type DeleteProjectTaskCommentMutationFn = Apollo.MutationFunction<DeleteProjectTaskCommentMutation, DeleteProjectTaskCommentMutationVariables>;

/**
 * __useDeleteProjectTaskCommentMutation__
 *
 * To run a mutation, you first call `useDeleteProjectTaskCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectTaskCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectTaskCommentMutation, { data, loading, error }] = useDeleteProjectTaskCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProjectTaskCommentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProjectTaskCommentMutation, DeleteProjectTaskCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProjectTaskCommentMutation, DeleteProjectTaskCommentMutationVariables>(DeleteProjectTaskCommentDocument, options);
      }
export type DeleteProjectTaskCommentMutationHookResult = ReturnType<typeof useDeleteProjectTaskCommentMutation>;
export type DeleteProjectTaskCommentMutationResult = Apollo.MutationResult<DeleteProjectTaskCommentMutation>;
export type DeleteProjectTaskCommentMutationOptions = Apollo.BaseMutationOptions<DeleteProjectTaskCommentMutation, DeleteProjectTaskCommentMutationVariables>;
export const DeleteProjectTaskCommentFileDocument = gql`
    mutation deleteProjectTaskCommentFile($id: String!) {
  deleteProjectTaskCommentFile(id: $id)
}
    `;
export type DeleteProjectTaskCommentFileMutationFn = Apollo.MutationFunction<DeleteProjectTaskCommentFileMutation, DeleteProjectTaskCommentFileMutationVariables>;

/**
 * __useDeleteProjectTaskCommentFileMutation__
 *
 * To run a mutation, you first call `useDeleteProjectTaskCommentFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectTaskCommentFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectTaskCommentFileMutation, { data, loading, error }] = useDeleteProjectTaskCommentFileMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProjectTaskCommentFileMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProjectTaskCommentFileMutation, DeleteProjectTaskCommentFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProjectTaskCommentFileMutation, DeleteProjectTaskCommentFileMutationVariables>(DeleteProjectTaskCommentFileDocument, options);
      }
export type DeleteProjectTaskCommentFileMutationHookResult = ReturnType<typeof useDeleteProjectTaskCommentFileMutation>;
export type DeleteProjectTaskCommentFileMutationResult = Apollo.MutationResult<DeleteProjectTaskCommentFileMutation>;
export type DeleteProjectTaskCommentFileMutationOptions = Apollo.BaseMutationOptions<DeleteProjectTaskCommentFileMutation, DeleteProjectTaskCommentFileMutationVariables>;
export const LoginDocument = gql`
    mutation login($email: String!, $password: String!, $isFrom: isFromEnum!) {
  login(email: $email, password: $password, isFrom: $isFrom) {
    token
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      isFrom: // value for 'isFrom'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const LogoutDocument = gql`
    mutation logout {
  logout
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const ForgotPasswordDocument = gql`
    mutation forgotPassword($email: String!, $isFrom: isFromEnum!) {
  forgotPassword(email: $email, isFrom: $isFrom)
}
    `;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *      isFrom: // value for 'isFrom'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, options);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($password: String!, $token: String!, $isFrom: isFromEnum!) {
  resetPassword(password: $password, token: $token, isFrom: $isFrom) {
    token
  }
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *      token: // value for 'token'
 *      isFrom: // value for 'isFrom'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const AnswerKnowledgeBaseBlockTestDocument = gql`
    mutation answerKnowledgeBaseBlockTest($blockId: String!, $answerId: String!) {
  answerKnowledgeBaseBlockTest(blockId: $blockId, answerId: $answerId) {
    id
  }
}
    `;
export type AnswerKnowledgeBaseBlockTestMutationFn = Apollo.MutationFunction<AnswerKnowledgeBaseBlockTestMutation, AnswerKnowledgeBaseBlockTestMutationVariables>;

/**
 * __useAnswerKnowledgeBaseBlockTestMutation__
 *
 * To run a mutation, you first call `useAnswerKnowledgeBaseBlockTestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAnswerKnowledgeBaseBlockTestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [answerKnowledgeBaseBlockTestMutation, { data, loading, error }] = useAnswerKnowledgeBaseBlockTestMutation({
 *   variables: {
 *      blockId: // value for 'blockId'
 *      answerId: // value for 'answerId'
 *   },
 * });
 */
export function useAnswerKnowledgeBaseBlockTestMutation(baseOptions?: Apollo.MutationHookOptions<AnswerKnowledgeBaseBlockTestMutation, AnswerKnowledgeBaseBlockTestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AnswerKnowledgeBaseBlockTestMutation, AnswerKnowledgeBaseBlockTestMutationVariables>(AnswerKnowledgeBaseBlockTestDocument, options);
      }
export type AnswerKnowledgeBaseBlockTestMutationHookResult = ReturnType<typeof useAnswerKnowledgeBaseBlockTestMutation>;
export type AnswerKnowledgeBaseBlockTestMutationResult = Apollo.MutationResult<AnswerKnowledgeBaseBlockTestMutation>;
export type AnswerKnowledgeBaseBlockTestMutationOptions = Apollo.BaseMutationOptions<AnswerKnowledgeBaseBlockTestMutation, AnswerKnowledgeBaseBlockTestMutationVariables>;
export const CreatePtCheckListItemDocument = gql`
    mutation createPTCheckListItem($projectTaskId: String!, $name: String!) {
  createPTCheckListItem(projectTaskId: $projectTaskId, name: $name) {
    id
  }
}
    `;
export type CreatePtCheckListItemMutationFn = Apollo.MutationFunction<CreatePtCheckListItemMutation, CreatePtCheckListItemMutationVariables>;

/**
 * __useCreatePtCheckListItemMutation__
 *
 * To run a mutation, you first call `useCreatePtCheckListItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePtCheckListItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPtCheckListItemMutation, { data, loading, error }] = useCreatePtCheckListItemMutation({
 *   variables: {
 *      projectTaskId: // value for 'projectTaskId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreatePtCheckListItemMutation(baseOptions?: Apollo.MutationHookOptions<CreatePtCheckListItemMutation, CreatePtCheckListItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePtCheckListItemMutation, CreatePtCheckListItemMutationVariables>(CreatePtCheckListItemDocument, options);
      }
export type CreatePtCheckListItemMutationHookResult = ReturnType<typeof useCreatePtCheckListItemMutation>;
export type CreatePtCheckListItemMutationResult = Apollo.MutationResult<CreatePtCheckListItemMutation>;
export type CreatePtCheckListItemMutationOptions = Apollo.BaseMutationOptions<CreatePtCheckListItemMutation, CreatePtCheckListItemMutationVariables>;
export const UpdatePtCheckListItemDocument = gql`
    mutation updatePTCheckListItem($id: String!, $name: String!) {
  updatePTCheckListItem(id: $id, name: $name) {
    id
  }
}
    `;
export type UpdatePtCheckListItemMutationFn = Apollo.MutationFunction<UpdatePtCheckListItemMutation, UpdatePtCheckListItemMutationVariables>;

/**
 * __useUpdatePtCheckListItemMutation__
 *
 * To run a mutation, you first call `useUpdatePtCheckListItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePtCheckListItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePtCheckListItemMutation, { data, loading, error }] = useUpdatePtCheckListItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdatePtCheckListItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePtCheckListItemMutation, UpdatePtCheckListItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePtCheckListItemMutation, UpdatePtCheckListItemMutationVariables>(UpdatePtCheckListItemDocument, options);
      }
export type UpdatePtCheckListItemMutationHookResult = ReturnType<typeof useUpdatePtCheckListItemMutation>;
export type UpdatePtCheckListItemMutationResult = Apollo.MutationResult<UpdatePtCheckListItemMutation>;
export type UpdatePtCheckListItemMutationOptions = Apollo.BaseMutationOptions<UpdatePtCheckListItemMutation, UpdatePtCheckListItemMutationVariables>;
export const DeletePtCheckListItemDocument = gql`
    mutation deletePTCheckListItem($id: String!) {
  deletePTCheckListItem(id: $id)
}
    `;
export type DeletePtCheckListItemMutationFn = Apollo.MutationFunction<DeletePtCheckListItemMutation, DeletePtCheckListItemMutationVariables>;

/**
 * __useDeletePtCheckListItemMutation__
 *
 * To run a mutation, you first call `useDeletePtCheckListItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePtCheckListItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePtCheckListItemMutation, { data, loading, error }] = useDeletePtCheckListItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePtCheckListItemMutation(baseOptions?: Apollo.MutationHookOptions<DeletePtCheckListItemMutation, DeletePtCheckListItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePtCheckListItemMutation, DeletePtCheckListItemMutationVariables>(DeletePtCheckListItemDocument, options);
      }
export type DeletePtCheckListItemMutationHookResult = ReturnType<typeof useDeletePtCheckListItemMutation>;
export type DeletePtCheckListItemMutationResult = Apollo.MutationResult<DeletePtCheckListItemMutation>;
export type DeletePtCheckListItemMutationOptions = Apollo.BaseMutationOptions<DeletePtCheckListItemMutation, DeletePtCheckListItemMutationVariables>;
export const ChangePtCheckListItemStatusDocument = gql`
    mutation changePTCheckListItemStatus($id: String!) {
  changePTCheckListItemStatus(id: $id) {
    id
    isCompleted
  }
}
    `;
export type ChangePtCheckListItemStatusMutationFn = Apollo.MutationFunction<ChangePtCheckListItemStatusMutation, ChangePtCheckListItemStatusMutationVariables>;

/**
 * __useChangePtCheckListItemStatusMutation__
 *
 * To run a mutation, you first call `useChangePtCheckListItemStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePtCheckListItemStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePtCheckListItemStatusMutation, { data, loading, error }] = useChangePtCheckListItemStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useChangePtCheckListItemStatusMutation(baseOptions?: Apollo.MutationHookOptions<ChangePtCheckListItemStatusMutation, ChangePtCheckListItemStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePtCheckListItemStatusMutation, ChangePtCheckListItemStatusMutationVariables>(ChangePtCheckListItemStatusDocument, options);
      }
export type ChangePtCheckListItemStatusMutationHookResult = ReturnType<typeof useChangePtCheckListItemStatusMutation>;
export type ChangePtCheckListItemStatusMutationResult = Apollo.MutationResult<ChangePtCheckListItemStatusMutation>;
export type ChangePtCheckListItemStatusMutationOptions = Apollo.BaseMutationOptions<ChangePtCheckListItemStatusMutation, ChangePtCheckListItemStatusMutationVariables>;
export const CreateProjectCorrectionDocument = gql`
    mutation createProjectCorrection($projectCorrectionData: CreateProjectCorrectionInput!) {
  createProjectCorrection(projectCorrectionData: $projectCorrectionData) {
    id
    text
    status {
      id
      displayName
    }
  }
}
    `;
export type CreateProjectCorrectionMutationFn = Apollo.MutationFunction<CreateProjectCorrectionMutation, CreateProjectCorrectionMutationVariables>;

/**
 * __useCreateProjectCorrectionMutation__
 *
 * To run a mutation, you first call `useCreateProjectCorrectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectCorrectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectCorrectionMutation, { data, loading, error }] = useCreateProjectCorrectionMutation({
 *   variables: {
 *      projectCorrectionData: // value for 'projectCorrectionData'
 *   },
 * });
 */
export function useCreateProjectCorrectionMutation(baseOptions?: Apollo.MutationHookOptions<CreateProjectCorrectionMutation, CreateProjectCorrectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProjectCorrectionMutation, CreateProjectCorrectionMutationVariables>(CreateProjectCorrectionDocument, options);
      }
export type CreateProjectCorrectionMutationHookResult = ReturnType<typeof useCreateProjectCorrectionMutation>;
export type CreateProjectCorrectionMutationResult = Apollo.MutationResult<CreateProjectCorrectionMutation>;
export type CreateProjectCorrectionMutationOptions = Apollo.BaseMutationOptions<CreateProjectCorrectionMutation, CreateProjectCorrectionMutationVariables>;
export const DeleteProjectCorrectionDocument = gql`
    mutation deleteProjectCorrection($projectCorrectionId: String!) {
  deleteProjectCorrection(projectCorrectionId: $projectCorrectionId)
}
    `;
export type DeleteProjectCorrectionMutationFn = Apollo.MutationFunction<DeleteProjectCorrectionMutation, DeleteProjectCorrectionMutationVariables>;

/**
 * __useDeleteProjectCorrectionMutation__
 *
 * To run a mutation, you first call `useDeleteProjectCorrectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectCorrectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectCorrectionMutation, { data, loading, error }] = useDeleteProjectCorrectionMutation({
 *   variables: {
 *      projectCorrectionId: // value for 'projectCorrectionId'
 *   },
 * });
 */
export function useDeleteProjectCorrectionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProjectCorrectionMutation, DeleteProjectCorrectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProjectCorrectionMutation, DeleteProjectCorrectionMutationVariables>(DeleteProjectCorrectionDocument, options);
      }
export type DeleteProjectCorrectionMutationHookResult = ReturnType<typeof useDeleteProjectCorrectionMutation>;
export type DeleteProjectCorrectionMutationResult = Apollo.MutationResult<DeleteProjectCorrectionMutation>;
export type DeleteProjectCorrectionMutationOptions = Apollo.BaseMutationOptions<DeleteProjectCorrectionMutation, DeleteProjectCorrectionMutationVariables>;
export const UpdateProjectCorrectionDocument = gql`
    mutation updateProjectCorrection($projectCorrectionData: UpdateProjectCorrectionInput!) {
  updateProjectCorrection(projectCorrectionData: $projectCorrectionData) {
    text
    updatedDate
  }
}
    `;
export type UpdateProjectCorrectionMutationFn = Apollo.MutationFunction<UpdateProjectCorrectionMutation, UpdateProjectCorrectionMutationVariables>;

/**
 * __useUpdateProjectCorrectionMutation__
 *
 * To run a mutation, you first call `useUpdateProjectCorrectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectCorrectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectCorrectionMutation, { data, loading, error }] = useUpdateProjectCorrectionMutation({
 *   variables: {
 *      projectCorrectionData: // value for 'projectCorrectionData'
 *   },
 * });
 */
export function useUpdateProjectCorrectionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProjectCorrectionMutation, UpdateProjectCorrectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProjectCorrectionMutation, UpdateProjectCorrectionMutationVariables>(UpdateProjectCorrectionDocument, options);
      }
export type UpdateProjectCorrectionMutationHookResult = ReturnType<typeof useUpdateProjectCorrectionMutation>;
export type UpdateProjectCorrectionMutationResult = Apollo.MutationResult<UpdateProjectCorrectionMutation>;
export type UpdateProjectCorrectionMutationOptions = Apollo.BaseMutationOptions<UpdateProjectCorrectionMutation, UpdateProjectCorrectionMutationVariables>;
export const CreateCorrectionCommentDocument = gql`
    mutation createCorrectionComment($correctionCommentData: CreateCorrectionCommentInput!) {
  createCorrectionComment(correctionCommentData: $correctionCommentData) {
    correction {
      id
      text
      createdDate
    }
    createdBy {
      firstName
    }
  }
}
    `;
export type CreateCorrectionCommentMutationFn = Apollo.MutationFunction<CreateCorrectionCommentMutation, CreateCorrectionCommentMutationVariables>;

/**
 * __useCreateCorrectionCommentMutation__
 *
 * To run a mutation, you first call `useCreateCorrectionCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCorrectionCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCorrectionCommentMutation, { data, loading, error }] = useCreateCorrectionCommentMutation({
 *   variables: {
 *      correctionCommentData: // value for 'correctionCommentData'
 *   },
 * });
 */
export function useCreateCorrectionCommentMutation(baseOptions?: Apollo.MutationHookOptions<CreateCorrectionCommentMutation, CreateCorrectionCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCorrectionCommentMutation, CreateCorrectionCommentMutationVariables>(CreateCorrectionCommentDocument, options);
      }
export type CreateCorrectionCommentMutationHookResult = ReturnType<typeof useCreateCorrectionCommentMutation>;
export type CreateCorrectionCommentMutationResult = Apollo.MutationResult<CreateCorrectionCommentMutation>;
export type CreateCorrectionCommentMutationOptions = Apollo.BaseMutationOptions<CreateCorrectionCommentMutation, CreateCorrectionCommentMutationVariables>;
export const CreateProjectTaskInKanbanDocument = gql`
    mutation createProjectTaskInKanban($taskData: CreateProjectTaskInput!, $workflowStepId: String!, $workflowStepOrder: Int!) {
  createProjectTaskInKanban(
    taskData: $taskData
    workflowStepId: $workflowStepId
    workflowStepOrder: $workflowStepOrder
  ) {
    id
  }
}
    `;
export type CreateProjectTaskInKanbanMutationFn = Apollo.MutationFunction<CreateProjectTaskInKanbanMutation, CreateProjectTaskInKanbanMutationVariables>;

/**
 * __useCreateProjectTaskInKanbanMutation__
 *
 * To run a mutation, you first call `useCreateProjectTaskInKanbanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectTaskInKanbanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectTaskInKanbanMutation, { data, loading, error }] = useCreateProjectTaskInKanbanMutation({
 *   variables: {
 *      taskData: // value for 'taskData'
 *      workflowStepId: // value for 'workflowStepId'
 *      workflowStepOrder: // value for 'workflowStepOrder'
 *   },
 * });
 */
export function useCreateProjectTaskInKanbanMutation(baseOptions?: Apollo.MutationHookOptions<CreateProjectTaskInKanbanMutation, CreateProjectTaskInKanbanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProjectTaskInKanbanMutation, CreateProjectTaskInKanbanMutationVariables>(CreateProjectTaskInKanbanDocument, options);
      }
export type CreateProjectTaskInKanbanMutationHookResult = ReturnType<typeof useCreateProjectTaskInKanbanMutation>;
export type CreateProjectTaskInKanbanMutationResult = Apollo.MutationResult<CreateProjectTaskInKanbanMutation>;
export type CreateProjectTaskInKanbanMutationOptions = Apollo.BaseMutationOptions<CreateProjectTaskInKanbanMutation, CreateProjectTaskInKanbanMutationVariables>;
export const UpdateProjectTaskDocument = gql`
    mutation updateProjectTask($taskData: UpdateProjectTaskInput!) {
  updateProjectTask(taskData: $taskData) {
    id
  }
}
    `;
export type UpdateProjectTaskMutationFn = Apollo.MutationFunction<UpdateProjectTaskMutation, UpdateProjectTaskMutationVariables>;

/**
 * __useUpdateProjectTaskMutation__
 *
 * To run a mutation, you first call `useUpdateProjectTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectTaskMutation, { data, loading, error }] = useUpdateProjectTaskMutation({
 *   variables: {
 *      taskData: // value for 'taskData'
 *   },
 * });
 */
export function useUpdateProjectTaskMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProjectTaskMutation, UpdateProjectTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProjectTaskMutation, UpdateProjectTaskMutationVariables>(UpdateProjectTaskDocument, options);
      }
export type UpdateProjectTaskMutationHookResult = ReturnType<typeof useUpdateProjectTaskMutation>;
export type UpdateProjectTaskMutationResult = Apollo.MutationResult<UpdateProjectTaskMutation>;
export type UpdateProjectTaskMutationOptions = Apollo.BaseMutationOptions<UpdateProjectTaskMutation, UpdateProjectTaskMutationVariables>;
export const ChangeProjectTaskWorkflowStepDocument = gql`
    mutation changeProjectTaskWorkflowStep($tasksId: String!, $workflowStepId: String!, $workflowStepOrder: Int!) {
  changeProjectTaskWorkflowStep(
    tasksId: $tasksId
    workflowStepId: $workflowStepId
    workflowStepOrder: $workflowStepOrder
  )
}
    `;
export type ChangeProjectTaskWorkflowStepMutationFn = Apollo.MutationFunction<ChangeProjectTaskWorkflowStepMutation, ChangeProjectTaskWorkflowStepMutationVariables>;

/**
 * __useChangeProjectTaskWorkflowStepMutation__
 *
 * To run a mutation, you first call `useChangeProjectTaskWorkflowStepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeProjectTaskWorkflowStepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeProjectTaskWorkflowStepMutation, { data, loading, error }] = useChangeProjectTaskWorkflowStepMutation({
 *   variables: {
 *      tasksId: // value for 'tasksId'
 *      workflowStepId: // value for 'workflowStepId'
 *      workflowStepOrder: // value for 'workflowStepOrder'
 *   },
 * });
 */
export function useChangeProjectTaskWorkflowStepMutation(baseOptions?: Apollo.MutationHookOptions<ChangeProjectTaskWorkflowStepMutation, ChangeProjectTaskWorkflowStepMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeProjectTaskWorkflowStepMutation, ChangeProjectTaskWorkflowStepMutationVariables>(ChangeProjectTaskWorkflowStepDocument, options);
      }
export type ChangeProjectTaskWorkflowStepMutationHookResult = ReturnType<typeof useChangeProjectTaskWorkflowStepMutation>;
export type ChangeProjectTaskWorkflowStepMutationResult = Apollo.MutationResult<ChangeProjectTaskWorkflowStepMutation>;
export type ChangeProjectTaskWorkflowStepMutationOptions = Apollo.BaseMutationOptions<ChangeProjectTaskWorkflowStepMutation, ChangeProjectTaskWorkflowStepMutationVariables>;
export const AssignProjectTaskLabelDocument = gql`
    mutation assignProjectTaskLabel($projectTaskId: String!, $labelId: String!) {
  assignProjectTaskLabel(projectTaskId: $projectTaskId, labelId: $labelId)
}
    `;
export type AssignProjectTaskLabelMutationFn = Apollo.MutationFunction<AssignProjectTaskLabelMutation, AssignProjectTaskLabelMutationVariables>;

/**
 * __useAssignProjectTaskLabelMutation__
 *
 * To run a mutation, you first call `useAssignProjectTaskLabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignProjectTaskLabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignProjectTaskLabelMutation, { data, loading, error }] = useAssignProjectTaskLabelMutation({
 *   variables: {
 *      projectTaskId: // value for 'projectTaskId'
 *      labelId: // value for 'labelId'
 *   },
 * });
 */
export function useAssignProjectTaskLabelMutation(baseOptions?: Apollo.MutationHookOptions<AssignProjectTaskLabelMutation, AssignProjectTaskLabelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AssignProjectTaskLabelMutation, AssignProjectTaskLabelMutationVariables>(AssignProjectTaskLabelDocument, options);
      }
export type AssignProjectTaskLabelMutationHookResult = ReturnType<typeof useAssignProjectTaskLabelMutation>;
export type AssignProjectTaskLabelMutationResult = Apollo.MutationResult<AssignProjectTaskLabelMutation>;
export type AssignProjectTaskLabelMutationOptions = Apollo.BaseMutationOptions<AssignProjectTaskLabelMutation, AssignProjectTaskLabelMutationVariables>;
export const RemoveProjectTaskLabelDocument = gql`
    mutation removeProjectTaskLabel($projectTaskId: String!, $labelId: String!) {
  removeProjectTaskLabel(projectTaskId: $projectTaskId, labelId: $labelId)
}
    `;
export type RemoveProjectTaskLabelMutationFn = Apollo.MutationFunction<RemoveProjectTaskLabelMutation, RemoveProjectTaskLabelMutationVariables>;

/**
 * __useRemoveProjectTaskLabelMutation__
 *
 * To run a mutation, you first call `useRemoveProjectTaskLabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveProjectTaskLabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeProjectTaskLabelMutation, { data, loading, error }] = useRemoveProjectTaskLabelMutation({
 *   variables: {
 *      projectTaskId: // value for 'projectTaskId'
 *      labelId: // value for 'labelId'
 *   },
 * });
 */
export function useRemoveProjectTaskLabelMutation(baseOptions?: Apollo.MutationHookOptions<RemoveProjectTaskLabelMutation, RemoveProjectTaskLabelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveProjectTaskLabelMutation, RemoveProjectTaskLabelMutationVariables>(RemoveProjectTaskLabelDocument, options);
      }
export type RemoveProjectTaskLabelMutationHookResult = ReturnType<typeof useRemoveProjectTaskLabelMutation>;
export type RemoveProjectTaskLabelMutationResult = Apollo.MutationResult<RemoveProjectTaskLabelMutation>;
export type RemoveProjectTaskLabelMutationOptions = Apollo.BaseMutationOptions<RemoveProjectTaskLabelMutation, RemoveProjectTaskLabelMutationVariables>;
export const CreatePurchaseDocument = gql`
    mutation createPurchase($productId: String!) {
  createPurchase(productId: $productId) {
    id
    status {
      status
    }
  }
}
    `;
export type CreatePurchaseMutationFn = Apollo.MutationFunction<CreatePurchaseMutation, CreatePurchaseMutationVariables>;

/**
 * __useCreatePurchaseMutation__
 *
 * To run a mutation, you first call `useCreatePurchaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePurchaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPurchaseMutation, { data, loading, error }] = useCreatePurchaseMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useCreatePurchaseMutation(baseOptions?: Apollo.MutationHookOptions<CreatePurchaseMutation, CreatePurchaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePurchaseMutation, CreatePurchaseMutationVariables>(CreatePurchaseDocument, options);
      }
export type CreatePurchaseMutationHookResult = ReturnType<typeof useCreatePurchaseMutation>;
export type CreatePurchaseMutationResult = Apollo.MutationResult<CreatePurchaseMutation>;
export type CreatePurchaseMutationOptions = Apollo.BaseMutationOptions<CreatePurchaseMutation, CreatePurchaseMutationVariables>;
export const DeletePurchaseDocument = gql`
    mutation deletePurchase($purchaseId: String!) {
  deletePurchase(purchaseId: $purchaseId)
}
    `;
export type DeletePurchaseMutationFn = Apollo.MutationFunction<DeletePurchaseMutation, DeletePurchaseMutationVariables>;

/**
 * __useDeletePurchaseMutation__
 *
 * To run a mutation, you first call `useDeletePurchaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePurchaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePurchaseMutation, { data, loading, error }] = useDeletePurchaseMutation({
 *   variables: {
 *      purchaseId: // value for 'purchaseId'
 *   },
 * });
 */
export function useDeletePurchaseMutation(baseOptions?: Apollo.MutationHookOptions<DeletePurchaseMutation, DeletePurchaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePurchaseMutation, DeletePurchaseMutationVariables>(DeletePurchaseDocument, options);
      }
export type DeletePurchaseMutationHookResult = ReturnType<typeof useDeletePurchaseMutation>;
export type DeletePurchaseMutationResult = Apollo.MutationResult<DeletePurchaseMutation>;
export type DeletePurchaseMutationOptions = Apollo.BaseMutationOptions<DeletePurchaseMutation, DeletePurchaseMutationVariables>;
export const ConfirmPurchaseDocument = gql`
    mutation confirmPurchase {
  confirmPurchase
}
    `;
export type ConfirmPurchaseMutationFn = Apollo.MutationFunction<ConfirmPurchaseMutation, ConfirmPurchaseMutationVariables>;

/**
 * __useConfirmPurchaseMutation__
 *
 * To run a mutation, you first call `useConfirmPurchaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmPurchaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmPurchaseMutation, { data, loading, error }] = useConfirmPurchaseMutation({
 *   variables: {
 *   },
 * });
 */
export function useConfirmPurchaseMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmPurchaseMutation, ConfirmPurchaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfirmPurchaseMutation, ConfirmPurchaseMutationVariables>(ConfirmPurchaseDocument, options);
      }
export type ConfirmPurchaseMutationHookResult = ReturnType<typeof useConfirmPurchaseMutation>;
export type ConfirmPurchaseMutationResult = Apollo.MutationResult<ConfirmPurchaseMutation>;
export type ConfirmPurchaseMutationOptions = Apollo.BaseMutationOptions<ConfirmPurchaseMutation, ConfirmPurchaseMutationVariables>;
export const CreateUserDocument = gql`
    mutation createUser($userData: CreateUserInput!, $isFrom: isFromEnum!) {
  createUser(userData: $userData, isFrom: $isFrom) {
    id
    firstName
    lastName
    phone
    email
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      userData: // value for 'userData'
 *      isFrom: // value for 'isFrom'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($userData: UpdateUserInput!, $photo: Upload) {
  updateUser(userData: $userData, photo: $photo) {
    id
    firstName
    lastName
    email
    phone
    photo
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      userData: // value for 'userData'
 *      photo: // value for 'photo'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const GetChartData2Document = gql`
    query getChartData2($projectId: String!, $isEpic: Boolean, $epicId: String) {
  getChartData2(projectId: $projectId, isEpic: $isEpic, epicId: $epicId) {
    id
    name
    start
    end
    progress
    type
    project
    isClient
    isAdditionalTask
  }
}
    `;

/**
 * __useGetChartData2Query__
 *
 * To run a query within a React component, call `useGetChartData2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetChartData2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChartData2Query({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      isEpic: // value for 'isEpic'
 *      epicId: // value for 'epicId'
 *   },
 * });
 */
export function useGetChartData2Query(baseOptions: Apollo.QueryHookOptions<GetChartData2Query, GetChartData2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetChartData2Query, GetChartData2QueryVariables>(GetChartData2Document, options);
      }
export function useGetChartData2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChartData2Query, GetChartData2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetChartData2Query, GetChartData2QueryVariables>(GetChartData2Document, options);
        }
export type GetChartData2QueryHookResult = ReturnType<typeof useGetChartData2Query>;
export type GetChartData2LazyQueryHookResult = ReturnType<typeof useGetChartData2LazyQuery>;
export type GetChartData2QueryResult = Apollo.QueryResult<GetChartData2Query, GetChartData2QueryVariables>;
export const GetBaseKanbanWorkflowStepsDocument = gql`
    query getBaseKanbanWorkflowSteps {
  getBaseKanbanWorkflowSteps {
    id
    name
  }
}
    `;

/**
 * __useGetBaseKanbanWorkflowStepsQuery__
 *
 * To run a query within a React component, call `useGetBaseKanbanWorkflowStepsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBaseKanbanWorkflowStepsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBaseKanbanWorkflowStepsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBaseKanbanWorkflowStepsQuery(baseOptions?: Apollo.QueryHookOptions<GetBaseKanbanWorkflowStepsQuery, GetBaseKanbanWorkflowStepsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBaseKanbanWorkflowStepsQuery, GetBaseKanbanWorkflowStepsQueryVariables>(GetBaseKanbanWorkflowStepsDocument, options);
      }
export function useGetBaseKanbanWorkflowStepsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBaseKanbanWorkflowStepsQuery, GetBaseKanbanWorkflowStepsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBaseKanbanWorkflowStepsQuery, GetBaseKanbanWorkflowStepsQueryVariables>(GetBaseKanbanWorkflowStepsDocument, options);
        }
export type GetBaseKanbanWorkflowStepsQueryHookResult = ReturnType<typeof useGetBaseKanbanWorkflowStepsQuery>;
export type GetBaseKanbanWorkflowStepsLazyQueryHookResult = ReturnType<typeof useGetBaseKanbanWorkflowStepsLazyQuery>;
export type GetBaseKanbanWorkflowStepsQueryResult = Apollo.QueryResult<GetBaseKanbanWorkflowStepsQuery, GetBaseKanbanWorkflowStepsQueryVariables>;
export const GetKanbanWorkflowStepsByProjectDocument = gql`
    query getKanbanWorkflowStepsByProject($projectId: String!) {
  getKanbanWorkflowStepsByProject(projectId: $projectId) {
    id
    name
    projectId
  }
}
    `;

/**
 * __useGetKanbanWorkflowStepsByProjectQuery__
 *
 * To run a query within a React component, call `useGetKanbanWorkflowStepsByProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKanbanWorkflowStepsByProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKanbanWorkflowStepsByProjectQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetKanbanWorkflowStepsByProjectQuery(baseOptions: Apollo.QueryHookOptions<GetKanbanWorkflowStepsByProjectQuery, GetKanbanWorkflowStepsByProjectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetKanbanWorkflowStepsByProjectQuery, GetKanbanWorkflowStepsByProjectQueryVariables>(GetKanbanWorkflowStepsByProjectDocument, options);
      }
export function useGetKanbanWorkflowStepsByProjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetKanbanWorkflowStepsByProjectQuery, GetKanbanWorkflowStepsByProjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetKanbanWorkflowStepsByProjectQuery, GetKanbanWorkflowStepsByProjectQueryVariables>(GetKanbanWorkflowStepsByProjectDocument, options);
        }
export type GetKanbanWorkflowStepsByProjectQueryHookResult = ReturnType<typeof useGetKanbanWorkflowStepsByProjectQuery>;
export type GetKanbanWorkflowStepsByProjectLazyQueryHookResult = ReturnType<typeof useGetKanbanWorkflowStepsByProjectLazyQuery>;
export type GetKanbanWorkflowStepsByProjectQueryResult = Apollo.QueryResult<GetKanbanWorkflowStepsByProjectQuery, GetKanbanWorkflowStepsByProjectQueryVariables>;
export const GetProjectTasksByWorkflowStepDocument = gql`
    query getProjectTasksByWorkflowStep($projectId: String!, $workflowStepId: String!, $limit: Int, $skip: Int) {
  getProjectTasksByWorkflowStep(
    projectId: $projectId
    workflowStepId: $workflowStepId
    limit: $limit
    skip: $skip
  ) {
    tasks {
      id
      name
      workflowStepOrder
    }
    total
  }
}
    `;

/**
 * __useGetProjectTasksByWorkflowStepQuery__
 *
 * To run a query within a React component, call `useGetProjectTasksByWorkflowStepQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectTasksByWorkflowStepQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectTasksByWorkflowStepQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      workflowStepId: // value for 'workflowStepId'
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetProjectTasksByWorkflowStepQuery(baseOptions: Apollo.QueryHookOptions<GetProjectTasksByWorkflowStepQuery, GetProjectTasksByWorkflowStepQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectTasksByWorkflowStepQuery, GetProjectTasksByWorkflowStepQueryVariables>(GetProjectTasksByWorkflowStepDocument, options);
      }
export function useGetProjectTasksByWorkflowStepLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectTasksByWorkflowStepQuery, GetProjectTasksByWorkflowStepQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectTasksByWorkflowStepQuery, GetProjectTasksByWorkflowStepQueryVariables>(GetProjectTasksByWorkflowStepDocument, options);
        }
export type GetProjectTasksByWorkflowStepQueryHookResult = ReturnType<typeof useGetProjectTasksByWorkflowStepQuery>;
export type GetProjectTasksByWorkflowStepLazyQueryHookResult = ReturnType<typeof useGetProjectTasksByWorkflowStepLazyQuery>;
export type GetProjectTasksByWorkflowStepQueryResult = Apollo.QueryResult<GetProjectTasksByWorkflowStepQuery, GetProjectTasksByWorkflowStepQueryVariables>;
export const GetProjectTasksByProjectDocument = gql`
    query getProjectTasksByProject($projectId: String!) {
  getProjectTasksByProject(projectId: $projectId) {
    id
    name
    kanbanWorkflowStepId
  }
}
    `;

/**
 * __useGetProjectTasksByProjectQuery__
 *
 * To run a query within a React component, call `useGetProjectTasksByProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectTasksByProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectTasksByProjectQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetProjectTasksByProjectQuery(baseOptions: Apollo.QueryHookOptions<GetProjectTasksByProjectQuery, GetProjectTasksByProjectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectTasksByProjectQuery, GetProjectTasksByProjectQueryVariables>(GetProjectTasksByProjectDocument, options);
      }
export function useGetProjectTasksByProjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectTasksByProjectQuery, GetProjectTasksByProjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectTasksByProjectQuery, GetProjectTasksByProjectQueryVariables>(GetProjectTasksByProjectDocument, options);
        }
export type GetProjectTasksByProjectQueryHookResult = ReturnType<typeof useGetProjectTasksByProjectQuery>;
export type GetProjectTasksByProjectLazyQueryHookResult = ReturnType<typeof useGetProjectTasksByProjectLazyQuery>;
export type GetProjectTasksByProjectQueryResult = Apollo.QueryResult<GetProjectTasksByProjectQuery, GetProjectTasksByProjectQueryVariables>;
export const GetProjectKanbanDocument = gql`
    query getProjectKanban($projectId: String!) {
  getProjectKanban(projectId: $projectId) {
    id
    name
  }
}
    `;

/**
 * __useGetProjectKanbanQuery__
 *
 * To run a query within a React component, call `useGetProjectKanbanQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectKanbanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectKanbanQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetProjectKanbanQuery(baseOptions: Apollo.QueryHookOptions<GetProjectKanbanQuery, GetProjectKanbanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectKanbanQuery, GetProjectKanbanQueryVariables>(GetProjectKanbanDocument, options);
      }
export function useGetProjectKanbanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectKanbanQuery, GetProjectKanbanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectKanbanQuery, GetProjectKanbanQueryVariables>(GetProjectKanbanDocument, options);
        }
export type GetProjectKanbanQueryHookResult = ReturnType<typeof useGetProjectKanbanQuery>;
export type GetProjectKanbanLazyQueryHookResult = ReturnType<typeof useGetProjectKanbanLazyQuery>;
export type GetProjectKanbanQueryResult = Apollo.QueryResult<GetProjectKanbanQuery, GetProjectKanbanQueryVariables>;
export const GetProjectStepsByProjectDocument = gql`
    query getProjectStepsByProject($projectId: String!) {
  getProjectStepsByProject(projectId: $projectId) {
    id
    name
  }
}
    `;

/**
 * __useGetProjectStepsByProjectQuery__
 *
 * To run a query within a React component, call `useGetProjectStepsByProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectStepsByProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectStepsByProjectQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetProjectStepsByProjectQuery(baseOptions: Apollo.QueryHookOptions<GetProjectStepsByProjectQuery, GetProjectStepsByProjectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectStepsByProjectQuery, GetProjectStepsByProjectQueryVariables>(GetProjectStepsByProjectDocument, options);
      }
export function useGetProjectStepsByProjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectStepsByProjectQuery, GetProjectStepsByProjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectStepsByProjectQuery, GetProjectStepsByProjectQueryVariables>(GetProjectStepsByProjectDocument, options);
        }
export type GetProjectStepsByProjectQueryHookResult = ReturnType<typeof useGetProjectStepsByProjectQuery>;
export type GetProjectStepsByProjectLazyQueryHookResult = ReturnType<typeof useGetProjectStepsByProjectLazyQuery>;
export type GetProjectStepsByProjectQueryResult = Apollo.QueryResult<GetProjectStepsByProjectQuery, GetProjectStepsByProjectQueryVariables>;
export const GetProjectStepsByProjectLightDocument = gql`
    query getProjectStepsByProjectLight($projectId: String!) {
  getProjectStepsByProject(projectId: $projectId) {
    id
    name
    key
  }
}
    `;

/**
 * __useGetProjectStepsByProjectLightQuery__
 *
 * To run a query within a React component, call `useGetProjectStepsByProjectLightQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectStepsByProjectLightQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectStepsByProjectLightQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetProjectStepsByProjectLightQuery(baseOptions: Apollo.QueryHookOptions<GetProjectStepsByProjectLightQuery, GetProjectStepsByProjectLightQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectStepsByProjectLightQuery, GetProjectStepsByProjectLightQueryVariables>(GetProjectStepsByProjectLightDocument, options);
      }
export function useGetProjectStepsByProjectLightLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectStepsByProjectLightQuery, GetProjectStepsByProjectLightQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectStepsByProjectLightQuery, GetProjectStepsByProjectLightQueryVariables>(GetProjectStepsByProjectLightDocument, options);
        }
export type GetProjectStepsByProjectLightQueryHookResult = ReturnType<typeof useGetProjectStepsByProjectLightQuery>;
export type GetProjectStepsByProjectLightLazyQueryHookResult = ReturnType<typeof useGetProjectStepsByProjectLightLazyQuery>;
export type GetProjectStepsByProjectLightQueryResult = Apollo.QueryResult<GetProjectStepsByProjectLightQuery, GetProjectStepsByProjectLightQueryVariables>;
export const GetKnowledgeBaseBlocksByThemeIdDocument = gql`
    query getKnowledgeBaseBlocksByThemeId($themeId: String!) {
  getKnowledgeBaseBlocksByThemeId(themeId: $themeId) {
    id
    type
    content
    photo
    video
    lists {
      id
      content
      blockId
      isNumbered
    }
    testQuestion
    testAnswers {
      id
      content
      blockId
      isAnswerTrue
    }
  }
}
    `;

/**
 * __useGetKnowledgeBaseBlocksByThemeIdQuery__
 *
 * To run a query within a React component, call `useGetKnowledgeBaseBlocksByThemeIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKnowledgeBaseBlocksByThemeIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKnowledgeBaseBlocksByThemeIdQuery({
 *   variables: {
 *      themeId: // value for 'themeId'
 *   },
 * });
 */
export function useGetKnowledgeBaseBlocksByThemeIdQuery(baseOptions: Apollo.QueryHookOptions<GetKnowledgeBaseBlocksByThemeIdQuery, GetKnowledgeBaseBlocksByThemeIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetKnowledgeBaseBlocksByThemeIdQuery, GetKnowledgeBaseBlocksByThemeIdQueryVariables>(GetKnowledgeBaseBlocksByThemeIdDocument, options);
      }
export function useGetKnowledgeBaseBlocksByThemeIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetKnowledgeBaseBlocksByThemeIdQuery, GetKnowledgeBaseBlocksByThemeIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetKnowledgeBaseBlocksByThemeIdQuery, GetKnowledgeBaseBlocksByThemeIdQueryVariables>(GetKnowledgeBaseBlocksByThemeIdDocument, options);
        }
export type GetKnowledgeBaseBlocksByThemeIdQueryHookResult = ReturnType<typeof useGetKnowledgeBaseBlocksByThemeIdQuery>;
export type GetKnowledgeBaseBlocksByThemeIdLazyQueryHookResult = ReturnType<typeof useGetKnowledgeBaseBlocksByThemeIdLazyQuery>;
export type GetKnowledgeBaseBlocksByThemeIdQueryResult = Apollo.QueryResult<GetKnowledgeBaseBlocksByThemeIdQuery, GetKnowledgeBaseBlocksByThemeIdQueryVariables>;
export const GetKnowledgeBaseBlocksTestByThemeIdDocument = gql`
    query getKnowledgeBaseBlocksTestByThemeId($themeId: String!, $isTestPublished: Boolean) {
  getKnowledgeBaseBlocksTestByThemeId(
    themeId: $themeId
    isTestPublished: $isTestPublished
  ) {
    id
    type
    testQuestion
    testAnswers {
      id
      content
      blockId
      isAnswerTrue
    }
    testsHistory {
      id
      answerId
      blockId
      createdBy {
        id
      }
    }
  }
}
    `;

/**
 * __useGetKnowledgeBaseBlocksTestByThemeIdQuery__
 *
 * To run a query within a React component, call `useGetKnowledgeBaseBlocksTestByThemeIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKnowledgeBaseBlocksTestByThemeIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKnowledgeBaseBlocksTestByThemeIdQuery({
 *   variables: {
 *      themeId: // value for 'themeId'
 *      isTestPublished: // value for 'isTestPublished'
 *   },
 * });
 */
export function useGetKnowledgeBaseBlocksTestByThemeIdQuery(baseOptions: Apollo.QueryHookOptions<GetKnowledgeBaseBlocksTestByThemeIdQuery, GetKnowledgeBaseBlocksTestByThemeIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetKnowledgeBaseBlocksTestByThemeIdQuery, GetKnowledgeBaseBlocksTestByThemeIdQueryVariables>(GetKnowledgeBaseBlocksTestByThemeIdDocument, options);
      }
export function useGetKnowledgeBaseBlocksTestByThemeIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetKnowledgeBaseBlocksTestByThemeIdQuery, GetKnowledgeBaseBlocksTestByThemeIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetKnowledgeBaseBlocksTestByThemeIdQuery, GetKnowledgeBaseBlocksTestByThemeIdQueryVariables>(GetKnowledgeBaseBlocksTestByThemeIdDocument, options);
        }
export type GetKnowledgeBaseBlocksTestByThemeIdQueryHookResult = ReturnType<typeof useGetKnowledgeBaseBlocksTestByThemeIdQuery>;
export type GetKnowledgeBaseBlocksTestByThemeIdLazyQueryHookResult = ReturnType<typeof useGetKnowledgeBaseBlocksTestByThemeIdLazyQuery>;
export type GetKnowledgeBaseBlocksTestByThemeIdQueryResult = Apollo.QueryResult<GetKnowledgeBaseBlocksTestByThemeIdQuery, GetKnowledgeBaseBlocksTestByThemeIdQueryVariables>;
export const GetKnowledgeBaseCategoriesByParticipantDocument = gql`
    query getKnowledgeBaseCategoriesByParticipant($userId: String!, $limit: Int, $skip: Int) {
  getKnowledgeBaseCategoriesByParticipant(
    userId: $userId
    limit: $limit
    skip: $skip
  ) {
    categories {
      category {
        id
        name
      }
    }
    total
  }
}
    `;

/**
 * __useGetKnowledgeBaseCategoriesByParticipantQuery__
 *
 * To run a query within a React component, call `useGetKnowledgeBaseCategoriesByParticipantQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKnowledgeBaseCategoriesByParticipantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKnowledgeBaseCategoriesByParticipantQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetKnowledgeBaseCategoriesByParticipantQuery(baseOptions: Apollo.QueryHookOptions<GetKnowledgeBaseCategoriesByParticipantQuery, GetKnowledgeBaseCategoriesByParticipantQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetKnowledgeBaseCategoriesByParticipantQuery, GetKnowledgeBaseCategoriesByParticipantQueryVariables>(GetKnowledgeBaseCategoriesByParticipantDocument, options);
      }
export function useGetKnowledgeBaseCategoriesByParticipantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetKnowledgeBaseCategoriesByParticipantQuery, GetKnowledgeBaseCategoriesByParticipantQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetKnowledgeBaseCategoriesByParticipantQuery, GetKnowledgeBaseCategoriesByParticipantQueryVariables>(GetKnowledgeBaseCategoriesByParticipantDocument, options);
        }
export type GetKnowledgeBaseCategoriesByParticipantQueryHookResult = ReturnType<typeof useGetKnowledgeBaseCategoriesByParticipantQuery>;
export type GetKnowledgeBaseCategoriesByParticipantLazyQueryHookResult = ReturnType<typeof useGetKnowledgeBaseCategoriesByParticipantLazyQuery>;
export type GetKnowledgeBaseCategoriesByParticipantQueryResult = Apollo.QueryResult<GetKnowledgeBaseCategoriesByParticipantQuery, GetKnowledgeBaseCategoriesByParticipantQueryVariables>;
export const GetKnowledgeBaseThemesByCategoryIdDocument = gql`
    query getKnowledgeBaseThemesByCategoryId($categoryId: String!, $isPublished: Boolean) {
  getKnowledgeBaseThemesByCategoryId(
    categoryId: $categoryId
    isPublished: $isPublished
  ) {
    id
    name
  }
}
    `;

/**
 * __useGetKnowledgeBaseThemesByCategoryIdQuery__
 *
 * To run a query within a React component, call `useGetKnowledgeBaseThemesByCategoryIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKnowledgeBaseThemesByCategoryIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKnowledgeBaseThemesByCategoryIdQuery({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *      isPublished: // value for 'isPublished'
 *   },
 * });
 */
export function useGetKnowledgeBaseThemesByCategoryIdQuery(baseOptions: Apollo.QueryHookOptions<GetKnowledgeBaseThemesByCategoryIdQuery, GetKnowledgeBaseThemesByCategoryIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetKnowledgeBaseThemesByCategoryIdQuery, GetKnowledgeBaseThemesByCategoryIdQueryVariables>(GetKnowledgeBaseThemesByCategoryIdDocument, options);
      }
export function useGetKnowledgeBaseThemesByCategoryIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetKnowledgeBaseThemesByCategoryIdQuery, GetKnowledgeBaseThemesByCategoryIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetKnowledgeBaseThemesByCategoryIdQuery, GetKnowledgeBaseThemesByCategoryIdQueryVariables>(GetKnowledgeBaseThemesByCategoryIdDocument, options);
        }
export type GetKnowledgeBaseThemesByCategoryIdQueryHookResult = ReturnType<typeof useGetKnowledgeBaseThemesByCategoryIdQuery>;
export type GetKnowledgeBaseThemesByCategoryIdLazyQueryHookResult = ReturnType<typeof useGetKnowledgeBaseThemesByCategoryIdLazyQuery>;
export type GetKnowledgeBaseThemesByCategoryIdQueryResult = Apollo.QueryResult<GetKnowledgeBaseThemesByCategoryIdQuery, GetKnowledgeBaseThemesByCategoryIdQueryVariables>;
export const GetNewsDocument = gql`
    query getNews($limit: Int, $skip: Int, $isPublished: Boolean) {
  getNews(limit: $limit, skip: $skip, isPublished: $isPublished) {
    news {
      id
      name
      description
      photo
      youTubeLink
      publishedAt
    }
    total
  }
}
    `;

/**
 * __useGetNewsQuery__
 *
 * To run a query within a React component, call `useGetNewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNewsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *      isPublished: // value for 'isPublished'
 *   },
 * });
 */
export function useGetNewsQuery(baseOptions?: Apollo.QueryHookOptions<GetNewsQuery, GetNewsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNewsQuery, GetNewsQueryVariables>(GetNewsDocument, options);
      }
export function useGetNewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNewsQuery, GetNewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNewsQuery, GetNewsQueryVariables>(GetNewsDocument, options);
        }
export type GetNewsQueryHookResult = ReturnType<typeof useGetNewsQuery>;
export type GetNewsLazyQueryHookResult = ReturnType<typeof useGetNewsLazyQuery>;
export type GetNewsQueryResult = Apollo.QueryResult<GetNewsQuery, GetNewsQueryVariables>;
export const GetNotificationsByParticipantDocument = gql`
    query getNotificationsByParticipant($userId: String!, $limit: Int, $skip: Int, $isPublished: Boolean) {
  getNotificationsByParticipant(
    userId: $userId
    limit: $limit
    skip: $skip
    isPublished: $isPublished
  ) {
    total
    notifications {
      notification {
        id
        name
        description
        publishedAt
      }
      isViewed
    }
  }
}
    `;

/**
 * __useGetNotificationsByParticipantQuery__
 *
 * To run a query within a React component, call `useGetNotificationsByParticipantQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationsByParticipantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationsByParticipantQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *      isPublished: // value for 'isPublished'
 *   },
 * });
 */
export function useGetNotificationsByParticipantQuery(baseOptions: Apollo.QueryHookOptions<GetNotificationsByParticipantQuery, GetNotificationsByParticipantQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNotificationsByParticipantQuery, GetNotificationsByParticipantQueryVariables>(GetNotificationsByParticipantDocument, options);
      }
export function useGetNotificationsByParticipantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNotificationsByParticipantQuery, GetNotificationsByParticipantQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNotificationsByParticipantQuery, GetNotificationsByParticipantQueryVariables>(GetNotificationsByParticipantDocument, options);
        }
export type GetNotificationsByParticipantQueryHookResult = ReturnType<typeof useGetNotificationsByParticipantQuery>;
export type GetNotificationsByParticipantLazyQueryHookResult = ReturnType<typeof useGetNotificationsByParticipantLazyQuery>;
export type GetNotificationsByParticipantQueryResult = Apollo.QueryResult<GetNotificationsByParticipantQuery, GetNotificationsByParticipantQueryVariables>;
export const NotificationParticipantStatusChangeDocument = gql`
    mutation notificationParticipantStatusChange($notificationIds: [String]!, $userId: String!) {
  notificationParticipantStatusChange(
    notificationIds: $notificationIds
    userId: $userId
  )
}
    `;
export type NotificationParticipantStatusChangeMutationFn = Apollo.MutationFunction<NotificationParticipantStatusChangeMutation, NotificationParticipantStatusChangeMutationVariables>;

/**
 * __useNotificationParticipantStatusChangeMutation__
 *
 * To run a mutation, you first call `useNotificationParticipantStatusChangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotificationParticipantStatusChangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notificationParticipantStatusChangeMutation, { data, loading, error }] = useNotificationParticipantStatusChangeMutation({
 *   variables: {
 *      notificationIds: // value for 'notificationIds'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useNotificationParticipantStatusChangeMutation(baseOptions?: Apollo.MutationHookOptions<NotificationParticipantStatusChangeMutation, NotificationParticipantStatusChangeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NotificationParticipantStatusChangeMutation, NotificationParticipantStatusChangeMutationVariables>(NotificationParticipantStatusChangeDocument, options);
      }
export type NotificationParticipantStatusChangeMutationHookResult = ReturnType<typeof useNotificationParticipantStatusChangeMutation>;
export type NotificationParticipantStatusChangeMutationResult = Apollo.MutationResult<NotificationParticipantStatusChangeMutation>;
export type NotificationParticipantStatusChangeMutationOptions = Apollo.BaseMutationOptions<NotificationParticipantStatusChangeMutation, NotificationParticipantStatusChangeMutationVariables>;
export const GetUserPaymentsByProjectDocument = gql`
    query getUserPaymentsByProject($projectId: String!, $limit: Int, $skip: Int, $isPublished: Boolean) {
  getUserPaymentsByProject(
    projectId: $projectId
    limit: $limit
    skip: $skip
    isPublished: $isPublished
  ) {
    total
    payments {
      id
      name
      cost
      estimatedDate
      createdDate
      status {
        displayName
      }
      isPaid
    }
  }
}
    `;

/**
 * __useGetUserPaymentsByProjectQuery__
 *
 * To run a query within a React component, call `useGetUserPaymentsByProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserPaymentsByProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserPaymentsByProjectQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      limit: // value for 'limit'
 *      skip: // value for 'skip'
 *      isPublished: // value for 'isPublished'
 *   },
 * });
 */
export function useGetUserPaymentsByProjectQuery(baseOptions: Apollo.QueryHookOptions<GetUserPaymentsByProjectQuery, GetUserPaymentsByProjectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserPaymentsByProjectQuery, GetUserPaymentsByProjectQueryVariables>(GetUserPaymentsByProjectDocument, options);
      }
export function useGetUserPaymentsByProjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserPaymentsByProjectQuery, GetUserPaymentsByProjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserPaymentsByProjectQuery, GetUserPaymentsByProjectQueryVariables>(GetUserPaymentsByProjectDocument, options);
        }
export type GetUserPaymentsByProjectQueryHookResult = ReturnType<typeof useGetUserPaymentsByProjectQuery>;
export type GetUserPaymentsByProjectLazyQueryHookResult = ReturnType<typeof useGetUserPaymentsByProjectLazyQuery>;
export type GetUserPaymentsByProjectQueryResult = Apollo.QueryResult<GetUserPaymentsByProjectQuery, GetUserPaymentsByProjectQueryVariables>;
export const GetUserPayrollsDocument = gql`
    query getUserPayrolls($userId: String!) {
  getUserPayrolls(userId: $userId) {
    id
    name
    bonuses {
      id
      name
      value
    }
    hoursPlan
    additionalData {
      total
      mainPart
      reports {
        salary
        mainPart
        times
        totalTime
      }
    }
  }
}
    `;

/**
 * __useGetUserPayrollsQuery__
 *
 * To run a query within a React component, call `useGetUserPayrollsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserPayrollsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserPayrollsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserPayrollsQuery(baseOptions: Apollo.QueryHookOptions<GetUserPayrollsQuery, GetUserPayrollsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserPayrollsQuery, GetUserPayrollsQueryVariables>(GetUserPayrollsDocument, options);
      }
export function useGetUserPayrollsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserPayrollsQuery, GetUserPayrollsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserPayrollsQuery, GetUserPayrollsQueryVariables>(GetUserPayrollsDocument, options);
        }
export type GetUserPayrollsQueryHookResult = ReturnType<typeof useGetUserPayrollsQuery>;
export type GetUserPayrollsLazyQueryHookResult = ReturnType<typeof useGetUserPayrollsLazyQuery>;
export type GetUserPayrollsQueryResult = Apollo.QueryResult<GetUserPayrollsQuery, GetUserPayrollsQueryVariables>;
export const GetUserPayrollDocument = gql`
    query getUserPayroll($payrollId: String!) {
  getUserPayroll(payrollId: $payrollId) {
    id
    name
    bonuses {
      id
      name
      value
    }
    bonusFund
    kpi {
      id
      name
      unit
      weigh
      plan
      fact
    }
    parts {
      id
      name
      value
      date
    }
    salaryDescription
    bonusesDescription
    totalDescription
    kpiDescription
    hoursPlan
    additionalData {
      reports {
        totalTime
        times
        salary
      }
      bonusesValue
      kpiValue
      total
      mainPart
    }
  }
}
    `;

/**
 * __useGetUserPayrollQuery__
 *
 * To run a query within a React component, call `useGetUserPayrollQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserPayrollQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserPayrollQuery({
 *   variables: {
 *      payrollId: // value for 'payrollId'
 *   },
 * });
 */
export function useGetUserPayrollQuery(baseOptions: Apollo.QueryHookOptions<GetUserPayrollQuery, GetUserPayrollQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserPayrollQuery, GetUserPayrollQueryVariables>(GetUserPayrollDocument, options);
      }
export function useGetUserPayrollLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserPayrollQuery, GetUserPayrollQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserPayrollQuery, GetUserPayrollQueryVariables>(GetUserPayrollDocument, options);
        }
export type GetUserPayrollQueryHookResult = ReturnType<typeof useGetUserPayrollQuery>;
export type GetUserPayrollLazyQueryHookResult = ReturnType<typeof useGetUserPayrollLazyQuery>;
export type GetUserPayrollQueryResult = Apollo.QueryResult<GetUserPayrollQuery, GetUserPayrollQueryVariables>;
export const GetUserInfoPayrollDocument = gql`
    query getUserInfoPayroll($userId: String!) {
  getUser(userId: $userId) {
    salary
    job {
      id
      name
    }
    firstName
    lastName
  }
}
    `;

/**
 * __useGetUserInfoPayrollQuery__
 *
 * To run a query within a React component, call `useGetUserInfoPayrollQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserInfoPayrollQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserInfoPayrollQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserInfoPayrollQuery(baseOptions: Apollo.QueryHookOptions<GetUserInfoPayrollQuery, GetUserInfoPayrollQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserInfoPayrollQuery, GetUserInfoPayrollQueryVariables>(GetUserInfoPayrollDocument, options);
      }
export function useGetUserInfoPayrollLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserInfoPayrollQuery, GetUserInfoPayrollQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserInfoPayrollQuery, GetUserInfoPayrollQueryVariables>(GetUserInfoPayrollDocument, options);
        }
export type GetUserInfoPayrollQueryHookResult = ReturnType<typeof useGetUserInfoPayrollQuery>;
export type GetUserInfoPayrollLazyQueryHookResult = ReturnType<typeof useGetUserInfoPayrollLazyQuery>;
export type GetUserInfoPayrollQueryResult = Apollo.QueryResult<GetUserInfoPayrollQuery, GetUserInfoPayrollQueryVariables>;
export const GetProductsDocument = gql`
    query getProducts {
  getProducts {
    id
    name
    description
    features
    type {
      displayName
    }
    flex
    requiredKpi
    requiredLevel
    image
    url
    location
  }
}
    `;

/**
 * __useGetProductsQuery__
 *
 * To run a query within a React component, call `useGetProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProductsQuery(baseOptions?: Apollo.QueryHookOptions<GetProductsQuery, GetProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductsQuery, GetProductsQueryVariables>(GetProductsDocument, options);
      }
export function useGetProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductsQuery, GetProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductsQuery, GetProductsQueryVariables>(GetProductsDocument, options);
        }
export type GetProductsQueryHookResult = ReturnType<typeof useGetProductsQuery>;
export type GetProductsLazyQueryHookResult = ReturnType<typeof useGetProductsLazyQuery>;
export type GetProductsQueryResult = Apollo.QueryResult<GetProductsQuery, GetProductsQueryVariables>;
export const GetProjectDocument = gql`
    query getProject($projectId: String!) {
  getProject(projectId: $projectId) {
    name
    projectManager {
      id
      firstName
      lastName
      phone
      photo
      job {
        name
      }
    }
    contactPersons {
      firstName
      lastName
      phone
      photo
      job {
        name
      }
    }
    employees {
      id
      firstName
      photo
      job {
        name
      }
    }
    links {
      title
      link
      login
      password
    }
    designFigma
    presentations {
      id
      title
      link
    }
    brief
    blockDiagrams
    contractTemplates
  }
}
    `;

/**
 * __useGetProjectQuery__
 *
 * To run a query within a React component, call `useGetProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetProjectQuery(baseOptions: Apollo.QueryHookOptions<GetProjectQuery, GetProjectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectQuery, GetProjectQueryVariables>(GetProjectDocument, options);
      }
export function useGetProjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectQuery, GetProjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectQuery, GetProjectQueryVariables>(GetProjectDocument, options);
        }
export type GetProjectQueryHookResult = ReturnType<typeof useGetProjectQuery>;
export type GetProjectLazyQueryHookResult = ReturnType<typeof useGetProjectLazyQuery>;
export type GetProjectQueryResult = Apollo.QueryResult<GetProjectQuery, GetProjectQueryVariables>;
export const GetProjectsByUserDocument = gql`
    query getProjectsByUser($userId: String!) {
  getProjectsByUser(userId: $userId) {
    name
    id
  }
}
    `;

/**
 * __useGetProjectsByUserQuery__
 *
 * To run a query within a React component, call `useGetProjectsByUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectsByUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectsByUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetProjectsByUserQuery(baseOptions: Apollo.QueryHookOptions<GetProjectsByUserQuery, GetProjectsByUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectsByUserQuery, GetProjectsByUserQueryVariables>(GetProjectsByUserDocument, options);
      }
export function useGetProjectsByUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectsByUserQuery, GetProjectsByUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectsByUserQuery, GetProjectsByUserQueryVariables>(GetProjectsByUserDocument, options);
        }
export type GetProjectsByUserQueryHookResult = ReturnType<typeof useGetProjectsByUserQuery>;
export type GetProjectsByUserLazyQueryHookResult = ReturnType<typeof useGetProjectsByUserLazyQuery>;
export type GetProjectsByUserQueryResult = Apollo.QueryResult<GetProjectsByUserQuery, GetProjectsByUserQueryVariables>;
export const GetProjectsByClientDocument = gql`
    query getProjectsByClient($userId: String!) {
  getProjectsByClient(userId: $userId) {
    id
    name
  }
}
    `;

/**
 * __useGetProjectsByClientQuery__
 *
 * To run a query within a React component, call `useGetProjectsByClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectsByClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectsByClientQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetProjectsByClientQuery(baseOptions: Apollo.QueryHookOptions<GetProjectsByClientQuery, GetProjectsByClientQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectsByClientQuery, GetProjectsByClientQueryVariables>(GetProjectsByClientDocument, options);
      }
export function useGetProjectsByClientLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectsByClientQuery, GetProjectsByClientQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectsByClientQuery, GetProjectsByClientQueryVariables>(GetProjectsByClientDocument, options);
        }
export type GetProjectsByClientQueryHookResult = ReturnType<typeof useGetProjectsByClientQuery>;
export type GetProjectsByClientLazyQueryHookResult = ReturnType<typeof useGetProjectsByClientLazyQuery>;
export type GetProjectsByClientQueryResult = Apollo.QueryResult<GetProjectsByClientQuery, GetProjectsByClientQueryVariables>;
export const GetProjectCorrectionsDocument = gql`
    query getProjectCorrections($projectId: String!, $statuses: [String]) {
  getProjectCorrections(projectId: $projectId, statuses: $statuses) {
    id
    text
    status {
      displayName
    }
    createdDate
    updatedDate
    comments {
      id
      text
      correction {
        id
        text
        createdDate
      }
    }
    createdBy {
      id
      firstName
      photo
      job {
        name
      }
    }
  }
}
    `;

/**
 * __useGetProjectCorrectionsQuery__
 *
 * To run a query within a React component, call `useGetProjectCorrectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectCorrectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectCorrectionsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      statuses: // value for 'statuses'
 *   },
 * });
 */
export function useGetProjectCorrectionsQuery(baseOptions: Apollo.QueryHookOptions<GetProjectCorrectionsQuery, GetProjectCorrectionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectCorrectionsQuery, GetProjectCorrectionsQueryVariables>(GetProjectCorrectionsDocument, options);
      }
export function useGetProjectCorrectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectCorrectionsQuery, GetProjectCorrectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectCorrectionsQuery, GetProjectCorrectionsQueryVariables>(GetProjectCorrectionsDocument, options);
        }
export type GetProjectCorrectionsQueryHookResult = ReturnType<typeof useGetProjectCorrectionsQuery>;
export type GetProjectCorrectionsLazyQueryHookResult = ReturnType<typeof useGetProjectCorrectionsLazyQuery>;
export type GetProjectCorrectionsQueryResult = Apollo.QueryResult<GetProjectCorrectionsQuery, GetProjectCorrectionsQueryVariables>;
export const GetProjectCorrectionsLightDocument = gql`
    query getProjectCorrectionsLight($projectId: String!) {
  getProjectCorrectionsLight(projectId: $projectId) {
    status
    count
  }
}
    `;

/**
 * __useGetProjectCorrectionsLightQuery__
 *
 * To run a query within a React component, call `useGetProjectCorrectionsLightQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectCorrectionsLightQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectCorrectionsLightQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetProjectCorrectionsLightQuery(baseOptions: Apollo.QueryHookOptions<GetProjectCorrectionsLightQuery, GetProjectCorrectionsLightQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectCorrectionsLightQuery, GetProjectCorrectionsLightQueryVariables>(GetProjectCorrectionsLightDocument, options);
      }
export function useGetProjectCorrectionsLightLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectCorrectionsLightQuery, GetProjectCorrectionsLightQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectCorrectionsLightQuery, GetProjectCorrectionsLightQueryVariables>(GetProjectCorrectionsLightDocument, options);
        }
export type GetProjectCorrectionsLightQueryHookResult = ReturnType<typeof useGetProjectCorrectionsLightQuery>;
export type GetProjectCorrectionsLightLazyQueryHookResult = ReturnType<typeof useGetProjectCorrectionsLightLazyQuery>;
export type GetProjectCorrectionsLightQueryResult = Apollo.QueryResult<GetProjectCorrectionsLightQuery, GetProjectCorrectionsLightQueryVariables>;
export const GetProjectStepsFromJiraDocument = gql`
    query getProjectStepsFromJira($projectId: String!) {
  getProjectStepsFromJira(projectId: $projectId) {
    fields {
      summary
      duedate
      customfield_10015
    }
  }
}
    `;

/**
 * __useGetProjectStepsFromJiraQuery__
 *
 * To run a query within a React component, call `useGetProjectStepsFromJiraQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectStepsFromJiraQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectStepsFromJiraQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetProjectStepsFromJiraQuery(baseOptions: Apollo.QueryHookOptions<GetProjectStepsFromJiraQuery, GetProjectStepsFromJiraQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectStepsFromJiraQuery, GetProjectStepsFromJiraQueryVariables>(GetProjectStepsFromJiraDocument, options);
      }
export function useGetProjectStepsFromJiraLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectStepsFromJiraQuery, GetProjectStepsFromJiraQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectStepsFromJiraQuery, GetProjectStepsFromJiraQueryVariables>(GetProjectStepsFromJiraDocument, options);
        }
export type GetProjectStepsFromJiraQueryHookResult = ReturnType<typeof useGetProjectStepsFromJiraQuery>;
export type GetProjectStepsFromJiraLazyQueryHookResult = ReturnType<typeof useGetProjectStepsFromJiraLazyQuery>;
export type GetProjectStepsFromJiraQueryResult = Apollo.QueryResult<GetProjectStepsFromJiraQuery, GetProjectStepsFromJiraQueryVariables>;
export const GetProjectTaskDocument = gql`
    query getProjectTask($id: String!) {
  getProjectTask(id: $id) {
    id
    name
    description
    comments {
      id
      text
      file
      createdBy {
        id
        firstName
        lastName
        photo
      }
    }
    items {
      id
      name
      isCompleted
    }
    key
    epicKey
    assigneeId
    responsibleId
    startDateEstimate
    startDateFact
    finishDateEstimate
    finishDateFact
    timeEstimate
    timeSpent
    labels {
      id
      name
      color
    }
    createdBy {
      firstName
      lastName
      photo
    }
  }
}
    `;

/**
 * __useGetProjectTaskQuery__
 *
 * To run a query within a React component, call `useGetProjectTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectTaskQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProjectTaskQuery(baseOptions: Apollo.QueryHookOptions<GetProjectTaskQuery, GetProjectTaskQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectTaskQuery, GetProjectTaskQueryVariables>(GetProjectTaskDocument, options);
      }
export function useGetProjectTaskLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectTaskQuery, GetProjectTaskQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectTaskQuery, GetProjectTaskQueryVariables>(GetProjectTaskDocument, options);
        }
export type GetProjectTaskQueryHookResult = ReturnType<typeof useGetProjectTaskQuery>;
export type GetProjectTaskLazyQueryHookResult = ReturnType<typeof useGetProjectTaskLazyQuery>;
export type GetProjectTaskQueryResult = Apollo.QueryResult<GetProjectTaskQuery, GetProjectTaskQueryVariables>;
export const GetLateTasksDocument = gql`
    query getLateTasks($projectId: String!, $taskStatusId: String, $isClient: Boolean) {
  getLateTasks(
    projectId: $projectId
    taskStatusId: $taskStatusId
    isClient: $isClient
  ) {
    id
    name
    lateReason
    assigneeName
    startDateFact
    startDateEstimate
    finishDateFact
    finishDateEstimate
    cost
    costEstimate
    lateStart
    lateFinish
  }
}
    `;

/**
 * __useGetLateTasksQuery__
 *
 * To run a query within a React component, call `useGetLateTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLateTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLateTasksQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      taskStatusId: // value for 'taskStatusId'
 *      isClient: // value for 'isClient'
 *   },
 * });
 */
export function useGetLateTasksQuery(baseOptions: Apollo.QueryHookOptions<GetLateTasksQuery, GetLateTasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLateTasksQuery, GetLateTasksQueryVariables>(GetLateTasksDocument, options);
      }
export function useGetLateTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLateTasksQuery, GetLateTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLateTasksQuery, GetLateTasksQueryVariables>(GetLateTasksDocument, options);
        }
export type GetLateTasksQueryHookResult = ReturnType<typeof useGetLateTasksQuery>;
export type GetLateTasksLazyQueryHookResult = ReturnType<typeof useGetLateTasksLazyQuery>;
export type GetLateTasksQueryResult = Apollo.QueryResult<GetLateTasksQuery, GetLateTasksQueryVariables>;
export const GetAvailableProjectTaskLabelsDocument = gql`
    query getAvailableProjectTaskLabels($projectId: String!, $projectTaskId: String!) {
  getAvailableProjectTaskLabels(
    projectId: $projectId
    projectTaskId: $projectTaskId
  ) {
    id
    name
    color
  }
}
    `;

/**
 * __useGetAvailableProjectTaskLabelsQuery__
 *
 * To run a query within a React component, call `useGetAvailableProjectTaskLabelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailableProjectTaskLabelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailableProjectTaskLabelsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      projectTaskId: // value for 'projectTaskId'
 *   },
 * });
 */
export function useGetAvailableProjectTaskLabelsQuery(baseOptions: Apollo.QueryHookOptions<GetAvailableProjectTaskLabelsQuery, GetAvailableProjectTaskLabelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAvailableProjectTaskLabelsQuery, GetAvailableProjectTaskLabelsQueryVariables>(GetAvailableProjectTaskLabelsDocument, options);
      }
export function useGetAvailableProjectTaskLabelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAvailableProjectTaskLabelsQuery, GetAvailableProjectTaskLabelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAvailableProjectTaskLabelsQuery, GetAvailableProjectTaskLabelsQueryVariables>(GetAvailableProjectTaskLabelsDocument, options);
        }
export type GetAvailableProjectTaskLabelsQueryHookResult = ReturnType<typeof useGetAvailableProjectTaskLabelsQuery>;
export type GetAvailableProjectTaskLabelsLazyQueryHookResult = ReturnType<typeof useGetAvailableProjectTaskLabelsLazyQuery>;
export type GetAvailableProjectTaskLabelsQueryResult = Apollo.QueryResult<GetAvailableProjectTaskLabelsQuery, GetAvailableProjectTaskLabelsQueryVariables>;
export const GetPurchasesInCartDocument = gql`
    query getPurchasesInCart($getPurchasesData: GetPurchasesByUserInput!) {
  getPurchasesInCart(getPurchasesData: $getPurchasesData) {
    purchases {
      id
      product {
        id
        name
        flex
        requiredKpi
        requiredLevel
        type {
          displayName
        }
        image
        description
        features
        url
        location
      }
      status {
        status
      }
    }
    total
  }
}
    `;

/**
 * __useGetPurchasesInCartQuery__
 *
 * To run a query within a React component, call `useGetPurchasesInCartQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPurchasesInCartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPurchasesInCartQuery({
 *   variables: {
 *      getPurchasesData: // value for 'getPurchasesData'
 *   },
 * });
 */
export function useGetPurchasesInCartQuery(baseOptions: Apollo.QueryHookOptions<GetPurchasesInCartQuery, GetPurchasesInCartQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPurchasesInCartQuery, GetPurchasesInCartQueryVariables>(GetPurchasesInCartDocument, options);
      }
export function useGetPurchasesInCartLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPurchasesInCartQuery, GetPurchasesInCartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPurchasesInCartQuery, GetPurchasesInCartQueryVariables>(GetPurchasesInCartDocument, options);
        }
export type GetPurchasesInCartQueryHookResult = ReturnType<typeof useGetPurchasesInCartQuery>;
export type GetPurchasesInCartLazyQueryHookResult = ReturnType<typeof useGetPurchasesInCartLazyQuery>;
export type GetPurchasesInCartQueryResult = Apollo.QueryResult<GetPurchasesInCartQuery, GetPurchasesInCartQueryVariables>;
export const GetPurchaseHistoryDocument = gql`
    query getPurchaseHistory($getPurchasesData: GetPurchasesByUserInput!) {
  getPurchaseHistory(getPurchasesData: $getPurchasesData) {
    purchases {
      id
      product {
        id
        name
        type {
          displayName
        }
        image
        description
        features
        url
        location
      }
      status {
        status
      }
    }
    total
  }
}
    `;

/**
 * __useGetPurchaseHistoryQuery__
 *
 * To run a query within a React component, call `useGetPurchaseHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPurchaseHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPurchaseHistoryQuery({
 *   variables: {
 *      getPurchasesData: // value for 'getPurchasesData'
 *   },
 * });
 */
export function useGetPurchaseHistoryQuery(baseOptions: Apollo.QueryHookOptions<GetPurchaseHistoryQuery, GetPurchaseHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPurchaseHistoryQuery, GetPurchaseHistoryQueryVariables>(GetPurchaseHistoryDocument, options);
      }
export function useGetPurchaseHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPurchaseHistoryQuery, GetPurchaseHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPurchaseHistoryQuery, GetPurchaseHistoryQueryVariables>(GetPurchaseHistoryDocument, options);
        }
export type GetPurchaseHistoryQueryHookResult = ReturnType<typeof useGetPurchaseHistoryQuery>;
export type GetPurchaseHistoryLazyQueryHookResult = ReturnType<typeof useGetPurchaseHistoryLazyQuery>;
export type GetPurchaseHistoryQueryResult = Apollo.QueryResult<GetPurchaseHistoryQuery, GetPurchaseHistoryQueryVariables>;
export const GetBaseProjectTaskStatusesDocument = gql`
    query getBaseProjectTaskStatuses {
  getBaseProjectTaskStatuses {
    id
    name
    isBase
  }
}
    `;

/**
 * __useGetBaseProjectTaskStatusesQuery__
 *
 * To run a query within a React component, call `useGetBaseProjectTaskStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBaseProjectTaskStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBaseProjectTaskStatusesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBaseProjectTaskStatusesQuery(baseOptions?: Apollo.QueryHookOptions<GetBaseProjectTaskStatusesQuery, GetBaseProjectTaskStatusesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBaseProjectTaskStatusesQuery, GetBaseProjectTaskStatusesQueryVariables>(GetBaseProjectTaskStatusesDocument, options);
      }
export function useGetBaseProjectTaskStatusesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBaseProjectTaskStatusesQuery, GetBaseProjectTaskStatusesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBaseProjectTaskStatusesQuery, GetBaseProjectTaskStatusesQueryVariables>(GetBaseProjectTaskStatusesDocument, options);
        }
export type GetBaseProjectTaskStatusesQueryHookResult = ReturnType<typeof useGetBaseProjectTaskStatusesQuery>;
export type GetBaseProjectTaskStatusesLazyQueryHookResult = ReturnType<typeof useGetBaseProjectTaskStatusesLazyQuery>;
export type GetBaseProjectTaskStatusesQueryResult = Apollo.QueryResult<GetBaseProjectTaskStatusesQuery, GetBaseProjectTaskStatusesQueryVariables>;
export const GetUserInfoDocument = gql`
    query getUserInfo {
  getUserInfo {
    id
    firstName
    lastName
    photo
    phone
    email
    stats {
      flex
      kpi
      levelValue
    }
    projects {
      id
      name
    }
    job {
      name
    }
    role {
      id
      displayName
    }
  }
}
    `;

/**
 * __useGetUserInfoQuery__
 *
 * To run a query within a React component, call `useGetUserInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetUserInfoQuery, GetUserInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserInfoQuery, GetUserInfoQueryVariables>(GetUserInfoDocument, options);
      }
export function useGetUserInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserInfoQuery, GetUserInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserInfoQuery, GetUserInfoQueryVariables>(GetUserInfoDocument, options);
        }
export type GetUserInfoQueryHookResult = ReturnType<typeof useGetUserInfoQuery>;
export type GetUserInfoLazyQueryHookResult = ReturnType<typeof useGetUserInfoLazyQuery>;
export type GetUserInfoQueryResult = Apollo.QueryResult<GetUserInfoQuery, GetUserInfoQueryVariables>;
export const GetUsersNameDocument = gql`
    query getUsersName($getUsersData: GetUsersInput!) {
  getUsers(getUsersData: $getUsersData) {
    users {
      id
      lastName
      firstName
      photo
    }
  }
}
    `;

/**
 * __useGetUsersNameQuery__
 *
 * To run a query within a React component, call `useGetUsersNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersNameQuery({
 *   variables: {
 *      getUsersData: // value for 'getUsersData'
 *   },
 * });
 */
export function useGetUsersNameQuery(baseOptions: Apollo.QueryHookOptions<GetUsersNameQuery, GetUsersNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersNameQuery, GetUsersNameQueryVariables>(GetUsersNameDocument, options);
      }
export function useGetUsersNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersNameQuery, GetUsersNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersNameQuery, GetUsersNameQueryVariables>(GetUsersNameDocument, options);
        }
export type GetUsersNameQueryHookResult = ReturnType<typeof useGetUsersNameQuery>;
export type GetUsersNameLazyQueryHookResult = ReturnType<typeof useGetUsersNameLazyQuery>;
export type GetUsersNameQueryResult = Apollo.QueryResult<GetUsersNameQuery, GetUsersNameQueryVariables>;
export const GetUserInfoLightDocument = gql`
    query getUserInfoLight {
  getUserInfo {
    id
    firstName
    lastName
    photo
  }
}
    `;

/**
 * __useGetUserInfoLightQuery__
 *
 * To run a query within a React component, call `useGetUserInfoLightQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserInfoLightQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserInfoLightQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserInfoLightQuery(baseOptions?: Apollo.QueryHookOptions<GetUserInfoLightQuery, GetUserInfoLightQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserInfoLightQuery, GetUserInfoLightQueryVariables>(GetUserInfoLightDocument, options);
      }
export function useGetUserInfoLightLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserInfoLightQuery, GetUserInfoLightQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserInfoLightQuery, GetUserInfoLightQueryVariables>(GetUserInfoLightDocument, options);
        }
export type GetUserInfoLightQueryHookResult = ReturnType<typeof useGetUserInfoLightQuery>;
export type GetUserInfoLightLazyQueryHookResult = ReturnType<typeof useGetUserInfoLightLazyQuery>;
export type GetUserInfoLightQueryResult = Apollo.QueryResult<GetUserInfoLightQuery, GetUserInfoLightQueryVariables>;