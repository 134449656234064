import React, { ReactChild } from 'react';
import styled from 'styled-components';
import { Button } from '@material-ui/core';
import { primary, white } from '../../constants/Styles/colors';

interface IButtonBody {
  bg_color?: string;
  txt_color?: string;
}

interface ICustomButton extends IButtonBody {
  children: ReactChild;
  callback(): any;
}

const ButtonBody = styled(Button)<IButtonBody>`
  &.MuiButtonBase-root {
    text-transform: unset;
    border-radius: 10px;
    background-color: ${({ bg_color }) => (bg_color ? bg_color : primary)};
    color: ${({ txt_color }) => (txt_color ? txt_color : white)};
    transition: opacity 0.3s;

    &:hover {
      background-color: ${({ bg_color }) => (bg_color ? bg_color : primary)};
      color: ${({ txt_color }) => (txt_color ? txt_color : white)};
      opacity: 0.7;
    }
  }
`;

export const CustomButton = ({ bg_color, txt_color, callback, children }: ICustomButton) => {
  return (
    <ButtonBody bg_color={bg_color} txt_color={txt_color} onClick={callback}>
      {children}
    </ButtonBody>
  );
};
