import React, { useContext } from 'react';

import { ShopTabContext } from '../../ShopTab/ShopTabContext';

import { ProductCard } from '../../ProductCard';

import styles from '../ProductsList.module.scss';

export const PurchaseProductsList = () => {
  const { body, controls, switchBtn_xl, list, item, chosen, listWrapper, emptyProductsList } = styles;

  const { purchaseHistory, chosenPurchase, chosePurchaseHandler } = useContext(ShopTabContext);

  return (
    <div className={body}>
      <ul className={controls}>
        <li className={switchBtn_xl}>Мои покупки</li>
      </ul>
      <div className={listWrapper}>
        <ul className={list}>
          {purchaseHistory?.length ? (
            purchaseHistory.map(purchase => {
              if (purchase.product) {
                return (
                  <li
                    className={chosenPurchase && purchase.id === chosenPurchase.id ? chosen : item}
                    key={purchase.id}
                    onClick={() => {
                      purchase.id && chosePurchaseHandler(purchase.id, 'history');
                    }}
                  >
                    <ProductCard role="purchase" purchase={purchase} />
                  </li>
                );
              }
            })
          ) : (
            <p className={emptyProductsList}>История покупок пуста!</p>
          )}
        </ul>
      </div>
    </div>
  );
};
