import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useLocation } from 'react-router';
import { SignIn } from './pages/SignIn';
import { SignUp } from './pages/SignUp';
import { InformationPlaceholder } from './pages/InformationPlaceholder';
import { PasswordRecovery } from './pages/PasswordRecovery';
import { Main } from './pages/Main';
import './App.scss';

function App() {
  const { pathname } = useLocation();
  const token = pathname.split('/').slice(-1)[0];

  return (
    <div className="App">
      <Switch>
        <Route path="/login" component={SignIn} />
        <Route path="/registration" component={SignUp} />
        <Route path="/welcome" render={() => <InformationPlaceholder registered />} />
        <Route path={`/confirm/${token}`} render={() => <InformationPlaceholder confirmed />} />
        <Route path="/password-recovery" render={() => <InformationPlaceholder passwordRecovery />} />
        <Route path={`/resetPassword/${token}`} render={() => <PasswordRecovery token={token} />} />
        <Route path="/" component={Main} />
      </Switch>
    </div>
  );
}

export default App;
