import React from 'react';

import { IProductCardProps } from '../ProductCard';

import styles from '../ProductCard.module.scss';

export const PurchaseProductCard = ({ purchase }: IProductCardProps) => {
  const { img, img_sm, statusWrapper, title, statusTxt, pending, confirmed, sent, complited } = styles;

  const { type, image, name } = purchase!.product!;
  const { status } = purchase!.status!;

  const statuses = {
    PENDING: {
      text: 'Покупка ожидает подтверждения',
      class: pending,
    },
    CONFIRMED: {
      text: 'Покупка подтверждена',
      class: confirmed,
    },
    SENT: {
      text: 'Покупка отправлена',
      class: sent,
    },
    COMPLETED: {
      text: 'Покупка получена',
      class: complited,
    },
  };

  return (
    <>
      <img className={type?.displayName === 'impression' ? img : img_sm} src={image ? image : ''} alt="product" />

      <div className={statusWrapper}>
        <h3 className={title}>{name}</h3>

        <p className={`${statusTxt} ${statuses[status!].class}`}>{statuses[status!].text}</p>
      </div>
    </>
  );
};
