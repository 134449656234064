import styled from 'styled-components';

export const ModalContainer = styled.div`
  background: #fff;
  padding: 20px 20px 30px 30px;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 20px 0;
`;

export const SectionContainer = styled.section`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

export const SectionTitle = styled.h3`
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 160%;
  letter-spacing: 0.04em;
  color: #101010;
  text-align: start;
  margin-bottom: 40px;
`;

export const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  margin-bottom: 60px;

  @media (min-width: 1200px) {
    grid-gap: 60px;
    grid-template-columns: 1fr 1fr;
  }
`;

export const Column = styled.div`
  min-height: 100px;

  &:last-of-type {
    @media (min-width: 1200px) {
      //margin-left: 60px;
    }
    padding-top: 50px;
  }
`;

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
  position: relative;
`;

export const FieldLabel = styled.label`
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 15px;
  color: #828282;
  margin-bottom: 10px;
`;

export const Select = styled.select<{ error: boolean }>`
  box-sizing: border-box;
  border-radius: 10px;
  width: 500px;
  padding: 10px;
  border: ${({ error }) => (error ? '1px solid #F44545' : '1px solid #828282')};

  &:focus {
    outline: 2px solid #ffbb4f;
    border: none;
  }
`;

export const Option = styled.option`
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 15px;
  color: #828282;
  background: transparent;

  &:hover {
    background-color: #828282;
  }

  &:first-letter {
    text-transform: uppercase;
  }
`;

export const Input = styled.input<{ error?: boolean }>`
  box-sizing: border-box;
  border-radius: 10px;
  width: 500px;
  padding: 10px;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 15px;
  color: #828282;
  border: ${({ error }) => (error ? '1px solid #F44545' : '1px solid #828282')};

  ::placeholder {
    color: #828282;
  }

  &:focus {
    outline: 2px solid #ffbb4f;
    border: none;
  }
`;

export const TextArea = styled.textarea`
  border: 1px solid #828282;
  box-sizing: border-box;
  border-radius: 10px;
  width: 500px;
  padding: 10px;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 15px;
  color: #828282;
  resize: none;

  &:focus {
    outline: 2px solid #ffbb4f;
    border: none;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const CloseButtonContainer = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
`;

export const ModalButton = styled.button<{ color: string; fontSize?: number; padding?: number[] }>`
  background: ${({ color }) => color};
  border: none;
  border-radius: 10px;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: ${props => `${props?.fontSize ? props.fontSize : 16}px`};
  line-height: 20px;
  color: #ffffff;
  padding: ${props => (props.padding ? `${props.padding[0]}px ${props.padding[1]}px` : '10px 30px')};

  &:first-of-type {
    margin-right: 20px;
  }
`;

export const ComponentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  min-height: 37px;
`;

export const ComponentLabel = styled.div`
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 160%;
  letter-spacing: 0.04em;
  color: #101010;
`;

export const ErrorSpan = styled.span`
  width: 250px;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.04em;
  color: #101010;
`;
