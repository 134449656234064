import React, { useRef, useState, useEffect, ChangeEvent } from 'react';
import { classNames } from '../../constants/classNames';
import { Button } from 'react-bootstrap';
import pen from '../../accets/icons/pen.svg';
import './EditableInput.scss';

interface IEditableInput {
  className: string;
  classNameError: string;
  id: string;
  name: string;
  label: string;
  type: string;
  placeholder?: string;
  value: string;
  error?: string;
  onChange(e: string | ChangeEvent<HTMLInputElement>): void;
}

export const EditableInput = ({
  className,
  classNameError,
  id,
  name,
  label,
  type,
  placeholder,
  value,
  onChange,
  error,
}: IEditableInput) => {
  const [disabled, setDisabled] = useState(true);
  const refInput = useRef<HTMLInputElement | null>(null);

  const focusOnInput = (): void => {
    if (refInput.current) {
      refInput.current.disabled = false;
      refInput.current.focus();
    }
  };

  const onBlurInput = (): void => {
    if (error) {
      focusOnInput();
      return;
    } else if (refInput.current) {
      refInput.current.disabled = true;
      setDisabled(true);
    }
  };

  useEffect(() => {
    if (error) {
      focusOnInput();
    } else {
      setDisabled(true);
    }
  }, [error]);

  return (
    <div className="editable-input position-relative w-100">
      <label className="input-label" htmlFor={id}>
        {label}
      </label>
      <input
        id={id}
        name={name}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlurInput}
        className={classNames({
          [className]: !error,
          [classNameError]: error,
        })}
        disabled={disabled}
        ref={refInput}
        autoComplete="true"
      />
      {error && <span className="logout-form-error w-100">{error}</span>}
      <Button
        className="btn-edit"
        onClick={() => {
          focusOnInput();
        }}
      >
        <img src={pen} className="btn-edit-img" alt="pen" />
      </Button>
    </div>
  );
};
