import React, { useContext } from 'react';
import { useLocation } from 'react-router';
import { User } from '../../../generated/graphql';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { UserInfoContext } from '../../Main/Main';
import projectNavIcon from '../../../accets/icons/projectNavIcon.svg';
import projectNavIconColor from '../../../accets/icons/projectNavIcon-color.svg';
import { ArrowForwardIos } from '@material-ui/icons';
import { LinkIcon, LinkTitle, NavbarContainer, NavbarLink } from './ProjectsNavbar.style';

export const ProjectsNavbar = () => {
  const { projects } = useContext<User>(UserInfoContext);
  const { search } = useLocation();

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };

  return (
    <NavbarContainer>
      <Carousel
        infinite={true}
        responsive={responsive}
        arrows={false}
        renderButtonGroupOutside={true}
        customButtonGroup={<ButtonGroup />}
      >
        {projects.map(project => (
          <NavbarLink
            key={project.id}
            active={search.includes(project.id) ? 'active' : ''}
            to={`/overdue-task?${project.id}`}
          >
            <LinkIcon url={search.includes(project.id) ? projectNavIconColor : projectNavIcon} />
            <LinkTitle title={project.name.length > 14 && project.name}>
              {project.name.length > 14 ? `${project.name.substr(0, 14)}...` : project.name}
            </LinkTitle>
          </NavbarLink>
        ))}
      </Carousel>
    </NavbarContainer>
  );
};

const ButtonGroup = ({ next, previous }: any) => {
  return (
    <div className="carousel-button-group">
      <SampleNextArrow onClick={() => next()} className="arrow--right" />
      <SamplePrevArrow onClick={() => previous()} className="arrow--left" />
    </div>
  );
};

const SampleNextArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <ArrowForwardIos />
    </div>
  );
};

const SamplePrevArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <ArrowForwardIos />
    </div>
  );
};
