import { gql } from '@apollo/client/core';
import { Product } from '../../generated/graphql';

export interface IProductsData {
  getProducts: Product[];
  loading: boolean;
}

export const GET_PRODUCTS = gql`
  query getProducts {
    getProducts {
      id
      name
      description
      features
      type {
        displayName
      }
      flex
      requiredKpi
      requiredLevel
      image
      url
      location
    }
  }
`;
