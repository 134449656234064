import React, { useEffect, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Portal } from 'react-portal';
import {
  CreateProjectTaskInKanbanDocument,
  CreateProjectTaskInKanbanMutation,
  GetProjectStepsByProjectLightDocument,
  GetProjectStepsByProjectLightQuery,
  GetProjectTaskDocument,
  GetProjectTaskQuery,
  GetProjectTasksByWorkflowStepDocument,
  GetUserInfoLightDocument,
  GetUserInfoLightQuery,
  UserRoleEnum,
  GetUsersNameDocument,
  GetUsersNameQuery,
  ProjectStep,
  ProjectTaskCheckListItem,
  ProjectTaskComment,
  UpdateProjectTaskDocument,
  UpdateProjectTaskMutation,
  User,
} from '../../../generated/graphql';

import { AddTaskSection } from './AddTaskSection/AddTaskSection';
import { ChecklistSection } from './ChecklistSection/ChecklistSection';
import { CommentSection } from './CommentSection/CommentSection';
import { EmployeeSelect } from './EmployeeSelect/EmployeeSelect';
import { LabelsSelect } from './LabelsSelect/LabelsSelect';
import { DatepickerSelect } from './DatepickerSelect/DatepickerSelect';
import { TimeInput } from './TimeInput/TimeInput';
import { RowWithLabel } from './RowWithLabel/RowWithLabel';
import { AuthorSection } from './AuthorSection/AuthorSection';
import { convertISOStringToDateWithOffset } from '../../../constants/convertISOStringToDateWithOffset';
import {
  ModalButton,
  ButtonsContainer,
  CloseButtonContainer,
  ModalContainer,
  Column,
  ContentContainer,
} from './DashboardModal.style';
import { CloseButton } from '../../../constants/Styles/CloseButton';
import { Snackbar } from '../../../components/Snackbar';
import { LoadingDisplay } from '../../../components/LoadingDisplay';

interface DashboardModalProps {
  closeModal(): void;
  projectId: string;
  taskId?: string;
  workflowStepId: string;
  workflowStepOrder: number;
}

export interface TaskData {
  name: string;
  epicId: string;
  description: string;
  assigneeId: string;
  responsibleId: string;
  startDateEstimate: Date;
  startDateFact: Date;
  finishDateEstimate: Date;
  finishDateFact: Date;
  timeEstimate: number;
  timeSpent: number;
}

const initialTaskData: TaskData = {
  name: '',
  epicId: '',
  description: '',
  assigneeId: '',
  responsibleId: '',
  startDateEstimate: null,
  startDateFact: null,
  finishDateEstimate: null,
  finishDateFact: null,
  timeEstimate: 0,
  timeSpent: 0,
};

export interface RequiredFieldError {
  epicIdError: boolean;
  nameError: boolean;
  responsibleIdError: boolean;
  assigneeIdError: boolean;
}

export const DashboardModal = ({
  closeModal,
  projectId,
  taskId,
  workflowStepId,
  workflowStepOrder,
}: DashboardModalProps) => {
  const [t] = useTranslation('translation');
  const [taskData, setTaskData] = useState(initialTaskData);
  const [taskItems, setTaskItems] = useState<ProjectTaskCheckListItem[]>([]);
  const [taskComments, setTaskComments] = useState<ProjectTaskComment[]>([]);
  const [createdTaskId, setCreatedTaskId] = useState('');
  const [requiredFieldError, setRequiredFieldError] = useState<RequiredFieldError>({
    epicIdError: false,
    nameError: false,
    responsibleIdError: false,
    assigneeIdError: false,
  });

  const {
    data: projectStepsData,
    loading: projectStepsLoading,
    error: projectStepsError,
  } = useQuery<GetProjectStepsByProjectLightQuery>(GetProjectStepsByProjectLightDocument, {
    variables: { projectId },
  });

  const {
    data: authorData,
    loading: authorLoading,
    error: authorError,
  } = useQuery<GetUserInfoLightQuery>(GetUserInfoLightDocument);

  const {
    data: usersNameData,
    loading: usersNameLoading,
    error: usersNameError,
  } = useQuery<GetUsersNameQuery>(GetUsersNameDocument, {
    variables: {
      getUsersData: {
        limit: 100,
        skip: 0,
        statuses: ['VERIFIED'],
        role: UserRoleEnum.Staff,
      },
    },
  });

  const [getTask, { data: editedTaskData, loading: editedTaskLoading, error: editedTaskError }] =
    useLazyQuery<GetProjectTaskQuery>(GetProjectTaskDocument, { fetchPolicy: 'cache-and-network' });

  const [createTask, { data: createTaskData, loading: createTaskLoading, error: createTaskError }] =
    useMutation<CreateProjectTaskInKanbanMutation>(CreateProjectTaskInKanbanDocument, {
      refetchQueries: [
        {
          query: GetProjectTasksByWorkflowStepDocument,
          variables: {
            projectId,
            workflowStepId,
            limit: 1000,
            skip: 0,
          },
        },
      ],
    });

  const [updateTask, { data: updateTaskData, loading: updateTaskLoading, error: updateTaskError }] =
    useMutation<UpdateProjectTaskMutation>(UpdateProjectTaskDocument, {
      refetchQueries: [
        {
          query: GetProjectTasksByWorkflowStepDocument,
          variables: {
            projectId,
            workflowStepId,
            limit: 1000,
            skip: 0,
          },
        },
      ],
    });

  useEffect(() => {
    if (taskId) {
      getTask({
        variables: {
          id: taskId,
        },
      });
    }
  }, [taskId]);

  useEffect(() => {
    if (editedTaskData && taskId) {
      const task = editedTaskData.getProjectTask;
      task?.items && setTaskItems(task.items);

      task?.comments && setTaskComments(task.comments);

      setTaskData({
        name: task?.name || '',
        description: task?.description || '',
        epicId: task?.epicKey || '',
        assigneeId: task?.assigneeId || '',
        responsibleId: task?.responsibleId || '',
        startDateEstimate: task?.startDateEstimate ? convertISOStringToDateWithOffset(task.startDateEstimate) : null,
        startDateFact: task?.startDateFact ? convertISOStringToDateWithOffset(task?.startDateFact) : null,
        finishDateEstimate: task?.finishDateEstimate
          ? convertISOStringToDateWithOffset(task?.finishDateEstimate)
          : null,
        finishDateFact: task?.finishDateFact ? convertISOStringToDateWithOffset(task?.finishDateFact) : null,
        timeEstimate: task?.timeEstimate || 0,
        timeSpent: task?.timeSpent || 0,
      });
    }
  }, [editedTaskData, taskId]);

  const closeModalAfterSave = (): void => {
    setTaskData(initialTaskData);
    setTaskItems([]);
    setTaskComments([]);
    closeModal();
    setCreatedTaskId('');
  };

  useEffect(() => {
    if (createTaskData || updateTaskData) {
      closeModalAfterSave();
    }
  }, [createTaskData, updateTaskData]);

  useEffect(() => {
    if (createTaskData || updateTaskData) {
      const id = createTaskData ? createTaskData?.createProjectTaskInKanban.id : updateTaskData?.updateProjectTask.id;
      setCreatedTaskId(id);
      getTask({ variables: { id: id } });
    }
  }, [createTaskData, updateTaskData]);

  const getEpicValue = (epics: ProjectStep[]): string => {
    if (taskId) {
      return epics.find(epic => epic.key === taskData.epicId)?.key || '';
    }

    return epics.find(epic => epic.id === taskData.epicId)?.key || '';
  };

  const onSave = () => {
    const data = Object.entries({
      nameError: taskData.name,
      epicIdError: taskData.epicId,
      assigneeIdError: taskData.assigneeId,
      responsibleIdError: taskData.responsibleId,
    });

    data.forEach(([key, value]) => {
      if (!value) {
        setRequiredFieldError(state => ({
          ...state,
          [key]: true,
        }));
      }
    });

    if (data.some(([_, value]) => !value)) {
      return;
    }

    taskId || createdTaskId
      ? updateTask({
          variables: {
            taskData: {
              id: taskId || createdTaskId,
              ...taskData,
              epicId: undefined,
            },
          },
        })
      : createTask({
          variables: {
            taskData,
            workflowStepId,
            workflowStepOrder,
          },
        });
  };

  let contentError: JSX.Element;

  if (projectStepsError || authorError || usersNameError || createTaskError || editedTaskError || updateTaskError) {
    let text: string;
    if (projectStepsError) {
      text = 'Не удалось загрузить данные';
    }

    if (createTaskError) {
      text = 'Ошибка при создании задачи';
    }

    if (updateTaskError) {
      text = 'Ошибка при редактировании задачи';
    }

    contentError = <Snackbar text={text} className="error" />;
  }

  let content: JSX.Element;

  if (
    projectStepsLoading ||
    authorLoading ||
    createTaskLoading ||
    usersNameLoading ||
    updateTaskLoading ||
    editedTaskLoading
  ) {
    content = <LoadingDisplay />;
  }

  if (projectStepsData && usersNameData && authorData) {
    const author = authorData.getUserInfo;
    const epics = projectStepsData.getProjectStepsByProject;
    const users = usersNameData.getUsers.users as User[];

    const creator = editedTaskData?.getProjectTask?.createdBy;
    const labels = editedTaskData?.getProjectTask?.labels;

    content = (
      <ContentContainer>
        <Column>
          <AddTaskSection
            taskId={taskId}
            task={taskData}
            setTaskData={setTaskData}
            epics={epics}
            value={getEpicValue(epics)}
            error={requiredFieldError}
            setError={setRequiredFieldError}
          />
          {(taskId || createdTaskId) && (
            <ChecklistSection
              taskId={taskId || createdTaskId}
              items={taskItems}
              description={editedTaskData?.getProjectTask?.description}
              saveTaskInfo={onSave}
            />
          )}
          {(taskId || createdTaskId) && (
            <CommentSection
              author={author}
              comments={taskComments}
              taskId={taskId || createdTaskId}
              description={editedTaskData?.getProjectTask?.description}
              saveTaskInfo={onSave}
            />
          )}
        </Column>

        <Column>
          <EmployeeSelect
            selectLabel="Ответственный"
            users={users}
            value={taskData.responsibleId}
            taskData={taskData}
            setTaskData={setTaskData}
            field="responsibleId"
            error={requiredFieldError}
            setError={setRequiredFieldError}
          />
          <EmployeeSelect
            selectLabel="Исполнитель"
            users={users}
            value={taskData.assigneeId}
            taskData={taskData}
            setTaskData={setTaskData}
            field="assigneeId"
            error={requiredFieldError}
            setError={setRequiredFieldError}
          />
          <AuthorSection selectLabel="Автор" author={creator} />
          <LabelsSelect label="Метки" labels={labels} projectId={projectId} projectTaskId={taskId || createdTaskId} />
          <DatepickerSelect
            label="Плановая дата начала"
            value={taskData.startDateEstimate}
            taskData={taskData}
            setTaskData={setTaskData}
            field="startDateEstimate"
            maxDate={taskData.finishDateEstimate}
          />
          <DatepickerSelect
            label="Плановая дата конца"
            value={taskData.finishDateEstimate}
            taskData={taskData}
            setTaskData={setTaskData}
            field="finishDateEstimate"
            minDate={taskData.startDateEstimate}
          />
          <DatepickerSelect
            label="Фактическая дата начала"
            value={taskData.startDateFact}
            taskData={taskData}
            setTaskData={setTaskData}
            field="startDateFact"
            maxDate={taskData.finishDateFact}
          />
          <DatepickerSelect
            label="Фактическая дата конца"
            value={taskData.finishDateFact}
            taskData={taskData}
            setTaskData={setTaskData}
            field="finishDateFact"
            minDate={taskData.startDateFact}
          />
          <TimeInput
            label="Длительность план"
            field="timeEstimate"
            value={taskData.timeEstimate}
            taskData={taskData}
            setTaskData={setTaskData}
            unit="ч."
          />
          <TimeInput
            label="Затраченное время"
            field="timeSpent"
            value={taskData.timeSpent}
            taskData={taskData}
            setTaskData={setTaskData}
            unit="ч."
          />
          {/*<RowWithLabel number={+(taskData.timeSpent / 60).toFixed(2)} unit="ч." />*/}
        </Column>
      </ContentContainer>
    );
  }

  return (
    <Portal>
      <div className="modal-container">
        <ModalContainer>
          {contentError}
          <CloseButtonContainer>
            <CloseButton active={true} onClick={() => closeModal()} />
          </CloseButtonContainer>

          {content}

          <ButtonsContainer>
            <ModalButton color="#101010" onClick={() => closeModal()}>
              {t('cancel')}
            </ModalButton>
            <ModalButton color="#FFBB4F" onClick={onSave}>
              {t('save')}
            </ModalButton>
          </ButtonsContainer>
        </ModalContainer>
      </div>
    </Portal>
  );
};
