import React, { useContext } from 'react';

import { ShopTabContext } from '../../ShopTab/ShopTabContext';

import { IProductCardProps } from '../ProductCard';

import styles from '../ProductCard.module.scss';

export const ProductsListProductCard = ({ product, addPurchase }: IProductCardProps) => {
  const { img, img_sm, title, costList, costList_sm, balance, kpi, level, costItem, wrapper, cartBtn, cartBtn_sm } =
    styles;

  const { chosenProduct, choseProductHandler } = useContext(ShopTabContext);

  const { id, type, image, name, flex, requiredKpi, requiredLevel } = product!;

  return (
    <>
      {type?.displayName === 'impression' ? (
        <>
          <img className={img} src={image ? image : ''} alt="product" />

          <h3 className={title}>{name}</h3>

          <div>
            <ul className={costList}>
              <li className={`${costItem} ${balance}`}>{flex + ' Flex'}</li>
              <li className={`${costItem} ${level}`}>{requiredLevel}</li>
              <li className={`${costItem} ${kpi}`}>{requiredKpi}</li>
            </ul>

            <button
              className={cartBtn}
              type="button"
              onClickCapture={e => {
                if (!chosenProduct || chosenProduct.id !== id) {
                  if (addPurchase && choseProductHandler && id !== null) {
                    addPurchase(id);
                    choseProductHandler(id!);
                    e.stopPropagation();
                    return;
                  }
                }

                if (addPurchase && id !== null) {
                  addPurchase(id);
                  e.stopPropagation();
                }
              }}
            ></button>
          </div>
        </>
      ) : (
        <>
          <img className={img_sm} src={image ? image : ''} alt="product" />

          <div className={wrapper}>
            <ul className={costList_sm}>
              <li className={`${costItem} ${balance}`}>{flex + ' Flex'}</li>
              <li className={`${costItem} ${level}`}>{requiredLevel}</li>
              <li className={`${costItem} ${kpi}`}>{requiredKpi}</li>
            </ul>

            <button
              className={cartBtn_sm}
              onClickCapture={e => {
                if (!chosenProduct || chosenProduct.id !== id) {
                  if (addPurchase && choseProductHandler && id !== null) {
                    addPurchase(id);
                    choseProductHandler(id!);
                    e.stopPropagation();
                    return;
                  }
                }

                if (addPurchase && id !== null) {
                  addPurchase(id);
                  e.stopPropagation();
                }
              }}
            ></button>
          </div>
        </>
      )}
    </>
  );
};
