import React from 'react';
import { UserPayment } from '../../generated/graphql';
import classNames from 'classnames';
import { Button } from 'react-bootstrap';
import downloadIcon from '../../accets/icons/download-file-icon.svg';
import './Payment.scss';

interface IPaymentProps {
  payment: UserPayment;
}

export const Payment = ({ payment }: IPaymentProps) => {
  const dataPayment = payment?.createdDate?.split('T')[0];
  const timePayment = payment?.createdDate?.split('T')[1].slice(0, -8);
  const estimateDate = payment?.estimatedDate?.split('T')[0];

  const printStatus = (status: string): string => {
    switch (status) {
      case 'Late':
        return 'Просрочен';
      case 'Paid':
        return 'Оплачен';
      default:
        return 'Ожидается';
    }
  };

  return (
    <>
      <div className="payment w-100">
        <span className="payment-data text-center">{payment.name}</span>
        <span className="payment-data text-center">
          <span className="mr-1">{dataPayment ? dataPayment.split('-').reverse().join('.') : '-'}</span>
          {timePayment ? timePayment : '-'}
        </span>
        <span className="payment-sum text-center">{`${payment.cost} грн.`}</span>
        <span
          className={classNames({
            'payment-status w-100 text-center': true,
            'status-expected': payment.status?.displayName === 'Pending',
            'status-expired': payment.status?.displayName === 'Late',
            'status-paid': payment.status?.displayName === 'Paid',
          })}
        >
          {printStatus(payment.status?.displayName as string)}
          {payment.status?.displayName === 'Pending' && (
            <span className="ml-1"> до {estimateDate.split('-').reverse().join('.')}</span>
          )}
        </span>
        {payment.status?.displayName !== 'Paid' && (
          <span className="d-flex justify-content-center align-items-center">
            <Button
              variant="link"
              className="download-bill-btn position-relative"
              onClick={() => console.log(payment.id)}
            >
              <img className="download-icon" src={downloadIcon} alt="icon" />
            </Button>
          </span>
        )}
      </div>
      <div className="payment-mobile position-relative">
        <div className="data-mobile-container position-absolute d-flex">
          <span className="payment-data payment-mobile-data text-center">
            {dataPayment ? dataPayment.split('-').reverse().join('.') : ''}
          </span>
          <span className="payment-data payment-mobile-data text-center">{timePayment ? timePayment : ''}</span>
        </div>
        <span className="payment-data">{payment.name}</span>
        <div className="payment-sum-container position-relative d-flex justify-content-center">
          <span className="payment-sum text-center">{`${payment.cost} грн.`}</span>
          <Button
            variant="link"
            className="download-bill-btn position-absolute"
            onClick={() => console.log(payment.id)}
          >
            <img className="download-icon" src={downloadIcon} alt="icon" />
          </Button>
        </div>
        <span
          className={classNames({
            'payment-status w-100 text-center': true,
            'status-expected': payment.status?.displayName === 'Pending',
            'status-expired': payment.status?.displayName === 'Late',
            'status-paid': payment.status?.displayName === 'Paid',
          })}
        >
          {printStatus(payment.status?.displayName as string)}
          {payment.status?.displayName === 'Pending' && (
            <span className="ml-1"> до {estimateDate.split('-').reverse().join('.')}</span>
          )}
        </span>
      </div>
    </>
  );
};
