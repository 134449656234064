import styled from 'styled-components';

export const SectionTitleContainer = styled.div`
  position: relative;
`;

export const ButtonContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
`;

export const ButtonsContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const ItemsContainer = styled.div`
  padding: 0 30px;
  display: flex;
  flex-direction: column;
`;

export const ItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

export const Item = styled.div``;

export const ItemInput = styled.input<{ active: boolean }>`
  border: none;
  width: 290px;
  outline: ${({ active }) => (active ? '2px solid #ffbb4f' : 'none')};

  &:disabled {
    background: transparent;
  }
`;

export const ItemCheckbox = styled.input<{ checked: boolean }>`
  margin-right: 10px;
  position: relative;
  width: 15px;
  height: 15px;
  border: none;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    border: 1px solid #ffbb4f;
    width: 15px;
    height: 15px;
    background: ${({ checked }) => (checked ? '#FFBB4F' : '#fff')};
  }

  &:disabled {
    background: #fff;
  }
`;

export const ItemButtonsContainer = styled.div``;
