import React, { useContext } from 'react';
import { GreetingUser } from '../../components/GreetingUser';
import { UserInfoContext } from '../Main/Main';

export const GreetingPage = () => {
  const user = useContext(UserInfoContext);

  return (
    <div className="d-flex justify-content-center" style={{ height: 'calc(100% - 110px)' }}>
      <div className="page-container w-100 d-flex justify-content-center">
        <GreetingUser firstName={user?.firstName as string} lastName={user?.lastName as string} />
      </div>
    </div>
  );
};
