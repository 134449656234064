import React from 'react';
import { useMutation } from '@apollo/client';
import {
  AssignProjectTaskLabelDocument,
  AssignProjectTaskLabelMutation,
  GetAvailableProjectTaskLabelsDocument,
  GetProjectTaskDocument,
  ProjectTaskLabel,
} from '../../../../../generated/graphql';
import { Snackbar } from '../../../../../components/Snackbar';
import { LoadingDisplay } from '../../../../../components/LoadingDisplay';
import { CustomOption, OptionListContainer } from './OptionList.style';

interface OptionListProps {
  list: ProjectTaskLabel[];
  projectId: string;
  projectTaskId: string;
  setIsOptionList(state: boolean): void;
}
export const OptionList = ({ list, projectId, projectTaskId, setIsOptionList }: OptionListProps) => {
  const [addLabel, { data: addLabelData, loading: addLabelLoading, error: addLabelError }] =
    useMutation<AssignProjectTaskLabelMutation>(AssignProjectTaskLabelDocument, {
      refetchQueries: [
        {
          query: GetAvailableProjectTaskLabelsDocument,
          variables: {
            projectId,
            projectTaskId,
          },
        },
        {
          query: GetProjectTaskDocument,
          variables: {
            id: projectTaskId,
          },
        },
      ],
    });

  if (addLabelLoading) {
    return <LoadingDisplay />;
  }

  if (addLabelData) {
    setIsOptionList(false);
  }

  return (
    <OptionListContainer>
      {list.map(item => (
        <CustomOption
          key={item.id}
          onClick={() => {
            addLabel({
              variables: {
                projectTaskId,
                labelId: item.id,
              },
            });
          }}
        >
          {item.name}
        </CustomOption>
      ))}

      {addLabelError && <Snackbar text="При добавлении метки произошла ошибка" />}
    </OptionListContainer>
  );
};
