import React from 'react';
import { TransparentButton } from './TransparentButton';
import addFileIcon from '../../accets/icons/add-file-icon.svg';
import addFileIconDisabled from '../../accets/icons/addFileIconDisabled.svg';
import styled from 'styled-components';

export const ButtonIcon = styled.img<{ size?: number }>`
  width: ${props => (props?.size ? `${props.size}px` : 'inherit')};
`;

export const AddFileButton = ({
  onClick,
  size,
  active,
}: {
  onClick(): void;
  size?: number;
  active?: boolean;
}): JSX.Element => {
  return (
    <TransparentButton onClick={onClick}>
      <ButtonIcon size={size} src={active ? addFileIcon : addFileIconDisabled} alt="icon" />
    </TransparentButton>
  );
};
