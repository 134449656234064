import React, { useContext } from 'react';

import { ShopTabContext } from '../../ShopTab/ShopTabContext';

import { ProductCard } from '../../ProductCard';

import styles from '../Modal.module.scss';

export const AddToCartModal = () => {
  const { txt, card, card_sm } = styles;

  const { chosenProduct } = useContext(ShopTabContext);

  return (
    <>
      {chosenProduct && (
        <>
          <div className={chosenProduct.type?.displayName === 'impression' ? card : card_sm}>
            <ProductCard role="modal" product={chosenProduct} />
          </div>
          <p className={txt}>Товар успешно добавлен в корзину!</p>
        </>
      )}
    </>
  );
};
