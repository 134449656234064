import React from 'react';

export const Info = () => {
  return (
    <>
      <div className="page-container">
        <h1 className="text-center">Здесь будет информация о компании</h1>
      </div>
    </>
  );
};
