import React from 'react';
import './YoutubeEmbed.scss';

export const YoutubeEmbed = ({ url }: { url: string }) => {
  const embedId = url.split('v=')[1];

  return (
    <div className="video-responsive">
      <iframe
        width="853"
        height="480"
        src={`https://www.youtube.com/embed/${embedId}?rel=0&amp;`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </div>
  );
};
