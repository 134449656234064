import { gql } from '@apollo/client';

export const CREATE_PURCHASE = gql`
  mutation createPurchase($productId: String!) {
    createPurchase(productId: $productId) {
      id
      status {
        status
      }
    }
  }
`;

export const DELETE_PURCHASE = gql`
  mutation deletePurchase($purchaseId: String!) {
    deletePurchase(purchaseId: $purchaseId)
  }
`;

export const CONFIRM_PURCHASES = gql`
  mutation confirmPurchase {
    confirmPurchase
  }
`;
