import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form } from 'react-bootstrap';
import classNames from 'classnames/bind';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { Box } from '@material-ui/core';
import { VisibilityButton } from '../../constants/Styles/VisibilityButton';
import { isValidPhoneNumber } from 'react-phone-number-input';
import './FormLoginRegistration.scss';

interface IFormLoginRegistrationProps {
  formik: any;
  registration?: boolean;
  passwordRecovery?: boolean;
  passwordUpdate?: boolean;
}

export const FormLoginRegistration = ({
  formik,
  registration,
  passwordRecovery,
  passwordUpdate,
}: IFormLoginRegistrationProps) => {
  const [t] = useTranslation('translation');
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [visiblePassword, setVisiblePassword] = useState(false);

  return (
    <Form
      className={classNames({
        'sing-in-form position-relative': true,
        'modal-form': passwordRecovery,
      })}
      onSubmit={formik.handleSubmit}
    >
      <div
        className={classNames({
          'mb-0': passwordRecovery,
          'form-group-container': true,
        })}
      >
        {registration && (
          <>
            <Form.Group className="form-group position-relative">
              <Form.Label className="form-label" style={{ marginBottom: '10px' }} htmlFor="formBasicFirstName">
                {t('name')}
              </Form.Label>
              <Form.Control
                className="form-control"
                id="formBasicFirstName"
                name="firstName"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.firstName}
              />
              {formik.errors.firstName && <Form.Text className="form-error">{formik.errors.firstName}</Form.Text>}
            </Form.Group>

            <Form.Group className="form-group position-relative">
              <Form.Label className="form-label" style={{ marginBottom: '10px' }} htmlFor="formBasicLastName">
                {t('surname')}
              </Form.Label>
              <Form.Control
                className="form-control"
                id="formBasicLastName"
                name="lastName"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lastName}
              />
              {formik.errors.lastName && <Form.Text className="form-error">{formik.errors.lastName}</Form.Text>}
            </Form.Group>

            <Form.Group className="form-group position-relative">
              <Form.Label className="form-label" style={{ marginBottom: '10px' }} htmlFor="formBasicPhone">
                телефон
              </Form.Label>
              <PhoneInput
                className="form-control"
                international
                countryCallingCodeEditable={false}
                defaultCountry="UA"
                value={formik.values.phone}
                onChange={number => {
                  formik.setFieldValue('phone', number);
                }}
              />
              {formik.errors.phone && <Form.Text className="form-error">{formik.errors.phone}</Form.Text>}
            </Form.Group>
          </>
        )}

        {!passwordUpdate && (
          <Form.Group className="form-group position-relative">
            <Form.Label className="form-label" style={{ marginBottom: '10px' }} htmlFor="formBasicEmail">
              Email
            </Form.Label>
            <Form.Control
              className="form-control"
              id="formBasicEmail"
              name="email"
              type="email"
              onChange={formik.handleChange}
              value={formik.values.email}
              ref={inputRef}
            />

            {formik.errors.email && <Form.Text className="form-error">{formik.errors.email}</Form.Text>}

            {passwordRecovery && (
              <div className="d-flex justify-content-center pt-5">
                <Button className="send-password-btn" type="submit">
                  {t('send')}
                </Button>
              </div>
            )}
          </Form.Group>
        )}

        {!passwordRecovery && (
          <Form.Group className="form-group position-relative">
            <Form.Label className="form-label" htmlFor="formBasicPassword" style={{ marginBottom: '10px' }}>
              {t('password')}
            </Form.Label>
            <Form.Control
              className="form-control"
              type={visiblePassword ? 'text' : 'password'}
              id="formBasicPassword"
              name="password"
              onChange={formik.handleChange}
              value={formik.values.password || ''}
              ref={inputRef}
            />
            <Box position="absolute" right={7} top={32}>
              <VisibilityButton
                active={visiblePassword}
                show={() => setVisiblePassword(true)}
                hide={() => setVisiblePassword(false)}
              />
            </Box>
            {formik.errors.password && <Form.Text className="form-error">{formik.errors.password}</Form.Text>}

            {!registration && !passwordUpdate && (
              <div className="w-100 text-center mt-4">
                <Button className="password-reminder-link" variant="link" href="/password-recovery">
                  {t('password reminder')}
                </Button>
              </div>
            )}
          </Form.Group>
        )}

        {(registration || passwordUpdate) && (
          <Form.Group className="form-group position-relative">
            <Form.Label className="form-label" htmlFor="formBasicConfirmPassword" style={{ marginBottom: '10px' }}>
              {t('password confirmation')}
            </Form.Label>
            <Form.Control
              className="form-control"
              type={visiblePassword ? 'text' : 'password'}
              id="formBasicConfirmPassword"
              name="confirmPassword"
              onChange={formik.handleChange}
              value={formik.values.confirmPassword || ''}
            />
            {formik.errors.confirmPassword && (
              <Form.Text className="form-error">{formik.errors.confirmPassword}</Form.Text>
            )}
          </Form.Group>
        )}
      </div>

      <div className="sign-in-control">
        {passwordRecovery || passwordUpdate ? null : registration ? (
          <>
            <Button className="sign-in-link" variant="link" href="/login">
              {t('signin')}
            </Button>
            <Button className="sing-in-btn" type="submit">
              {t('register')}
            </Button>
          </>
        ) : (
          <>
            <Button className="sign-in-link" variant="link" href="/registration">
              {t('register')}
            </Button>
            <Button className="sing-in-btn" type="submit">
              {t('signin')}
            </Button>
          </>
        )}

        {passwordUpdate && (
          <div className="d-flex justify-content-center w-100">
            <Button className="sing-in-btn" type="submit">
              {t('save')}
            </Button>
          </div>
        )}
      </div>
    </Form>
  );
};
