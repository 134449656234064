import React from 'react';
import './Snackbar.scss';

interface SnackbarProps {
  text: string;
  className?: string;
}

export const Snackbar = ({ text, className }: SnackbarProps) => {
  return (
    <div className={`position-fixed d-flex justify-content-end ${className}`} style={{ right: '50px', top: '50px' }}>
      <div>{text}</div>
    </div>
  );
};
