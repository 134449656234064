import React, { useContext } from 'react';

import { Switch, Route, Link } from 'react-router-dom';

import { IShopTabContext, ShopTabContext } from '../ShopTabContext';

import { ProductPanel } from '../../ProductPanel';
import { ProductsList } from '../../ProductsList';

import { IShopTabInnerProps } from '../ShopTab';

import styles from './PersonalShopTab.module.scss';

export const PersonalShopTab = ({ addPurchase, deletePurchase }: IShopTabInnerProps) => {
  const { body, cartBtn, backBtn, purchasesBtn } = styles;

  const { setChosenProduct, setChosenPurchase }: IShopTabContext = useContext(ShopTabContext);

  return (
    <div className={body}>
      <Switch>
        <Route path={'/gamification/shop/personal/shoppingCart'}>
          <div>
            <Link className={backBtn} to={'/gamification/shop/personal'} onClick={() => setChosenProduct(undefined)} />
            <Link
              className={purchasesBtn}
              to={'/gamification/shop/personal/purchase'}
              onClick={() => setChosenPurchase(undefined)}
            />
          </div>

          <ProductPanel role="shoppingCart" />

          <ProductsList role="shoppingCart" deletePurchase={deletePurchase} />
        </Route>

        <Route path={'/gamification/shop/personal/purchase'}>
          <div>
            <Link className={backBtn} to={'/gamification/shop/personal'} onClick={() => setChosenProduct(undefined)} />
            <Link
              className={cartBtn}
              to={'/gamification/shop/personal/shoppingCart'}
              onClick={() => setChosenPurchase(undefined)}
            />
          </div>

          <ProductPanel role="purchase" />

          <ProductsList role="purchase" />
        </Route>

        <Route path={'/gamification/shop/personal'}>
          <div>
            <Link
              className={cartBtn}
              to={'/gamification/shop/personal/shoppingCart'}
              onClick={() => setChosenPurchase(undefined)}
            />
            <Link
              className={purchasesBtn}
              to={'/gamification/shop/personal/purchase'}
              onClick={() => setChosenPurchase(undefined)}
            />
          </div>

          <ProductPanel addPurchase={addPurchase} />

          <ProductsList addPurchase={addPurchase} />
        </Route>
      </Switch>
    </div>
  );
};
