import { Task } from 'gantt-task-react';

import { TaskType } from 'gantt-task-react/dist/types/public-types';
import { ChartData2 } from '../generated/graphql';

interface InitTasksProps {
  epics: ChartData2[];
  tasks: ChartData2[] | [];
}

export const initTasks = ({ epics, tasks }: InitTasksProps) => {
  const formattedEpics = epics.map(epic => ({
    start: new Date(epic.start),
    end: new Date(epic.end),
    name: epic.name,
    id: epic.id,
    progress: epic.progress,
    type: epic.type as TaskType,
    hideChildren: true,
  }));
  const formattedTasks: Task[] = tasks.map(task => ({
    project: task.project,
    name: task.name,
    id: task.id,
    progress: task.progress,
    type: task.type as TaskType,
    start: new Date(task.start),
    end: new Date(task.end),
    styles: {
      backgroundColor: task.isClient || task.isAdditionalTask ? '#F8CBCB' : 'rgba(255, 182, 29, 0.5)',
      progressColor: task.isClient || task.isAdditionalTask ? '#F58D8D' : '#f7bb53',
      backgroundSelectedColor: task.isClient || task.isAdditionalTask ? '#F8CBCB' : 'rgba(255, 182, 29, 0.5)',
      progressSelectedColor: task.isClient || task.isAdditionalTask ? '#F58D8D' : '#f7bb53',
    },
  }));

  const tasksForGantt: Task[] = [];

  for (let i = 0; i < formattedEpics.length; i++) {
    const id = formattedEpics[i].id;
    tasksForGantt.push(formattedEpics[i]);

    for (let j = 0; j < formattedTasks.length; j++) {
      const project = formattedTasks[j].project;

      if (id === project) {
        tasksForGantt.push(formattedTasks[j]);
      }
    }
  }

  return tasksForGantt;
};

export const getStartEndDateForProject = (tasks: Task[], projectId: string) => {
  const projectTasks = tasks.filter(t => t.project === projectId);
  let start = projectTasks[0].start;
  let end = projectTasks[0].end;

  for (let i = 0; i < projectTasks.length; i++) {
    const task = projectTasks[i];

    if (start.getTime() > task.start.getTime()) {
      start = task.start;
    }

    if (end.getTime() < task.end.getTime()) {
      end = task.end;
    }
  }

  return [start, end];
};
