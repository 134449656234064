import styled from 'styled-components';

export const ColumnContainer = styled.div`
  min-width: 265px;
  margin-right: 30px;
  min-height: 300px;

  &:last-of-type {
    margin-right: 0;
  }
`;

export const Container = styled.div`
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  height: fit-content;
  background: #eeeeee;
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.1), -1px -1px 4px 1px rgba(232, 232, 232, 0.1);
  margin-right: 30px;
  margin-bottom: 30px;
  min-height: 100px;

  &:nth-child(2) {
    margin-right: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }

  @media (min-width: 769px) {
    margin-bottom: 0;
    &:nth-child(2) {
      margin-right: 30px;
    }
  }
`;

export const ColumnTitle = styled.h3`
  background: #000;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #fff;
  padding: 15px 20px;
  border-radius: 10px 10px 0 0;
  margin-bottom: 0;
`;

export const TaskList = styled.div<{ isDraggingOver: boolean }>`
  display: flex;
  flex-direction: column;
  background: ${({ isDraggingOver }) => (isDraggingOver ? `#fff` : '#eee')};
  transition: background-color 0.2s ease;
  flex-grow: 1;
  max-height: 40vh;
  overflow-y: auto;
`;

export const AddTaskButton = styled.button`
  border-radius: 0 0 10px 10px;
  background: #ffbb4f;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  padding: 15px 20px;
  text-align: start;
  border: none;

  &:first-letter {
    text-transform: uppercase;
  }
`;
