import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import {
  GetProjectDocument,
  GetProjectQuery,
  // GetProjectWithJiraDataDocument,
  // GetProjectWithJiraDataQuery,
  Project,
  ProjectLink,
  User,
} from '../../generated/graphql';
import Carousel from 'react-multi-carousel';
import { ProjectLinkSection } from '../../components/ProjectLinkSection/ProjectLinkSection';
import { ErrorDisplay } from '../../components/ErrorDisplay';
import { LoadingDisplay } from '../../components/LoadingDisplay';
import { responsive } from '../../constants/responsiveCarousel';
import arrow from '../../accets/icons/black-arrow.svg';
import designerPhoto from '../../accets/icons/defaultUserPhoto.svg';
import defaultUserPhoto from '../../accets/icons/defaultUserPhoto.svg';
import viberIcon from '../../accets/icons/viber-icon.svg';
import telegramIcon from '../../accets/icons/telegram-icon.svg';
import 'react-multi-carousel/lib/styles.css';
import { NavBar } from '../../components/NavBar';
import { UserInfoContext } from '../Main/Main';
import { ProjectRequiredLinkSection } from '../../components/ProjectRequiredLinkSection';
import './ProjectInformation.scss';

const ButtonGroup = ({ next, previous, goToSlide, ...rest }: any) => {
  const {
    carouselState: { currentSlide },
  } = rest;

  return (
    <div className="carousel-button-group  position-relative">
      {currentSlide !== 3 && (
        <button className="left-arrow" onClick={() => previous()}>
          <img className="control-arrow-left" src={arrow} alt="icon" />
        </button>
      )}
      {currentSlide !== 9 && (
        <button className="right-arrow" onClick={() => goToSlide(currentSlide + 1)}>
          <img className="control-arrow" src={arrow} alt="icon" />
        </button>
      )}
    </div>
  );
};

export const ProjectInformation = () => {
  const [t] = useTranslation('translation');
  const { projectId } = useParams<{ projectId: string }>();
  const { role } = useContext<User>(UserInfoContext);

  const {
    data: projectData,
    loading,
    error,
  } = useQuery<GetProjectQuery>(GetProjectDocument, {
    variables: {
      projectId,
    },
  });

  if (loading) {
    return <LoadingDisplay />;
  }

  if (error) {
    return <ErrorDisplay message={error.message} />;
  }

  let content: JSX.Element;

  if (projectData) {
    const project = projectData.getProject;
    const contacts = project.contactPersons || [];
    const contactPersons = [...contacts];
    const projectLinks = project?.links as ProjectLink[];

    content = (
      <>
        {' '}
        {project?.employees?.length !== 0 && (
          <>
            <h4 className="project-info-subtitle">{`${t('your project is being worked on')} :`}</h4>
            <div className="employees-list">
              {project?.employees && project.employees.length >= 5 ? (
                <Carousel
                  swipeable={true}
                  draggable={false}
                  arrows={false}
                  renderButtonGroupOutside={true}
                  customButtonGroup={<ButtonGroup />}
                  responsive={responsive}
                  infinite={true}
                  customTransition="all .5"
                  transitionDuration={500}
                  minimumTouchDrag={50}
                  focusOnSelect={true}
                  containerClass="carousel-container"
                  removeArrowOnDeviceType={['tablet', 'mobile']}
                  itemClass="carousel-item-padding-40-px"
                  centerMode={false}
                >
                  {project.employees?.map((employee, i) => (
                    <div className="employee-container d-flex" key={i}>
                      <img className="employee-photo" src={employee?.photo || designerPhoto} alt="employee photo" />
                      <div
                        className="d-flex flex-column align-items-center justify-content-start"
                        style={{ height: '57px' }}
                      >
                        <span className="employee-name">{employee?.firstName}</span>
                        <span className="employee-position text-center">{employee?.job?.name}</span>
                      </div>
                    </div>
                  ))}
                </Carousel>
              ) : (
                <div className="d-flex justify-content-center">
                  {project.employees?.map((employee, i) => (
                    <div className="employee-container d-flex" key={i}>
                      <img className="employee-photo" src={employee?.photo || designerPhoto} alt=" " />
                      <div className="d-flex flex-column align-items-center justify-content-center">
                        <span className="employee-name">{employee?.firstName}</span>
                        <span className="employee-position">{employee?.job?.name}</span>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </>
        )}
        {contactPersons && (
          <>
            <h4 className="project-info-subtitle">{`${t(
              'contact information for all questions about the project',
            )} :`}</h4>
            {/*<div className="employees-list sales">*/}
            {/*  /!*{project?.projectManager && (*!/*/}
            {/*  /!*  <div className="employees-list-item d-flex justify-content-center product-area">*!/*/}
            {/*  /!*    <div className="employee-container contact-staff-container d-flex justify-content-between  position-relative">*!/*/}
            {/*  /!*      <div className="d-flex">*!/*/}
            {/*  /!*        <img*!/*/}
            {/*  /!*          className="employee-photo"*!/*/}
            {/*  /!*          src={project?.projectManager?.photo || defaultUserPhoto}*!/*/}
            {/*  /!*          alt="employee photo"*!/*/}
            {/*  /!*        />*!/*/}
            {/*  /!*        <div className="d-flex flex-column align-items-start justify-content-center">*!/*/}
            {/*  /!*          <span className="employee-name">{project?.projectManager?.firstName}</span>*!/*/}
            {/*  /!*          <span className="employee-position sales-position">{project?.projectManager?.position}</span>*!/*/}
            {/*  /!*        </div>*!/*/}
            {/*  /!*      </div>*!/*/}

            {/*  /!*      <div className="d-flex flex-column align-items-start justify-content-center">*!/*/}
            {/*  /!*        <span className="employee-name">{project?.projectManager?.phone}</span>*!/*/}
            {/*  /!*        <span className="d-flex justify-content-start">*!/*/}
            {/*  /!*          <img className="employee-social-icon" src={telegramIcon} alt="icon" />*!/*/}
            {/*  /!*          <img className="employee-social-icon" src={viberIcon} alt="icon" />*!/*/}
            {/*  /!*        </span>*!/*/}
            {/*  /!*      </div>*!/*/}
            {/*  /!*    </div>*!/*/}
            {/*  /!*  </div>*!/*/}
            {/*  /!*)}*!/*/}
            {/*</div>*/}
            <div className="contact-persons-list">
              {project.projectManager && (
                <div className="contact-persons-list-item d-flex justify-content-center account-area">
                  <div className="employee-container contact-staff-container d-flex justify-content-between  position-relative">
                    <div className="d-flex">
                      <img className="employee-photo" src={project.projectManager?.photo || defaultUserPhoto} alt=" " />
                      <div className="d-flex flex-column align-items-start justify-content-center">
                        <span className="employee-name">{project.projectManager?.firstName}</span>
                        <span className="employee-position sales-position">{project.projectManager?.job?.name}</span>
                      </div>
                    </div>
                    <div className="d-flex flex-column align-items-start justify-content-center">
                      <span className="employee-name">{project.projectManager?.phone}</span>
                      <span className="d-flex justify-content-start">
                        <img className="employee-social-icon" src={telegramIcon} alt="icon" />
                        <img className="employee-social-icon" src={viberIcon} alt="icon" />
                      </span>
                    </div>
                  </div>
                </div>
              )}
              {contactPersons &&
                contactPersons.map((user, i) => (
                  <div key={i} className="contact-persons-list-item d-flex justify-content-center account-area">
                    <div className="employee-container contact-staff-container d-flex justify-content-between  position-relative">
                      <div className="d-flex">
                        <img className="employee-photo" src={user?.photo || defaultUserPhoto} alt=" " />
                        <div className="d-flex flex-column align-items-start justify-content-center">
                          <span className="employee-name">{user?.firstName}</span>
                          <span className="employee-position sales-position">{user?.job?.name}</span>
                        </div>
                      </div>
                      <div className="d-flex flex-column align-items-start justify-content-center">
                        <span className="employee-name">{user?.phone}</span>
                        <span className="d-flex justify-content-start">
                          <img className="employee-social-icon" src={telegramIcon} alt="icon" />
                          <img className="employee-social-icon" src={viberIcon} alt="icon" />
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </>
        )}
        {projectLinks?.map(link => (
          <ProjectLinkSection key={link.id} projectLink={link} />
        ))}
        <div className="required-links-container">
          {project.blockDiagrams && (
            <ProjectRequiredLinkSection projectLink={{ title: 'Блок-схему', link: project.blockDiagrams }} />
          )}
          {project.brief && <ProjectRequiredLinkSection projectLink={{ title: 'Бриф', link: project.brief }} />}
          {project.contractTemplates && (
            <ProjectRequiredLinkSection
              projectLink={{ title: 'Техническое задание', link: project.contractTemplates }}
            />
          )}
          {project.presentations.length &&
            project.presentations.map(presentation => (
              <ProjectRequiredLinkSection
                key={presentation.id}
                projectLink={{ title: presentation.title, link: presentation.link }}
              />
            ))}

          {project.designFigma && (
            <ProjectRequiredLinkSection projectLink={{ title: 'Дизайн', link: project.designFigma }} />
          )}
        </div>
      </>
    );
  }

  return (
    <div className="page-container">
      {role?.id !== 3 && <NavBar />}
      <h2 className="project-info-title text-center">
        {projectData?.getProject.name ? `${projectData?.getProject.name}` : 'у вас нет проекта или выбирите проект'}
      </h2>
      {content}
    </div>
  );
};
