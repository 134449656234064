import styled from 'styled-components';

export const AddFileButtonContainer = styled.div`
  position: absolute;
  bottom: 10px;
  right: 4px;
`;

export const AddFileButtonIcon = styled.img`
  width: 10px;
`;

export const FileNameSection = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
`;

export const FileName = styled.span``;

export const AttachmentSection = styled.div``;

export const AttachmentImageContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
`;

export const AttachmentImgContainerModal = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 400;
`;

export const AttachmentImgContainer = styled.div`
  width: 600px;
  padding: 60px 30px 30px 30px;
  border-radius: 10px;
  background: #fff;
  position: relative;
`;

export const ButtonContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
`;

export const AttachmentImg = styled.img`
  width: 100%;
  height: auto;
`;

export const DeleteAttachmentImageButton = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
`;

export const AttachmentImage = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 10px;
  justify-self: center;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 20px;
`;

export const HiddenInput = styled.input`
  visibility: hidden;
  height: 0;
  width: 0;
  display: inline;
`;

export const CommentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  position: relative;
  border: 1px solid #828282;
  border-radius: 10px;
  padding: 10px;
`;

export const CommentContent = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const AuthorAvatar = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 10px;
  margin-right: 20px;
`;

export const CommentTextArea = styled.textarea<{ active: boolean }>`
  border: none;
  width: 280px;
  min-height: 50px;
  outline: ${({ active }) => (active ? '2px solid #ffbb4f' : 'none')};
  resize: none;

  &:disabled {
    background: transparent;
  }
`;
