import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { Redirect } from 'react-router';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { CreateUserDocument, CreateUserMutation, IsFromEnum } from '../../generated/graphql';
import { Logo } from '../../components/Logo';
import { FormLoginRegistration } from '../../components/FormLoginRegistration';
import { Snackbar } from '../../components/Snackbar';
import { LoadingDisplay } from '../../components/LoadingDisplay';
import { Col, Row } from 'react-bootstrap';
import '../SignIn/SignIn.scss';

interface IInitialValues {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  password: string;
  isFrom: string;
}

const initialValues: IInitialValues = {
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  password: '',
  isFrom: '',
};

export const SignUp = () => {
  const [t] = useTranslation('translation');
  const [createUser, { data, loading, error }] = useMutation<CreateUserMutation>(CreateUserDocument);

  const phoneRegExp = /^[\\+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{6}$/im;
  const passwordRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object({
      firstName: Yup.string().min(2, t('invalid name')).required(t('required')),
      lastName: Yup.string().min(2, t('invalid surname')).required(t('required')),
      phone: Yup.string().matches(phoneRegExp, t('invalid phone')).max(14, 'long').required(t('required')),
      email: Yup.string().email(t('invalid email')).required(t('required')),
      password: Yup.string().matches(passwordRegExp, t('invalid password')).required(t('required')),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password')], t('passwords do not match'))
        .required(t('required')),
    }),

    onSubmit: async values => {
      const userData = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phone: values.phone,
        password: values.password,
      };
      await createUser({
        variables: {
          userData,
          isFrom: IsFromEnum.LkStaff,
        },
      });
    },
  });

  if (data) {
    return <Redirect to="/welcome" />;
  }

  if (loading) {
    return <LoadingDisplay />;
  }

  return (
    <>
      <Row className="h-100vh">
        <Col className="left-section p-0  d-none d-md-flex  align-items-center justify-content-center">
          <div className="d-block d-lg-none">
            <Logo logoFontSize={55} typeLogo="mainLogo" />
          </div>
          <div className="d-none d-lg-block">
            <Logo logoFontSize={75} typeLogo="mainLogo" />
          </div>
        </Col>

        <Col className="right-section p-0 d-flex flex-column justify-content-center align-items-center">
          <div className="d-block d-md-none">
            <Logo logoFontSize={30} typeLogo="mainLogo" />
          </div>
          <div className="position-relative mb-5">
            <FormLoginRegistration formik={formik} registration />
            {error && <Snackbar text="Логин уже занят" className="authorizationError" />}
          </div>
        </Col>
      </Row>
    </>
  );
};
