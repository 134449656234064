import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { User } from '../../generated/graphql';
import { Logo } from '../Logo';
import { Button } from 'react-bootstrap';
import bellIcon from '../../accets/icons/bell-icon.svg';
import userPhoto from '../../accets/icons/defaultUserPhoto.svg';
import blackArrowIcon from '../../accets/icons/black-arrow.svg';
import gearIcon from '../../accets/icons/gear-icon.svg';
import burgerMenuIcon from '../../accets/icons/burger-menu-icon.svg';
import closeIcon from '../../accets/icons/close-icon.svg';
import arrowBackIcon from '../../accets/icons/arrow-back-icon.svg';
import { ModalNotifications } from '../ModalNotifications';
import './Header.scss';
import { headerBtnBackContext, UserInfoContext } from '../../pages/Main/Main';
import { ModalNotificationsWithScroll } from '../ModalNotificationsWithScroll';

interface HeaderProps {
  isSideBarOpen?: boolean;
  isProfile: boolean;
  setIsSideBarOpen?(isOpen: boolean): void;
  setIsProfile(isProfile: boolean): void;
}

export const Header = ({ isSideBarOpen, setIsSideBarOpen, isProfile, setIsProfile }: HeaderProps) => {
  const [t] = useTranslation('translation');
  const [isNotification, setIsNotification] = useState(false);
  const userContext: User = useContext(UserInfoContext);
  const [isBackBtn, setIsBackBtn] = useContext<any>(headerBtnBackContext);

  const { firstName, lastName, photo } = userContext;

  const closeNotification = (event: React.SyntheticEvent<HTMLDivElement>): void => {
    event.stopPropagation();
    setIsNotification(false);
  };

  return (
    <div className="header-container d-flex align-items-center w-100 mb-4 position-relative">
      {isBackBtn && (
        <Button className="burger-btn d-flex d-lg-none" onClick={() => setIsBackBtn(false)}>
          <img className="burger-icon" src={arrowBackIcon} alt="icon" />
        </Button>
      )}
      {!isBackBtn &&
        (isSideBarOpen ? (
          <Button className="burger-btn d-flex d-lg-none" onClick={() => setIsSideBarOpen && setIsSideBarOpen(false)}>
            <img className="burger-icon" src={closeIcon} alt="icon" />
          </Button>
        ) : (
          <Button className="burger-btn d-flex d-lg-none" onClick={() => setIsSideBarOpen && setIsSideBarOpen(true)}>
            <img className="burger-icon" src={burgerMenuIcon} alt="icon" />
          </Button>
        ))}

      <div className="d-block d-lg-none">
        <Logo logoFontSize={20} typeLogo="smallLogo" />
      </div>
      {/*<Link*/}
      {/*  to="/gamification"*/}
      {/*  className="gaming-btn position-relative d-none d-lg-flex justify-content-center align-items-center"*/}
      {/*>*/}
      {/*  <span>Gaming</span>*/}
      {/*</Link>*/}
      <Button
        className="notification-btn  position-relative d-none d-lg-flex justify-content-center align-items-center"
        onClick={() => setIsNotification(true)}
      >
        <img src={bellIcon} alt="icon" />
        {/*<span className="position-absolute circle-notification" />*/}
      </Button>
      <Button className="user-data-btn position-relative d-flex" onClick={() => setIsProfile(!isProfile)}>
        <div className="user-photo-container">
          <img className="user-photo" src={photo || userPhoto} alt="user photo" />
        </div>
        <div className="btn-title-container d-none d-lg-flex flex-column justify-content-center">
          <span className="user-name">{`${lastName} ${firstName}`}</span>
          <span className="btn-title d-inline-block">{t('private office')}</span>
        </div>
        <img className="btn-icon position-absolute d-none d-lg-block" src={blackArrowIcon} alt="icon" />
        <img className="gear-icon position-absolute d-block d-lg-none" src={gearIcon} alt="icon" />
      </Button>
      {isNotification && <ModalNotifications onClick={closeNotification} />}
      {/*{isNotification && <ModalNotificationsWithScroll />}*/}
    </div>
  );
};
