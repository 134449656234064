import React, { useState } from 'react';

import { KnowledgeBaseList } from '../../components/KnowledgeBaseList';

import { PageContainer } from '../../constants/Styles/generalPageStyle';

import { Box } from '@material-ui/core';

export const KnowledgeBase = () => {
  const [categoryId, setCategoryId] = useState('');

  return (
    <PageContainer>
      <Box width="100%" display="flex" justifyContent="space-between">
        <KnowledgeBaseList isTheme={false} categoryId={categoryId} setCategoryId={setCategoryId} />

        <KnowledgeBaseList isTheme={true} categoryId={categoryId} setCategoryId={setCategoryId} />
      </Box>
    </PageContainer>
  );
};
