import React from 'react';
import FRLogo from '../../accets/icons/FR-logo-icon.svg';
import { classNames } from '../../constants/classNames';
import './Logo.scss';

interface LogoProps {
  logoFontSize: number;
  typeLogo: string;
}

export const Logo = ({ logoFontSize, typeLogo }: LogoProps) => {
  return (
    <div
      className={classNames({
        'd-flex flex-column-reverse align-items-center': typeLogo === 'mainLogo',
        'd-flex justify-content-center align-items-center': typeLogo === 'smallLogo',
      })}
    >
      <img
        className={classNames({
          'logo-img': typeLogo === 'mainLogo',
          'small-logo-img': typeLogo === 'smallLogo',
        })}
        src={FRLogo}
        alt="logo"
      />
      <h1
        className={classNames({
          'logo-title': typeLogo === 'mainLogo',
          'small-logo-title': typeLogo === 'smallLogo',
        })}
      >
        <span className="first-word" style={{ fontSize: `${logoFontSize}px` }}>
          Flex
        </span>
        <span className="second-word" style={{ fontSize: `${logoFontSize}px` }}>
          Reality
        </span>
      </h1>
    </div>
  );
};
