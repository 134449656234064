import React, { createContext, Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { PrivateRoute } from '../../routes/PrivatesRoutes/PrivatesRoute';
import { GetUserInfoDocument, GetUserInfoQuery, User } from '../../generated/graphql';
import { Redirect, Switch, useHistory } from 'react-router-dom';
import { Layout } from '../../components/Layout';
import { routes } from '../../routes/routes';
import { useQuery } from '@apollo/client';
import { Spinner } from 'react-bootstrap';

export const UserInfoContext = createContext<User>({} as User);
export const headerBtnBackContext = createContext<(boolean | Dispatch<SetStateAction<boolean>>)[] | null>(null);

export const Main = () => {
  const history = useHistory();
  const { data, loading } = useQuery<GetUserInfoQuery>(GetUserInfoDocument);
  const [isBackBtn, setIsBackBtn] = useState<boolean>(false);

  const user = data?.getUserInfo as User;

  useEffect(() => {
    if (user?.role?.id === 3) {
      history.push('/news');
    }
  }, [user]);

  if (loading) {
    return (
      <div className="w-100 h-100vh d-flex justify-content-center align-items-center">
        <Spinner animation="grow" />
        <span>Подождите...</span>
      </div>
    );
  }

  if (!data || !data.getUserInfo) {
    return <Redirect to="/login" />;
  }

  return (
    <UserInfoContext.Provider value={user}>
      <headerBtnBackContext.Provider value={[isBackBtn, setIsBackBtn]}>
        <Layout>
          <Switch>
            {routes.map(route => (
              <PrivateRoute key={route.id} component={route.component} path={route.path} exact={route.exact} />
            ))}
          </Switch>
        </Layout>
      </headerBtnBackContext.Provider>
    </UserInfoContext.Provider>
  );
};
