import React, { useContext } from 'react';

import { Link } from 'react-router-dom';

import avatar from '../../../accets/img/avatar.jpg';
import cvadro from '../../../accets/img/cvadro.jpg';

import styles from './MainUserBar.module.scss';
import { User } from '../../../generated/graphql';
import Carousel from 'react-multi-carousel';
import { UserInfoContext } from '../../../pages/Main/Main';

interface ICarouselProps {
  showIndicators: boolean;
}

export const MainUserBar = () => {
  const {
    body,
    avatarImg,
    userInfo,
    routeBtn,
    routeBtn_black,
    statsList,
    stat,
    balance,
    kpi,
    level,
    statValueTxt,
    userJob,
    userName,
    routeBtnsGroup,
    employeePhoto,
  } = styles;

  const user: User = useContext(UserInfoContext);
  const { firstName, lastName, middleName, stats, job, photo } = user;

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 3000, min: 1360 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 1360, min: 720 },
      items: 3,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 720, min: 464 },
      items: 3,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
      slidesToSlide: 1,
    },
  };

  return (
    <section className={body}>
      <div className={userInfo}>
        <img src={photo ? photo : avatar} alt="avatar" className={avatarImg} />

        <div>
          {middleName ? (
            <h3 className={userName}>{lastName + ' ' + firstName + ' ' + middleName}</h3>
          ) : (
            <h3 className={userName}>{lastName + ' ' + firstName}</h3>
          )}

          <p className={userJob}>{job?.name}</p>

          <ul className={statsList}>
            <li className={`${stat} ${balance}`}>
              Баланс: <span className={statValueTxt}>{stats?.flex} Flex</span>
            </li>
            <li className={`${stat} ${kpi}`}>
              KPI: <span className={statValueTxt}>{stats?.flex}</span>
            </li>
            <li className={`${stat} ${level}`}>
              Уровень: <span className={statValueTxt}>{stats?.flex}</span>
            </li>
          </ul>
        </div>

        <div className={routeBtnsGroup}>
          <Link to={'/gamification/shop'} className={routeBtn}>
            Перейти в магазин
          </Link>

          <Link to={'/gamification/rating/friends'} className={routeBtn_black}>
            Друзья
          </Link>
        </div>
      </div>

      <Carousel responsive={responsive}>
        <img className={employeePhoto} src={cvadro} alt="" />
        <img className={employeePhoto} src={cvadro} alt="" />
        <img className={employeePhoto} src={cvadro} alt="" />
        <img className={employeePhoto} src={cvadro} alt="" />
        <img className={employeePhoto} src={cvadro} alt="" />
      </Carousel>

      <div style={{ display: 'block' }}> gallery</div>
    </section>
  );
};
