import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProjectLink } from '../../generated/graphql';
import { LinkInput } from './LinkInput';
import { Button } from 'react-bootstrap';
import orangeArrowIcon from '../../accets/icons/orange-arrow-btn.svg';
import './ProjectLinkSectiion.scss';

interface ProjectLinkSectionProps {
  projectLink: ProjectLink;
}

export const ProjectLinkSection = ({ projectLink }: ProjectLinkSectionProps) => {
  const [t] = useTranslation('translation');

  return (
    <section className="project-links-container d-flex flex-column">
      <div className="project-links">
        <div className="figma-links-container">
          <span className="title-link flex-1">{`${t('link to')} ${projectLink?.title}`}</span>
          {projectLink?.login && <span className="title-link  flex-1 text-center d-none d-md-block">{t('login')}</span>}
          {projectLink?.password && (
            <span className="title-link  flex-1 text-center d-none d-md-block">{t('password')}</span>
          )}
        </div>

        <div className="figma-links-container">
          <Button
            className="figma-link d-flex align-items-center position-relative"
            variant="link"
            href={projectLink?.link ? projectLink.link : '#'}
            target="_blank"
          >
            <span className="figma-link-title">{projectLink?.link !== null ? projectLink?.link : 'Нет ссылки'}</span>
            <img className="figma-link-img position-absolute" src={orangeArrowIcon} alt="icon" />
          </Button>

          {projectLink?.login && <LinkInput linkValue={projectLink.login as string} />}
          {projectLink?.password && <LinkInput linkValue={projectLink.password as string} />}
        </div>
      </div>
    </section>
  );
};
