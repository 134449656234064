import React, { useContext, useEffect, useState } from 'react';

import { UserInfoContext } from '../Main/Main';

import { useTranslation } from 'react-i18next';

import { useParams, useHistory } from 'react-router';

import { useQuery, useMutation } from '@apollo/client';
import {
  AnswerKnowledgeBaseBlockTestDocument,
  AnswerKnowledgeBaseBlockTestMutation,
  GetKnowledgeBaseBlocksTestByThemeIdDocument,
  GetKnowledgeBaseBlocksTestByThemeIdQuery,
} from '../../generated/graphql';

import { Box, Checkbox, FormControlLabel } from '@material-ui/core';
import { CheckBox, IndeterminateCheckBox } from '@material-ui/icons';

import { CustomButton } from '../../components/CustomButton';
import { ErrorDisplay } from '../../components/ErrorDisplay';
import { LoadingDisplay } from '../../components/LoadingDisplay';
import { MessageSnackbar } from '../../components/MessageSnackbar';

import { black } from '../../constants/Styles/colors';
import { PageContainer } from '../../constants/Styles/generalPageStyle';
import { BlockQuestion, EmptyThemePlaceholder, ThemeTitle } from '../../constants/Styles/styles';

export const KnowledgeBaseTest = () => {
  const { themeName, themeId } = useParams<{ themeName: string; themeId: string }>();
  const history = useHistory();
  const user = useContext(UserInfoContext);
  const [t] = useTranslation('translation');

  const [requestError, setRequestError] = useState(false);

  const [answerTest, { data: answerTestData, loading: answerTestLoading }] =
    useMutation<AnswerKnowledgeBaseBlockTestMutation>(AnswerKnowledgeBaseBlockTestDocument, {
      refetchQueries: [
        { query: GetKnowledgeBaseBlocksTestByThemeIdDocument, variables: { themeId, isTestPublished: true } },
      ],
    });

  const answerTestHandler = async (blockId: string, answerId: string) => {
    if (!blockId || !answerId) {
      return;
    }

    try {
      await answerTest({ variables: { blockId, answerId } });
    } catch (e) {
      setRequestError(true);
    }
  };

  useEffect(() => {
    if (answerTestData) {
      setRequestError(false);
    }
  }, [answerTestData]);

  const { data, loading, error } = useQuery<GetKnowledgeBaseBlocksTestByThemeIdQuery>(
    GetKnowledgeBaseBlocksTestByThemeIdDocument,
    { variables: { themeId, isTestPublished: true } },
  );

  let content;

  if (loading) {
    content = <LoadingDisplay />;
  }

  if (error) {
    content = <ErrorDisplay message={error.message} />;
  }

  if (data) {
    const tests = data.getKnowledgeBaseBlocksTestByThemeId;

    content = tests.length ? (
      tests.map(({ id, testQuestion, testAnswers, testsHistory }) => {
        return (
          <Box key={id}>
            {!!testAnswers.length && (
              <>
                <BlockQuestion>{testQuestion}</BlockQuestion>

                {testAnswers.map(answer => (
                  <Box key={answer.id} mb="10px">
                    <FormControlLabel
                      disabled={
                        answerTestLoading ||
                        !!testsHistory.find(history => history.blockId === id && history.createdBy.id === user.id)
                      }
                      control={
                        <Checkbox
                          onClick={() => answerTestHandler(id, answer.id)}
                          checked={
                            !!testsHistory.find(
                              history =>
                                history.blockId === id &&
                                history.createdBy.id === user.id &&
                                history.answerId === answer.id,
                            )
                          }
                          checkedIcon={
                            answer.isAnswerTrue ? (
                              <CheckBox htmlColor="green" />
                            ) : (
                              <IndeterminateCheckBox htmlColor="red" />
                            )
                          }
                        />
                      }
                      label={answer.content}
                    />
                  </Box>
                ))}
              </>
            )}
          </Box>
        );
      })
    ) : (
      <EmptyThemePlaceholder>Тест по данной теме отсутствует</EmptyThemePlaceholder>
    );
  }

  return (
    <PageContainer>
      <ThemeTitle>{themeName}</ThemeTitle>
      <Box width="100%" fontFamily="Roboto">
        {content}
      </Box>

      <Box m="20px 0" width="100%" display="flex" justifyContent="flex-end">
        <Box mr="20px">
          <CustomButton bg_color={black} callback={() => history.push(`/knowledge-base/theme/${themeName}/${themeId}`)}>
            <span>{t('Back')}</span>
          </CustomButton>
        </Box>

        <CustomButton txt_color={black} callback={() => history.push(`/knowledge-base/theme/${themeName}/${themeId}`)}>
          <span>{t('Submit')}</span>
        </CustomButton>
      </Box>

      <MessageSnackbar
        open={requestError}
        onClose={() => setRequestError(false)}
        message="При сохранении ответа произошла ошибка"
      />
    </PageContainer>
  );
};
