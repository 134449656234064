export let daysData = Array(31)
  .fill(1)
  .map((day, index) => index + 1);

export const createDaysData = (date: number[]) => {
  daysData = date
    ? Array(date.length)
        .fill(1)
        .map((day, index) => index + 1)
    : [];

  return daysData;
};

export const isOddDaysInMonth = daysData.length % 2 === 0;

export const getFirstHalfDayCell = () => {
  return isOddDaysInMonth ? 740 / (daysData.length / 2) : 740 / ((daysData.length - 1) / 2);
};

export const getSecondHalfDayCell = () => {
  return isOddDaysInMonth ? 740 / (daysData.length / 2) : 740 / ((daysData.length + 1) / 2);
};
