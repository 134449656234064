import React from 'react';

import styles from '../Modal.module.scss';

interface IRejectModalProps {
  credits?: {
    flex: boolean;
    kpi: boolean;
    level: boolean;
  };
}

export const RejectConfirmModal = ({ credits }: IRejectModalProps) => {
  const { txt, credit, toTaskBtn, flex, kpi, level } = styles;

  return (
    <>
      {credits && (
        <>
          <p className={txt}>Для приобретения товаров у Вас недостаточно</p>

          <p className={txt}>
            {Object.entries(credits).map(
              ([creditName, isEnough]) =>
                !isEnough && (
                  <span
                    key={creditName}
                    className={`${credit} ${[[flex, kpi, level].find(name => name.includes(creditName))]}`}
                  >
                    {creditName}
                  </span>
                ),
            )}
          </p>

          <p className={txt}>Выполняйте задачи, чтобы получить больше возможностей.</p>
          <button className={toTaskBtn}>Перейти к задачам</button>
        </>
      )}
    </>
  );
};
