import React from 'react';

import styles from './ShareModal.module.scss';

export const ShareModal = () => {
  const { socialsWrapper, txt, socials, link, tg, fb, download } = styles;

  return (
    <div className={socialsWrapper}>
      <p className={txt}>Поделиться в:</p>

      <ul className={socials}>
        <li>
          <a className={`${link} ${tg}`} href="#"></a>
        </li>
        <li>
          <a className={`${link} ${fb}`} href="#"></a>
        </li>
        <li>
          <a className={`${link} ${download}`} href="#"></a>
        </li>
      </ul>
    </div>
  );
};
