import styled from 'styled-components';
import {
  TableHead,
  TableFooter,
  TableRow,
  TableCell,
  TableContainer,
  IconButton,
  Select,
  TextField,
} from '@material-ui/core';
import { lightBlack, primary, secondary, white } from '../../constants/Styles/colors';
// import { DatePicker } from "@material-ui/pickers";

export const CustomScrollBtnsWrapper = styled.div`
  position: relative;
`;

export const CustomTableHead = styled(TableHead)`
  position: sticky;
  top: 0;
  z-index: 2;
`;

export const CustomTableFooter = styled(TableFooter)`
  position: sticky;
  bottom: 0;
  z-index: 2;
  background-color: #fff;
`;

interface ICustomTableWrapperProps {
  height?: number;
  unlimited?: boolean;
}

export const CustomTableWrapper = styled.div<ICustomTableWrapperProps>`
  max-height: ${props => {
    return props.unlimited ? 'unset' : props.height ? `${props.height}px` : '60vh';
  }};
  overflow: auto;
  scroll-behavior: smooth;
  border-radius: 4px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  position: relative;

  &::-webkit-scrollbar {
    height: 24px;
    width: 24px;
    border-top: 1px solid #eee;
    border-left: 1px solid #eee;
    background-color: transparent;
    border-radius: 4px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }

  &::-webkit-scrollbar-button:single-button {
    width: 24px;
  }

  &::-webkit-scrollbar-track {
    height: 20px;
    width: 20px;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    height: 14px;
    width: 14px;
    border: 3px solid #fff;
    background: #c4c4c4;
    border-radius: 7px;
  }
`;

interface ICustomTableScrollbarBtnProps {
  vertical?: boolean;
  isHorizontalScrollVisible?: boolean;
  isVerticalScrollVisible?: boolean;
}

const CustomTableScrollbarBtn = styled(IconButton)<ICustomTableScrollbarBtnProps>`
  position: absolute;
  background-color: transparent;
  width: 20px;
  height: 20px;
  color: #c4c4c4;
  z-index: 40;
`;

export const CustomTableScrollbarBtnDecrement = styled(CustomTableScrollbarBtn)`
  bottom: ${props => (props.vertical ? 'unset' : 0)};
  top: ${props => (props.vertical ? 0 : 'unset')};
  left: ${props => (props.vertical ? 'unset' : 0)};
  right: ${props => (props.vertical ? 0 : 'unset')};
  transform: ${props => (props.vertical ? 'rotate(90deg)' : 'unset')};
`;

export const CustomTableScrollbarBtnIncrement = styled(CustomTableScrollbarBtn)`
  bottom: ${props => (props.vertical && props.isHorizontalScrollVisible ? '24px' : 0)};
  right: ${props => (!props.vertical && props.isVerticalScrollVisible ? '24px' : 0)};
  transform: ${props => (props.vertical ? 'rotate(90deg)' : 'unset')};
`;

export const CustomTableContainer = styled(TableContainer)`
  border-radius: 4px;
  overflow: unset;
`;

interface ICustomTableRowProps {
  clickable?: boolean;
}

export const CustomTableRow = styled(TableRow)<ICustomTableRowProps>`
  cursor: ${props => props.clickable && 'pointer'};
  transition: opacity 0.3s;
  &:hover {
    opacity: ${props => props.clickable && 0.6};
  }
`;

export const CustomTableEpicRow = styled(CustomTableRow)<ICustomTableRowProps>`
  background-color: rgba(255, 182, 29, 0.1);
  border-bottom: 1px solid #eee;
`;

interface ICustomTableCellProps {
  width?: string;
  fontSize?: number;
}

export const CustomTableHeaderCell = styled(TableCell)<ICustomTableCellProps>`
  background-color: #000;
  position: relative;
  padding: 7px !important;
  max-width: ${props => (props.width ? `${props.width}` : 'unset')};
  min-width: ${props => (props.width ? `${props.width}` : 'fit-content')};
  box-sizing: border-box;
  color: #fff !important;
  text-align: center !important;
  z-index: 3;
  border-right: 1px solid rgba(196, 196, 196, 0.3);
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: bold !important;
  font-size: 14px !important;
  line-height: 16px !important;

  &:last-of-type {
    border-right: none;
    border-top-right-radius: 4px;
  }
`;

export const CustomTableCell = styled(TableCell)<ICustomTableCellProps>`
  background: ${white};
  color: ${lightBlack};
  max-width: ${props => (props.width ? `${props.width}` : 'unset')};
  font-size: ${props => (props.fontSize ? `${props.fontSize}px` : '14px')};
  min-width: fit-content;
  position: relative;
  box-sizing: border-box;
  text-align: center !important;
  border-left: 1px solid #eee;
`;

export const CustomTableCellNoBorder = styled(CustomTableCell)<{
  transparent?: boolean;
}>`
  border-left: ${props => (props.transparent ? '1px solid transparent' : '1px solid rgba(255, 182, 29, 0.1)')};
  position: relative;
  font-size: ${props => (props.fontSize ? `${props.fontSize}px` : '14px')};
  padding: ${props => (props.padding === 'none' ? 0 : '16px 24px')};
`;

export const CustomTableCellNoBottom = styled(CustomTableCell)`
  border-bottom: 1px solid transparent;
`;

export const ColorizedTableCell = styled(CustomTableCell)<{ number: number }>`
  background-color: ${props => {
    if (props.number > 0) {
      return '#CBF8DF';
    }

    if (props.number < 0) {
      return '#F8CBCB';
    }
  }};
`;

interface InputProps {
  fontSize?: number;
  error?: boolean;
}

export const CustomTableInput = styled.input<InputProps>`
  height: 100%;
  width: 100%;
  font-size: ${props => (props.fontSize ? `${props.fontSize}px` : '14px')};
  line-height: 16px;
  padding: 18px 10px;
  text-align: center;
  background-color: transparent;
  border: ${props => {
    if (props.disabled) {
      return '1px solid transparent';
    }

    if (props.error) {
      return '1px solid red';
    }

    return '1px solid #FFB61D';
  }};
  color: ${lightBlack};
  outline: none;
  transition: border-color 0.3s;

  &::-webkit-outer-spin-button {
    appearance: none;
  }
  &::-webkit-inner-spin-button {
    appearance: none;
  }
`;

export const CustomTableInputWithoutBorders = styled(CustomTableInput)`
  border: 1px solid transparent;
  border-bottom: ${props => (props.error ? '1px solid red' : '1px solid transparent')};

  &:disabled {
    opacity: 0.7;
  }
`;

export const NoItemsPlaceholder = styled.p`
  text-align: center;
  color: ${lightBlack};
`;

export const CustomTableSelect = styled(Select)<InputProps>`
  height: 52px;
  border-width: 1px;
  border-style: solid;
  color: ${lightBlack};
  border-color: ${props => (props.disabled ? 'transparent' : primary)};

  & .MuiInputBase-input {
    font-size: ${props => (props.fontSize ? `${props.fontSize}px` : '14px')};
  }

  &.MuiInput-underline {
    &::before {
      content: unset;
    }
    &::after {
      content: unset;
    }
  }

  & .MuiSelect-select:focus {
    background-color: transparent;
  }

  & .MuiOutlinedInput-input {
    padding: 16px 14px;
  }

  &.MuiInputBase-root.Mui-disabled {
    color: ${lightBlack};
  }
`;

export const CustomTableTextfield = styled(TextField)<InputProps>`
  border-width: 1px;
  border-style: solid;
  border-color: ${props => {
    if (props.error && !props.disabled) {
      return 'red';
    }

    if (props.disabled) {
      return 'transparent';
    }

    return primary;
  }};

  & .MuiInputBase-root {
    font-size: ${props => (props.fontSize ? `${props.fontSize}px` : '14px')};
  }

  & .MuiAutocomplete-inputRoot {
    padding: 12px 14px;
  }

  & .MuiInput-underline {
    &::before {
      content: unset;
    }
    &::after {
      content: unset;
    }
  }
`;

export const EmptyTableRow = styled(TableRow)<ICustomTableRowProps>`
  cursor: ${props => props.clickable && 'pointer'};
  transition: opacity 0.3s;
  height: 43px;
  border-bottom: 1px solid #eee;
  &:hover {
    opacity: ${props => props.clickable && 0.6};
  }
`;
