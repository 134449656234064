import { gql } from '@apollo/client';
import { Purchase } from '../../generated/graphql';

export interface IPurchasesInCartData {
  getPurchasesInCart: {
    purchases: [Purchase];
    total: number;
  };
  loading: boolean;
}

export const GET_PURCHASES_IN_CART = gql`
  query getPurchasesInCart($getPurchasesData: GetPurchasesByUserInput!) {
    getPurchasesInCart(getPurchasesData: $getPurchasesData) {
      purchases {
        id
        product {
          id
          name
          flex
          requiredKpi
          requiredLevel
          type {
            displayName
          }
          image
          description
          features
          url
          location
        }
        status {
          status
        }
      }
      total
    }
  }
`;

export interface IPurchasesHistoryData {
  getPurchaseHistory: {
    purchases: [Purchase];
    total: number;
  };
  loading: boolean;
}

export const GET_PURCHASES_HISTORY = gql`
  query getPurchaseHistory($getPurchasesData: GetPurchasesByUserInput!) {
    getPurchaseHistory(getPurchasesData: $getPurchasesData) {
      purchases {
        id
        product {
          id
          name
          type {
            displayName
          }
          image
          description
          features
          url
          location
        }
        status {
          status
        }
      }
      total
    }
  }
`;
