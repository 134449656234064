import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import defaultUserPhoto from '../../../accets/icons/defaultUserPhoto.svg';
import pencilIcon from '../../../accets/icons/pencil-icon.svg';

import { AuthorPhoto, EditButtonContainer, P, TaskContainer, TaskContent } from './Task.style';
import { TransparentButton } from '../../../constants/Styles/TransparentButton';

interface TaskProps {
  task: { id: string; name: string; photo: null | string };
  index: number;
  edit(): void;
}

export const Task = ({ task, index, edit }: TaskProps) => {
  return (
    <Draggable draggableId={task.id} index={index}>
      {(provided, snapshot) => (
        <TaskContainer
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          isDragging={snapshot.isDragging}
        >
          <TaskContent>
            <EditButtonContainer onClick={() => edit()}>
              <TransparentButton>
                <img src={pencilIcon} />
              </TransparentButton>
            </EditButtonContainer>
            <P>{task.name}</P>
            <AuthorPhoto src={task.photo || defaultUserPhoto} alt="photo" />
          </TaskContent>
        </TaskContainer>
      )}
    </Draggable>
  );
};
