import React from 'react';
import { Button } from 'react-bootstrap';
import instagramIcon from '../../accets/icons/inst-icon.svg';
import instaColoredIcon from '../../accets/icons/insta-colored-icon.svg';
import youtubeIcon from '../../accets/icons/youtube-icon.svg';
import youtubeColoredIcon from '../../accets/icons/youtube-colored-icon.svg';
import fbIcon from '../../accets/icons/fb-icon.svg';
import fbColoredIcon from '../../accets/icons/fb-colored-icon.svg';

export const SidebarContacts = () => {
  return (
    <div className="sidebar-contacts d-flex flex-column">
      <span className="sidebar-phone text-center">+380 91 48 19 595</span>
      <Button
        className="sidebar-web-site-link text-center"
        variant="link"
        href="https://flexreality.pro/ua"
        target="_blank"
        style={{ paddingLeft: '0', paddingRight: '0' }}
      >
        flexreality.pro/ua/
      </Button>
      <div className="d-flex justify-content-between">
        <Button
          className="social-link position-relative"
          variant="link"
          href="https://www.instagram.com/flexreality/"
          target="_blank"
        >
          <img className="sidebar-icon" src={instagramIcon} alt="icon" />
          <img className="sidebar-icon-color" src={instaColoredIcon} alt="icon" />
        </Button>
        <Button
          className="social-link position-relative"
          variant="link"
          href="https://www.youtube.com/channel/UCF8JJRvIJjhecaPkY8Ga1YA"
          target="_blank"
        >
          <img className="sidebar-icon" src={youtubeIcon} alt="icon" />
          <img className="sidebar-icon-color" src={youtubeColoredIcon} alt="icon" />
        </Button>
        <Button
          className="social-link position-relative"
          variant="link"
          href="https://www.facebook.com/flexreality.pro"
          target="_blank"
        >
          <img className="sidebar-icon" src={fbIcon} alt="icon" />
          <img className="sidebar-icon-color" src={fbColoredIcon} alt="icon" />
        </Button>
      </div>
    </div>
  );
};
