import React from 'react';
import ReactPaginate from 'react-paginate';
import './CustomPagination.scss';

interface CustomPaginationProps {
  pageCount: number;
  handlePageClick(selectedItem: { selected: number }): void;
}

export const CustomPagination = ({ pageCount, handlePageClick }: CustomPaginationProps) => {
  return (
    <ReactPaginate
      previousLabel={'←'}
      nextLabel={'→'}
      pageCount={pageCount}
      onPageChange={handlePageClick}
      containerClassName={'pagination'}
      previousLinkClassName={'pagination__link'}
      nextLinkClassName={'pagination__link'}
      disabledClassName={'pagination__link--disabled'}
      activeClassName={'pagination__link--active'}
      marginPagesDisplayed={2}
      pageRangeDisplayed={2}
    />
  );
};
