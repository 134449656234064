import React, { useContext, useEffect, useReducer, useState } from 'react';
import { dataParserToMonth } from '../../utils/dataParserToMonth';
import { classNames } from '../../constants/classNames';
import { Button } from 'react-bootstrap';
import burgerIcon from '../../accets/icons/burger-menu-icon.svg';
import { UserInfoContext } from '../../pages/Main/Main';
import {
  GetUserInfoPayrollDocument,
  GetUserPayrollDocument,
  GetUserPayrollsDocument,
  GetUserInfoPayrollQuery,
  GetUserPayrollQuery,
  GetUserPayrollsQuery,
  User,
} from '../../generated/graphql';
import { useLazyQuery, useQuery } from '@apollo/client';
import { formatDataToDDMMYY } from '../../utils/formatDataToDDMMYY';
import { SalaryAdditionalDataModal } from '../SalaryAdditionalDataModal';
import { LoadingDisplay } from '../LoadingDisplay';
import '../Payroll/Payroll.scss';
import { ErrorDisplay } from '../ErrorDisplay';

type State = {
  additionalModalRole?: string;
  isAllMonth?: boolean;
};

type Action =
  | { type: 'setAdditionalModalRole'; payload: string }
  | { type: 'setIsAllMonth' }
  | { type: 'setNotAllMonth' };

const initialState: State = {
  additionalModalRole: '',
  isAllMonth: false,
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'setAdditionalModalRole':
      return { additionalModalRole: action.payload };
    case 'setIsAllMonth':
      return { isAllMonth: !state.isAllMonth };

    default:
      throw new Error();
  }
};

export const UserPayrollNew = () => {
  const { id } = useContext<User>(UserInfoContext);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [activeTab, setActiveTab] = useState<string>('');
  const [isSalaryAdditionalModalActive, setIsSalaryAdditionalModalActive] = useState(false);

  const {
    data: userInfoPayrollData,
    loading: userInfoPayrollLoading,
    error: userInfoPayrollError,
  } = useQuery<GetUserInfoPayrollQuery>(GetUserInfoPayrollDocument, {
    variables: { userId: id },
  });

  const {
    data: payrollsData,
    loading: payrollsLoading,
    error: payrollsError,
  } = useQuery<GetUserPayrollsQuery>(GetUserPayrollsDocument, {
    variables: { userId: id },
  });

  const [chosenPayroll, setChosenPayroll] = useState<string | null>(null);

  const [getUserPayroll, { data: payrollData, loading: payrollLoading, error: payrollError }] =
    useLazyQuery<GetUserPayrollQuery>(GetUserPayrollDocument, { variables: { payrollId: chosenPayroll } });

  const tabs =
    payrollsData?.getUserPayrolls.length > 6
      ? payrollsData?.getUserPayrolls.slice(payrollsData?.getUserPayrolls.length - 5)
      : payrollsData?.getUserPayrolls;

  useEffect(() => {
    tabs && tabs?.length !== 0 && setActiveTab(tabs[0].name);
    tabs && tabs?.length !== 0 && setChosenPayroll(tabs[0]?.id as string);
  }, [tabs]);

  useEffect(() => {
    if (payrollsData?.getUserPayrolls.length) {
      setChosenPayroll(payrollsData.getUserPayrolls[payrollsData.getUserPayrolls.length - 1].id);
    }
  }, [payrollsLoading, payrollsData?.getUserPayrolls.length]);

  useEffect(() => {
    if (chosenPayroll) {
      getUserPayroll();
    }
  }, [chosenPayroll]);

  let content;

  if (userInfoPayrollLoading || payrollsLoading || payrollLoading) {
    return <LoadingDisplay />;
  }

  if (payrollsError || userInfoPayrollError || payrollError) {
    return <ErrorDisplay message={payrollsError.message || userInfoPayrollError.message || payrollError.message} />;
  }

  if (userInfoPayrollData && payrollsData && payrollData) {
    const { salary, firstName, lastName, job } = userInfoPayrollData.getUser;
    const payrolls = payrollsData.getUserPayrolls;
    const payroll = payrollData.getUserPayroll;
    const hoursFact = (payroll.additionalData.reports.reduce((acc, p) => acc + p.totalTime, 0) / 60).toFixed(0);

    content = (
      <>
        <div className="table-responsive position-relative pb-2 w-100">
          <div className="table-row">
            <div className="table-slot dark-slot light-color slot">{`${firstName} ${lastName}`}</div>
            <div className="table-slot dark-slot light-color slot current-month">{dataParserToMonth(activeTab)}</div>
            <div className="table-slot dark-slot light-color slot ">{job?.name}</div>
            <div className="table-slot dark-slot light-color slot " />
            <div className="table-slot dark-slot light-color slot" />
          </div>

          <div className="table-row">
            <div className="border-slot slot color-slot d-flex justify-content-center align-items-center">Ставка</div>
            <div className="border-slot slot d-flex justify-content-center align-items-center">{salary}</div>
            <div className="border-slot color-slot slot d-flex justify-content-center align-items-center">Оклад</div>
            <div className="border-slot slot d-flex justify-content-center align-items-center">
              {payroll.additionalData.mainPart}
            </div>
            <div className="border-slot slot d-flex justify-content-center align-items-center">
              {payroll.salaryDescription}
            </div>
          </div>

          <div className="table-row">
            <div className="border-slot slot color-slot d-flex justify-content-center align-items-center">
              Премиальный фонд
            </div>
            <div className="border-slot slot d-flex justify-content-center align-items-center">{payroll.bonusFund}</div>
            <div
              className="border-slot color-slot slot d-flex justify-content-center align-items-center text-center color-td cursor-pointer"
              onClick={() => {
                dispatch({ type: 'setAdditionalModalRole', payload: 'kpi' });
                setIsSalaryAdditionalModalActive(true);
              }}
            >
              KPI
            </div>
            <div className="border-slot slot d-flex justify-content-center align-items-center">
              {payroll.additionalData.kpiValue}
            </div>
            <div className="border-slot slot d-flex justify-content-center align-items-center">
              {payroll.kpiDescription}
            </div>
          </div>

          <div className="table-row">
            <div
              className="border-slot slot dark-bg-slot light-color cursor-pointer d-flex justify-content-center align-items-center text-center"
              onClick={() => {
                dispatch({ type: 'setAdditionalModalRole', payload: '' });
                setIsSalaryAdditionalModalActive(true);
              }}
            >
              Часы факт
            </div>
            <div className="border-slot slot d-flex justify-content-center align-items-center">{hoursFact}</div>
            <div
              className="border-slot slot color-slot cursor-pointer d-flex justify-content-center align-items-center"
              onClick={() => {
                dispatch({ type: 'setAdditionalModalRole', payload: 'bonus' });
                setIsSalaryAdditionalModalActive(true);
              }}
            >
              Бонус
            </div>
            <div className="border-slot slot d-flex justify-content-center align-items-center">
              {payroll.additionalData.bonusesValue}
            </div>
            <div className="border-slot slot d-flex justify-content-center align-items-center">
              {payroll.bonusesDescription}
            </div>
          </div>

          <div className="table-row">
            <div className="border-slot slot dark-bg-slot light-color d-flex justify-content-center align-items-center text-center">
              Часы план
            </div>
            <div className="border-slot slot d-flex justify-content-center align-items-center">{payroll.hoursPlan}</div>
            <div className="border-slot slot color-slot d-flex justify-content-center align-items-center">
              Совокупный доход
            </div>
            <div className="border-slot slot d-flex justify-content-center align-items-center">
              {payroll.additionalData.total}
            </div>
            <div className="border-slot slot d-flex justify-content-center align-items-center">
              {payroll.totalDescription}
            </div>
          </div>

          <div style={{ minHeight: '50px' }}>
            <div />
            <div />
            <div />
            <div />
            <div />
          </div>

          <div className="table-row">
            <div />
            <div />
            <div className="dark-slot light-color slot d-flex justify-content-center align-items-center">Выплата</div>
            <div className="dark-slot light-color slot min-width d-flex justify-content-center align-items-center">
              Дата
            </div>
            <div className="dark-slot light-color slot d-flex justify-content-center align-items-center">Сумма</div>
          </div>

          {payroll.parts.map(part => (
            <div className="table-row" key={part.id}>
              <div />
              <div />
              <div className="border-slot slot d-flex justify-content-center align-items-center">{part.name}</div>
              <div className="border-slot slot min-width d-flex justify-content-center align-items-center">
                {formatDataToDDMMYY(part.date)}
              </div>
              <div className="border-slot slot  d-flex justify-content-center align-items-center">{part.value}</div>
            </div>
          ))}

          {state.isAllMonth && (
            <ul className="payroll-month-container position-absolute p-4 overflow-auto">
              {payrolls.map(data => (
                <li
                  className={classNames({
                    'cursor-pointer': true,
                    'active-month': data.name === activeTab,
                  })}
                  key={data.id}
                  onClick={() => {
                    setActiveTab(data.name);
                    setChosenPayroll(data.id as string);
                  }}
                >
                  {dataParserToMonth(data.name)}
                </li>
              ))}
            </ul>
          )}
          {isSalaryAdditionalModalActive && (
            <SalaryAdditionalDataModal
              payrollId={payroll.id}
              kpi={payroll.kpi}
              bonuses={payroll.bonuses}
              role={state.additionalModalRole}
              reports={payroll.additionalData.reports}
              month={payroll.name}
              bonusFund={payroll.bonusFund}
              kpiValue={payroll.additionalData.kpiValue}
              closeModal={setIsSalaryAdditionalModalActive}
            />
          )}
        </div>
        <div className="table-bar d-flex">
          <Button className="table-bar-menu" onClick={() => dispatch({ type: 'setIsAllMonth' })}>
            <img src={burgerIcon} alt="icon" />
          </Button>

          <ul className="d-flex justify-content-center w-100 mb-0">
            {tabs?.map(data => (
              <li
                className={classNames({
                  'table-bar-tab d-flex justify-content-center align-items-center': true,
                  'active-tab': activeTab === data.name,
                })}
                key={data.id}
                onClick={() => {
                  setActiveTab(data.name);
                  setChosenPayroll(data.id as string);
                }}
              >
                {dataParserToMonth(data.name)}
              </li>
            ))}
          </ul>
        </div>
      </>
    );
  }

  if (!payrollsData?.getUserPayrolls?.length && !payrollData) {
    content = <h6 className="text-center">Зарплатные ведомости отсутствуют</h6>;
  }

  return <div className="payroll-container w-100 mb-4">{content}</div>;
};
