import React from 'react';
import { Portal } from 'react-portal';
import './NewsModal.scss';
import '../NewsItem/NewsItem.scss';
import { YoutubeEmbed } from '../YoutubeEmbed';

interface newsItem {
  id: string;
  name: string;
  photo: string;
  youTubeLink: string;
  publishedAt: string;
  description: string;
}

interface NewsModalProps {
  closeModal(state: boolean): void;
  currentNews: newsItem;
}

export const NewsModal = ({ closeModal, currentNews }: NewsModalProps) => {
  const data = currentNews.publishedAt.split('T')[0].split('-').reverse().join('.');
  const time = currentNews.publishedAt.split('T')[1].slice(0, 5);

  return (
    <Portal>
      <div
        className="modal-news-modal-container d-flex justify-content-center align-items-center"
        onClick={() => closeModal(false)}
      >
        <div className="modal-news-modal-notification d-flex flex-column align-items-start">
          <span className="news-item-data mb-2">{`${data} ${time}`}</span>
          <div className="d-flex justify-content-center w-100">
            <img className="news-item-img mb-2" src={currentNews.photo} alt="new-img" />
          </div>
          {currentNews.youTubeLink && <YoutubeEmbed url={currentNews.youTubeLink} />}
          <h3 className="news-item-title">{currentNews.name}</h3>
          <p>{currentNews.description}</p>
        </div>
      </div>
    </Portal>
  );
};
