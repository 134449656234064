import React from 'react';
import { Portal } from 'react-portal';
import { kpiTableHeader } from '../../constants/kpiTableHeader';
import { PayrollKpi } from '../../generated/graphql';

interface KPIModalProps {
  payrollId: string;
  kpi: PayrollKpi[];
  bonusFund?: number;
  kpiValue: number;
  closeModal(close: boolean): void;
}

export const KPIModal = ({ kpi, bonusFund, kpiValue, closeModal }: KPIModalProps) => {
  return (
    <Portal>
      <div className="modal-container" onClick={() => closeModal(false)}>
        <div className="working-hours-modal">
          <div className="d-flex">
            <h3 className="working-hours-title mr-4 overflow-auto">KPI</h3>
            <div className="working-hours-subtitle">
              Премиальный фонд -<span className="working-hours-subtitle-color ml-1">{bonusFund}</span>
            </div>
          </div>

          <div className="kpi-table overflow-auto">
            <div className="kpi-row">
              {kpiTableHeader.map((slot, i) => (
                <div className="dark-slot light-color slot d-flex justify-content-center align-items-center" key={i}>
                  {slot.title}
                </div>
              ))}
            </div>

            {kpi.map((kpi, i) => (
              <div className="kpi-row" key={i}>
                <div className="border-slot slot  d-flex justify-content-center align-items-center">{kpi.name}</div>
                <div className="border-slot slot  d-flex justify-content-center align-items-center">{kpi.unit}</div>
                <div className="border-slot slot  d-flex justify-content-center align-items-center">{kpi.weigh}</div>
                <div className="border-slot slot  d-flex justify-content-center align-items-center">{kpi.plan}</div>
                <div className="border-slot slot  d-flex justify-content-center align-items-center">{kpi.fact}</div>
                <div className="border-slot slot  d-flex justify-content-center align-items-center">
                  {((kpi.fact / kpi.plan) * 100).toFixed() || 0}
                </div>
                <div className="border-slot slot  d-flex justify-content-center align-items-center">
                  {((kpi.fact / kpi.plan) * 100 * kpi.weigh).toFixed() || 0}
                </div>
              </div>
            ))}
            <div className="kpi-row mt-3">
              <div
                className="border-slot slot  d-flex justify-content-center align-items-center"
                style={{ backgroundColor: '#FFB61D' }}
              >
                Итог (Сумма МВО * Премиальный фонд)
              </div>
              <div
                className="border-slot slot  d-flex justify-content-center align-items-center"
                style={{ backgroundColor: '#FFB61D' }}
              >
                {kpiValue}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Portal>
  );
};
