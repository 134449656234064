import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';
import { User } from '../../generated/graphql';
import { UserInfoContext } from '../../pages/Main/Main';
import { classNames } from '../../constants/classNames';
import dashboardIcon from '../../accets/icons/dashboard.svg';
import dashboardColoredIcon from '../../accets/icons/dashboaard-colored.svg';
import scheduleIcon from '../../accets/icons/schedule-icon.svg';
import scheduleColoredIcon from '../../accets/icons/schedule-colored-icon.svg';
import projectInformationIcon from '../../accets/icons/project-information-icon.svg';
import projectInformationColoredIcon from '../../accets/icons/project-information-colored-icon.svg';
import editsIcon from '../../accets/icons/edits-icon.svg';
import editsColoredIcon from '../../accets/icons/edits-colored-icon.svg';
import financesIcon from '../../accets/icons/finances-icon.svg';
import financesColoredIcon from '../../accets/icons/finances-colored-icon.svg';
import infoIcon from '../../accets/icons/detailed-information-icon.svg';
import infoColoredIcon from '../../accets/icons/detailed-information-colored-icon.svg';
import './NavBar.scss';

export const NavBar = () => {
  const [t] = useTranslation('translation');
  const { role } = useContext<User>(UserInfoContext);
  const { pathname } = useLocation();

  const projectId = pathname.split('/')[2];

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    if (!projectId) e.preventDefault();
  };

  return (
    <nav
      className={classNames({
        'nav w-100 d-flex  align-items-center': true,
        'nav-w justify-content-between': role?.id === 3,
        'w-100 justify-content-center': role?.id !== 3,
      })}
    >
      {role?.id !== 3 && (
        <NavLink
          onClick={handleClick}
          className="nav-link  d-flex flex-column justify-content-center align-items-center"
          activeClassName="activeRoute"
          to={`/project/${projectId}/dashboard`}
        >
          <img
            className="nav-link-img"
            src={pathname === `/project/${projectId}/dashboard` ? dashboardColoredIcon : dashboardIcon}
            alt="icon"
          />
          <span className="nav-link-title text-center">{t('kanban dashboard')}</span>
        </NavLink>
      )}
      <NavLink
        onClick={handleClick}
        className="nav-link  d-flex flex-column justify-content-center align-items-center"
        activeClassName="activeRoute"
        to={`/project/${projectId}/project-schedule?month`}
      >
        <img
          className="nav-link-img"
          src={pathname === `/project/${projectId}/project-schedule` ? scheduleColoredIcon : scheduleIcon}
          alt="icon"
        />
        <span className="nav-link-title text-center">{t('project schedule')}</span>
      </NavLink>

      {role?.id !== 3 && (
        <>
          {/*<NavLink*/}
          {/*  className="nav-link  d-flex flex-column justify-content-center align-items-center"*/}
          {/*  activeClassName="activeRoute"*/}
          {/*  to="/news"*/}
          {/*>*/}
          {/*  <img className="nav-link-img" src={pathname === `/news` ? newsColoredIcon : newsIcon} alt="icon" />*/}
          {/*  <span className="nav-link-title text-center">{t('company news')}</span>*/}
          {/*</NavLink>*/}
          <NavLink
            className="nav-link  d-flex flex-column justify-content-center align-items-center"
            activeClassName="activeRoute"
            to={`/project/${projectId}/project-information`}
          >
            <img
              className="nav-link-img"
              src={pathname === `/project/${projectId}/project-information` ? infoColoredIcon : infoIcon}
              alt="icon"
            />
            <span className="nav-link-title text-center">{t('detailed information')}</span>
          </NavLink>
          {/*<NavLink*/}
          {/*  className="nav-link  d-flex flex-column justify-content-center align-items-center"*/}
          {/*  activeClassName="activeRoute"*/}
          {/*  to="/payroll"*/}
          {/*>*/}
          {/*  <img*/}
          {/*    className="nav-link-img"*/}
          {/*    src={pathname === `/payroll` ? financesColoredIcon : financesIcon}*/}
          {/*    alt="icon"*/}
          {/*  />*/}
          {/*  <span className="nav-link-title text-center">{t('payroll')}</span>*/}
          {/*</NavLink>*/}
          {/*<NavLink*/}
          {/*  className="nav-link  d-flex flex-column justify-content-center align-items-center"*/}
          {/*  activeClassName="activeRoute"*/}
          {/*  to="/management-contact"*/}
          {/*>*/}
          {/*  <img*/}
          {/*    className="nav-link-img"*/}
          {/*    src={pathname === `/management-contact` ? peopleColoredIcon : peopleIcon}*/}
          {/*    alt="icon"*/}
          {/*  />*/}
          {/*  <span className="nav-link-title text-center">{t('management contact')}</span>*/}
          {/*</NavLink>*/}
        </>
      )}

      {role?.id === 3 && (
        <>
          <NavLink
            onClick={handleClick}
            className="nav-link  d-flex flex-column justify-content-center align-items-center"
            activeClassName="activeRoute"
            to={`/project/${projectId}/project-information`}
          >
            <img
              className="nav-link-img"
              src={
                pathname === `/project/${projectId}/project-information`
                  ? projectInformationColoredIcon
                  : projectInformationIcon
              }
              alt="icon"
            />
            <span className="nav-link-title text-center">{t('project information')}</span>
          </NavLink>
          <NavLink
            onClick={handleClick}
            className="nav-link  d-flex flex-column justify-content-center align-items-center"
            activeClassName="activeRoute"
            to={`/project/${projectId}/corrections`}
          >
            <img
              className="nav-link-img"
              src={pathname === `/project/${projectId}/corrections` ? editsColoredIcon : editsIcon}
              alt="icon"
            />
            <span className="nav-link-title text-center">{t('edits')}</span>
          </NavLink>
          <NavLink
            onClick={handleClick}
            className="nav-link  d-flex flex-column justify-content-center align-items-center"
            activeClassName="activeRoute"
            to={`/project/${projectId}/finances`}
          >
            <img
              className="nav-link-img"
              src={pathname === `/project/${projectId}/finances` ? financesColoredIcon : financesIcon}
              alt="icon"
            />
            <span className="nav-link-title text-center">{t('finances')}</span>
          </NavLink>
        </>
      )}
    </nav>
  );
};
