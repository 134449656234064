import styled from 'styled-components';

export const InputContainer = styled.div`
  border: 1px solid #828282;
  box-sizing: border-box;
  border-radius: 10px;
  width: 250px;
  height: 35px;
  display: flex;
  position: relative;
`;

export const Input = styled.input`
  border: none;
  margin-left: 5px;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #101010;
  width: 95%;

  &:focus {
    outline: none;
  }
`;

export const Unit = styled.label`
  position: absolute;
  right: 7px;
  top: 5px;
  padding: 5px;
  background: #ffffff;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #101010;
`;
