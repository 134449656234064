import styled from 'styled-components';

export const SmallAddButton = styled.button`
  position: relative;
  background: #ffb61d;
  width: 18px;
  height: 18px;
  border: none;
  border-radius: 2px;

  &:before {
    content: '+';
    color: #fff;
    position: absolute;
    font-size: 23px;
    font-weight: bold;
    top: -11px;
    left: 1px;
  }
`;
