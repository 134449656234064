import React from 'react';
import { PayrollBonus, PayrollKpi, Report } from '../../generated/graphql';
import { WorkingHoursModal } from '../WorkingHoursModal';
import { KPIModal } from '../KPIModal';
import { BonusModal } from '../BonusModal';

interface SalaryAdditionalModalProps {
  role?: string;
  payrollId: string;
  kpi: PayrollKpi[];
  reports: Report[];
  month: string;
  bonuses?: PayrollBonus[];
  bonusFund?: number;
  kpiValue?: number;
  closeModal(close: boolean): void;
}

export const SalaryAdditionalDataModal = (props: SalaryAdditionalModalProps) => {
  let modal;

  switch (props.role) {
    case 'kpi':
      modal = (
        <KPIModal
          payrollId={props.payrollId}
          kpi={props.kpi}
          closeModal={props.closeModal}
          bonusFund={props.bonusFund}
          kpiValue={props.kpiValue}
        />
      );
      break;

    case 'bonus':
      modal = <BonusModal bonuses={props.bonuses} closeModal={props.closeModal} />;
      break;

    default:
      modal = <WorkingHoursModal reports={props.reports} month={props.month} closeModal={props.closeModal} />;
  }
  return modal;
};
