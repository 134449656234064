import React from 'react';
import { useTranslation } from 'react-i18next';
import { CorrectionsLight } from '../../generated/graphql';
import { Button } from 'react-bootstrap';
import { classNames } from '../../constants/classNames';
import './FilterCorrections.scss';

interface FilterCorrectionsProps {
  activeFilterBtn: string;
  setActiveFilterBtn(btn: string): void;
  setIsEditForm(show: boolean): void;
  projectCorrectionsCounts?: CorrectionsLight[];
  withCreateBtn?: boolean;
}

export const FilterCorrections = ({
  activeFilterBtn,
  setActiveFilterBtn,
  setIsEditForm,
  projectCorrectionsCounts,
  withCreateBtn = true,
}: FilterCorrectionsProps) => {
  const [t] = useTranslation('translation');

  const newCorrections = projectCorrectionsCounts?.find(el => el.status === 'NEW');
  const reviewCorrections = projectCorrectionsCounts?.find(el => el.status === 'REVIEW');
  const discussionCorrections = projectCorrectionsCounts?.find(el => el.status === 'DISCUSSION');
  const acceptedCorrections = projectCorrectionsCounts?.find(el => el.status === 'ACCEPTED');
  const doneCorrections = projectCorrectionsCounts?.find(el => el.status === 'DONE');
  const rejectedCorrections = projectCorrectionsCounts?.find(el => el.status === 'REJECTED');

  return (
    <div className="filter-btn-container w-100">
      <div className="filter-btn-first-container">
        <Button
          className={classNames({
            'new-btn position-relative': true,
            'active-btn': activeFilterBtn === 'NEW',
          })}
          onClick={() => setActiveFilterBtn('NEW')}
        >
          <span className="filter-btn-title d-inline-block">{t('new')}</span>
          {newCorrections?.count && <span className="position-absolute filter-btn-count">{newCorrections?.count}</span>}
        </Button>
        <Button
          className={classNames({
            'under-review-btn position-relative': true,
            'active-btn': activeFilterBtn === 'REVIEW',
          })}
          onClick={() => setActiveFilterBtn('REVIEW')}
        >
          <span className="filter-btn-title d-inline-block">{t('under review')}</span>
          {reviewCorrections?.count && (
            <span className="position-absolute filter-btn-count">{reviewCorrections?.count}</span>
          )}
        </Button>
        <Button
          className={classNames({
            'discuss-btn position-relative': true,
            'active-btn': activeFilterBtn === 'DISCUSSION',
          })}
          onClick={() => setActiveFilterBtn('DISCUSSION')}
        >
          <span className="filter-btn-title d-inline-block">{t('under discussion')}</span>
          {discussionCorrections?.count && (
            <span className="position-absolute filter-btn-count">{discussionCorrections?.count}</span>
          )}
        </Button>
      </div>

      <div className="filter-btn-second-container">
        <Button
          className={classNames({
            'accepted-btn position-relative': true,
            'active-btn': activeFilterBtn === 'ACCEPTED',
          })}
          onClick={() => setActiveFilterBtn('ACCEPTED')}
        >
          <span className="filter-btn-title d-inline-block">{t('accepted')}</span>
          {acceptedCorrections?.count && (
            <span className="position-absolute filter-btn-count">{acceptedCorrections?.count}</span>
          )}
        </Button>
        <Button
          className={classNames({
            'done-btn position-relative': true,
            'active-btn': activeFilterBtn === 'DONE',
          })}
          onClick={() => setActiveFilterBtn('DONE')}
        >
          <span className="filter-btn-title d-inline-block">{t('done')}</span>
          {doneCorrections?.count && (
            <span className="position-absolute filter-btn-count">{doneCorrections?.count}</span>
          )}
        </Button>
        <Button
          className={classNames({
            'reject-btn position-relative': true,
            'active-btn': activeFilterBtn === 'REJECTED',
          })}
          onClick={() => setActiveFilterBtn('REJECTED')}
        >
          <span className="filter-btn-title d-inline-block">{t('rejected')}</span>
          {rejectedCorrections?.count && (
            <span className="position-absolute filter-btn-count">{rejectedCorrections?.count}</span>
          )}
        </Button>
      </div>

      {withCreateBtn && (
        <div className="d-none d-lg-flex justify-content-center">
          <Button
            className="create-edit-btn"
            onClick={() => {
              setIsEditForm(true);
              setActiveFilterBtn('NEW');
            }}
          >
            {t('create an correction')}
          </Button>
        </div>
      )}
    </div>
  );
};
