import { KnowledgeBase } from './../pages/KnowledgeBase/KnowledgeBase';
import { Main } from '../pages/Main';
import { ProjectSchedule } from '../pages/ProjectSchedule';
import { ProjectInformation } from '../pages/ProjectInformation';
import { Corrections } from '../pages/Corrections';
import { Finance } from '../pages/Finance';
import { Notifications } from '../pages/Notifications';
import { Gamification } from '../pages/Gamification';
import { UserPayroll } from '../pages/UserPayroll';
import { News } from '../pages/News';
import { Info } from '../pages/Info';
import { ManagementContact } from '../pages/ManagementContact';
import { GreetingPage } from '../pages/GreetingPage';
import { Dashboard } from '../pages/Dashboard/Dashboard';
import { OverdueTasks } from '../pages/OverdueTasks/OverdueTasks';
import { KnowledgeBaseTheme } from '../pages/KnowledgeBaseTheme';
import { KnowledgeBaseTest } from '../pages/KnowledgeBaseTest';

export const routes = [
  {
    id: 1,
    path: '/project',
    component: GreetingPage,
    exact: true,
  },
  {
    id: 2,
    path: '/project/:projectId/project-schedule',
    component: ProjectSchedule,
    exact: true,
  },
  {
    id: 3,
    path: '/project/:projectId/project-information',
    component: ProjectInformation,
    exact: true,
  },
  {
    id: 4,
    path: '/project/:projectId/corrections',
    component: Corrections,
    exact: true,
  },
  {
    id: 5,
    path: '/project/:projectId/corrections/comment',
    component: Corrections,
    exact: true,
  },
  {
    id: 6,
    path: '/project/:projectId/finances',
    component: Finance,
    exact: true,
  },
  {
    id: 7,
    path: '/project/:projectId/notifications',
    component: Notifications,
    exact: false,
  },
  {
    id: 8,
    path: '/gamification',
    component: Gamification,
    exact: false,
  },
  {
    id: 9,
    path: '/news',
    component: News,
    exact: true,
  },

  {
    id: 10,
    path: '/info',
    component: Info,
    exact: true,
  },
  {
    id: 11,
    path: '/payroll',
    component: UserPayroll,
    exact: true,
  },
  {
    id: 12,
    path: '/management-contact',
    component: ManagementContact,
    exact: true,
  },
  {
    id: 13,
    path: '/project/:projectId/dashboard',
    component: Dashboard,
    exact: true,
  },
  { id: 15, path: '/overdue-task', component: OverdueTasks, exact: true },
  { id: 16, path: '/knowledge-base', component: KnowledgeBase, exact: true },
  { id: 16, path: '/knowledge-base/theme/:themeName/:themeId', component: KnowledgeBaseTheme, exact: true },
  { id: 16, path: '/knowledge-base/test/:themeName/:themeId', component: KnowledgeBaseTest, exact: true },
  {
    id: 14,
    path: '*',
    component: GreetingPage,
    exact: true,
  },
];
