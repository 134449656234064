import React, { createContext, useState } from 'react';

import { Product } from '../../generated/graphql';
import { GET_PRODUCTS, IProductsData } from '../../graphql/queries/products';
import { useQuery } from '@apollo/client';

import { Switch, Route } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';

import { SideBar } from '../../components/SideBar';
import { UserBar } from '../../components/UserBar';
import { Shop } from '../Shop';

import styles from './Gamification.module.scss';

export const ProductsContext = createContext<Product[] | undefined>(undefined);

export const Gamification = () => {
  const [isSideBarOpen, setIsSideBarOpen] = useState(false);

  const { data, loading } = useQuery<IProductsData | undefined>(GET_PRODUCTS);

  return (
    <div className={styles.gamification}>
      <SideBar isSideBarOpen={isSideBarOpen} setIsSideBarOpen={setIsSideBarOpen} />

      <div className={styles.contnet}>
        <Switch>
          <Route path={'/gamification'} exact>
            <UserBar />
          </Route>

          <Route path={'/gamification/shop'}>
            <UserBar role="shopUserBar" />
            {loading ? (
              <Spinner animation="grow" />
            ) : (
              <ProductsContext.Provider value={data && data.getProducts}>
                <Shop />
              </ProductsContext.Provider>
            )}
          </Route>
        </Switch>
      </div>
    </div>
  );
};
