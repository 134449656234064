import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import { GetProjectsByClientDocument, GetProjectsByClientQuery, Project, User } from '../../generated/graphql';
import { UserInfoContext } from '../../pages/Main/Main';
import { NavLink, useParams } from 'react-router-dom';
import { classNames } from '../../constants/classNames';
import projectSettingsColorIcon from '../../accets/icons/projectSettingsColoredIcon.svg';
import projectSettingsIcon from '../../accets/icons/project-settings-icon.svg';
import blackArrowIcon from '../../accets/icons/black-arrow.svg';
import './ProjectNav.scss';
import { useLocation } from 'react-router';

export const ProjectNav = () => {
  const { projects } = useContext<User>(UserInfoContext);
  const { pathname } = useLocation();
  const projectId = pathname.split('/')[2];

  return (
    <div className="d-flex flex-column align-items-center w-100" style={{ maxHeight: '17vh', overflow: 'auto' }}>
      {projects.map(project => (
        <NavLink
          to={`/project/${project!.id}/dashboard`}
          className={classNames({
            'nav-project-btn d-flex justify-content-start align-items-center position-relative w-75': true,
          })}
          key={project.id}
        >
          <img
            className="project-btn-icon"
            src={projectId === project!.id ? projectSettingsColorIcon : projectSettingsIcon}
            alt="icon"
          />
          <span
            className={classNames({
              'active-navbar-btn': projectId === project!.id,
              'project-btn-title d-inline-block': true,
            })}
            title={project.name.length > 20 && project.name}
          >
            {project.name.length > 20 ? `${project.name.substr(0, 20)}...` : project.name}
          </span>
          <div className="project-btn-arrow-icon position-absolute d-flex align-items-center">
            <img src={blackArrowIcon} alt="icon" />
          </div>
        </NavLink>
      ))}
    </div>
  );
};
