import styled from 'styled-components';

export const TaskContainer = styled.div<{ isDragging: boolean }>`
  background: ${({ isDragging }) => (isDragging ? '#f3f3f3' : '#fff')};
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 15px 20px;
  display: flex;
`;

export const TaskContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`;

export const P = styled.p`
  padding-right: 60px;
`;

export const AuthorPhoto = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  align-self: flex-end;
`;

export const EditButtonContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 14px;
  height: 14px;
`;
