import React, { useContext, useEffect, useState } from 'react';
import { Portal } from 'react-portal';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useMutation, useQuery } from '@apollo/client';
import {
  GetNotificationsByParticipantDocument,
  NotificationParticipantStatusChangeDocument,
  GetNotificationsByParticipantQuery,
  NotificationByParticipant,
  NotificationParticipantStatusChangeMutation,
  User,
} from '../../generated/graphql';
import { Notification } from '../Notification';
import { ErrorDisplay } from '../ErrorDisplay';
import { Button, Spinner } from 'react-bootstrap';
import closeIcon from '../../accets/icons/close-icon.svg';
import { UserInfoContext } from '../../pages/Main/Main';
import './ModesNotifications.scss';

interface IModalProps {
  onClick?(e: React.SyntheticEvent<HTMLElement>): void;
}

export const ModalNotifications = ({ onClick }: IModalProps) => {
  const [t] = useTranslation('translation');
  const { id } = useContext<User>(UserInfoContext);

  const [skip, setSkip] = useState<number>(0);
  const [notifications, setNotifications] = useState<NotificationByParticipant[]>([]);

  const [changeNotifyStatus] = useMutation<NotificationParticipantStatusChangeMutation>(
    NotificationParticipantStatusChangeDocument,
  );

  const {
    data: userNotificationData,
    loading: userNotificationDataLoading,
    error: userNotificationDataError,
  } = useQuery<GetNotificationsByParticipantQuery>(GetNotificationsByParticipantDocument, {
    variables: { userId: id, isPublished: true, skip: skip, limit: 5 },
    fetchPolicy: 'network-only',
  });

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 992px)',
  });

  useEffect(() => {
    const userNotifications = userNotificationData?.getNotificationsByParticipant?.notifications;

    if (userNotifications) {
      setNotifications([...notifications, ...userNotifications]);

      const notificationsIds = userNotifications.map(n => n.notification.id);
      notificationsIds.length && changeNotifyStatus({ variables: { notificationIds: notificationsIds, userId: id } });
    }
  }, [userNotificationData?.getNotificationsByParticipant?.notifications]);

  if (userNotificationDataError) {
    return <ErrorDisplay message={userNotificationDataError.message} />;
  }

  const total = userNotificationData?.getNotificationsByParticipant?.total;

  const getNotifications = (): void => {
    total > skip && setSkip(skip + 5);
  };

  const clearEvent = (e: any): void => {
    e.stopPropagation();
  };

  return (
    <Portal>
      <div className="modal-notification-container d-flex justify-content-center" onClick={e => onClick && onClick(e)}>
        <div className="modal-notification overflow-auto" onClick={clearEvent}>
          {isDesktopOrLaptop && (
            <div className="position-relative">
              <h3 className="notifications-title text-center">{t('notifications')}</h3>
              <Button className="close-btn d-flex position-absolute" onClick={e => onClick && onClick(e)}>
                <img className="burger-icon" src={closeIcon} alt="icon" />
              </Button>
            </div>
          )}

          <div className="notifications-container">
            {userNotificationDataLoading ? (
              <div className="d-flex justify-content-center align-items-center">
                <Spinner animation="grow" />
                <span>Подождите...</span>
              </div>
            ) : (
              notifications.map((notification: NotificationByParticipant) => {
                return <Notification key={notification?.notification?.id} notification={notification} />;
              })
            )}
          </div>
          {total > 5 && notifications.length !== total && (
            <Button className="notification-see-more-btn" onClick={getNotifications}>
              предыдущие сообщения
            </Button>
          )}
          {!notifications?.length && (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '88%' }}>
              У вас нет уведомлений
            </div>
          )}
        </div>
      </div>
    </Portal>
  );
};
