import React, { useContext } from 'react';

import { Product } from '../../../generated/graphql';
import { ShopTabContext } from '../../ShopTab/ShopTabContext';

import { IProductCardProps } from '../ProductCard';

import styles from '../ProductCard.module.scss';

export const ModalProductCard = () => {
  const { img, img_sm, title } = styles;

  const { chosenProduct } = useContext(ShopTabContext);

  const { type, image, name } = chosenProduct!;

  return (
    <>
      {type?.displayName === 'impression' ? (
        <>
          <img className={img} src={image ? image : ''} alt="product" />

          <h3 className={title}>{name}</h3>
        </>
      ) : (
        <>
          <img className={img_sm} src={image ? image : ''} alt="product" />
        </>
      )}
    </>
  );
};
