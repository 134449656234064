import styled from 'styled-components';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import { SvgIcon } from '@material-ui/core';
import { Button } from '@material-ui/core';

export const primary = '#FFB61D';
export const lightBlack = '#101010';
export const secondary = '#C4C4C4';
export const white = '#ffffff';
export const red = '#ff2020';
export const black = '#000000';

export const LocalDropzone = styled.div`
  .container .dropzone-instance {
  }
  position: relative;
  margin-bottom: 30px;
`;

export const EditorContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
`;

export const StyledCrossIcon = styled(CancelRoundedIcon)`
  position: absolute;
  right: 8px;
  top: 8px;
  cursor: pointer;
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Label = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #c4c4c4;
`;

export const SVG = styled(SvgIcon)`
  &.MuiSvgIcon-root {
    width: 61px;
    height: 61px;
    margin-bottom: 16px;
    justify-self: center;
    align-self: center;
  }
`;

export const ButtonCustom = styled(Button)<{
  isActive?: boolean;
  width?: string;
}>`
  &.MuiButton-root {
    background-color: ${props => (props.isActive ? (props.color ? `${props.color}` : `black`) : `${primary}`)};
    color: ${white};
    box-shadow: none;
    border-radius: 2px;
    width: ${props => (props.width ? `${props.width}px` : null)};

    &.MuiButton-text {
      padding: 10px 10px 9px 10px;
    }

    &:hover {
      background-color: ${props => (props.isActive ? `black` : `${primary}`)};
    }

    &:focus {
      background-color: ${props => (props.isActive ? `black` : `${primary}`)};
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24);
    }

    &:disabled {
      background-color: ${secondary};
    }
  }
`;
