import styled from 'styled-components';

export const PickerContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;

  .react-datepicker__input-container input {
    padding: 10px 8px;
  }
`;

export const PickerIcon = styled.div<{ url: string }>`
  position: absolute;
  top: 10px;
  right: 14px;
  width: 20px;
  height: 20px;
  background: ${({ url }) => `url(${url})`};
`;
