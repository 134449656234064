import React from 'react';
import { ProjectStep } from '../../../../generated/graphql';
import { RequiredFieldError, TaskData } from '../DashboardModal';

import {
  FieldContainer,
  FieldLabel,
  Input,
  Option,
  SectionContainer,
  SectionTitle,
  Select,
  TextArea,
} from '../DashboardModal.style';
import { ErrorText } from '../EmployeeSelect/EmployeeSelect.style';

interface AddTaskSectionProps {
  taskId?: string;
  task: TaskData;
  setTaskData(obj: TaskData): void;
  epics: ProjectStep[];
  value: string;
  error: RequiredFieldError;
  setError(state: RequiredFieldError): void;
}

export const AddTaskSection = ({ taskId, task, setTaskData, epics, value, error, setError }: AddTaskSectionProps) => {
  const onChange = (value: string): void => {
    if (taskId) {
      return;
    }

    if (typeof value === 'string') {
      setTaskData({ ...task, epicId: epics.find(epic => epic.key === value)?.id });
      setError({ ...error, epicIdError: false });
    }

    if (!value) {
      setTaskData({ ...task, epicId: '' });
    }
  };

  return (
    <SectionContainer>
      <SectionTitle>Добавление задачи</SectionTitle>
      <FieldContainer>
        <FieldLabel>Этап</FieldLabel>
        <Select
          name="select"
          value={value}
          onChange={e => {
            onChange(e.target.value);
          }}
          error={error.epicIdError}
        >
          <Option value="" />
          {epics.map(epic => (
            <Option key={epic.id} value={epic.key}>
              {epic.name}
            </Option>
          ))}
        </Select>
        {error.epicIdError && <ErrorText>Обязательное поле</ErrorText>}
      </FieldContainer>
      <FieldContainer>
        <FieldLabel>Заголовок</FieldLabel>
        <Input
          error={error.nameError}
          value={task.name}
          onChange={e => {
            setTaskData({ ...task, name: e.target.value });
            e.target.value ? setError({ ...error, nameError: false }) : setError({ ...error, nameError: true });
          }}
        />
        {error.nameError && <ErrorText>Обязательное поле</ErrorText>}
      </FieldContainer>
      <FieldContainer>
        <FieldLabel>Описание</FieldLabel>
        <TextArea value={task.description} onChange={e => setTaskData({ ...task, description: e.target.value })} />
      </FieldContainer>
    </SectionContainer>
  );
};
