import React, { useContext, useState } from 'react';

import { IShopTabContext, ShopTabContext } from '../ShopTabContext';

import { ProductsList } from '../../ProductsList';
import { Modal } from '../../Modal';

import styles from './CharacterShopTab.module.scss';
import overlayStyles from '../../../accets/scss/gamification/overlay.module.scss';
import { IShopTabInnerProps } from '../ShopTab';

export const CharacterShopTab = ({ addPurchase, deletePurchase }: IShopTabInnerProps) => {
  const { character, screenshotBtn, shareBtn } = styles;

  const { products, chosenProduct, choseProductHandler }: IShopTabContext = useContext(ShopTabContext);

  const [isModalActive, setModalActive] = useState(false);

  return (
    <div className={character}>
      <div>
        <button className={screenshotBtn} />

        <button className={shareBtn} onClick={() => setModalActive(true)} />
      </div>

      <ProductsList
        role="character"
        // buyProduct={buyProductHandler}
      />

      {isModalActive && <Modal role="share" close={() => setModalActive(false)} />}

      <div className={isModalActive ? overlayStyles.overlay : `${overlayStyles.overlay} ${overlayStyles.hidden}`}></div>
    </div>
  );
};
