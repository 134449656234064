import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import {
  CreateProjectCorrectionDocument,
  DeleteProjectCorrectionDocument,
  GetProjectCorrectionsLightDocument,
  GetProjectCorrectionsDocument,
  CreateCorrectionCommentDocument,
  UpdateProjectCorrectionDocument,
  CreateProjectCorrectionMutation,
  DeleteProjectCorrectionMutation,
  ProjectCorrection,
  GetProjectCorrectionsLightQuery,
  GetProjectCorrectionsQuery,
  CorrectionsLight,
  CreateCorrectionCommentMutation,
  UpdateProjectCorrectionMutation,
} from '../../generated/graphql';
import { ProjectCorrectionItem } from '../../components/ProjectCorrectionItem';
import { CommentForm } from '../../components/CommentForm';
import { FilterCorrections } from '../../components/FilterCorrections';
import { ErrorDisplay } from '../../components/ErrorDisplay';
import { LoadingDisplay } from '../../components/LoadingDisplay';
import './Corrections.scss';

export interface CorrectionParams {
  correctionId: string;
  text: string;
}

export const Corrections = () => {
  const { projectId } = useParams<{ projectId: string }>();

  const [isEditForm, setIsEditForm] = useState<boolean>(false);
  const [activeFilterBtn, setActiveFilterBtn] = useState<string>('');
  const [inputValue, setInputValue] = useState<string>('');
  const [isTextArea, setIsTextArea] = useState<boolean>(false);
  const [textAreaValue, setTextAreaValue] = useState<string>('');
  const [file, setFile] = useState<null | File>(null);

  const {
    data: projectCorrectionsLightData,
    loading: projectCorrectionsLightDataLoading,
    error: projectCorrectionsLightDataLoadingError,
  } = useQuery<GetProjectCorrectionsLightQuery>(GetProjectCorrectionsLightDocument, {
    variables: { projectId: projectId },
  });

  const projectCorrectionsLight = projectCorrectionsLightData?.getProjectCorrectionsLight as CorrectionsLight[];

  const {
    data: projectCorrectionsData,
    loading: projectCorrectionsDataLoading,
    error: projectCorrectionsDataError,
  } = useQuery<GetProjectCorrectionsQuery>(GetProjectCorrectionsDocument, {
    variables: {
      projectId: projectId,
      statuses: [activeFilterBtn || (projectCorrectionsLight && projectCorrectionsLight[0]?.status)],
    },
  });

  const [createProjectCorrection, { loading: createProjectCorrectionLoading, error: createProjectCorrectionError }] =
    useMutation<CreateProjectCorrectionMutation>(CreateProjectCorrectionDocument);
  const [updateProjectCorrection, { loading: updateProjectCorrectionLoading, error: updateProjectCorrectionError }] =
    useMutation<UpdateProjectCorrectionMutation>(UpdateProjectCorrectionDocument);
  const [deleteProjectCorrection, { loading: deleteProjectCorrectionLoading, error: deleteProjectCorrectionError }] =
    useMutation<DeleteProjectCorrectionMutation>(DeleteProjectCorrectionDocument);
  const [createCorrectionComment, { loading: createCorrectionCommentLoading, error: createCorrectionCommentError }] =
    useMutation<CreateCorrectionCommentMutation>(CreateCorrectionCommentDocument);

  const projectCorrections = projectCorrectionsData?.getProjectCorrections as ProjectCorrection[];

  if (
    projectCorrectionsLightDataLoading ||
    projectCorrectionsDataLoading ||
    createProjectCorrectionLoading ||
    updateProjectCorrectionLoading ||
    deleteProjectCorrectionLoading ||
    createCorrectionCommentLoading
  ) {
    return <LoadingDisplay />;
  }

  if (
    projectCorrectionsLightDataLoadingError ||
    projectCorrectionsDataError ||
    createProjectCorrectionError ||
    updateProjectCorrectionError ||
    deleteProjectCorrectionError ||
    createCorrectionCommentError
  ) {
    return (
      <ErrorDisplay
        message={
          projectCorrectionsLightDataLoadingError?.message ||
          projectCorrectionsDataError?.message ||
          createProjectCorrectionError?.message ||
          updateProjectCorrectionError?.message ||
          deleteProjectCorrectionError?.message ||
          createCorrectionCommentError?.message
        }
      />
    );
  }

  const getInputFile = (files: FileList): void => {
    const photo: File = files[0];
    setFile(photo);
  };

  const onCheckList = (): void => {
    setIsTextArea(true);
    setTextAreaValue('1. Текст' + '\n' + '2. Текст' + '\n' + '3. Текст' + '\n' + '4. Текст');
  };

  const createNewCorrection = async (): Promise<void> => {
    if (inputValue !== '') {
      await createProjectCorrection({
        variables: { projectCorrectionData: { projectId, text: inputValue } },
        refetchQueries: [
          {
            query: GetProjectCorrectionsDocument,
            variables: { projectId, statuses: [activeFilterBtn] },
          },
          {
            query: GetProjectCorrectionsLightDocument,
            variables: { projectId },
          },
        ],
      });
    }
  };

  const updateNewCorrection = async ({ correctionId, text }: CorrectionParams): Promise<void> => {
    await updateProjectCorrection({
      variables: {
        projectCorrectionData: {
          id: correctionId,
          text,
        },
      },
      refetchQueries: [
        {
          query: GetProjectCorrectionsDocument,
          variables: { projectId, statuses: [activeFilterBtn] },
        },
        {
          query: GetProjectCorrectionsLightDocument,
          variables: { projectId },
        },
      ],
    });
  };

  const deleteNewCorrection = async (projectCorrectionId: string): Promise<void> => {
    await deleteProjectCorrection({
      variables: {
        projectCorrectionId,
      },
      refetchQueries: [
        {
          query: GetProjectCorrectionsDocument,
          variables: { projectId, statuses: [activeFilterBtn] },
        },
        {
          query: GetProjectCorrectionsLightDocument,
          variables: { projectId },
        },
      ],
    });
  };

  const createComment = async ({ correctionId, text }: CorrectionParams): Promise<void> => {
    await createCorrectionComment({
      variables: {
        correctionCommentData: {
          text,
          correctionId,
        },
      },
      refetchQueries: [
        {
          query: GetProjectCorrectionsDocument,
          variables: { projectId, statuses: [activeFilterBtn] },
        },
        {
          query: GetProjectCorrectionsLightDocument,
          variables: { projectId },
        },
      ],
    });
  };

  return (
    <>
      <div className="page-container">
        <h1 className="edits-page-title d-lg-none text-center">Правки</h1>
        <FilterCorrections
          activeFilterBtn={activeFilterBtn}
          setActiveFilterBtn={setActiveFilterBtn}
          setIsEditForm={setIsEditForm}
          projectCorrectionsCounts={projectCorrectionsLight}
        />

        <section className="edits-section">
          {isEditForm && activeFilterBtn === 'NEW' && (
            <div className="edits-form-container d-none d-lg-block">
              <CommentForm
                btnName="edd"
                inputValue={inputValue}
                placeholder="Правка"
                isTextArea={isTextArea}
                textAreaValue={textAreaValue}
                createCorrection
                desktop
                setIsEditForm={setIsEditForm}
                setInputValue={setInputValue}
                setTextAreaValue={setTextAreaValue}
                setIsTextArea={setIsTextArea}
                onCheckList={onCheckList}
                sendNewEdit={createNewCorrection}
                getInputFile={getInputFile}
              />
            </div>
          )}
          {activeFilterBtn !== '' &&
            projectCorrections?.map(correction => (
              <ProjectCorrectionItem
                key={correction?.id}
                correction={correction}
                filter={activeFilterBtn}
                isTextArea={isTextArea}
                textAreaValue={textAreaValue}
                setTextAreaValue={setTextAreaValue}
                setIsTextArea={setIsTextArea}
                onCheckList={onCheckList}
                getInputFile={getInputFile}
                deleteNewCorrection={deleteNewCorrection}
                createComment={createComment}
                updateNewCorrection={updateNewCorrection}
              />
            ))}
          {projectCorrections?.length === 0 && <h6 className="text-center">Нет правок в этом разделе</h6>}
        </section>

        {activeFilterBtn === 'NEW' && (
          <div className="edits-form-container-mobile d-lg-none">
            <CommentForm
              btnName=""
              inputValue={inputValue}
              placeholder="Впишите правку..."
              isTextArea={isTextArea}
              setIsEditForm={setIsEditForm}
              setInputValue={setInputValue}
              textAreaValue={textAreaValue}
              setTextAreaValue={setTextAreaValue}
              setIsTextArea={setIsTextArea}
              onCheckList={onCheckList}
              sendNewEdit={createNewCorrection}
              getInputFile={getInputFile}
            />
          </div>
        )}
      </div>
    </>
  );
};
