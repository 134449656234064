import React, { useContext } from 'react';
import { classNames } from '../../constants/classNames';
import { Button } from 'react-bootstrap';
import closeIcon from '../../accets/icons/close-icon.svg';
import { Logo } from '../Logo';
import { NavLink } from 'react-router-dom';
import projectSettingsIcon from '../../accets/icons/project-settings-icon.svg';
import blackArrowIcon from '../../accets/icons/black-arrow.svg';
import { SidebarContacts } from '../SidebarContacts';
import burgerMenu from '../../accets/icons/burger-menu-icon.svg';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { GetProjectsByClientDocument, GetProjectsByClientQuery, Project, User } from '../../generated/graphql';
import { UserInfoContext } from '../../pages/Main/Main';
import { useLocation } from 'react-router';

interface ClientSidebarProps {
  isSideBarOpen: boolean;
  setIsSideBarOpen(isOpen: boolean): void;
}

export const ClientSidebar = ({ isSideBarOpen, setIsSideBarOpen }: ClientSidebarProps) => {
  const [t] = useTranslation('translation');
  const { id: userId } = useContext<User>(UserInfoContext);
  const { pathname } = useLocation();
  const projectId = pathname.split('/')[2];

  const {
    data: projectsData,
    loading,
    error,
  } = useQuery<GetProjectsByClientQuery>(GetProjectsByClientDocument, {
    variables: { userId },
  });

  if (loading) {
    return null;
  }

  if (error) {
    return null;
  }

  const projects = projectsData?.getProjectsByClient as Project[];

  return (
    <aside
      className={classNames({
        sidebar: true,
        'sidebar-open': isSideBarOpen,
      })}
    >
      {isSideBarOpen ? (
        <>
          <Button
            className="sidebar-close-btn d-none d-lg-block position-absolute"
            onClick={() => {
              setIsSideBarOpen(false);
            }}
          >
            <img src={closeIcon} alt="icon" />
          </Button>
          <div className="sidebar-logo-container d-none d-lg-block">
            <Logo logoFontSize={20} typeLogo="smallLogo" />
          </div>

          <h2 className="sidebar-title text-center">{t('my projects')}</h2>

          <div className="sidebar-projects">
            {!projects.length && <h4 className="project-btn-title text-center">У вас пока нет проектов</h4>}
            <div className="project-btn-container d-flex flex-column align-items-center overflow-auto">
              {projects.map(project => (
                <NavLink
                  to={`/project/${project!.id}/project-schedule`}
                  className={classNames({
                    'sidebar-project-btn d-flex justify-content-start align-items-center position-relative': true,
                    'active-navbar-btn': projectId === project!.id,
                  })}
                  key={project.id}
                >
                  <img className="project-btn-icon" src={projectSettingsIcon} alt="icon" />
                  <span className="project-btn-title d-inline-block">{project.name}</span>
                  <img className="project-btn-arrow-icon position-absolute" src={blackArrowIcon} alt="icon" />
                </NavLink>
              ))}
            </div>
          </div>

          <SidebarContacts />
        </>
      ) : (
        <Button
          className="sidebar-burger-btn d-none d-lg-block"
          onClick={() => {
            setIsSideBarOpen(true);
          }}
        >
          <img src={burgerMenu} alt="icon" />
        </Button>
      )}
    </aside>
  );
};
