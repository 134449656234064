import React, { useState } from 'react';
import { FilterCorrections } from '../../components/FilterCorrections';
import './ManagemetnContact.scss';
import { CommentForm } from '../../components/CommentForm';
// import flexik from '../../accets/img/flexik.svg';

export const ManagementContact = () => {
  const [activeFilterBtn, setActiveFilterBtn] = useState<string>('');
  const [isEditForm, setIsEditForm] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');
  const [isTextArea, setIsTextArea] = useState<boolean>(false);
  const [textAreaValue, setTextAreaValue] = useState<string>('');
  const [file, setFile] = useState<null | File>(null);

  const onCheckList = (): void => {
    setIsTextArea(true);
    setTextAreaValue('1. Текст' + '\n' + '2. Текст' + '\n' + '3. Текст' + '\n' + '4. Текст');
  };

  const createNewCorrection = async (): Promise<void> => {
    if (inputValue !== '') {
      console.log('create');
    }
  };

  const getInputFile = (files: FileList): void => {
    const photo: File = files[0];
    setFile(photo);
  };

  return (
    <>
      <div className="page-container">
        <h1 className="management-contact-title text-center">Связь с руководством</h1>
        <div className="hello-section d-flex justify-content-between align-items-center">
          <span className="text">Есть вопросы или пожелания? Нужна помощь? Обращайтесь! :)</span>
          {/* <img src={flexik} alt="flexik" /> */}
        </div>
        <div className="mb-5">
          <CommentForm
            btnName="написать"
            inputValue={inputValue}
            placeholder="Ввести сообщение"
            isTextArea={isTextArea}
            textAreaValue={textAreaValue}
            createCorrection
            desktop
            setIsEditForm={setIsEditForm}
            setInputValue={setInputValue}
            setTextAreaValue={setTextAreaValue}
            setIsTextArea={setIsTextArea}
            onCheckList={onCheckList}
            sendNewEdit={createNewCorrection}
            getInputFile={getInputFile}
          />
        </div>

        <FilterCorrections
          activeFilterBtn={activeFilterBtn}
          setActiveFilterBtn={setActiveFilterBtn}
          setIsEditForm={setIsEditForm}
          withCreateBtn={false}
        />
      </div>
    </>
  );
};
