import React, { useEffect, useState } from 'react';

import { DeleteFromCartModal } from './DeleteFromCartModal';
import { AddToCartModal } from './AddToCartModal';
import { RejectConfirmModal } from './RejectConfirmModal';
import { ShareModal } from './ShareModal';
import { InfoModal } from './InfoModal';

import styles from './Modal.module.scss';
import { SuccessConfirmModal } from './SuccessConfirmModal';

interface IModalProps {
  close(): void;
  role?: string;
  creditName?: string;
  credits?: {
    flex: boolean;
    kpi: boolean;
    level: boolean;
  };
}

export const Modal = ({ role, close, creditName, credits }: IModalProps) => {
  const { body, hide, closeBtn } = styles;

  const [isVisible, setIsVisible] = useState(false);

  const autoDelay = 4000;
  const forcedDelay = 300;

  let autoCloseTimer: ReturnType<typeof setTimeout>;
  let autoVisibilityTimer: ReturnType<typeof setTimeout>;

  const modalHide = (): void => setIsVisible(false);

  const closeHandler = (delay: number): void => {
    autoCloseTimer = setTimeout(close, delay);
    autoVisibilityTimer = setTimeout(modalHide, delay - 300);
  };

  useEffect(() => {
    setIsVisible(true);
    closeHandler(autoDelay);

    return () => {
      clearTimeout(autoCloseTimer);
      clearTimeout(autoVisibilityTimer);
    };
  }, []);

  let content;

  switch (role) {
    case 'add':
      content = <AddToCartModal />;
      break;

    case 'delete':
      content = <DeleteFromCartModal />;
      break;

    case 'reject':
      content = <RejectConfirmModal credits={credits} />;
      break;

    case 'success':
      content = <SuccessConfirmModal />;
      break;

    case 'share':
      content = <ShareModal />;
      break;

    case 'info':
      content = <InfoModal creditName={creditName} />;
      break;
  }

  return (
    <div className={isVisible ? body : `${body} ${hide}`}>
      <button className={closeBtn} onClick={() => closeHandler(forcedDelay)}></button>
      {content}
    </div>
  );
};
