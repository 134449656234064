import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import { CREATE_PURCHASE, DELETE_PURCHASE } from '../../graphql/mutations/purchases';
import { GET_PURCHASES_IN_CART } from '../../graphql/queries/purchases';

import { Product } from '../../generated/graphql';
import { IShopTabContext, ShopTabContext } from './ShopTabContext';

import { CharacterShopTab } from './CharacterShopTab';
import { PersonalShopTab } from './PersonalShopTab';
import { Modal } from '../Modal';

import overlayStyles from '../../accets/scss/gamification/overlay.module.scss';

interface IShopTabProps {
  role?: string;
}

export interface IShopTabInnerProps {
  addPurchase(id: string): void;
  deletePurchase(id: string): void;
}

export const ShopTab = ({ role }: IShopTabProps) => {
  const { getProduct }: IShopTabContext = useContext(ShopTabContext);

  const [addPurchase] = useMutation(CREATE_PURCHASE, {
    refetchQueries: [{ query: GET_PURCHASES_IN_CART, variables: { getPurchasesData: { limit: 1000 } } }],
  });
  const [deletePurchase] = useMutation(DELETE_PURCHASE, {
    refetchQueries: [{ query: GET_PURCHASES_IN_CART, variables: { getPurchasesData: { limit: 1000 } } }],
  });

  const [isAddModalActive, setAddModalActive] = useState(false);
  const [isDeleteModalActive, setDeleteModalActive] = useState(false);

  const addPurchaseHandler = (id: string): void => {
    const product: Product | undefined = getProduct(id);

    setAddModalActive(true);

    addPurchase({
      variables: { productId: product?.id },
    });
  };

  const deletePurchaseHandler = (id: string): void => {
    setDeleteModalActive(true);

    deletePurchase({
      variables: { purchaseId: id },
    });
  };

  let tab;

  switch (role) {
    case 'personal':
      tab = <PersonalShopTab addPurchase={addPurchaseHandler} deletePurchase={deletePurchaseHandler} />;
      break;

    default:
      tab = <CharacterShopTab addPurchase={addPurchaseHandler} deletePurchase={deletePurchaseHandler} />;
      break;
  }

  return (
    <>
      {tab}
      {isAddModalActive && <Modal close={() => setAddModalActive(false)} role="add" />}

      {isDeleteModalActive && <Modal role="delete" close={() => setDeleteModalActive(false)} />}

      <div
        className={
          isAddModalActive || isDeleteModalActive
            ? overlayStyles.overlay
            : `${overlayStyles.overlay} ${overlayStyles.hidden}`
        }
      ></div>
    </>
  );
};
