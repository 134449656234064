import React from 'react';

import { useHistory, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import { useQuery } from '@apollo/client';
import {
  GetKnowledgeBaseBlocksByThemeIdDocument,
  GetKnowledgeBaseBlocksByThemeIdQuery,
  KnowledgeBaseBlockTypeEnum,
} from '../../generated/graphql';

import { Box, Checkbox } from '@material-ui/core';
import { CheckBox, IndeterminateCheckBox, RadioButtonUnchecked } from '@material-ui/icons';

import { CustomButton } from '../../components/CustomButton';
import { ErrorDisplay } from '../../components/ErrorDisplay';
import { LoadingDisplay } from '../../components/LoadingDisplay';

import { black } from '../../constants/Styles/colors';
import { PageContainer } from '../../constants/Styles/generalPageStyle';
import { BlockQuestion, EmptyThemePlaceholder, ThemeTitle } from '../../constants/Styles/styles';

import { HeaderBlock, SubtitleBlock, TextBlock, PhotoBlock } from './KnowledgeBaseTheme.style';

export const KnowledgeBaseTheme = () => {
  const { themeName, themeId } = useParams<{ themeName: string; themeId: string }>();
  const history = useHistory();
  const [t] = useTranslation('translation');

  const { data, loading, error } = useQuery<GetKnowledgeBaseBlocksByThemeIdQuery>(
    GetKnowledgeBaseBlocksByThemeIdDocument,
    { variables: { themeId } },
  );

  let content;

  if (loading) {
    content = <LoadingDisplay />;
  }

  if (error) {
    content = <ErrorDisplay message={error.message} />;
  }

  if (data) {
    const blocks = data.getKnowledgeBaseBlocksByThemeId;

    content = blocks.length ? (
      blocks.map(({ id, type, content, lists, photo, video, testQuestion, testAnswers }) => {
        switch (type) {
          case KnowledgeBaseBlockTypeEnum.Header:
            return <HeaderBlock key={id}>{content}</HeaderBlock>;

          case KnowledgeBaseBlockTypeEnum.Subtitle:
            return <SubtitleBlock key={id}>{content}</SubtitleBlock>;

          case KnowledgeBaseBlockTypeEnum.Text:
            return <TextBlock key={id}>{content}</TextBlock>;

          case KnowledgeBaseBlockTypeEnum.List:
            return (
              <Box key={id} mt="20px">
                {!!lists.length &&
                  lists.map((item, i) => (
                    <Box key={item.id} mb="10px">
                      {item.isNumbered ? (
                        `${i + 1}.`
                      ) : (
                        <RadioButtonUnchecked style={{ fontSize: '8px', background: 'black', borderRadius: '50%' }} />
                      )}
                      {` ${item.content}`}
                    </Box>
                  ))}
              </Box>
            );

          case KnowledgeBaseBlockTypeEnum.Photo:
            return <PhotoBlock key={id} src={photo} />;

          case KnowledgeBaseBlockTypeEnum.Video:
            return (
              <Box m="20px 0" textAlign="center" maxWidth="100%">
                <iframe
                  frameBorder="0"
                  width="100%"
                  height="480"
                  src={video}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="video block"
                />
              </Box>
            );

          case KnowledgeBaseBlockTypeEnum.Question:
            return (
              <Box key={id}>
                {!!testAnswers.length && (
                  <>
                    <BlockQuestion>{testQuestion}</BlockQuestion>

                    {testAnswers.map(answer => (
                      <Box key={answer.id} mb="10px">
                        <Checkbox
                          checkedIcon={
                            answer.isAnswerTrue ? (
                              <CheckBox htmlColor="green" />
                            ) : (
                              <IndeterminateCheckBox htmlColor="red" />
                            )
                          }
                        />
                        {answer.content}
                      </Box>
                    ))}
                  </>
                )}
              </Box>
            );

          default:
            return <TextBlock key={id}>{content}</TextBlock>;
        }
      })
    ) : (
      <EmptyThemePlaceholder>Информация по данной теме отсутствует</EmptyThemePlaceholder>
    );
  }

  return (
    <PageContainer>
      <ThemeTitle>{themeName}</ThemeTitle>
      <Box width="100%" fontFamily="Roboto">
        {content}
      </Box>

      <Box m="20px 0" width="100%" display="flex" justifyContent="flex-end">
        <Box mr="20px">
          <CustomButton bg_color={black} callback={() => history.push('/knowledge-base')}>
            <span>{t('Back')}</span>
          </CustomButton>
        </Box>

        <CustomButton txt_color={black} callback={() => history.push(`/knowledge-base/test/${themeName}/${themeId}`)}>
          <span>{t('Test')}</span>
        </CustomButton>
      </Box>
    </PageContainer>
  );
};
