import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Masonry } from 'masonic';
import { useTranslation } from 'react-i18next';
import { GetNewsDocument, GetNewsQuery } from '../../generated/graphql';
import { NewsItem } from '../../components/NewsItem';
import { NewsModal } from '../../components/NewsModal';
import { ErrorDisplay } from '../../components/ErrorDisplay';
import './News.scss';

interface newsItem {
  id: string;
  name: string;
  photo: string;
  youTubeLink: string;
  publishedAt: string;
  description: string;
}

interface Items {
  publishedAt: string;
  setIsNewsModal: (value: ((prevState: boolean) => boolean) | boolean) => void;
  setCurrentNews: (value: ((prevState: newsItem | null) => newsItem | null) | newsItem | null) => void;
  name: string | null;
  photo: string | null;
  description: string | null;
  id: string | null;
  youTubeLink: string | null;
}

interface State {
  news: any[];
  curPage: number;
  pageSize: number;
  totalPage: number;
  total: number;
}

export const News = () => {
  const [t] = useTranslation('translation');
  const [isNewsModal, setIsNewsModal] = useState<boolean>(false);
  const [currentNews, setCurrentNews] = useState<null | newsItem>(null);
  const [state, setState] = useState<State>({
    news: [],
    curPage: 1,
    pageSize: 4,
    totalPage: 0,
    total: 0,
  });

  const {
    data: newsData,
    loading: newsLoading,
    error: newsError,
  } = useQuery<GetNewsQuery>(GetNewsDocument, {
    variables: {
      limit: state.pageSize,
      skip: (state.curPage - 1) * state.pageSize,
      isPublished: true,
    },
  });

  useEffect(() => {
    if (newsData?.getNews.news) {
      setState({ ...state, news: [...state.news, ...newsData.getNews.news], total: newsData.getNews.total });
    }
  }, [newsData?.getNews.news]);

  const handleScroll = (e: SyntheticEvent<HTMLDivElement>) => {
    const bottom =
      +(e.currentTarget.scrollHeight - e.currentTarget.scrollTop).toFixed() === e.currentTarget.clientHeight;
    const stop = Math.ceil(state.total / state.pageSize) === state.curPage;

    if (bottom) {
      if (stop) {
        return;
      }

      setState({ ...state, curPage: state.curPage + 1 });
    }
  };

  let items: Items[] = [];

  if (state.news.length) {
    items = state.news.map(el => {
      return {
        id: el.id,
        name: el.name,
        photo: el.photo,
        youTubeLink: el.youTubeLink,
        publishedAt: el.publishedAt as string,
        description: el.description,
        setIsNewsModal: setIsNewsModal,
        setCurrentNews: setCurrentNews,
      };
    });
  }

  if (newsError) {
    return <ErrorDisplay message={newsError?.message} />;
  }

  return (
    <>
      <div className="page-container">
        <h2 className="project-info-title text-center">{t('company news')}</h2>
        <div className="news-items-container" id="news-items" onScroll={handleScroll}>
          {items.length ? (
            <Masonry items={items} columnGutter={8} columnWidth={400} overscanBy={5} render={NewsItem} />
          ) : (
            <h6 className="text-center">Новостей пока нет</h6>
          )}
        </div>
        {newsLoading && (
          <p className="position-absolute" style={{ bottom: '0px' }}>
            Loading...
          </p>
        )}
        {isNewsModal && <NewsModal closeModal={setIsNewsModal} currentNews={currentNews as newsItem} />}
      </div>
    </>
  );
};
