import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import Cookies from "js-cookie";
import { TOKEN } from "../../core/apollo/client";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Logo } from "../../components/Logo";
import { FormLoginRegistration } from "../../components/FormLoginRegistration";
import { IsFromEnum, LoginDocument, LoginMutation } from "../../generated/graphql";
import { Col, Row } from "react-bootstrap";
import { Snackbar } from "../../components/Snackbar";
import "./SignIn.scss";
import { LoadingDisplay } from "../../components/LoadingDisplay";

interface IInitialValues {
  email: string;
  password: string;
  isFrom: IsFromEnum;
}

const initialValues: IInitialValues = {
  email: "",
  password: "",
  isFrom: IsFromEnum.LkStaff,
};

export const SignIn = () => {
  const history = useHistory();
  const [t] = useTranslation("translation");
  const [login, { data, loading, error }] = useMutation<LoginMutation>(LoginDocument);

  const passwordRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      email: Yup.string().email(t("invalid email")).required(t("required")),
      password: Yup.string()
        .matches(passwordRegExp, t("invalid password"))
        .max(22, t("invalid password"))
        .required(t("required")),
    }),
    onSubmit: async values => {
      await login({
        variables: {
          email: values.email.toLowerCase(),
          password: values.password,
          isFrom: values.isFrom,
        },
      });
    },
  });

  useEffect(() => {
    if (data) {
      Cookies.set(TOKEN, data.login.token, { expires: 1 });
      history.push("/");
    }
  }, [data]);

  if (loading) {
    return <LoadingDisplay />;
  }

  const errorHandler = (errorMessage: string): string => {
    let message;

    if (errorMessage.includes("Not Allowed")) {
      message = "Your email is verified. You will be able to log in after the administrator has verified your details";
    }

    if (errorMessage.includes("No such user found for email")) {
      message = "such user not exist";
    }

    return message;
  };

  return (
    <>
      <Row className="h-100vh">
        <Col className="left-section p-0 d-none d-md-flex align-items-center justify-content-center">
          <div className="d-block d-lg-none">
            <Logo logoFontSize={55} typeLogo="mainLogo" />
          </div>
          <div className="d-none d-lg-block">
            <Logo logoFontSize={75} typeLogo="mainLogo" />
          </div>
        </Col>

        <Col className="right-section p-0 d-flex flex-column justify-content-center  align-items-center">
          <div className="d-block d-md-none">
            <Logo logoFontSize={30} typeLogo="mainLogo" />
          </div>

          <div className="position-relative">
            <FormLoginRegistration formik={formik} />
            {error && <Snackbar text={t(errorHandler(error.message))} className="authorizationError" />}
          </div>
        </Col>
      </Row>
    </>
  );
};
