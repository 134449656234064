import React, { useContext } from 'react';
import classNames from 'classnames';
import defaultUserPhoto from '../../accets/icons/defaultUserPhoto.svg';
import { Maybe, User } from '../../generated/graphql';
import { useTranslation } from 'react-i18next';
import './Comment.scss';
import { UserInfoContext } from '../../pages/Main/Main';

interface ICommentProps {
  createdBy: Maybe<User> | undefined;
  commentText: string | undefined;
}

export const Comment = ({ createdBy, commentText }: ICommentProps) => {
  const [t] = useTranslation('translation');
  const { id: userId } = useContext<User>(UserInfoContext);

  return (
    <>
      <div
        className={classNames({
          'comment-content': true,
          'self-message': createdBy?.id === userId,
        })}
      >
        <img
          className="comment-user-img d-none d-lg-block"
          src={createdBy?.photo ? createdBy?.photo : defaultUserPhoto}
          alt="photo"
        />
        <div className="d-flex flex-column">
          <span
            className={classNames({
              'comment-name': true,
              'self-name': createdBy?.id === userId,
            })}
          >
            {createdBy?.id === userId ? t('you') : createdBy?.firstName}
          </span>
          <p className="comment-text">{commentText}</p>
        </div>
      </div>
    </>
  );
};
