import React from 'react';
import { Portal } from 'react-portal';
import { Spinner } from 'react-bootstrap';

export const LoadingDisplay = () => {
  return (
    <Portal>
      <div className="confirmation-modal-container d-flex justify-content-center align-items-center">
        <div className="d-flex justify-content-center align-items-center ">
          <Spinner animation="grow" />
          <span>Подождите...</span>
        </div>
      </div>
    </Portal>
  );
};
