import styled from 'styled-components';

export const HeaderBlock = styled.h3`
  font-size: 34px;
  font-weight: bold;
  margin: 40px 0;
  text-transform: uppercase;
`;

export const SubtitleBlock = styled.h4`
  font-size: 28px;
  font-weight: bold;
  margin: 20px 0;
`;

export const TextBlock = styled.p`
  margin: 20px 0;
`;

export const PhotoBlock = styled.img`
  margin: 20px 0;
  width: 100%;
  height: 100%;
`;
