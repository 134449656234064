import React from 'react';
import { Snackbar } from '@material-ui/core';
import { Alert, Color } from '@material-ui/lab';

const severity: { [key: string]: Color } = {
  error: 'error',
  warning: 'warning',
  success: 'success',
  info: 'info',
};

interface IMessageSnackbarProps {
  open: boolean;
  onClose(): void;
  variant?: string;
  message: string;
}

export const MessageSnackbar = ({ open, onClose, variant, message }: IMessageSnackbarProps) => {
  return (
    <Snackbar open={open} onClose={onClose} autoHideDuration={5000}>
      <Alert severity={variant ? severity[variant] : 'error'}>{message}</Alert>
    </Snackbar>
  );
};
