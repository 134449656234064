import React from 'react';
import { ComponentContainer, ComponentLabel } from '../DashboardModal.style';
import { Input, InputContainer, Unit } from './TimeInput.style';
import { TaskData } from '../DashboardModal';

interface TimeInputProps {
  label: string;
  field: string;
  value: number;
  taskData: TaskData;
  setTaskData(obj: TaskData): void;
  unit: string;
}

export const TimeInput = ({ label, field, value, taskData, setTaskData, unit }: TimeInputProps) => {
  return (
    <ComponentContainer>
      <ComponentLabel>{label}</ComponentLabel>
      <InputContainer>
        <Input
          type="number"
          //value={(value / 60).toFixed(2)}
          onChange={({ target: { value } }) => {
            setTaskData({ ...taskData, [field]: Math.round(+value * 60) });
            console.log('e', value);
          }}
          value={Math.round((+value / 60) * 100) / 100}
          // onChange={({ target: { value } }) =>
          //   setTaskData({
          //     ...taskData,
          //     timeSpent: Math.round(+value * 60),
          //   })
          // }
        />
        <Unit>{unit}</Unit>
      </InputContainer>
    </ComponentContainer>
  );
};
