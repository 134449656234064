import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Gantt, Task, ViewMode } from 'gantt-task-react';
import 'gantt-task-react/dist/index.css';
import { ChartData2, GetChartData2Document, GetChartData2Query } from '../../../generated/graphql';
import { initTasks } from '../../../utils/initGanttTasks';
import { useLazyQuery } from '@apollo/client';
import { ErrorDisplay } from '../../../components/ErrorDisplay';
import { Spinner } from 'react-bootstrap';

interface GanttTaskReactProps {
  epics: ChartData2[];
}

export const GanttTaskReact = ({ epics }: GanttTaskReactProps) => {
  const { search } = useLocation();
  const { projectId } = useParams<{ projectId: string }>();

  const [view, setView] = useState<ViewMode>(ViewMode.Day);
  const [tasks, setTasks] = useState<Task[]>(initTasks({ epics: epics, tasks: [] }));

  const [getTasks, { data: tasksData, loading: tasksLoading, error: tasksError }] =
    useLazyQuery<GetChartData2Query>(GetChartData2Document);

  useEffect(() => {
    search.includes('month') && setView(ViewMode.Month);
    search.includes('weeks') && setView(ViewMode.Week);
    search.includes('days') && setView(ViewMode.Day);
  }, [search]);

  useEffect(() => {
    if (tasksData) {
      const tasks = tasksData.getChartData2;
      setTasks(initTasks({ epics: epics, tasks: tasks }));
    }
  }, [tasksData]);

  let columnWidth = 60;

  if (view === ViewMode.Month) {
    columnWidth = 300;
  } else if (view === ViewMode.Week) {
    columnWidth = 250;
  }

  const handleExpanderClick = (task: Task) => {
    getTasks({
      variables: {
        projectId,
        isEpic: false,
        epicId: task.id,
      },
    });

    tasksData && setTasks(tasks.map(t => (t.id === task.id ? task : t)));
  };

  let content: JSX.Element;

  if (tasksLoading) {
    console.log('tasksLoading');
    content = (
      <div className="d-flex justify-content-center align-items-center ">
        <Spinner animation="grow" />
        <span>Подождите...</span>
      </div>
    );
  }

  if (tasksError) {
    content = <ErrorDisplay message={tasksError.message} />;
  }

  if (tasks?.length) {
    content = (
      <Gantt
        tasks={tasks}
        viewMode={view}
        locale="ru"
        onExpanderClick={handleExpanderClick}
        columnWidth={columnWidth}
        barFill={50}
      />
    );
  }

  return <>{content}</>;
};
