import React, { useContext } from 'react';

import { ShopTabContext } from '../../ShopTab/ShopTabContext';

import { IProductCardProps } from '../ProductCard';

import styles from '../ProductCard.module.scss';

export const ShoppingCartProductCard = ({ purchase, deletePurchase }: IProductCardProps) => {
  const { img, title, removeBtn, costList, costItem, balance, level, kpi } = styles;
  const { image, name, flex, requiredLevel, requiredKpi } = purchase!.product!;
  const id = purchase?.id;

  const { setChosenPurchase } = useContext(ShopTabContext);

  return (
    <>
      <img className={img} src={`../${image}`} alt="product" />

      <h3 className={title}>{name}</h3>

      <div>
        <ul className={costList}>
          <li className={`${costItem} ${balance}`}>{flex + ' Flex'}</li>
          <li className={`${costItem} ${level}`}>{requiredLevel}</li>
          <li className={`${costItem} ${kpi}`}>{requiredKpi}</li>
        </ul>

        <button
          className={removeBtn}
          type="button"
          onClickCapture={e => {
            if (deletePurchase && id!) {
              setChosenPurchase(undefined);
              deletePurchase(id);
              e.stopPropagation();
            }
          }}
        ></button>
      </div>
    </>
  );
};
