import React, { useState, useContext } from 'react';
import { Modal } from '../../Modal';
import { Link } from 'react-router-dom';
import { User } from '../../../generated/graphql';
import styles from './ShopUserBar.module.scss';
import overlayStyles from '../../../accets/scss/gamification/overlay.module.scss';
import { UserInfoContext } from '../../../pages/Main/Main';

export const ShopUserBar = () => {
  const { body, userInfo, routeBtn, statsList, stat, balance, kpi, level, statValueTxt, userJob, userName } = styles;

  const user: User = useContext(UserInfoContext);

  const { firstName, lastName, middleName, job, stats } = user;

  const [isModalActive, setModalActive] = useState(false);
  const [chosenStat, setChosenStat] = useState('');

  return (
    <section className={body}>
      <div className={userInfo}>
        <Link to={'/gamification'} className={routeBtn}>
          Вернуться назад
        </Link>

        {middleName ? (
          <h3 className={userName}>{lastName + ' ' + firstName + ' ' + middleName}</h3>
        ) : (
          <h3 className={userName}>{lastName + ' ' + firstName}</h3>
        )}

        <p className={userJob}>{job?.name}</p>
      </div>

      <ul className={statsList}>
        <li
          className={`${stat} ${kpi}`}
          onClick={() => {
            setChosenStat('kpi');
            setModalActive(true);
          }}
        >
          KPI: <span className={statValueTxt}>{stats?.kpi}</span>
        </li>
        <li
          className={`${stat} ${balance}`}
          onClick={() => {
            setChosenStat('flex');
            setModalActive(true);
          }}
        >
          Баланс: <span className={statValueTxt}>{stats?.flex} Flex</span>
        </li>
        <li
          className={`${stat} ${level}`}
          onClick={() => {
            setChosenStat('level');
            setModalActive(true);
          }}
        >
          Уровень: <span className={statValueTxt}>{stats?.levelValue}</span>
        </li>
      </ul>
      {isModalActive && <Modal role={'info'} creditName={chosenStat} close={() => setModalActive(false)} />}
      <div className={isModalActive ? overlayStyles.overlay : `${overlayStyles.overlay} ${overlayStyles.hidden}`}></div>
    </section>
  );
};
