import React from 'react';
//import { Payroll } from '../../components/Payroll';
import { useTranslation } from 'react-i18next';
import { UserPayrollNew } from '../../components/UserPayrollNew';

export const UserPayroll = () => {
  const [t] = useTranslation('translation');
  return (
    <>
      <div className="page-container">
        <h2 className="project-info-title text-center">{t('payroll')}</h2>
        <UserPayrollNew />
      </div>
    </>
  );
};
