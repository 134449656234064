import styled from 'styled-components';

export const TableContainer = styled.div`
  width: inherit;
`;

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
`;

export const ProjectTaskStatusSelect = styled.div`
  min-width: 130px;
`;
