import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import {
  ChangePtCheckListItemStatusDocument,
  ChangePtCheckListItemStatusMutation,
  CreatePtCheckListItemDocument,
  CreatePtCheckListItemMutation,
  DeletePtCheckListItemDocument,
  DeletePtCheckListItemMutation,
  GetProjectTaskDocument,
  ProjectTaskCheckListItem,
  UpdatePtCheckListItemDocument,
  UpdatePtCheckListItemMutation,
} from '../../../../generated/graphql';
import { SmallAddButton } from '../../../../constants/Styles/SmallAddButton';
import {
  FieldContainer,
  FieldLabel,
  Input,
  ModalButton,
  SectionContainer,
  SectionTitle,
} from '../DashboardModal.style';
import {
  ButtonContainer,
  ItemContainer,
  ItemsContainer,
  SectionTitleContainer,
  ButtonsContainer,
  ItemInput,
  ItemCheckbox,
  ItemButtonsContainer,
  Item,
} from './ChecklistSection.style';
import { EditButton } from '../../../../constants/Styles/EditButton';
import { CloseButton } from '../../../../constants/Styles/CloseButton';
import { Snackbar } from '../../../../components/Snackbar';
import { Spinner } from 'react-bootstrap';

interface ChecklistSectionProps {
  taskId: string;
  items: ProjectTaskCheckListItem[];
  description: string | undefined;
  saveTaskInfo(): void;
}

export const ChecklistSection = ({ taskId, items, description, saveTaskInfo }: ChecklistSectionProps) => {
  const [t] = useTranslation('translation');
  const [isInput, setIsInput] = useState(false);
  const [chosenItemId, setChosenItemId] = useState('');

  const [name, setName] = useState('');

  const [createItem, { data: createItemData, loading: createItemLoading, error: createItemError }] =
    useMutation<CreatePtCheckListItemMutation>(CreatePtCheckListItemDocument, {
      variables: {
        projectTaskId: taskId,
        name,
      },
      refetchQueries: [{ query: GetProjectTaskDocument, variables: { id: taskId } }],
    });

  const [updateItem, { data: updateItemData, loading: updateItemLoading, error: updateItemError }] =
    useMutation<UpdatePtCheckListItemMutation>(UpdatePtCheckListItemDocument, {
      variables: {
        id: chosenItemId,
        name,
      },
      refetchQueries: [{ query: GetProjectTaskDocument, variables: { id: taskId } }],
    });

  const [deleteItem, { data: deleteItemData, loading: deleteItemLoading, error: deleteItemError }] =
    useMutation<DeletePtCheckListItemMutation>(DeletePtCheckListItemDocument, {
      refetchQueries: [{ query: GetProjectTaskDocument, variables: { id: taskId } }],
    });

  const [
    changeItemStatus,
    { data: changeItemStatusData, loading: changeItemStatusLoading, error: changeItemStatusError },
  ] = useMutation<ChangePtCheckListItemStatusMutation>(ChangePtCheckListItemStatusDocument, {
    refetchQueries: [{ query: GetProjectTaskDocument, variables: { id: taskId } }],
  });

  const onSave = () => {
    if (!name) {
      return;
    }

    if (isInput) {
      createItem();
      !description && saveTaskInfo();
      return;
    }
    updateItem();
  };

  const onEdit = (id: string, itemName: string): void => {
    if (chosenItemId === id) {
      itemName !== name && onSave();
      setChosenItemId('');
      return;
    }

    setName(itemName);
    setChosenItemId(id);
  };

  const closeInput = () => {
    setIsInput(false);
    setName('');
  };

  useEffect(() => {
    if (createItemData || updateItemData) {
      closeInput();
    }
  }, [createItemData, updateItemData]);

  let content: JSX.Element;
  if (createItemLoading || updateItemLoading || deleteItemLoading || changeItemStatusLoading) {
    content = (
      <div className="d-flex justify-content-center align-items-center ">
        <Spinner animation="grow" />
        <span>Подождите...</span>
      </div>
    );
  }

  if (items?.length) {
    content = (
      <ItemsContainer>
        {items.map(item => (
          <ItemContainer key={item.id}>
            <Item>
              <ItemCheckbox
                type="checkbox"
                checked={item.isCompleted}
                onChange={() => {
                  changeItemStatus({ variables: { id: item.id } });
                  setChosenItemId('');
                }}
              />
              <ItemInput
                value={chosenItemId === item.id ? name : item.name}
                onChange={({ target: { value } }) => setName(value)}
                disabled={chosenItemId !== item.id}
                active={chosenItemId === item.id}
              />
            </Item>

            <ItemButtonsContainer>
              <EditButton active={chosenItemId === item.id} size={12} onClick={() => onEdit(item.id, item.name)} />
              <CloseButton
                active={chosenItemId !== item.id}
                size={12}
                onClick={() => {
                  deleteItem({ variables: { id: item.id } });
                  closeInput();
                }}
              />
            </ItemButtonsContainer>
          </ItemContainer>
        ))}
      </ItemsContainer>
    );
  }

  if (createItemError || updateItemError || deleteItemError || changeItemStatusError) {
    let text: string;
    if (deleteItemError) {
      text = 'При удалении пункта произошла ошибка';
    }
    if (updateItemError) {
      text = 'При изменении пункта произошла ошибка';
    }
    if (createItemError) {
      text = 'При создании пункта произошла ошибка';
    }
    if (changeItemStatusError) {
      text = 'При изменении статуса пункта произошла ошибка';
    }

    return <Snackbar text={text} className="error" />;
  }

  return (
    <SectionContainer>
      <SectionTitleContainer>
        <SectionTitle>Чеклист</SectionTitle>

        <ButtonContainer>
          <SmallAddButton onClick={() => setIsInput(true)} />
        </ButtonContainer>
      </SectionTitleContainer>

      {isInput && (
        <FieldContainer>
          <Input
            value={name}
            placeholder="Ввести"
            onChange={e => {
              setName(e.target.value);
            }}
          />
          <ButtonsContainer>
            <ModalButton color="#101010" fontSize={12} padding={[5, 15]} onClick={() => closeInput()}>
              {t('cancel')}
            </ModalButton>
            <ModalButton color="#FFBB4F" fontSize={12} padding={[5, 15]} onClick={onSave}>
              {t('save')}
            </ModalButton>
          </ButtonsContainer>
        </FieldContainer>
      )}
      {content}
      {/*{items?.length ? (*/}
      {/*  <ItemsContainer>*/}
      {/*    {items.map(item => (*/}
      {/*      <ItemContainer key={item.id}>*/}
      {/*        <Item>*/}
      {/*          <ItemCheckbox*/}
      {/*            type="checkbox"*/}
      {/*            checked={item.isCompleted}*/}
      {/*            onChange={() => {*/}
      {/*              changeItemStatus({ variables: { id: item.id } });*/}
      {/*              setChosenItemId('');*/}
      {/*            }}*/}
      {/*          />*/}
      {/*          <ItemInput*/}
      {/*            value={chosenItemId === item.id ? name : item.name}*/}
      {/*            onChange={({ target: { value } }) => setName(value)}*/}
      {/*            disabled={chosenItemId !== item.id}*/}
      {/*            active={chosenItemId === item.id}*/}
      {/*          />*/}
      {/*        </Item>*/}

      {/*        <ItemButtonsContainer>*/}
      {/*          <EditButton active={chosenItemId === item.id} size={12} onClick={() => onEdit(item.id, item.name)} />*/}
      {/*          <CloseButton*/}
      {/*            active={chosenItemId !== item.id}*/}
      {/*            size={12}*/}
      {/*            onClick={() => {*/}
      {/*              deleteItem({ variables: { id: item.id } });*/}
      {/*              closeInput();*/}
      {/*            }}*/}
      {/*          />*/}
      {/*        </ItemButtonsContainer>*/}
      {/*      </ItemContainer>*/}
      {/*    ))}*/}
      {/*  </ItemsContainer>*/}
      {/*) : null}*/}
    </SectionContainer>
  );
};
