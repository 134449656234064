import React, { useContext, useState } from 'react';
import { CorrectionComment, ProjectCorrection } from '../../generated/graphql';
import { useMediaQuery } from 'react-responsive';
import { Button } from 'react-bootstrap';
import { classNames } from '../../constants/classNames';
import { CommentForm } from '../CommentForm';
import { Comment } from '../Comment';
import { ProjectCorrectionEditButtons } from './ProjectCorrectionEditButtons';
import { ProjectCorrectionContent } from './ProjectCorrectionContent';
import { CorrectionParams } from '../../pages/Corrections/Corrections';
import pdfIcon from '../../accets/icons/pdf-icon.svg';
import editItemImg from '../../accets/img/editItem-img.svg';
import editCommentIcon from '../../accets/icons/edit-comment-icon.svg';
import './ProjectCorrectionItem.scss';
import { headerBtnBackContext } from '../../pages/Main/Main';

interface ProjectCorrectionItemProps {
  correction: ProjectCorrection;
  filter: string;
  pdf?: string | undefined;
  example?: string | undefined;
  isTextArea: boolean;
  textAreaValue: string;
  onCheckList(): void;
  setTextAreaValue(text: string): void;
  setIsTextArea(textArea: boolean): void;
  getInputFile(file: FileList): void;
  deleteNewCorrection(correctionId: string): Promise<void>;
  createComment({ correctionId, text }: CorrectionParams): void;
  updateNewCorrection({ correctionId, text }: CorrectionParams): void;
}

export const ProjectCorrectionItem = ({
  correction,
  filter,
  pdf,
  example,
  onCheckList,
  isTextArea,
  textAreaValue,
  setTextAreaValue,
  setIsTextArea,
  getInputFile,
  deleteNewCorrection,
  createComment,
  updateNewCorrection,
}: ProjectCorrectionItemProps) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 992px)',
  });
  const [isCorrectionForm, setIsCorrectionForm] = useState(false);
  const [isCommentList, setIsCommentList] = useState(false);
  const [inputCorrectionValue, setInputCorrectionValue] = useState('');
  const [inputCommentValue, setInputCommentValue] = useState('');
  const [isBackBtn, setIsBackBtn] = useContext<any>(headerBtnBackContext);

  const comments = correction?.comments as CorrectionComment[];
  const formatData = correction?.updatedDate.split('T');

  const updateNewEdit = (): void => {
    updateNewCorrection({ correctionId: correction?.id as string, text: inputCorrectionValue });
  };

  const createNewComment = (): void => {
    createComment({ correctionId: correction?.id as string, text: inputCommentValue });
  };

  return (
    <div className="edit-item d-flex flex-column position-relative">
      <h3 className="edit-title d-lg-none position-absolute">{`Правка № ${correction.id}`}</h3>
      <div className="d-none d-lg-flex justify-content-start align-items-start">
        <div className="edit-item-data">{formatData[0].split('-').reverse().join('.')}</div>
        <time className="edit-item-time">{formatData[1].substr(0, formatData[1].length - 8)}</time>
        <div className="edit-item-text-container d-flex flex-column align-items-start w-75 overflow-hidden">
          <p className="edit-item-text">{correction.text}</p>
          {pdf && (
            <Button className="example-file d-flex justify-content-start align-items-center" variant="link">
              <img className="pdf-icon" src={pdfIcon} alt="icon" />
              <span className="example-file-title">Файл для примера.pdf</span>
            </Button>
          )}
          {example && <img className="example-img" src={editItemImg} alt="picture" />}
        </div>

        {filter === 'NEW' && (
          <ProjectCorrectionEditButtons
            correctionId={correction?.id as string}
            showCorrectionForm={setIsCorrectionForm}
            deleteNewCorrection={deleteNewCorrection}
          />
        )}
      </div>

      <ProjectCorrectionContent formatData={formatData} text={correction?.text as string} />

      {comments?.length !== 0 && (
        <>
          <Button
            className="comment-btn"
            onClick={() => {
              setIsCommentList(true);
              setIsBackBtn(true);
            }}
          >
            <span className="d-none d-lg-block">{`Комментарии (${correction?.comments?.length})`}</span>
            <img className="d-lg-none" src={editCommentIcon} alt="icon" />
          </Button>

          {isBackBtn && isCommentList && (
            <div className="comments-container">
              <div className=" overflow-auto h-75 mr-3 pr-3">
                <div className="d-flex d-lg-none justify-content-center">
                  <h3 className="comment-field-title">Комментарии Правка № {correction?.id}</h3>
                </div>

                {comments?.map(comment => (
                  <Comment key={comment?.id} createdBy={correction?.createdBy} commentText={comment?.text as string} />
                ))}

                <div
                  className={classNames({
                    'comment-form-container': isDesktopOrLaptop,
                    'comment-form-container-mobile': !isDesktopOrLaptop,
                  })}
                >
                  <CommentForm
                    sendNewEdit={createNewComment}
                    setIsEditForm={setIsCommentList}
                    setInputValue={setInputCommentValue}
                    inputValue={inputCommentValue}
                    placeholder="Изменить комментарий..."
                    btnName="Изменить"
                    desktop={isDesktopOrLaptop}
                    onCheckList={onCheckList}
                    isTextArea={isTextArea}
                    textAreaValue={textAreaValue}
                    setTextAreaValue={setTextAreaValue}
                    setIsTextArea={setIsTextArea}
                    getInputFile={getInputFile}
                  />
                </div>
              </div>
            </div>
          )}
        </>
      )}

      {isCorrectionForm && (
        <div className="comment-form-container d-none d-lg-block">
          <CommentForm
            btnName="Изменить"
            inputValue={inputCorrectionValue}
            placeholder={'Изменить правку...'}
            createCorrection
            isTextArea={isTextArea}
            textAreaValue={textAreaValue}
            desktop
            setIsEditForm={setIsCorrectionForm}
            setInputValue={setInputCorrectionValue}
            setTextAreaValue={setTextAreaValue}
            setIsTextArea={setIsTextArea}
            onCheckList={onCheckList}
            sendNewEdit={updateNewEdit}
            getInputFile={getInputFile}
          />
        </div>
      )}
    </div>
  );
};
