export const kpiTableHeader = [
  {
    title: 'Показатели',
  },
  {
    title: 'Един. измерения',
  },
  {
    title: 'Вес',
  },
  {
    title: 'План',
  },
  {
    title: 'Факт',
  },
  {
    title: '% выполнения',
  },
  {
    title: 'МВО',
  },
];
