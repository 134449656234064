import React from 'react';
import { EmployeePhoto, SelectContainer, SelectLabel } from '../EmployeeSelect/EmployeeSelect.style';
import defaultUserPhoto from '../../../../accets/icons/defaultUserPhoto.svg';
import { AuthorName, ContentContainer } from './AuthorSection.style';
import { User } from '../../../../generated/graphql';

interface AuthorSectionProps {
  selectLabel: string;
  author: User;
}

export const AuthorSection = ({ selectLabel, author }: AuthorSectionProps) => {
  return (
    <SelectContainer>
      <SelectLabel>{selectLabel}</SelectLabel>
      <ContentContainer>
        <EmployeePhoto src={author?.photo || defaultUserPhoto} alt="photo" />
        {author && <AuthorName>{`${author?.firstName} ${author?.lastName}`}</AuthorName>}
      </ContentContainer>
    </SelectContainer>
  );
};
