import React, { useEffect, useState } from 'react';
import overdueTaskColoredIcon from '../../accets/icons/overdueTasksIconColored.svg';
import overdueTaskIcon from '../../accets/icons/overdueTaskIcon.svg';
import scheduleIcon from '../../accets/icons/schedule-icon.svg';
import scheduleColoredIcon from '../../accets/icons/schedule-colored-icon.svg';
import financesIcon from '../../accets/icons/finances-icon.svg';
import financesColoredIcon from '../../accets/icons/finances-colored-icon.svg';
import newsIcon from '../../accets/icons/news-icon.svg';
import newsColoredIcon from '../../accets/icons/news-icon-colored.svg';
import infoIcon from '../../accets/icons/info-icon.svg';
import infoColoredIcon from '../../accets/icons/info-colored-icon.svg';
import peopleIcon from '../../accets/icons/people-icon.svg';
import peopleColoredIcon from '../../accets/icons/people-colored-icon.svg';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { classNames } from '../../constants/classNames';
import { ProjectNav } from '../ProjectNav/ProjectNav';
import './SidebarNav.scss';

const sidebarButtons = [
  { id: 2, title: 'company news', icon: newsIcon, coloredIcon: newsColoredIcon, path: '/news' },
  // { id: 3, title: 'info', icon: infoIcon, coloredIcon: infoColoredIcon, path: '/info' },
  { id: 4, title: 'payroll', icon: financesIcon, coloredIcon: financesColoredIcon, path: '/payroll' },
  { id: 5, title: 'knowledge base', icon: infoIcon, coloredIcon: infoColoredIcon, path: '/knowledge-base' },
  // {
  //   id: 5,
  //   title: 'management contact',
  //   icon: peopleIcon,
  //   coloredIcon: peopleColoredIcon,
  //   path: '/management-contact',
  // },
];

export const SidebarNav = () => {
  const [t] = useTranslation('translation');
  const { pathname } = useLocation();
  const [activeProjectsBtn, setActiveProjectsBtn] = useState<boolean>(false);

  useEffect(() => {
    pathname.startsWith('/project') && setActiveProjectsBtn(true);
  }, []);

  return (
    <>
      <nav className="d-flex flex-column">
        <NavLink
          className={classNames({
            'sidebarNav-link d-flex align-items-center': true,
            'active-btn': pathname.startsWith('/overdue-task'),
          })}
          activeClassName="activeRoute"
          to="/overdue-task"
          onClick={() => setActiveProjectsBtn(false)}
        >
          <img
            className="sidebarNav-link-img"
            src={pathname.startsWith('/overdue-task') ? overdueTaskColoredIcon : overdueTaskIcon}
            alt="icon"
          />
          <span className="nav-link-title text-center">{t('overdue task')}</span>
        </NavLink>

        <NavLink
          className={classNames({
            'sidebarNav-link d-flex align-items-center': true,
            'active-btn': activeProjectsBtn || pathname.startsWith('/project'),
          })}
          activeClassName="activeRoute"
          to="/project"
          onClick={() => setActiveProjectsBtn(true)}
        >
          <img
            className="sidebarNav-link-img"
            src={activeProjectsBtn || pathname.startsWith('/project') ? scheduleColoredIcon : scheduleIcon}
            alt="icon"
          />
          <span className="nav-link-title text-center">{t('projects')}</span>
        </NavLink>
        {activeProjectsBtn && <ProjectNav />}

        {sidebarButtons.map(link => (
          <NavLink
            key={link.id}
            className={classNames({
              'sidebarNav-link d-flex align-items-center': true,
              'active-btn': pathname === link.path && !activeProjectsBtn,
            })}
            activeClassName="activeRoute"
            to={link.path}
            onClick={() => setActiveProjectsBtn(false)}
          >
            <img
              className="sidebarNav-link-img"
              src={pathname === link.path && !activeProjectsBtn ? link.coloredIcon : link.icon}
              alt="icon"
            />
            <span className="nav-link-title text-center">{t(link.title)}</span>
          </NavLink>
        ))}
      </nav>
    </>
  );
};
