import styled from 'styled-components';

export const ContentContainer = styled.div`
  width: 320px;
  display: flex;
  align-items: center;
`;

export const AuthorName = styled.div`
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #101010;
  padding-left: 3px;
`;
