import styled from 'styled-components';

export const SelectContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const ContentContainer = styled.div<{ error: boolean; url: string }>`
  border-radius: 10px;
  border: ${({ error }) => (error ? '1px solid #F44545' : 'none')};
  position: relative;

  .MuiSelect-selectMenu {
    width: 225px;
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #101010;
  }

  .MuiSelect-icon {
    display: none;
  }

  .MuiInput-underline:before,
  .MuiInput-underline:after {
    display: none;
    content: '';
  }
  .MuiSelect-select:focus {
    background: transparent;
  }

  &:before {
    content: ${({ url }) => `url(${url})`};
    position: absolute;
    right: 17px;
    top: 10px;
    transform: rotate(90deg);
  }
`;

export const ErrorText = styled.span`
  position: absolute;
  bottom: -16px;
  right: 10px;
  color: #f44545;
  font-size: 12px;
`;

export const SelectLabel = styled.span`
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 160%;
  letter-spacing: 0.04em;
  color: #101010;
  margin-right: 20px;
`;

export const EmployeePhoto = styled.img`
  margin-right: 20px;
  width: 50px;
  height: 50px;
  border-radius: 10px;
`;
