import React from 'react';
import { Portal } from 'react-portal';
import { PayrollBonus } from '../../generated/graphql';
import './BonusModal.scss';

interface BonusModalProps {
  bonuses: PayrollBonus[];
  closeModal(e: boolean): void;
}

export const BonusModal = ({ bonuses, closeModal }: BonusModalProps) => {
  console.log('b', bonuses);
  return (
    <Portal>
      <div className="modal-container" onClick={() => closeModal(false)}>
        <div className="bonus-modal">
          <h3 className="working-hours-title mb-4 overflow-auto">Бонусы</h3>
          <table className="table">
            <thead className="bonus-table-header">
              <th>Сумма</th>
              <th>Комментарий</th>
            </thead>
            {bonuses.map(bonus => (
              <tr className="bonus-table-row w-100" key={bonus.id}>
                <td>{bonus.value}</td>
                <td>{bonus.name}</td>
              </tr>
            ))}
          </table>
        </div>
      </div>
    </Portal>
  );
};
