import React from 'react';
import { IconButton } from '@material-ui/core';
import { primary, secondary } from '../colors';
import { Visibility, VisibilityOff } from '@material-ui/icons';

interface VisibilityButtonProps {
  active?: boolean;
  show(): void;
  hide(): void;
  fz?: number;
}

export const VisibilityButton = ({ active, show, hide }: VisibilityButtonProps) => {
  return (
    <IconButton onMouseDown={show} onMouseUp={hide} style={{ color: active ? primary : secondary, padding: '5px' }}>
      {active ? (
        <Visibility color="inherit" fontSize="inherit" />
      ) : (
        <VisibilityOff color="inherit" fontSize="inherit" />
      )}
    </IconButton>
  );
};
