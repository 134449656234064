import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  GetProjectTaskDocument,
  RemoveProjectTaskLabelDocument,
  GetAvailableProjectTaskLabelsDocument,
  RemoveProjectTaskLabelMutation,
  ProjectTaskLabel,
  GetAvailableProjectTaskLabelsQuery,
} from '../../../../generated/graphql';
import { OptionList } from './OptionList/OptionList';
import { Snackbar } from '../../../../components/Snackbar';
import { LoadingDisplay } from '../../../../components/LoadingDisplay';
import arrow from '../../../../accets/icons/black-arrow.svg';
import closeIcon from '../../../../accets/icons/close-icon.svg';
import { TransparentButton } from '../../../../constants/Styles/TransparentButton';
import { ComponentContainer, ComponentLabel, ErrorSpan } from '../DashboardModal.style';
import {
  CustomSelect,
  Placeholder,
  SelectButtonContainer,
  ButtonImg,
  LabelsContainer,
  LabelItem,
  LabelItemContainer,
  CloseButtonIcon,
} from './LabelsSelect.style';

interface LabelsSelectProps {
  label: string;
  projectId: string;
  projectTaskId: string;
  labels?: ProjectTaskLabel[];
}

export const LabelsSelect = ({ label, projectId, projectTaskId, labels }: LabelsSelectProps) => {
  const [isOptionList, setIsOptionList] = useState<boolean>(false);

  const [removeLabel, { loading: removeLabelLoading, error: removeLabelError }] =
    useMutation<RemoveProjectTaskLabelMutation>(RemoveProjectTaskLabelDocument, {
      refetchQueries: [
        {
          query: GetAvailableProjectTaskLabelsDocument,
          variables: {
            projectId,
            projectTaskId,
          },
        },
        {
          query: GetProjectTaskDocument,
          variables: {
            id: projectTaskId,
          },
        },
      ],
    });

  const {
    data: labelsData,
    loading: labelsLoading,
    error: labelsError,
  } = useQuery<GetAvailableProjectTaskLabelsQuery>(GetAvailableProjectTaskLabelsDocument, {
    variables: {
      projectId,
      projectTaskId,
    },
  });

  let content: JSX.Element;

  if (labelsLoading || removeLabelLoading) {
    content = <LoadingDisplay />;
  }

  if (labelsError) {
    content = <ErrorSpan>Нет</ErrorSpan>;
  }

  if (removeLabelError) {
    content = <ErrorSpan>Ошибка при удалении метки</ErrorSpan>;
  }

  if (labelsData) {
    const labelsFromServer = labelsData.getAvailableProjectTaskLabels;

    content = (
      <CustomSelect>
        {labels?.length ? (
          <LabelsContainer>
            {labels.map(label => (
              <LabelItemContainer key={label.id} color={label.color}>
                <LabelItem>{label.name}</LabelItem>
                <TransparentButton onClick={() => removeLabel({ variables: { projectTaskId, labelId: label.id } })}>
                  <CloseButtonIcon src={closeIcon} alt="icon" />
                </TransparentButton>
              </LabelItemContainer>
            ))}
          </LabelsContainer>
        ) : (
          <Placeholder>Выбрать метку...</Placeholder>
        )}

        {labelsFromServer?.length ? (
          <SelectButtonContainer>
            <TransparentButton onClick={() => setIsOptionList(!isOptionList)}>
              <ButtonImg src={arrow} />
            </TransparentButton>
          </SelectButtonContainer>
        ) : null}

        {isOptionList && (
          <OptionList
            list={labelsFromServer}
            projectId={projectId}
            projectTaskId={projectTaskId}
            setIsOptionList={setIsOptionList}
          />
        )}
      </CustomSelect>
    );
  }

  return (
    <ComponentContainer>
      <ComponentLabel>{label}</ComponentLabel>
      {content}
    </ComponentContainer>
  );
};
