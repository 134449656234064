import React from 'react';
import { Portal } from 'react-portal';
import {
  createDaysData,
  getFirstHalfDayCell,
  getSecondHalfDayCell,
  isOddDaysInMonth,
} from '../../utils/monthForPayroll';
import { convertMinuteToHours } from '../../utils/convertMinuteToHours';
import { Report } from '../../generated/graphql';
import './WorkingHoursModal.scss';

interface WorkingHoursModalProps {
  reports: Report[];
  month: string;
  closeModal(close: boolean): void;
}

export const WorkingHoursModal = ({ reports, month, closeModal }: WorkingHoursModalProps) => {
  const report = reports && reports.length && (reports[0].times as number[]);
  const monthNumber = month.split('T')[0].split('-')[1];
  const daysData = report ? createDaysData(report as number[]) : [];

  return (
    <Portal>
      <div className="modal-container" onClick={() => closeModal(false)}>
        <div className="working-hours-modal">
          <div className="d-flex">
            <h3 className="working-hours-title mr-4 overflow-auto">ЧАСЫ ФАКТ</h3>
            <div className="working-hours-subtitle">
              Добавленное время -<span className="working-hours-subtitle-color ml-1">10</span>
            </div>
          </div>

          <div className="working-hours-table overflow-auto">
            <div
              className="w-100"
              style={{
                display: 'grid',
                gridTemplateColumns: `repeat(${
                  isOddDaysInMonth ? daysData.length / 2 : (daysData.length - 1) / 2
                }, ${getFirstHalfDayCell()}px)
    repeat(${isOddDaysInMonth ? daysData.length / 2 : (daysData.length + 1) / 2}, ${getSecondHalfDayCell()}px)`,
              }}
            >
              {daysData.map((day, index) => (
                <div
                  className="dark-slot light-color slot d-flex justify-content-center align-items-center"
                  key={index}
                >
                  {`${day}.${monthNumber}`}
                </div>
              ))}
            </div>

            {reports.map((report, i) => (
              <div className="tbody-tr-second-line" key={i}>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: `repeat(${
                      isOddDaysInMonth ? daysData.length / 2 : (daysData.length - 1) / 2
                    }, ${getFirstHalfDayCell()}px)
          repeat(${isOddDaysInMonth ? daysData.length / 2 : (daysData.length + 1) / 2}, ${getSecondHalfDayCell()}px)`,
                  }}
                >
                  {report.times.map((time, index) => (
                    <div className="border-slot slot  d-flex justify-content-center align-items-center" key={index}>
                      {time !== 0 ? convertMinuteToHours(time as number) : time}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Portal>
  );
};
