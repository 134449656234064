import React from 'react';

import { Product } from '../../generated/graphql';

import { CharacterProductsList } from './CharacterProductsList';
import { PersonalProductsList } from './PersonalProductsList';
import { PurchaseProductsList } from './PurchaseProductsList';
import { ShoppingCartProductsList } from './ShoppingCartProductsList';

export interface IProductsListProps {
  role?: string;
  addPurchase?(id: string | undefined): void;
  deletePurchase?(id: string): void;
}

export const ProductsList = (props: IProductsListProps) => {
  switch (props.role) {
    case 'character':
      return <CharacterProductsList {...props} />;

    case 'purchase':
      return <PurchaseProductsList />;

    case 'shoppingCart':
      return <ShoppingCartProductsList {...props} />;

    default:
      return <PersonalProductsList {...props} />;
  }
};
