import React from 'react';
import { TransparentButton } from './TransparentButton';
import closeIcon from '../../accets/icons/close-icon.svg';
import closeIconDisabled from '../../accets/icons/closeIconDisabled.svg';

import { ButtonIcon } from './EditButton';

export const CloseButton = ({
  onClick,
  size,
  active,
}: {
  onClick(): void;
  size?: number;
  active?: boolean;
}): JSX.Element => {
  return (
    <TransparentButton onClick={onClick}>
      <ButtonIcon size={size} src={active ? closeIcon : closeIconDisabled} alt="icon" />
    </TransparentButton>
  );
};
