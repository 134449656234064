import React, { useRef, useState, useEffect } from 'react';

import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';

import {
  CustomScrollBtnsWrapper,
  CustomTableContainer,
  CustomTableWrapper,
  CustomTableScrollbarBtnDecrement,
  CustomTableScrollbarBtnIncrement,
} from './CustomTable.style';

interface ICustomTableProps {
  data: any[] | undefined | null;
  tableContent: JSX.Element | undefined;
  height?: number;
  unlimited?: boolean;
}

export const CustomTable = ({ data, tableContent, height, unlimited }: ICustomTableProps) => {
  const tableWrapperRef = useRef<HTMLDivElement | null>(null);

  const [isVerticalScrollVisible, setVerticalScrollVisible] = useState(false);

  const [isHorizontalScrollVisible, setHorizontalScrollVisible] = useState(false);

  useEffect(() => {
    if (tableWrapperRef) {
      const isVerticalOverflow = tableWrapperRef!.current!.clientHeight < tableWrapperRef!.current!.scrollHeight;
      const isHorizontalOverflow = tableWrapperRef!.current!.clientWidth < tableWrapperRef!.current!.scrollWidth;

      if (isVerticalOverflow) {
        setVerticalScrollVisible(true);
      } else {
        setVerticalScrollVisible(false);
      }

      if (isHorizontalOverflow) {
        setHorizontalScrollVisible(true);
      } else {
        setHorizontalScrollVisible(false);
      }
    }
  }, [data, tableContent]);

  return (
    <CustomScrollBtnsWrapper>
      <CustomTableWrapper ref={tableWrapperRef} height={height} unlimited={unlimited}>
        <CustomTableContainer>{tableContent}</CustomTableContainer>
      </CustomTableWrapper>

      {isHorizontalScrollVisible && (
        <CustomTableScrollbarBtnDecrement onClick={() => (tableWrapperRef!.current!.scrollLeft -= 150)}>
          <ArrowLeftIcon fontSize={'small'} />
        </CustomTableScrollbarBtnDecrement>
      )}

      {isHorizontalScrollVisible && (
        <CustomTableScrollbarBtnIncrement
          isVerticalScrollVisible={isVerticalScrollVisible}
          onClick={() => (tableWrapperRef!.current!.scrollLeft += 150)}
        >
          <ArrowRightIcon fontSize={'small'} />
        </CustomTableScrollbarBtnIncrement>
      )}

      {isVerticalScrollVisible && (
        <CustomTableScrollbarBtnDecrement vertical={true} onClick={() => (tableWrapperRef!.current!.scrollTop -= 150)}>
          <ArrowLeftIcon fontSize={'small'} />
        </CustomTableScrollbarBtnDecrement>
      )}

      {isVerticalScrollVisible && (
        <CustomTableScrollbarBtnIncrement
          vertical={true}
          isHorizontalScrollVisible={isHorizontalScrollVisible}
          onClick={() => (tableWrapperRef!.current!.scrollTop += 150)}
        >
          <ArrowRightIcon fontSize={'small'} />
        </CustomTableScrollbarBtnIncrement>
      )}
    </CustomScrollBtnsWrapper>
  );
};
