import React, { ReactNode, useState } from 'react';
import { Product, Purchase } from '../../generated/graphql';
import { IProductsData, GET_PRODUCTS } from '../../graphql/queries/products';
import { useQuery } from '@apollo/client';
import {
  GET_PURCHASES_HISTORY,
  GET_PURCHASES_IN_CART,
  IPurchasesHistoryData,
  IPurchasesInCartData,
} from '../../graphql/queries/purchases';

export interface IShopTabContext {
  products: Product[] | undefined;
  purchasesInCart: Purchase[] | undefined;
  purchaseHistory: Purchase[] | undefined;
  getProduct(id: string): Product | undefined;
  chosenProduct: Product | undefined;
  setChosenProduct(state: undefined): void;
  choseProductHandler(id: string): void;
  chosenPurchase: Purchase | undefined;
  setChosenPurchase(state: undefined): void | undefined;
  chosePurchaseHandler(id: string | undefined, location: string): void;
}

export const ShopTabContext = React.createContext({
  products: undefined,
  purchasesInCart: undefined,
  purchaseHistory: undefined,
  chosenProduct: undefined,
  setChosenProduct: (state: undefined): void => {
    return;
  },
  choseProductHandler: (id: string): void => {
    return;
  },
  chosenPurchase: undefined,
  setChosenPurchase: (state: undefined): void => {
    return;
  },
} as IShopTabContext);

interface ShopTabProviderProps {
  children: ReactNode;
}

export const ShopTabProvider = ({ children }: ShopTabProviderProps) => {
  //#region Queries

  const { data } = useQuery<IProductsData | undefined>(GET_PRODUCTS);
  const products = data?.getProducts;

  const getPurchasesInCart = useQuery<IPurchasesInCartData | undefined>(GET_PURCHASES_IN_CART, {
    variables: { getPurchasesData: { limit: 1000 } },
  });
  const purchasesInCart = getPurchasesInCart.data?.getPurchasesInCart.purchases;

  const getPurchaseHistory = useQuery<IPurchasesHistoryData | undefined>(GET_PURCHASES_HISTORY, {
    variables: { getPurchasesData: { limit: 1000 } },
  });
  const purchaseHistory = getPurchaseHistory.data?.getPurchaseHistory.purchases;

  //#endregion

  //#region Product

  const getProduct = (productId: string): Product | undefined => {
    return products?.find(product => product.id === productId);
  };

  const [chosenProduct, setChosenProduct] = useState<Product | undefined>(undefined);

  const choseProductHandler = (id: string): void => {
    if (chosenProduct && chosenProduct.id === id) {
      setChosenProduct(undefined);
      return;
    }
    setChosenProduct(getProduct(id));
  };

  //#endregion

  //#region Purchase

  const getPurchase = (purchaseId: string | undefined, location: string): Purchase | undefined => {
    if (location === 'shoppingCart') {
      return purchasesInCart?.find(purchase => purchase.id === purchaseId);
    }

    return purchaseHistory?.find(purchase => purchase.id === purchaseId);
  };

  const [chosenPurchase, setChosenPurchase] = useState<Purchase | undefined>(undefined);

  const chosePurchaseHandler = (id: string | undefined, location: string): void => {
    if (chosenPurchase && chosenPurchase.id === id) {
      setChosenPurchase(undefined);
      return;
    }
    if (id) {
      setChosenPurchase(getPurchase(id, location));
    }
  };

  //#endregion

  const contextValue = {
    products,
    purchasesInCart,
    purchaseHistory,
    getProduct,
    chosenProduct,
    setChosenProduct,
    choseProductHandler,
    chosenPurchase,
    setChosenPurchase,
    chosePurchaseHandler,
  };

  return <ShopTabContext.Provider value={contextValue}>{children}</ShopTabContext.Provider>;
};
