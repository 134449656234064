import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ru from 'date-fns/locale/ru';
registerLocale('ru', ru);
import { TaskData } from '../DashboardModal';

import dataPickerIcon from '../../../../accets/icons/datePicker-icon.svg';
import { PickerContainer, PickerIcon } from './DatepickerSelect.style';
import { ComponentContainer, ComponentLabel } from '../DashboardModal.style';
import './DatePicker.scss';

interface DatepickerSelectProps {
  label: string;
  value: Date;
  taskData: TaskData;
  setTaskData(obj: TaskData): void;
  field: string;
  maxDate?: Date;
  minDate?: Date;
}

export const DatepickerSelect = ({
  label,
  value,
  taskData,
  setTaskData,
  field,
  maxDate,
  minDate,
}: DatepickerSelectProps) => {
  const changeDate = (date: Date | [Date, Date]): void => {
    setTaskData({ ...taskData, [field]: date });
  };

  return (
    <ComponentContainer>
      <ComponentLabel>{label}</ComponentLabel>
      <PickerContainer>
        <DatePicker
          maxDate={maxDate}
          minDate={minDate}
          showDisabledMonthNavigation
          dateFormat="dd.MM.yyyy"
          locale="ru"
          selected={value}
          onChange={date => {
            changeDate(date as Date);
          }}
        />
        <PickerIcon url={dataPickerIcon} />
      </PickerContainer>
    </ComponentContainer>
  );
};
