import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import {
  GetChartData2Document,
  GetChartData2Query,
  GetProjectStepsFromJiraDocument,
  ProjectStep,
  User,
} from '../../generated/graphql';
import { ViewMode } from 'gantt-task-react';
//import { ProjectDiagramGantt } from '../../components/ProjectDiagramGantt/ProjectDiagramGantt';
import { NavBar } from '../../components/NavBar';
import { ProjectScheduleFilter } from '../../components/ProjectScheduleFilter';
import { LoadingDisplay } from '../../components/LoadingDisplay';
import { ErrorDisplay } from '../../components/ErrorDisplay';
import { GanttTaskReact } from './GanttTask/GanttTask';
import { UserInfoContext } from '../Main/Main';
import './ProjectSchedule.scss';

export const ProjectSchedule = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const { role } = useContext<User>(UserInfoContext);
  // const [viewModes, setViewModes] = useState(ViewMode.Month);
  // const [columnWidth, setColumnWidth] = useState<number>(85);
  // const [activeBtn, setActiveBtn] = useState<string>('month');

  // const {
  //   data: projectStepsFromJiraData,
  //   loading,
  //   error,
  // } = useQuery(GetProjectStepsFromJiraDocument, {
  //   variables: { projectId },
  // });

  const {
    data: chartDataData,
    loading: chartDataLoading,
    error: chartDataError,
  } = useQuery<GetChartData2Query>(GetChartData2Document, {
    variables: { projectId, isEpic: true },
    fetchPolicy: 'network-only',
  });

  //const projectSteps = projectStepsFromJiraData?.getProjectStepsFromJira as ProjectStep[];

  // const displayByDay = (): void => {
  //   setViewModes(ViewMode.Day);
  //   setColumnWidth(30);
  // };
  // const displayByWeek = (): void => {
  //   setViewModes(ViewMode.Week);
  //   setColumnWidth(60);
  // };
  // const displayByMonth = (): void => {
  //   setViewModes(ViewMode.Month);
  //   setColumnWidth(85);
  // };

  if (chartDataLoading) {
    return <LoadingDisplay />;
  }

  if (chartDataError) {
    return <ErrorDisplay message={chartDataError.message} />;
  }

  let timelineContent;

  if (chartDataData) {
    const epicsChart = chartDataData.getChartData2;

    timelineContent = (
      <div className="gant-container2">
        <GanttTaskReact epics={epicsChart} />
      </div>
    );
  }

  return (
    <>
      <div className="project-schedule-container">
        {role?.id !== 3 && <NavBar />}

        <h2 className="project-reporting text-center">Динамика проекта по этапам</h2>
        <ProjectScheduleFilter />

        {/*{!!projectSteps && projectSteps?.length !== 0 ? (*/}
        {/*  <div className="project-schedule">*/}
        {/*    /!*<ProjectDiagramGantt viewModes={viewModes} columnWidth={columnWidth} diagramSteps={projectSteps} />*!/*/}
        {/*  </div>*/}
        {/*) : (*/}
        {/*  <h6 className="text-center">Пока нет диаграммы</h6>*/}
        {/*)}*/}
        <div className="gant-container1">{timelineContent}</div>
      </div>
    </>
  );
};
