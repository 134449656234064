import styled from 'styled-components';

export const Container = styled.div`
  min-height: 80vh;
`;

export const Title = styled.h1`
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 40px;
  color: #101010;

  &:first-letter {
    text-transform: uppercase;
  }
`;

export const ColumnContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const KanbanScrollContainer = styled.div`
  overflow: auto;
  //height: 500px;
  position: relative;
`;
