import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { GetUserPaymentsByProjectDocument, GetUserPaymentsByProjectQuery, UserPayment } from '../../generated/graphql';
import { Payment } from '../../components/Payment';
import { CustomPagination } from '../../components/CustomPagination';
import { LoadingDisplay } from '../../components/LoadingDisplay';
import { ErrorDisplay } from '../../components/ErrorDisplay';
import './Finance.scss';

export const Finance = () => {
  const [t] = useTranslation('translation');
  const { projectId } = useParams<{ projectId: string }>();
  const [currentPage, setCurrentPage] = useState<number>(0);
  const perPage = 5;

  const {
    data: UserPaymentsData,
    loading: UserPaymentsDataLoading,
    error: UserPaymentsDataError,
  } = useQuery<GetUserPaymentsByProjectQuery>(GetUserPaymentsByProjectDocument, {
    variables: {
      projectId: projectId,
      limit: perPage,
      skip: currentPage * perPage,
    },
  });

  const bills = UserPaymentsData?.getUserPaymentsByProject?.payments as UserPayment[];
  const total = UserPaymentsData?.getUserPaymentsByProject?.total as number;

  const handlePageClick = ({ selected: selectedPage }: { selected: number }) => {
    setCurrentPage(selectedPage);
  };
  if (UserPaymentsDataLoading) {
    return <LoadingDisplay />;
  }

  if (UserPaymentsDataError) {
    return <ErrorDisplay message={UserPaymentsDataError?.message} />;
  }
  const pageCount = Math.ceil((total as number) / perPage);

  return (
    <>
      <div className="page-container mb-3 d-flex flex-column position-relative align-items-center">
        <div className="title-page-container d-flex justify-content-center align-items-center w-100 position-relative">
          <h3 className="finance-title">история платежей</h3>
        </div>
        {bills && bills.length !== 0 && (
          <div className="payment-table">
            <div className="pay-table w-100">
              <span className="table-title text-center">название</span>
              <span className="table-title text-center">дата</span>
              <span className="table-title text-center">сумма</span>
              <span className="table-title text-center">статус</span>
              <span className="table-title text-center">расчетный счет</span>
            </div>

            <div className="payment-container w-100">
              {bills.map(payment => (
                <Payment key={payment.id} payment={payment} />
              ))}
            </div>
          </div>
        )}
      </div>

      {total > perPage && (
        <div className="d-flex flex-column align-items-center">
          <CustomPagination pageCount={pageCount} handlePageClick={handlePageClick} />
          <span className="align-self-end">
            {t('total payments')} {total}
          </span>
        </div>
      )}
    </>
  );
};
