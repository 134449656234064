import React from 'react';
import classNames from 'classnames';
import { NotificationByParticipant } from '../../generated/graphql';
import yellowBellIcon from '../../accets/icons/yellow-bell-icon.svg';
import bellIcon from '../../accets/icons/bell-icon.svg';
import './Notification.scss';

interface INotification {
  notification: NotificationByParticipant;
  ref?: any;
}

export const Notification = ({ notification, ref }: INotification) => {
  const date = new Date(notification.notification.publishedAt);

  return (
    <div className="notification d-flex" ref={ref}>
      <div
        className={classNames({
          'notification-img-container d-flex justify-content-center align-items-center': true,
          watched: notification?.isViewed,
        })}
      >
        <img src={notification?.isViewed ? bellIcon : yellowBellIcon} alt="icon" />
      </div>

      <div className="notification-content d-flex flex-column">
        <div className="d-flex flex-column align-items-start">
          {notification?.notification?.name && (
            <span
              className={classNames({
                'notify-title': true,
                disabled: status === null,
              })}
            >
              {notification?.notification?.name}
            </span>
          )}

          <span className={classNames({ 'notify-text': true, disabled: status === null })}>
            {notification?.notification?.description}
          </span>
          <span
            className={classNames({
              'notify-data': true,
            })}
          >
            {`${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`}{' '}
            {`${date.getHours()}:${date.getMinutes()}`}
          </span>
        </div>
      </div>
    </div>
  );
};
