import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProjectLink } from '../../generated/graphql';
import { Button } from 'react-bootstrap';
import orangeArrowIcon from '../../accets/icons/orange-arrow-btn.svg';
import '../ProjectLinkSection/ProjectLinkSectiion.scss';

interface ProjectLinkSectionProps {
  projectLink: ProjectLink;
}

export const ProjectRequiredLinkSection = ({ projectLink }: ProjectLinkSectionProps) => {
  const [t] = useTranslation('translation');

  return (
    <section className="project-links-container d-flex flex-column">
      <div className="project-links">
        <div className="link-title-container">
          <span className="title-link flex-1">{`${t('link to')} ${projectLink?.title}`}</span>
        </div>

        <div className="link-title-container">
          <Button
            className="figma-link d-flex align-items-center position-relative"
            variant="link"
            href={projectLink?.link ? projectLink.link : '#'}
            target="_blank"
          >
            <span className="figma-link-title">{projectLink?.link !== null ? projectLink?.link : 'Нет ссылки'}</span>
            <img className="figma-link-img position-absolute" src={orangeArrowIcon} alt="icon" />
          </Button>
        </div>
      </div>
    </section>
  );
};
