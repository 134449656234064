import styled from 'styled-components';

export const CustomSelect = styled.div`
  position: relative;
  width: 250px;
  border: 1px solid #828282;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 10px 33px 10px 10px;
  display: flex;
`;

export const SelectButtonContainer = styled.div`
  position: absolute;
  top: 4px;
  right: 10px;
`;

export const ButtonImg = styled.img`
  transform: rotate(90deg);
`;

export const Placeholder = styled.span`
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #101010;
`;

export const LabelsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
//rgba(255, 187, 79, 0.1)
export const LabelItemContainer = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  background: ${props => props.color};
  border-radius: 10px;
  margin: 0 10px 10px 0;
  padding: 5px;
`;

export const LabelItem = styled.span`
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  margin-right: 5px;
  color: #101010;
`;

export const CloseButtonIcon = styled.img`
  width: 10px;
  height: 10px;
`;
