import React from 'react';

import { PersonalProductPanel } from './PersonalProductPanel';
import { PurchaseProductPanel } from './PurchaseProductPanel';
import { ShoppingCartProductPanel } from './ShoppingCartProductPanel';

export interface IProductPanelProps {
  role?: string;
  addPurchase?(id: string | undefined): void;
}

export const ProductPanel = ({ role, addPurchase }: IProductPanelProps) => {
  switch (role) {
    case 'purchase':
      return <PurchaseProductPanel />;

    case 'shoppingCart':
      return <ShoppingCartProductPanel />;

    default:
      return <PersonalProductPanel addPurchase={addPurchase} />;
  }
};
