import React, { useContext } from 'react';

import { ShopTabContext } from '../../ShopTab/ShopTabContext';

import styles from '../ProductPanel.module.scss';

export const PurchaseProductPanel = () => {
  const {
    card,
    card_noInfo,
    about,
    productImg,
    productImg_big,
    infoWrapper,
    infoWrapper_widthXl,
    title,
    title_marginSm,
    subtitle,
    details,
    detailsTxt,
    additional,
    placeLink,
    noItem,
    noItemTxt,
    statusTxt,
    pending,
    confirmed,
    sent,
    complited,
  } = styles;

  const { chosenPurchase } = useContext(ShopTabContext);

  const { type, image, name, description, features, url, location } = chosenPurchase?.product || {};
  const status = chosenPurchase?.status?.status || 'PENDING';

  const statuses = {
    PENDING: {
      text: 'Покупка ожидает подтверждения',
      class: pending,
    },
    CONFIRMED: {
      text: 'Покупка подтверждена',
      class: confirmed,
    },
    SENT: {
      text: 'Покупка отправлена',
      class: sent,
    },
    COMPLETED: {
      text: 'Покупка получена',
      class: complited,
    },
  };

  const isImpressionType = type?.displayName === 'impression';

  return (
    <>
      {chosenPurchase?.product ? (
        isImpressionType ? (
          <div className={card}>
            <div className={about}>
              <img src={image ? image : ''} alt="product" className={productImg} />

              <div className={infoWrapper}>
                <h2 className={title}>{name}</h2>
                <p className={`${statusTxt} ${statuses[status].class}`}>{statuses[status].text}</p>
              </div>

              <img style={{ height: '138px', width: '136px', backgroundColor: 'white' }} alt="QR code" />
            </div>

            <div className={details}>
              {description && <p className={detailsTxt}>{description}</p>}
              {features?.[0] !== '[]' && features?.length && (
                <>
                  <h3 className={subtitle}>Дополнительная информация</h3>
                  <ul className={additional}>
                    {features?.map(feature => (
                      <li className={detailsTxt} key={feature}>
                        {feature}
                      </li>
                    ))}
                  </ul>
                </>
              )}
              {(url || location) && (
                <>
                  <h3 className={subtitle}>Место проведения</h3>
                  <a className={placeLink} href={url ? url : ''}>
                    {url}
                  </a>
                  <a className={placeLink} href={`https://www.google.com//maps/search/${location}`}>
                    {location}
                  </a>
                </>
              )}
            </div>
          </div>
        ) : (
          <div className={card_noInfo}>
            <div className={about}>
              <img src={image ? image : ''} alt="product" className={productImg_big} />

              <div className={isImpressionType ? infoWrapper : infoWrapper_widthXl}>
                <h2 className={isImpressionType ? title : title_marginSm}>{name}</h2>
                <p className={`${statusTxt} ${statuses[status].class}`}>{statuses[status].text}</p>
              </div>

              <img style={{ height: '138px', width: '136px', backgroundColor: 'white' }} alt="QR code" />
            </div>
          </div>
        )
      ) : (
        <div className={card}>
          <div className={noItem}>
            <p className={noItemTxt}>Выберите покупку</p>
          </div>
        </div>
      )}
    </>
  );
};
