import React from 'react';

interface ProjectCorrectionContentProps {
  formatData: string;
  text: string;
}

export const ProjectCorrectionContent = ({ formatData, text }: ProjectCorrectionContentProps) => {
  return (
    <div className="d-flex d-lg-none flex-column-reverse justify-content-start align-items-start">
      <div className="d-flex">
        <div className="edit-item-data">{formatData[0].split('-').reverse().join('.')}</div>
        <time className="edit-item-time">{formatData[1]}</time>
      </div>
      <div className="edit-item-text-container d-flex flex-column align-items-start w-75 overflow-hidden">
        <p className="edit-item-text">{text}</p>
      </div>
    </div>
  );
};
