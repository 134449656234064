import React from 'react';
import { Button } from 'react-bootstrap';
import pencilIcon from '../../accets/icons/pencil-icon.svg';
import basketIcon from '../../accets/icons/basket-icon.svg';

interface ProjectCorrectionEditButtons {
  correctionId: string;
  showCorrectionForm(show: boolean): void;
  deleteNewCorrection(id: string): void;
}

export const ProjectCorrectionEditButtons = ({
  correctionId,
  showCorrectionForm,
  deleteNewCorrection,
}: ProjectCorrectionEditButtons) => {
  return (
    <>
      <Button className="edit-item-btn position-absolute" onClick={() => showCorrectionForm(true)}>
        <img src={pencilIcon} alt="icon" />
      </Button>
      <Button className="delete-item-btn position-absolute" onClick={() => deleteNewCorrection(correctionId)}>
        <img src={basketIcon} alt="icon" />
      </Button>
    </>
  );
};
