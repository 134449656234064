import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const NavbarContainer = styled.div`
  width: 100%;
  margin: 50px 0 30px 0;
  position: relative;

  .carousel-button-group {
    position: absolute;
    top: 50%;
    width: 100%;
  }

  .arrow--left,
  .arrow--right {
    position: absolute;
    right: -40px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;

    &:hover {
      background-color: #f1f1f1;
      transition: background-color 0.5s;
    }
  }

  .arrow--left {
    left: -40px;
    transform: rotate(180deg);
  }
`;

export const NavbarLink = styled<any>(Link)`
  background: ${props => (props?.active ? 'rgba(255, 187, 79, 0.1)' : '#f3f3f3')};
  border: ${props => (props?.active ? '1px solid #ffbb4f' : 'none')};
  box-sizing: border-box;
  border-radius: 10px;
  padding: 10px 20px;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 129px;
  max-width: 166px;
  margin-right: 20px;

  &:hover {
    color: #101010;
    text-decoration: none;
  }

  &:active {
    background: rgba(255, 187, 79, 0.1);
    border: 1px solid #ffbb4f;
  }
`;

export const LinkIcon = styled.div<{ url: string }>`
  background: ${({ url }) => `url(${url})`};
  width: 54px;
  height: 54px;
  margin-bottom: 24px;
`;

export const LinkTitle = styled.span`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #101010;
  white-space: nowrap;
`;
