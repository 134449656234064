import React from 'react';
import { useTranslation } from 'react-i18next';
import cloudIcon from '../../accets/img/cloud.svg';
import robotImg from '../../accets/img/robot.png';
import waiveHandImg from '../../accets/img/waving-hand-sign_1f44b 1.svg';
import './GreetingUser.scss';
import { Cloud } from './GreetingUser.style';

interface IGreetingUserProps {
  firstName: string;
  lastName: string;
}

export const GreetingUser = ({ firstName, lastName }: IGreetingUserProps) => {
  const [t] = useTranslation('translation');

  return (
    <div className="d-flex flex-column align-items-center position-relative h-100" style={{ width: '717px' }}>
      <Cloud src={cloudIcon}>
        <div className="greeting-content d-flex flex-column">
          <div className="greeting-user-title-container d-flex align-items-center">
            <img className="greeting-content-waive-hand" src={waiveHandImg} alt="waive hand" />
            <span className="greeting-user-title">{`${t('greetings')}, ${firstName} ${lastName}!`}</span>
          </div>

          <span className="greeting-user-subtitle">{t('to get started, select a project')}</span>
        </div>
      </Cloud>
      <div className="robot-img-container position-relative w-100">
        <img className="robot-img position-absolute" src={robotImg} alt="robot" />
      </div>
    </div>
  );
};
