import React from 'react';

import { Switch, Route, NavLink } from 'react-router-dom';
import { ShopTab } from '../../components/ShopTab';
import { ShopTabProvider } from '../../components/ShopTab/ShopTabContext';

import styles from './Shop.module.scss';

export const Shop = () => {
  const { shop, nav, navItem, navLink, active } = styles;

  return (
    <ShopTabProvider>
      <section className={shop}>
        <ul className={nav}>
          <li className={navItem}>
            <NavLink to={'/gamification/shop'} className={navLink} activeClassName={active} exact>
              Персонаж
            </NavLink>
          </li>
          <li className={navItem}>
            <NavLink to={'/gamification/shop/personal'} className={navLink} activeClassName={active}>
              Персональное
            </NavLink>
          </li>
        </ul>

        <Switch>
          <Route path={'/gamification/shop/personal'}>
            <ShopTab role="personal" />
          </Route>

          <Route path={'/gamification/shop'}>
            <ShopTab />
          </Route>
        </Switch>
      </section>
    </ShopTabProvider>
  );
};
