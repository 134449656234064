import React, { useEffect, useContext, useState } from 'react';

import { useMutation } from '@apollo/client';
import { CONFIRM_PURCHASES } from '../../../graphql/mutations/purchases';
import { GET_PURCHASES_HISTORY, GET_PURCHASES_IN_CART } from '../../../graphql/queries/purchases';

import { ShopTabContext } from '../../ShopTab/ShopTabContext';

import { IProductsListProps } from '../ProductsList';

import { ProductCard } from '../../ProductCard';
import { Modal } from '../../Modal';

import styles from '../ProductsList.module.scss';
import overlayStyles from '../../../accets/scss/gamification/overlay.module.scss';
import { User } from '../../../generated/graphql';
import { UserInfoContext } from '../../../pages/Main/Main';

export const ShoppingCartProductsList = ({ deletePurchase }: IProductsListProps) => {
  const {
    body_cart,
    controls,
    switchBtn_xl,
    list,
    item,
    chosen,
    listWrapper,
    emptyProductsList,
    body_total,
    totals_list,
    total,
    balance,
    kpi,
    level,
    enough,
    notEnough,
    confirmBtn,
  } = styles;

  const user: User = useContext(UserInfoContext);

  const { purchasesInCart, chosenPurchase, chosePurchaseHandler } = useContext(ShopTabContext);

  let totalFlex: number | undefined;
  let maxPurchaseLevel: number | undefined;
  let maxPurchaseKpi: number | undefined;

  if (purchasesInCart?.length) {
    totalFlex = purchasesInCart
      .map(purchase => purchase.product!.flex!)
      .reduce((cur, acc) => {
        return cur + acc;
      }, 0);
    maxPurchaseLevel = Math.max(...purchasesInCart.map(purchase => purchase.product!.requiredLevel!));
    maxPurchaseKpi = Math.max(...purchasesInCart.map(purchase => purchase.product!.requiredKpi!));
  }

  const [isEnough, setEnough] = useState({
    flex: true,
    level: true,
    kpi: true,
  });

  // useEffect(() => {
  //   if (totalFlex && maxPurchaseLevel && maxPurchaseKpi) {
  //     setEnough({
  //       flex: totalFlex <= user.stats.flex,
  //       level: maxPurchaseLevel <= user.stats.levelValue,
  //       kpi: maxPurchaseKpi <= user.stats.kpi,
  //     });
  //   }
  // }, [totalFlex, maxPurchaseLevel, maxPurchaseKpi]);

  const [isSuccessConfirmModalActive, setSucessConfirmModalActive] = useState(false);
  const [isRejectConfirmModalActive, setRejectConfirmModalActive] = useState(false);
  const notEnoughCredits = Object.values(isEnough).some(enough => !enough);

  const [confirmPurchase] = useMutation(CONFIRM_PURCHASES, {
    refetchQueries: [
      { query: GET_PURCHASES_IN_CART, variables: { getPurchasesData: { limit: 1000 } } },
      { query: GET_PURCHASES_HISTORY, variables: { getPurchasesData: { limit: 1000 } } },
    ],
  });

  const confirmPurchasesHandler = (): void => {
    if (Object.values(isEnough).some(enough => !enough)) {
      setRejectConfirmModalActive(true);
      return;
    }

    confirmPurchase();
    setSucessConfirmModalActive(true);
  };

  return (
    <div>
      <div className={body_cart}>
        <ul className={controls}>
          <li className={switchBtn_xl}>Мои покупки</li>
        </ul>
        <div className={listWrapper}>
          <ul className={list}>
            {purchasesInCart?.length ? (
              purchasesInCart.map(purchase => {
                return (
                  <li
                    className={chosenPurchase && purchase.id === chosenPurchase.id ? chosen : item}
                    key={purchase.id}
                    onClick={() => {
                      purchase.id && chosePurchaseHandler(purchase.id, 'shoppingCart');
                    }}
                  >
                    <ProductCard role="shoppingCart" purchase={purchase} deletePurchase={deletePurchase} />
                  </li>
                );
              })
            ) : (
              <p className={emptyProductsList}>У вас нет покупок</p>
            )}
          </ul>
        </div>
      </div>

      <div className={body_total}>
        <ul className={totals_list}>
          <li className={`${total} ${kpi}`}>
            KPI: &nbsp; <span className={isEnough.kpi ? enough : notEnough}>{maxPurchaseKpi || 0}</span>
          </li>
          <li className={`${total} ${balance}`}>
            Баланс: &nbsp; <span className={isEnough.flex ? enough : notEnough}>{totalFlex || 0}</span> &nbsp; flex
          </li>
          <li className={`${total} ${level}`}>
            Уровень: &nbsp; <span className={isEnough.level ? enough : notEnough}>{maxPurchaseLevel || 0}</span>
          </li>
        </ul>
        <button className={confirmBtn} disabled={notEnoughCredits} onClick={() => confirmPurchasesHandler()}>
          Купить
        </button>
      </div>

      {notEnoughCredits
        ? isRejectConfirmModalActive && (
            <Modal role="reject" close={() => setRejectConfirmModalActive(false)} credits={isEnough} />
          )
        : isSuccessConfirmModalActive && <Modal role="success" close={() => setSucessConfirmModalActive(false)} />}

      <div
        className={
          isRejectConfirmModalActive || isSuccessConfirmModalActive
            ? overlayStyles.overlay
            : `${overlayStyles.overlay} ${overlayStyles.hidden}`
        }
      ></div>
    </div>
  );
};
