import React from 'react';
import { Button } from 'react-bootstrap';
import './NewsItem.scss';
import { YoutubeEmbed } from '../YoutubeEmbed';

interface newsItem {
  id: string;
  name: string;
  photo: string;
  youTubeLink: string;
  publishedAt: string;
  description: string;
}

export interface NewsItemProps {
  id: string;
  name: string;
  photo: string;
  youTubeLink: string;
  publishedAt: string;
  description: string;
  setIsNewsModal(state: boolean): void;
  setCurrentNews(currentItem: newsItem): void;
}

export const NewsItem = ({
  data: { id, name, photo, youTubeLink, description, publishedAt, setIsNewsModal, setCurrentNews },
}: {
  data: NewsItemProps;
}) => {
  const date = new Date(publishedAt);

  return (
    <div className="news-item-container w-100">
      <div className="news-item-content w-100 d-flex flex-column align-items-start">
        <span className="news-item-data">
          {' '}
          {`${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`} {`${date.getHours()}:${date.getMinutes()}`}
        </span>
        {
          <div className="d-flex w-100 justify-content-center">
            <img className="news-item-img mb-2" src={photo} alt="new-img" />{' '}
          </div>
        }
        {name && <h3 className="news-item-title">{name}</h3>}
        {youTubeLink && <YoutubeEmbed url={youTubeLink} />}
        {description && (
          <div className="position-relative">
            <p className="news-item-text mb-2">{description}</p>
            {description.length >= 448 && (
              <div className="d-flex justify-content-center">
                <Button
                  className="see-more-btn"
                  onClick={() => {
                    setIsNewsModal(true);
                    setCurrentNews({ id, name, photo, publishedAt, description, youTubeLink });
                  }}
                >
                  Читать далее
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
