import React from 'react';
import { useTranslation } from 'react-i18next';
import { Logo } from '../../components/Logo';
import { Button } from 'react-bootstrap';
import { ConfirmationModal } from '../../components/ConfirmationModal';
import './InformationPlaceholder.scss';

interface WelcomeProps {
  registered?: boolean;
  confirmed?: boolean;
  passwordRecovery?: boolean;
}

export const InformationPlaceholder = ({ registered, confirmed, passwordRecovery }: WelcomeProps) => {
  const [t] = useTranslation('translation');

  return (
    <div className="bg position-relative d-flex flex-column align-items-center justify-content-center">
      <div className="d-block d-lg-none">
        <Logo logoFontSize={30} typeLogo="mainLogo" />
      </div>
      <div className="d-none d-lg-block mb-2">
        <Logo logoFontSize={75} typeLogo="mainLogo" />
      </div>

      {registered && <span className="welcome-text">{t('an email has been sent to you to confirm')}</span>}

      {confirmed && (
        <div className="d-flex flex-column align-items-center">
          <span className="welcome-text">{`${t('thanks for registering')} !`}</span>
          <span className="welcome-text">
            {t('Your email is verified. You will be able to log in after the administrator has verified your details')}
          </span>
          <Button className="welcome-link" variant="link" href="/login">
            {t('ok')}
          </Button>
        </div>
      )}

      {passwordRecovery && (
        <div className="d-flex flex-column align-items-center">
          <ConfirmationModal text={t('enter your email to recover your password')} passwordRecovery />
        </div>
      )}
    </div>
  );
};
