import React, { useContext, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { FetchResult, useMutation } from '@apollo/client';
import { Redirect } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import * as Yup from 'yup';
import {
  LogoutDocument,
  UpdateUserDocument,
  GetUserInfoDocument,
  LogoutMutation,
  UpdateUserMutation,
  User,
} from '../../generated/graphql';
import { EditableInput } from '../EditableInput';
import { ConfirmationModal } from '../ConfirmationModal';
import { LoadingDisplay } from '../LoadingDisplay';
import { ErrorDisplay } from '../ErrorDisplay';
import { classNames } from '../../constants/classNames';
import { Button } from 'react-bootstrap';
import userDefaultPhoto from '../../accets/icons/defaultUserPhoto.svg';
import closeIcon from '../../accets/icons/close-icon.svg';
import arrowBackIcon from '../../accets/icons/arrow-back-icon.svg';
import { UserInfoContext } from '../../pages/Main/Main';
import { AvatarEditor } from './AvatarEditor';
import './Profile.scss';

interface ILogoutModalProps {
  closeLogoutModal(showModal: boolean): void;
}

interface IInitialValues {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
}

const initialValues: IInitialValues = {
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
};

const blobToFile = (theBlob: Blob, fileName: string): File => {
  return new File([theBlob], fileName);
};

export const Profile = ({ closeLogoutModal }: ILogoutModalProps) => {
  const [t] = useTranslation('translation');
  const { id, firstName, lastName, phone, email, photo } = useContext<User>(UserInfoContext);
  const [cropper, setCropper] = useState<Cropper | null>();
  const [cropped, setCropped] = useState<string>();
  const [edit, setEdit] = useState(false);

  const [logout, { client, data: logoutSuccess, loading: logoutLoading, error: logoutError }] =
    useMutation<LogoutMutation>(LogoutDocument);
  const [updateUser, { loading: updateUserLoading, error: updateUserError }] =
    useMutation<UpdateUserMutation>(UpdateUserDocument);

  const [file, setFile] = useState<File>();
  const [isChanges, setIsChanges] = useState<boolean>(false);
  const [clickedBtnId, setClickedBtnId] = useState<string>();
  const [prevFormValues, setFormValues] = useState<IInitialValues>();

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 992px)',
  });

  const phoneRegExp = /^[\\+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{6}$/im;

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object({
      firstName: Yup.string().min(2, t('invalid name')),
      lastName: Yup.string().min(2, t('invalid name')),
      phone: Yup.string().matches(phoneRegExp, t('invalid phone')),
      email: Yup.string().email(t('invalid email')),
    }),

    onSubmit: async values => {
      const userData = {
        id: id,
        firstName: values.firstName,
        lastName: values.lastName,
        phone: values.phone,
        email: values.email,
      };

      await updateUser({
        variables: {
          userData: userData,
          photo: file && file,
        },
        refetchQueries: [{ query: GetUserInfoDocument }],
      });
      closeLogoutModal(false);
    },
  });

  const prevFormValuesToString = JSON.stringify(prevFormValues);
  const formValuesToString = JSON.stringify(formik.values);

  const closeProfile = async (e: React.SyntheticEvent<{ id: string }>): Promise<any> => {
    setClickedBtnId(e.currentTarget.id);

    if (
      (e.currentTarget.id === 'close-profile-btn' || e.currentTarget.id === 'profile-container') &&
      !cropped &&
      prevFormValuesToString === formValuesToString
    ) {
      closeLogoutModal(false);
      return;
    }

    if (e.currentTarget.id === 'logout-btn' && !cropped && prevFormValuesToString === formValuesToString) {
      await logout();
      await client.resetStore();
      return;
    }

    setIsChanges(true);
  };

  const closeConfirmMessage = async (): Promise<FetchResult<any> | void> => {
    if (clickedBtnId === 'logout-btn') {
      await logout();
      await client.resetStore();
    }

    if (clickedBtnId === 'close-profile-btn') {
      closeLogoutModal(false);
    }

    setIsChanges(false);
  };

  useEffect(() => {
    formik.setFieldValue('lastName', lastName);
    formik.setFieldValue('firstName', firstName);
    formik.setFieldValue('phone', phone);
    formik.setFieldValue('email', email);
    setFormValues({
      firstName: firstName as string,
      lastName: lastName as string,
      phone: phone as string,
      email: email as string,
    });
  }, [firstName]);

  if (logoutSuccess) {
    return <Redirect to="/login" />;
  }

  if (logoutLoading || updateUserLoading) {
    return <LoadingDisplay />;
  }

  if (logoutError || updateUserError) {
    return <ErrorDisplay message={logoutError?.message || updateUserError?.message} />;
  }

  const onChange = async (cropper: Cropper) => {
    const canvasScaled = cropper.getCroppedCanvas({
      maxWidth: 720,
    });
    const blob = await fetch(canvasScaled.toDataURL()).then(res => res.blob());
    const result = blobToFile(blob, 'avatar.jpg');
    setCropped(canvasScaled.toDataURL());
    setFile(result);
  };

  return (
    <>
      <div className="logout-modal d-flex flex-column align-items-center">
        <div className="position-relative w-100">
          <Button className="btn-close position-absolute" id="close-profile-btn" onClick={e => closeProfile(e)}>
            <img className="btn-close-img d-none d-lg-block" src={closeIcon} alt="icon" />
            <img className="btn-back-img d-lg-none" src={arrowBackIcon} alt="icon" />
          </Button>
        </div>
        <div className="profile-photo-container d-flex flex-column align-items-center position-relative">
          {photo !== null ? (
            <div className="logout-modal-photo d-flex justify-content-center align-items-center">
              <img className="img" src={cropped || photo || userDefaultPhoto} alt="user-photo" />
            </div>
          ) : (
            <div className="logout-modal-default-user-photo d-flex justify-content-center align-items-center">
              <img className="img" src={cropped || userDefaultPhoto} alt="user-photo" />
            </div>
          )}
          <label
            className={classNames({
              'btn-change-photo': isDesktopOrLaptop,
              'btn-change-photo-icon position-absolute': !isDesktopOrLaptop,
            })}
            onClick={() => setEdit(true)}
          >
            {t('change photo')}
          </label>
          {edit && (
            <AvatarEditor
              cropper={cropper}
              setCropper={setCropper}
              onChange={onChange}
              setCropped={setCropped}
              onCancel={() => setEdit(false)}
            />
          )}
        </div>

        <h3 className="profile-user-name">{`${lastName} ${firstName}`}</h3>
        <form className="form-logout w-100 d-flex flex-column align-items-center" onSubmit={formik.handleSubmit}>
          <EditableInput
            className="logout-input w-100"
            classNameError="logout-input-error"
            id="lastName"
            name="lastName"
            label={t('surname')}
            type="text"
            placeholder={t('surname')}
            value={formik.values.lastName}
            error={formik.errors.lastName}
            onChange={formik.handleChange}
          />
          <EditableInput
            className="logout-input w-100"
            classNameError="logout-input-error"
            id="firstName"
            name="firstName"
            label={t('name')}
            type="text"
            placeholder={t('name')}
            value={formik.values.firstName}
            error={formik.errors.firstName}
            onChange={formik.handleChange}
          />
          <EditableInput
            className="logout-input w-100"
            classNameError="logout-input-error"
            id="email"
            label={t('login')}
            name="email"
            type="email"
            placeholder={t('login')}
            value={formik.values.email}
            error={formik.errors.email}
            onChange={formik.handleChange}
          />
          <EditableInput
            className="logout-input w-100"
            classNameError="logout-input-error"
            id="phone"
            label={t('phone')}
            name="phone"
            type="text"
            placeholder={t('phone')}
            value={formik.values.phone}
            error={formik.errors.phone}
            onChange={formik.handleChange}
          />

          <div className="profile-btn-container d-flex">
            <Button className="form-logout-btn-save" type="submit">
              {t('save')}
            </Button>

            <Button className="form-logout-btn-logout" id="logout-btn" onClick={closeProfile}>
              {t('logout')}
            </Button>
          </div>
        </form>
        {isChanges && (
          <ConfirmationModal
            text={t('changes have been made, save?')}
            handleSubmit={formik.handleSubmit}
            onCancelClick={closeConfirmMessage}
          />
        )}
      </div>
      <div className="profile-container" id="profile-container" onClick={closeProfile} />
    </>
  );
};
