import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FileInput } from '../FileInput/FileInput';
import { User } from '../../generated/graphql';
import { Button } from 'react-bootstrap';
import addImgIcon from '../../accets/icons/add-img-icon.svg';
import addListIcon from '../../accets/icons/add-list-icon.svg';
import addFileIcon from '../../accets/icons/add-file-icon.svg';
import pencilIcon from '../../accets/icons/white-pencil-icon.svg';
import uploadIcon from '../../accets/icons/upload-icon.svg';
import userDefaultPhoto from '../../accets/icons/defaultUserPhoto.svg';
import './CommentForm.scss';
import { UserInfoContext } from '../../pages/Main/Main';

interface ICommentFormProps {
  btnName: string;
  inputValue: string;
  placeholder: string;
  isTextArea: boolean;
  textAreaValue: string;
  createCorrection?: boolean;
  desktop?: boolean;
  setIsEditForm(isForm: boolean): void;
  setInputValue(value: string): void;
  setTextAreaValue(text: string): void;
  setIsTextArea(textArea: boolean): void;
  onCheckList(): void;
  sendNewEdit(): void;
  getInputFile(file: FileList): void;
}

export const CommentForm = ({
  createCorrection,
  sendNewEdit,
  setIsEditForm,
  setInputValue,
  inputValue,
  placeholder,
  btnName,
  desktop,
  onCheckList,
  isTextArea,
  textAreaValue,
  setTextAreaValue,
  setIsTextArea,
  getInputFile,
}: ICommentFormProps) => {
  const [t] = useTranslation('translation');
  const { photo } = useContext<User>(UserInfoContext);

  return (
    <form
      className="edit-form"
      onSubmit={e => {
        e.preventDefault();
        sendNewEdit();
        createCorrection && setIsEditForm(false);
        setInputValue('');
        setTextAreaValue('');
        setIsTextArea(false);
      }}
    >
      <div className="edit-form-input-container position-relative d-flex align-items-center  w-100">
        {desktop && <img className="form-user-photo" src={photo ? photo : userDefaultPhoto} alt="user img" />}
        <div className="position-relative d-flex align-items-center w-100">
          {!desktop && (
            <label className="edit-form-label upload-label position-relative" htmlFor="edit-form-img-input">
              <img className="edit-form-img" src={uploadIcon} alt="icon" />
              <input
                className="edit-form-input-file position-absolute"
                type="file"
                id="edit-form-img-input"
                accept=".png, .jpg, .jpeg"
                title="Выберите картинку"
              />
            </label>
          )}

          {desktop && (
            <div className="edit-form-btn-container position-absolute d-flex align-items-center">
              <label className="edit-form-label position-relative" htmlFor="edit-form-img-input">
                <img className="edit-form-img" src={addImgIcon} alt="icon" />
                <FileInput
                  className="edit-form-input-file position-absolute"
                  id="edit-form-img-input"
                  accept=".png, .jpg, .jpeg"
                  title="Выберите картинку"
                  onChange={getInputFile}
                />
              </label>
              <label className="edit-form-label position-relative" htmlFor="edit-form-list-input">
                <img className="edit-form-img" src={addListIcon} alt="icon" />
                <input
                  onClick={onCheckList}
                  className="edit-form-input-file position-absolute pointer-event"
                  type="text"
                  id="edit-form-list-input"
                  title="Заполните чеклист"
                />
              </label>
              <label className="edit-form-label position-relative" htmlFor="edit-form-file-input">
                <img className="edit-form-img" src={addFileIcon} alt="icon" />
                <input
                  className="edit-form-input-file position-absolute"
                  type="file"
                  id="edit-form-file-input"
                  accept=".doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  title="Выберите документ"
                />
              </label>
            </div>
          )}

          {isTextArea ? (
            <textarea
              className="edit-form-input  w-100"
              rows={4}
              value={textAreaValue}
              onChange={e => setTextAreaValue(e.target.value)}
            />
          ) : (
            <input
              className="edit-form-input  w-100"
              placeholder={placeholder}
              value={inputValue}
              onChange={e => setInputValue(e.target.value)}
            />
          )}
        </div>
      </div>
      <Button className="edit-form-btn" type="submit">
        <img className="d-lg-none" src={pencilIcon} alt="icon" />
        <span className="d-none d-lg-block">{`${t(btnName)}`}</span>
      </Button>
    </form>
  );
};
