import React, { Component, LazyExoticComponent } from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';

interface IPrivateRotesProps {
  component:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>
    | LazyExoticComponent<React.ComponentType<any>>;
  path?: string | string[];
  exact?: boolean;
  location?: any;
}

export const PrivateRoute = ({ component: Component, ...rest }: IPrivateRotesProps) => {
  return <Route {...rest} render={(props: any) => <Component {...props} />} />;
};
