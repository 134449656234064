import React from 'react';

import { MainUserBar } from './MainUserBar/MainUserBar';
import { ShopUserBar } from './ShopUserBar/ShopUserBar';

interface IUserBarProps {
  role?: string;
}

export const UserBar = ({ role }: IUserBarProps) => {
  switch (role) {
    case 'shopUserBar':
      return <ShopUserBar />;

    default:
      return <MainUserBar />;
  }
};
