import React, { useRef } from 'react';

interface IFileInput {
  onChange(e: FileList | null): void;
  className?: string;
  id?: string;
  accept?: string;
  title?: string;
  children?: any;
}

export const FileInput = ({ onChange, className, id, accept, title, children }: IFileInput) => {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <input
      className={className}
      id={id}
      accept={accept}
      title={title}
      style={{ display: 'none' }}
      type="file"
      ref={inputRef}
      onChange={e => {
        const target = e.target as HTMLInputElement;
        const files = target.files;
        onChange(files);
      }}
    >
      {children}
    </input>
  );
};
