import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditButton } from '../../../../constants/Styles/EditButton';
import { CloseButton } from '../../../../constants/Styles/CloseButton';
import { getFileExtension } from '../../../../constants/getFileExtension';
import { AddFileButton } from '../../../../constants/Styles/AddFileButton';
import { useMutation } from '@apollo/client';
import {
  CreateProjectTaskCommentDocument,
  CreateProjectTaskCommentMutation,
  DeleteProjectTaskCommentDocument,
  DeleteProjectTaskCommentFileDocument,
  DeleteProjectTaskCommentFileMutation,
  GetProjectTaskDocument,
  ProjectTaskComment,
  UpdateProjectTaskCommentDocument,
  UpdateProjectTaskCommentMutation,
  User,
} from '../../../../generated/graphql';
import addFileIcon from '../../../../accets/icons/add-file-icon.svg';
import defaultUserPhoto from '../../../../accets/icons/defaultUserPhoto.svg';
import { SmallAddButton } from '../../../../constants/Styles/SmallAddButton';
import {
  AddFileButtonContainer,
  AuthorAvatar,
  ButtonsContainer,
  CommentContainer,
  FileName,
  HiddenInput,
  FileNameSection,
  AttachmentSection,
  AttachmentImageContainer,
  AttachmentImage,
  CommentContent,
  DeleteAttachmentImageButton,
  CommentTextArea,
  AttachmentImgContainerModal,
  AttachmentImgContainer,
  AttachmentImg,
  ButtonContainer,
} from './CommentSection.style';
import { TransparentButton } from '../../../../constants/Styles/TransparentButton';
import { FieldContainer, TextArea, SectionContainer, SectionTitle, ModalButton } from '../DashboardModal.style';
import {
  ItemButtonsContainer,
  ItemsContainer,
  SectionTitleContainer,
} from '../ChecklistSection/ChecklistSection.style';
import { Snackbar } from '../../../../components/Snackbar';

interface CommentSectionProps {
  taskId: string;
  author: User;
  comments: ProjectTaskComment[];
  description: string | undefined;
  saveTaskInfo(): void;
}

const imgExtensions: string[] = ['.png', '.jpg', '.jpeg', '.svg'];

export const CommentSection = ({ taskId, author, comments, description, saveTaskInfo }: CommentSectionProps) => {
  const [t] = useTranslation('translation');
  const [creating, setCreating] = useState(false);
  const [chosenCommentId, setChosenCommentId] = useState('');
  const [isAttachmentPhoto, setIsAttachmentPhoto] = useState(false);
  const [currentAttachmentPhoto, setCurrentAttachmentPhoto] = useState('');
  const fileInput = useRef<HTMLInputElement>(null);

  const [text, setText] = useState('');
  const [file, setFile] = useState<File>(null);

  const [requestError, setRequestError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [createComment, { data: createCommentData, loading: createCommentLoading, error: createCommentError }] =
    useMutation<CreateProjectTaskCommentMutation>(CreateProjectTaskCommentDocument, {
      variables: {
        projectTaskId: taskId,
        text,
        file: file,
      },
      refetchQueries: [{ query: GetProjectTaskDocument, variables: { id: taskId } }],
    });

  const [updateComment, { data: updateCommentData, loading: updateCommentLoading, error: updateCommentError }] =
    useMutation<UpdateProjectTaskCommentMutation>(UpdateProjectTaskCommentDocument, {
      variables: {
        id: chosenCommentId,
        text,
        file,
      },
      refetchQueries: [{ query: GetProjectTaskDocument, variables: { id: taskId } }],
    });

  const [deleteComment, { data: deleteCommentData, loading: deleteCommentLoading, error: deleteCommentError }] =
    useMutation<DeleteProjectTaskCommentFileMutation>(DeleteProjectTaskCommentDocument, {
      refetchQueries: [{ query: GetProjectTaskDocument, variables: { id: taskId } }],
    });

  const [
    deleteCommentFile,
    { data: deleteCommentFileData, loading: deleteCommentFileLoading, error: deleteCommentFileError },
  ] = useMutation<DeleteProjectTaskCommentFileMutation>(DeleteProjectTaskCommentFileDocument, {
    refetchQueries: [{ query: GetProjectTaskDocument, variables: { id: taskId } }],
  });

  useEffect(() => {
    if (deleteCommentFileData || deleteCommentData || updateCommentData || createCommentData) {
      setRequestError(false);
      setFile(null);
      setText('');
      setChosenCommentId('');
    }
  }, [deleteCommentFileData, deleteCommentData, updateCommentData, createCommentData]);

  useEffect(() => {
    if (createCommentData) {
      setCreating(false);
    }
  }, [createCommentData]);

  useEffect(() => {
    if (deleteCommentFileError || deleteCommentError || updateCommentError || createCommentError) {
      setRequestError(true);
      setChosenCommentId('');
      deleteCommentError && setErrorMessage('При удалении комментария произошла ошибка');
      updateCommentError && setErrorMessage('При изменении комментария произошла ошибка');
      createCommentError && setErrorMessage('При создании комментария произошла ошибка');
      deleteCommentFileError && setErrorMessage('При изменении статуса комментария произошла ошибка');
    }
  }, [deleteCommentFileError, deleteCommentError, updateCommentError, createCommentError]);

  const closeInput = () => {
    setCreating(false);
    setText('');
    setFile(null);
  };

  const saveComment = () => {
    if (creating) {
      createComment();
      !description && saveTaskInfo();
      return;
    }
    updateComment();
  };

  const editHandler = (id: string, commentText: string): void => {
    if (chosenCommentId === id) {
      saveComment();
      setChosenCommentId('');
      return;
    }

    setText(commentText);
    setChosenCommentId(id);
  };

  return (
    <SectionContainer>
      <SectionTitleContainer>
        <SectionTitle>Комментарий</SectionTitle>
        <ButtonContainer>
          <SmallAddButton onClick={() => setCreating(true)} />
        </ButtonContainer>
      </SectionTitleContainer>

      {creating && (
        <>
          <FieldContainer>
            <TextArea placeholder="Ввести" value={text} onChange={({ target: { value } }) => setText(value)} rows={5} />

            {!file && (
              <AddFileButtonContainer>
                <TransparentButton onClick={() => fileInput.current.click()}>
                  <img src={addFileIcon} alt="icon" />
                </TransparentButton>
              </AddFileButtonContainer>
            )}

            {file?.name && (
              <FileNameSection>
                <FileName>{file.name}</FileName>
                <CloseButton
                  size={12}
                  active={!!file.name}
                  onClick={() => {
                    fileInput.current.value = '';
                    setFile(null);
                  }}
                />
              </FileNameSection>
            )}
          </FieldContainer>

          <ButtonsContainer>
            <ModalButton color="#101010" fontSize={12} padding={[5, 15]} onClick={closeInput}>
              {t('cancel')}
            </ModalButton>
            <ModalButton color="#FFBB4F" fontSize={12} padding={[5, 15]} onClick={saveComment}>
              {t('save')}
            </ModalButton>
          </ButtonsContainer>
        </>
      )}

      {comments?.length ? (
        <ItemsContainer>
          {comments.map(comment => (
            <CommentContainer key={comment.id}>
              <CommentContent>
                <AuthorAvatar src={author?.photo ? author.photo : defaultUserPhoto} alt="user photo" />
                <CommentTextArea
                  rows={5}
                  value={chosenCommentId === comment.id ? text : comment.text}
                  onChange={({ target: { value } }) => setText(value)}
                  disabled={chosenCommentId !== comment.id}
                  active={chosenCommentId === comment.id}
                />

                <ItemButtonsContainer>
                  {!comment.file && !file && (
                    <AddFileButton
                      size={12}
                      active={chosenCommentId === comment.id}
                      onClick={() => (chosenCommentId === comment.id ? fileInput.current.click() : null)}
                    />
                  )}

                  <EditButton
                    active={chosenCommentId === comment.id}
                    size={12}
                    onClick={() => editHandler(comment.id, comment.text)}
                  />
                  <CloseButton
                    active={chosenCommentId !== comment.id}
                    size={12}
                    onClick={() => deleteComment({ variables: { id: comment.id } })}
                  />
                </ItemButtonsContainer>
              </CommentContent>

              {comment.file ? (
                <AttachmentSection>
                  {imgExtensions.includes(getFileExtension(comment.file)) ? (
                    <AttachmentImageContainer>
                      <AttachmentImage
                        onClick={() => {
                          setIsAttachmentPhoto(true);
                          setCurrentAttachmentPhoto(comment.file);
                        }}
                        src={comment.file}
                        alt={comment.file}
                      />
                      <DeleteAttachmentImageButton>
                        <CloseButton
                          size={12}
                          active={chosenCommentId !== comment.id}
                          onClick={() => {
                            fileInput.current.value = '';
                            setFile(null);
                            deleteCommentFile({ variables: { id: comment.id } });
                          }}
                        />
                      </DeleteAttachmentImageButton>
                    </AttachmentImageContainer>
                  ) : (
                    <div>inneer1</div>
                  )}
                </AttachmentSection>
              ) : (
                chosenCommentId === comment.id &&
                file && (
                  <>
                    {file?.name && (
                      <FileNameSection>
                        <FileName>{file.name}</FileName>
                        <CloseButton
                          active={chosenCommentId === comment.id}
                          size={12}
                          onClick={() => {
                            fileInput.current.value = '';
                            setFile(null);
                          }}
                        />
                      </FileNameSection>
                    )}
                  </>
                )
              )}
            </CommentContainer>
          ))}
        </ItemsContainer>
      ) : (
        <div>Комментарии отсутствуют</div>
      )}
      <HiddenInput ref={fileInput} type="file" onChange={({ target: { files } }) => setFile(files[0])} />

      {isAttachmentPhoto && (
        <AttachmentImgContainerModal>
          <AttachmentImgContainer>
            <ButtonContainer>
              <CloseButton active={true} onClick={() => setIsAttachmentPhoto(false)} />
            </ButtonContainer>
            <AttachmentImg src={currentAttachmentPhoto} alt="attachmentImg" />
          </AttachmentImgContainer>
        </AttachmentImgContainerModal>
      )}
      {requestError && <Snackbar text={errorMessage} className="error" />}
    </SectionContainer>
  );
};
