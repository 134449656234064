export const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 3000, min: 1360 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 1360, min: 720 },
    items: 3,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 720, min: 464 },
    items: 3,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
    slidesToSlide: 1,
  },
};
