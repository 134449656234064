import React, { useContext, useEffect, useLayoutEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { classNames } from '../../constants/classNames';
import { User } from '../../generated/graphql';
import { Logo } from '../Logo';
import { Button } from 'react-bootstrap';
import burgerMenu from '../../accets/icons/burger-menu-icon.svg';
import closeIcon from '../../accets/icons/close-icon.svg';
import { SidebarContacts } from '../SidebarContacts';
import { SidebarNav } from '../SidebarNav/SidebarNav';
import { UserInfoContext } from '../../pages/Main/Main';
import './SideBar.scss';

interface ISideBarProps {
  isSideBarOpen: boolean;
  setIsSideBarOpen(isOpen: boolean): void;
}

export const SideBar = ({ isSideBarOpen, setIsSideBarOpen }: ISideBarProps) => {
  const [t] = useTranslation('translation');
  const { role } = useContext<User>(UserInfoContext);
  const sideBarRef = useRef(null);

  useEffect(() => {
    const d = sideBarRef.current.clientHeight;
    const d1 = sideBarRef.current.offsetHeight;
    const scrollHeight = Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.offsetHeight,
      document.body.clientHeight,
      document.documentElement.clientHeight,
    );
    console.log('d', d, d1, scrollHeight);
  }, []);

  return (
    <aside
      ref={sideBarRef}
      className={classNames({
        sidebar: true,
        'sidebar-open': isSideBarOpen,
      })}
    >
      {isSideBarOpen ? (
        <div className="d-flex flex-column justify-content-between h-100 align-items-center">
          <div className="fixed-bg">
            <div className="sidebar-logo-container d-none d-lg-block">
              <Logo logoFontSize={20} typeLogo="smallLogo" />
            </div>
            <Button
              className="sidebar-close-btn d-none d-lg-block"
              onClick={() => {
                setIsSideBarOpen(false);
              }}
            >
              <img src={closeIcon} alt="icon" />
            </Button>
          </div>
          <div>
            <h2 className="sidebar-title text-center">{t('menu')}</h2>
            <div className="sidebar-nav-container w-100">{role?.id !== 3 && <SidebarNav />}</div>
          </div>

          <SidebarContacts />
        </div>
      ) : (
        <div className="fixed-bg-close">
          <Button
            className="sidebar-burger-btn d-none d-lg-block"
            onClick={() => {
              setIsSideBarOpen(true);
            }}
          >
            <img src={burgerMenu} alt="icon" />
          </Button>
        </div>
      )}
    </aside>
  );
};
