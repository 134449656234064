import React from 'react';

import flexIcon from '../../../accets/icons/flex.svg';
import kpiIcon from '../../../accets/icons/kpi.svg';
import levelIcon from '../../../accets/icons/level.svg';

import styles from './InfoModal.module.scss';

interface IInfoModalProps {
  creditName?: string;
}

export const InfoModal = ({ creditName }: IInfoModalProps) => {
  const { txt, credit, image, toTaskBtn, flex, kpi, level } = styles;

  let imageSrc;
  let iconClassName;

  switch (creditName) {
    case 'flex':
      imageSrc = flexIcon;
      iconClassName = flex;
      break;

    case 'kpi':
      imageSrc = kpiIcon;
      iconClassName = kpi;
      break;

    case 'level':
      imageSrc = levelIcon;
      iconClassName = level;
      break;
  }

  return (
    <>
      <img className={image} src={imageSrc} alt={creditName} />

      <p className={txt}>
        Чтобы повысить
        <span className={`${credit} ${iconClassName}`}>{creditName}</span>
        выполняйте задачи и получайте больше возможностей.
      </p>

      <button className={toTaskBtn}>Перейти к задачам</button>
    </>
  );
};
