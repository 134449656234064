import React, { useEffect, useState } from 'react';
import { PageContainer, PageTitle } from '../../constants/Styles/generalPageStyle';
import { CustomTable } from '../../components/CustomTable';
import {
  CustomTableCell,
  CustomTableHead,
  CustomTableHeaderCell,
  CustomTableRow,
} from '../../components/CustomTable/CustomTable.style';
import { TableRow } from '@material-ui/core';
import {
  GetBaseProjectTaskStatusesDocument,
  GetBaseProjectTaskStatusesQuery,
  GetLateTasksDocument,
  GetLateTasksQuery,
} from '../../generated/graphql';
import { useLazyQuery, useQuery } from '@apollo/client';
import { ProjectsNavbar } from './ProjectsNavbar/ProjectsNavbar';
import { useLocation } from 'react-router';
import { NavbarContainer, ProjectTaskStatusSelect, TableContainer } from './OverdueTasks.style';
import { DictionaryProjectTaskStatusSelect } from './ProjectStatusSelect/ProjectStatusSelect';
import { convertDateStringToLocaleHandler } from '../../constants/convertDateStringToLocalHandler';
import { LoadingDisplay } from '../../components/LoadingDisplay';
import { ErrorDisplay } from '../../components/ErrorDisplay';

export const tableHeaders = [
  {
    title: 'Задача',
    width: '241px',
  },
  {
    title: 'Исполнитель',
    width: '278px',
  },
  {
    title: 'Начало план',
    width: '90px',
  },
  {
    title: 'Конец план',
    width: '90px',
  },
  {
    title: 'Начало факт',
    width: '90px',
  },
  {
    title: 'Конец факт',
    width: '90px',
  },
  {
    title: 'Время задержки начала',
    width: '160px',
  },
  {
    title: 'Время задержки конца',
    width: '160px',
  },
  {
    title: 'Причина',
    width: '100%',
  },
];

export interface ProjectDataForRequest {
  projectId: string;
  statusId: string;
}

export const OverdueTasks = () => {
  const { search } = useLocation();
  const [projectData, setProjectData] = useState<ProjectDataForRequest>({
    projectId: '',
    statusId: '',
  });

  const [getTasks, { data: tasksData, loading: tasksLoading, error: tasksError }] =
    useLazyQuery<GetLateTasksQuery>(GetLateTasksDocument);

  const {
    data: statusesData,
    loading: statusesLoading,
    error: statusesError,
  } = useQuery<GetBaseProjectTaskStatusesQuery>(GetBaseProjectTaskStatusesDocument);

  useEffect(() => {
    if (search) {
      setProjectData({ ...projectData, projectId: search.substring(1) });
    }
  }, [search]);

  useEffect(() => {
    if (projectData.statusId && projectData.projectId) {
      getTasks({
        variables: {
          projectId: projectData.projectId,
          taskStatusId: projectData.statusId,
          isClient: true,
        },
      });
    }
  }, [projectData]);

  let selectContent: JSX.Element;
  let content: JSX.Element;

  if (tasksLoading || statusesLoading) {
    content = <LoadingDisplay />;
  }

  if (tasksError || statusesError) {
    content = <ErrorDisplay message={tasksError.message || statusesError.message} />;
  }

  if (statusesData) {
    const statuses = statusesData.getBaseProjectTaskStatuses;
    const status = statuses.find(status => status.name === 'inProgress');

    selectContent = (
      <ProjectTaskStatusSelect>
        <DictionaryProjectTaskStatusSelect
          label="Статус"
          values={statuses}
          currentStatus={status}
          projectData={projectData}
          setStatus={setProjectData}
        />
      </ProjectTaskStatusSelect>
    );
  }

  if (tasksData) {
    const tasks = tasksData.getLateTasks;

    content = (
      <table>
        <CustomTableHead>
          <TableRow>
            {tableHeaders.map((header, i) => (
              <CustomTableHeaderCell key={i} width={header.width}>
                {header.title}
              </CustomTableHeaderCell>
            ))}
          </TableRow>
        </CustomTableHead>

        <tbody>
          {tasks?.length
            ? tasks.map(task => (
                <CustomTableRow key={task.id}>
                  <CustomTableCell>{task.name}</CustomTableCell>
                  <CustomTableCell>{`${task.assigneeName}`}</CustomTableCell>
                  <CustomTableCell>{convertDateStringToLocaleHandler(task.startDateEstimate)}</CustomTableCell>
                  <CustomTableCell>{convertDateStringToLocaleHandler(task.finishDateEstimate)}</CustomTableCell>
                  <CustomTableCell>{convertDateStringToLocaleHandler(task.startDateFact)}</CustomTableCell>
                  <CustomTableCell>{convertDateStringToLocaleHandler(task.finishDateFact)}</CustomTableCell>
                  <CustomTableCell>{task.lateStart}</CustomTableCell>
                  <CustomTableCell>{task.lateFinish}</CustomTableCell>
                  <CustomTableCell>{task.lateReason}</CustomTableCell>
                </CustomTableRow>
              ))
            : null}
        </tbody>
      </table>
    );
  }

  return (
    <PageContainer>
      <ProjectsNavbar />

      <NavbarContainer>
        {selectContent}
        <PageTitle>Просроченные задачи по проектам</PageTitle>
      </NavbarContainer>

      {tasksData?.getLateTasks ? (
        <TableContainer>
          <CustomTable data={undefined} tableContent={content} />
        </TableContainer>
      ) : (
        <span>В этом проекте нет просроченных задач</span>
      )}
    </PageContainer>
  );
};
