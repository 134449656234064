import React from 'react';

import { Product, Purchase } from '../../generated/graphql';

import { ModalProductCard } from './ModalProductCard';
import { ProductsListProductCard } from './ProductsListProductCard';
import { PurchaseProductCard } from './PurchaseProductCard';
import { ShoppingCartProductCard } from './ShoppingCartProductCard';

export interface IProductCardProps {
  role?: string;
  product?: Product;
  purchase?: Purchase;
  addPurchase?(id: string | undefined): void;
  deletePurchase?(id: string): void;
}

export const ProductCard = (props: IProductCardProps) => {
  switch (props.role) {
    case 'modal':
      return <ModalProductCard />;

    case 'purchase':
      return <PurchaseProductCard {...props} />;

    case 'shoppingCart':
      return <ShoppingCartProductCard {...props} />;

    default:
      return <ProductsListProductCard {...props} />;
  }
};
