import React from 'react';
import {
  // Select,
  SelectContainer,
  SelectLabel,
  EmployeePhoto,
  ContentContainer,
  ErrorText,
} from './EmployeeSelect.style';

import MenuItem from '@material-ui/core/MenuItem';

import defaultUserPhoto from '../../../../accets/icons/defaultUserPhoto.svg';
import { User } from '../../../../generated/graphql';
import { RequiredFieldError, TaskData } from '../DashboardModal';
import Select from '@material-ui/core/Select';
import { ButtonImg, SelectButtonContainer } from '../LabelsSelect/LabelsSelect.style';
import { TransparentButton } from '../../../../constants/Styles/TransparentButton';
import arrow from '../../../../accets/icons/black-arrow.svg';

interface EmployeeSelectProps {
  selectLabel: string;
  users: User[];
  value: string;
  taskData: TaskData;
  setTaskData(obj: TaskData): void;
  field: string;
  error: RequiredFieldError;
  setError(state: RequiredFieldError): void;
}

export const EmployeeSelect = ({
  selectLabel,
  users,
  value,
  taskData,
  setTaskData,
  field,
  error,
  setError,
}: EmployeeSelectProps) => {
  const userPhoto = users.find(user => user.id === value)?.photo;

  return (
    <SelectContainer>
      <SelectLabel>{selectLabel}</SelectLabel>

      <ContentContainer error={field === 'assigneeId' ? error.assigneeIdError : error.responsibleIdError} url={arrow}>
        <EmployeePhoto src={userPhoto || defaultUserPhoto} alt="photo" />
        <Select
          name="select"
          value={value}
          onChange={e => {
            setTaskData({ ...taskData, [field]: e.target.value });
            setError(
              field === 'assigneeId' ? { ...error, assigneeIdError: false } : { ...error, responsibleIdError: false },
            );
          }}
        >
          <MenuItem value="" />
          {users.map(user => (
            <MenuItem key={user.id} value={user.id}>{`${user.firstName} ${user.lastName}`}</MenuItem>
          ))}
        </Select>
        {/*<SelectButtonContainer>*/}
        {/*  <TransparentButton>*/}
        {/*    <ButtonImg src={arrow} />*/}
        {/*  </TransparentButton>*/}
        {/*</SelectButtonContainer>*/}
        {(error.assigneeIdError || error.responsibleIdError) && <ErrorText>Обязательное поле</ErrorText>}
      </ContentContainer>
    </SelectContainer>
  );
};
