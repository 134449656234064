import React, { useContext } from 'react';

import { ShopTabContext } from '../../ShopTab/ShopTabContext';

import { IProductPanelProps } from '../ProductPanel';

import styles from '../ProductPanel.module.scss';

export const PersonalProductPanel = ({ addPurchase }: IProductPanelProps) => {
  const {
    card,
    card_noInfo,
    about,
    productImg,
    productImg_big,
    infoWrapper,
    infoWrapper_widthXl,
    title,
    title_marginSm,
    subtitle,
    addBtn,
    costList,
    costItem,
    balance,
    kpi,
    level,
    details,
    detailsTxt,
    additional,
    placeLink,
    noItem,
    noItemTxt,
  } = styles;

  const { chosenProduct } = useContext(ShopTabContext);

  const { id, type, image, name, description, features, flex, requiredLevel, requiredKpi, url, location } =
    chosenProduct || {};

  const isImpressionType = type?.displayName === 'impression';

  return (
    <div className={isImpressionType ? card : card_noInfo}>
      {chosenProduct ? (
        <>
          <div className={about}>
            {image && <img src={image} alt="product" className={isImpressionType ? productImg : productImg_big} />}

            <div className={isImpressionType ? infoWrapper : infoWrapper_widthXl}>
              <h2 className={isImpressionType ? title : title_marginSm}>{name}</h2>

              <button
                className={addBtn}
                type="button"
                onClick={() => {
                  addPurchase && addPurchase(id!);
                }}
              >
                Выбрать
              </button>
            </div>

            <ul className={costList}>
              <li className={`${costItem} ${balance}`}>{flex + ' Flex'}</li>
              <li className={`${costItem} ${level}`}>{requiredLevel}</li>
              <li className={`${costItem} ${kpi}`}>{requiredKpi}</li>
            </ul>
          </div>

          <div className={details}>
            {isImpressionType && description && <p className={detailsTxt}>{description}</p>}
            {features?.[0] !== '[]' && features?.length && (
              <>
                <h3 className={subtitle}>Дополнительная информация</h3>
                <ul className={additional}>
                  {features?.map(feature => (
                    <li className={detailsTxt} key={feature}>
                      {feature}
                    </li>
                  ))}
                </ul>
              </>
            )}
            {(url || location) && (
              <>
                <h3 className={subtitle}>Место проведения</h3>
                <a className={placeLink} href={url ? url : ''}>
                  {url}
                </a>
                <a className={placeLink} href={`https://www.google.com//maps/search/${location}`}>
                  {location}
                </a>
              </>
            )}
          </div>
        </>
      ) : (
        <div className={noItem}>
          <p className={noItemTxt}>Выберите товар</p>
        </div>
      )}
    </div>
  );
};
