import React from 'react';
import { NavLink, useParams } from 'react-router-dom';
import classNames from 'classnames';
import './ProjectScheduleFilter.scss';
import { useLocation } from 'react-router';

// interface ProjectScheduleFilterProps {
//   activeBtn: string;
//   setActiveBtn(btn: string): void;
//   displayByDay(): void;
//   displayByWeek(): void;
//   displayByMonth(): void;
// }

export const ProjectScheduleFilter = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const { search } = useLocation();

  return (
    <div className="d-flex">
      <NavLink
        to={`/project/${projectId}/project-schedule?days`}
        className={classNames({
          'project-reporting-btn': true,
          'active-btn': search.includes('days'),
        })}
        // onClick={() => {
        //   displayByDay();
        //   setActiveBtn('day');
        // }}
      >
        По дням
      </NavLink>
      <NavLink
        to={`/project/${projectId}/project-schedule?weeks`}
        className={classNames({
          'project-reporting-btn': true,
          'active-btn': search.includes('weeks'),
        })}
        // onClick={() => {
        //   displayByWeek();
        //   setActiveBtn('week');
        // }}
      >
        По неделям
      </NavLink>
      <NavLink
        to={`/project/${projectId}/project-schedule?month`}
        className={classNames({
          'project-reporting-btn': true,
          'active-btn': search.includes('month'),
        })}
      >
        По месяцам
      </NavLink>
    </div>
  );
};

// export const ProjectScheduleFilter = ({
//   activeBtn,
//   setActiveBtn,
//   displayByDay,
//   displayByWeek,
//   displayByMonth,
// }: ProjectScheduleFilterProps) => {
//   return (
//     <div className="d-flex">
//       <Button
//         className={classNames({
//           'project-reporting-btn': true,
//           'active-btn': activeBtn === 'day',
//         })}
//         onClick={() => {
//           displayByDay();
//           setActiveBtn('day');
//         }}
//       >
//         По дням
//       </Button>
//       <Button
//         className={classNames({
//           'project-reporting-btn': true,
//           'active-btn': activeBtn === 'week',
//         })}
//         onClick={() => {
//           displayByWeek();
//           setActiveBtn('week');
//         }}
//       >
//         По неделям
//       </Button>
//       <Button
//         className={classNames({
//           'project-reporting-btn': true,
//           'active-btn': activeBtn === 'month',
//         })}
//         onClick={() => {
//           displayByMonth();
//           setActiveBtn('month');
//         }}
//       >
//         По месяцам
//       </Button>
//     </div>
//   );
// };
