import styled from 'styled-components';

export const OptionListContainer = styled.div`
  position: absolute;
  top: 36px;
  left: 0;
  width: 100%;
  min-height: 50px;
  background: #ffffff;
  box-shadow: 0 0 10px rgba(16, 16, 16, 0.2);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 15px;
  z-index: 100;
`;

export const CustomOption = styled.div`
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #101010;
  margin-bottom: 20px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;
