import React, { useState, useContext } from 'react';

import { ShopTabContext } from '../../ShopTab/ShopTabContext';

import { IProductsListProps } from '../ProductsList';

import { ProductCard } from '../../ProductCard';

import styles from '../ProductsList.module.scss';

export const CharacterProductsList = ({ addPurchase, deletePurchase }: IProductsListProps) => {
  const { body, controls, switchBtn, active, list_sm, item_sm, chosen_sm, listWrapper } = styles;

  const { products, chosenProduct, choseProductHandler } = useContext(ShopTabContext);

  const [activeTab, setActiveTab] = useState('item');

  const tabProducts = products?.length ? products.filter(product => product.type?.displayName === activeTab) : [];

  const tabs = [
    {
      name: 'Одежда',
      type: 'clothes',
    },
    {
      name: 'Предметы',
      type: 'item',
    },
    {
      name: 'Танцы',
      type: 'dance',
    },
  ];

  return (
    <>
      <div className={body}>
        <ul className={controls}>
          {tabs.map(tab => {
            return (
              <li
                className={activeTab === tab.type ? active : switchBtn}
                key={tab.type}
                onClick={() => setActiveTab(tab.type)}
              >
                {tab.name}
              </li>
            );
          })}
        </ul>
        <div className={listWrapper}>
          <ul className={list_sm}>
            {tabProducts.map(product => {
              if (product) {
                return (
                  <li
                    className={chosenProduct && product.id === chosenProduct.id ? chosen_sm : item_sm}
                    key={product.id}
                    onClick={() => {
                      product.id && choseProductHandler(product.id);
                    }}
                  >
                    <ProductCard
                      role="character"
                      product={product}
                      // buyProduct={buyProduct}
                      // currentProduct={currentProduct}
                    />
                  </li>
                );
              }
            })}
          </ul>
        </div>
      </div>
    </>
  );
};
