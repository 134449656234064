import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import copyIcon from '../../accets/icons/copy-icon.svg';
import './LinkInput.scss';

interface LinkInputProps {
  linkValue: string;
}

export const LinkInput = ({ linkValue }: LinkInputProps) => {
  const [t] = useTranslation('translation');
  const inputRef = useRef<HTMLInputElement>(null);
  const [isCopyAlert, setIsCopyAlert] = useState<string>('');

  const copyToClipboard = (): void => {
    setIsCopyAlert('copied');
    inputRef?.current?.select();
    document.execCommand('copy');

    setTimeout(() => setIsCopyAlert(''), 2000);
  };

  return (
    <div className="link-input-container d-flex align-items-center position-relative">
      <input className="link-input" ref={inputRef} readOnly value={linkValue ? linkValue : ''} />
      <span className="input-value  position-absolute">{linkValue}</span>
      <Button className="link-input-btn position-absolute" onClick={copyToClipboard} id="mainSiteLogin">
        <span className="input-alert position-absolute">{t(isCopyAlert)}</span>
        <img className="link-input-img" src={copyIcon} alt="icon" />
      </Button>
    </div>
  );
};
