import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import TRANSLATIONS_UA from './locales/ua/translation.json';
import TRANSLATIONS_RU from './locales/ru/translation.json';
import TRANSLATIONS_EN from './locales/en/translation.json';

// the translations
const resources = {
  en: {
    translation: TRANSLATIONS_EN,
  },
  ru: {
    translation: TRANSLATIONS_RU,
  },
  ua: {
    translation: TRANSLATIONS_UA,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: 'ru',

    debug: true,

    fallbackLng: 'ru',

    keySeparator: false,

    interpolation: {
      escapeValue: false,
    },

    ns: ['translation'],

    defaultNS: 'translation',
  });

export default i18n;
