import React, { useState, useContext } from 'react';

import { ShopTabContext } from '../../ShopTab/ShopTabContext';

import { IProductsListProps } from '../ProductsList';

import { ProductCard } from '../../ProductCard';

import styles from '../ProductsList.module.scss';

export const PersonalProductsList = ({ addPurchase }: IProductsListProps) => {
  const { body, controls, switchBtn, active, list, list_sm, item, item_sm, chosen, chosen_sm, listWrapper } = styles;

  const { products, chosenProduct, choseProductHandler } = useContext(ShopTabContext);

  const [activeTab, setActiveTab] = useState('impression');

  const tabProducts = products?.length ? products.filter(product => product.type?.displayName === activeTab) : [];

  const tabs = [
    {
      name: 'Мерч',
      type: 'merch',
    },
    {
      name: 'Впечатления',
      type: 'impression',
    },
    {
      name: 'Другое',
      type: 'other',
    },
  ];

  return (
    <div className={body}>
      <ul className={controls}>
        {tabs.map(tab => {
          return (
            <li
              className={activeTab === tab.type ? active : switchBtn}
              key={tab.type}
              onClick={() => setActiveTab(tab.type)}
            >
              {tab.name}
            </li>
          );
        })}
      </ul>

      <div className={listWrapper}>
        {activeTab === 'impression' ? (
          <ul className={list}>
            {tabProducts.map(product => {
              if (product) {
                return (
                  <li
                    className={chosenProduct && product.id === chosenProduct.id ? chosen : item}
                    key={product.id}
                    onClick={() => {
                      product.id && choseProductHandler(product.id);
                    }}
                  >
                    <ProductCard product={product} addPurchase={addPurchase} />
                  </li>
                );
              }
            })}
          </ul>
        ) : (
          <ul className={list_sm}>
            {tabProducts.map(product => {
              if (product) {
                return (
                  <li
                    className={chosenProduct && product.id === chosenProduct.id ? chosen_sm : item_sm}
                    key={product.id}
                    onClick={() => {
                      product.id && choseProductHandler(product.id);
                    }}
                  >
                    <ProductCard product={product} addPurchase={addPurchase} />
                  </li>
                );
              }
            })}
          </ul>
        )}
      </div>
    </div>
  );
};
