import React from 'react';
import { Notification } from '../../components/Notification';
import { Footer } from '../../components/Footer';
import { useMediaQuery } from 'react-responsive';
//import { IUserInitialState } from '../../store/reducers/userReducer';
//import { INotificationsState, requestNotifications } from '../../store/reducers/notificationsReducer';

export const Notifications = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 992px)',
  });

  return (
    <>
      <div className="page-container">
        {/*<div className="notification-container" style={{ paddingTop: '12px' }}>*/}
        {/*  {!isDesktopOrLaptop &&*/}
        {/*    notifications &&*/}
        {/*    notifications.map(notification => (*/}
        {/*      <Notification*/}
        {/*        key={notification.id}*/}
        {/*        title={notification.title}*/}
        {/*        text={notification.text}*/}
        {/*        data={notification.data}*/}
        {/*        status={notification.status}*/}
        {/*      />*/}
        {/*    ))}*/}
        {/*</div>*/}
      </div>
      <h1>Notifiaction</h1>
    </>
  );
};
