import React, { useContext, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { useHistory } from 'react-router';

import { UserInfoContext } from '../../pages/Main/Main';

import { useLazyQuery } from '@apollo/client';
import {
  GetKnowledgeBaseCategoriesByParticipantDocument,
  GetKnowledgeBaseCategoriesByParticipantQuery,
  GetKnowledgeBaseThemesByCategoryIdDocument,
  GetKnowledgeBaseThemesByCategoryIdQuery,
} from '../../generated/graphql';

import { ErrorDisplay } from '../ErrorDisplay';
import { LoadingDisplay } from '../LoadingDisplay';

import { Box, Collapse, List, ListItem } from '@material-ui/core';

interface IKnowledgeBaseListProps {
  isTheme: boolean;
  categoryId: string;
  setCategoryId(categoryId: string): void;
}

export const KnowledgeBaseList = ({ categoryId, setCategoryId, isTheme }: IKnowledgeBaseListProps) => {
  const { id: userId } = useContext(UserInfoContext);
  const [t] = useTranslation('translation');
  const history = useHistory();

  const [getCategories, { data: categoriesData, loading: categoriesLoading, error: categoriesError }] =
    useLazyQuery<GetKnowledgeBaseCategoriesByParticipantQuery>(GetKnowledgeBaseCategoriesByParticipantDocument, {
      variables: {
        userId,
        limit: 100,
        skip: 0,
      },
    });

  const [getThemes, { data: themesData, loading: themesLoading, error: themesError }] =
    useLazyQuery<GetKnowledgeBaseThemesByCategoryIdQuery>(GetKnowledgeBaseThemesByCategoryIdDocument, {
      variables: {
        categoryId,
        isPublished: true,
      },
    });

  useEffect(() => {
    if (isTheme) {
      categoryId && getThemes();
      return;
    }

    getCategories();
  }, [categoryId]);

  let content;

  if (themesLoading || categoriesLoading) {
    content = <LoadingDisplay />;
  }

  if (themesError || categoriesError) {
    content = <ErrorDisplay message={themesError ? themesError.message : categoriesError.message} />;
  }

  if (themesData) {
    const items = themesData.getKnowledgeBaseThemesByCategoryId;

    content = (
      <List>
        {items.map(({ id, name }) => (
          <ListItem key={id} button divider onClick={() => history.push(`/knowledge-base/theme/${name}/${id}`)}>
            {name}
          </ListItem>
        ))}
      </List>
    );
  }

  if (categoriesData) {
    const items = categoriesData.getKnowledgeBaseCategoriesByParticipant.categories;

    content = (
      <List>
        {items.map(({ category: { id, name } }) => (
          <ListItem key={id} button divider selected={categoryId === id} onClick={() => setCategoryId(id)}>
            {name}
          </ListItem>
        ))}
      </List>
    );
  }

  return (
    <Box flexBasis="48%">
      <h2 className="sidebar-title">{isTheme ? t('themes') : t('categories')}</h2>

      <Collapse in={isTheme ? !!themesData : !!categoriesData}>{content}</Collapse>
    </Box>
  );
};
